import { IColumns, ISearch, getDefaultColumnProps } from "../../../app/common/table/utils";
import { IReportData } from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";



const getDayStatsColumns = (
    searchState: ISearch,
    setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
  ) => {
    const columns: IColumns<IReportData> = [
      {
        ...getDefaultColumnProps("solicitud", "Solicitud", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("paciente", "Paciente", {
          searchState,
          setSearchState,
          width: "35%",
        }),
      },
      {
        ...getDefaultColumnProps("estatus", "Estatus", {
          searchState,
          setSearchState,
          width: "25%",
        }),
      },
      {
          ...getDefaultColumnProps("total", "Total", {
              width: "20%",
            }),
            render: (value) => moneyFormatter.format(value),
        },
        {
        ...getDefaultColumnProps("cia", "CIA", { 
            searchState,
            setSearchState,
            width: "35%",
        }),
        },
    ];
  
    return columns;
  };
  
  export default getDayStatsColumns;