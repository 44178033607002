import "../../utils/styles.css";

import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { IInvoice, IInvoiceDetail } from "../../../../../app/models/invoice";
import { useStore } from "../../../../../app/stores/store";
import { InvoiceType, isRequestCompanyType } from "../../utils/utils";
import InvoiceConceptDetail from "./InvoiceConceptDetail";
import InvoiceConceptForm from "./InvoiceConceptForm";
import InvoiceConceptHeader from "./InvoiceConceptHeader";
import InvoiceConceptType from "./InvoiceConceptType";

const { Title } = Typography;

export interface IConcept {
  alias: string;
  descripcion: string;
}

type Props = {
  id: string | undefined;
  type: InvoiceType;
  series: string | undefined;
  seriesNo: string | undefined;
  invoice?: IInvoice;
  detail: IInvoiceDetail[];
  setDetail: React.Dispatch<React.SetStateAction<IInvoiceDetail[]>>;
};

const InvoiceConcept = ({
  id,
  type,
  series,
  seriesNo,
  invoice,
  detail,
  setDetail,
}: Props) => {
  const { invoiceRequestCompanyStore } = useStore();
  const { getDetailByRequests } = invoiceRequestCompanyStore;

  const { state }: { state: any } = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [detailType, setDetailType] = useState("detailed");
  // prettier-ignore
  const [detailedConcepts, setDetailedConcepts] = useState<IInvoiceDetail[]>([]);
  const [branchConcepts, setBranchConcepts] = useState<IInvoiceDetail[]>([]);
  const [patientConcepts, setPatientConcepts] = useState<IInvoiceDetail[]>([]);
  const [totalConcepts, setTotalConcepts] = useState<IInvoiceDetail[]>([]);

  const requests = state && state.requests ? state.requests : undefined;

  const isNew = !id;

  useEffect(() => {
    const getDetail = async () => {
      const detail = await getDetailByRequests(type as any, requests);
      setDetailedConcepts(detail!.desglozado);
      setBranchConcepts(detail!.sucursal);
      setPatientConcepts(detail!.paciente);
      setTotalConcepts(detail!.total);

      if (
        type === "company" ||
        (detail!.desglozado && detail!.desglozado.length > 0)
      ) {
        setDetail(detail!.desglozado);
      } else if (type === "request") {
        setDetail(detail!.sucursal);
        setDetailType("branch");
      }
    };

    if (isNew && requests && isRequestCompanyType(type)) {
      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    if (detailType === "detailed") {
      setDetail(detailedConcepts);
    } else if (detailType === "branch") {
      setDetail(branchConcepts);
    } else if (detailType === "patient") {
      setDetail(patientConcepts);
    } else if (detailType === "total") {
      setDetail(totalConcepts);
    } else {
      setDetail([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailType]);

  const addDetail = (values: IInvoiceDetail) => {
    const unitPrice = values.importe;
    // prettier-ignore
    const subUnitPrice = Number((unitPrice / (1 + values.tasaIva)).toFixed(2));
    const price = values.unidades * values.importe;
    const subPrice = Number((price / (1 + values.tasaIva)).toFixed(2));

    setDetail((detail) => [
      ...detail,
      {
        ...values,
        importeUnitario: unitPrice,
        subtotalUnitario: subUnitPrice,
        importe: price,
        subtotal: subPrice,
        id: uuid(),
        claveProdServ:
          "85121800 - Laboratorios médicos (Servicios de análisis clínicos)",
      },
    ]);
  };

  return (
    <>
      <Title level={5}>Detalle de la factura</Title>
      {isNew && isRequestCompanyType(type) && (
        <InvoiceConceptType
          type={type}
          validDetailed={detailedConcepts && detailedConcepts.length > 0}
          detailType={detailType}
          setDetailType={setDetailType}
        />
      )}
      {isNew && type !== "request" && (
        <InvoiceConceptForm addDetail={addDetail} />
      )}
      <InvoiceConceptHeader series={series} seriesNo={seriesNo} />
      <InvoiceConceptDetail
        isNew={isNew}
        type={type}
        invoice={invoice}
        detail={detail}
        setDetail={setDetail}
      />
    </>
  );
};

export default observer(InvoiceConcept);
