import { Button, Col, Row, Select, Table, Tooltip, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState, useRef } from "react";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../../app/common/table/utils";
import { IOptions } from "../../../../app/models/shared";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import { moneyFormatter } from "../../../../app/util/utils";
import {
  IAppointmentPack,
  IAppointmentStudy,
  IAppointmentStudyUpdate,
} from "../../../../app/models/appointment";
import InfoStudy from "../../../request/detail/InfoModal/InfoStudy";
import HeaderTitle from "../../../../app/common/header/HeaderTitle";

const { Link, Text } = Typography;

const AppointmentStudy = () => {
  const { appointmentStore, optionStore, modalStore } = useStore();
  const { openModal } = modalStore;
  const { studyOptions, packOptions, getStudyOptions, getPackOptions } =
    optionStore;
  const {
    isStudy,
    readonly,
    appointment,
    studies,
    packs,
    studyFilter,
    getPriceStudy,
    getPricePack,
    deleteStudies,
    changeStudyPromotion,
    changePackPromotion,
    setDisabledClave,
    focusClave,
    focusType,
    setFocusType,
  } = appointmentStore;

  const [selectedStudies, setSelectedStudies] = useState<
    (IAppointmentStudy | IAppointmentPack)[]
  >([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const claveRef = useRef<any>(null);
  const searchRef = useRef<any>(null);

  useEffect(() => {
    getStudyOptions();
    getPackOptions();
    setDisabledClave(false);
  }, [getPackOptions, getStudyOptions]);

  useEffect(() => {}, [studies, packs]);
  useEffect(() => {
    if (focusType == "clave") claveRef?.current?.focus();
    else if (focusType == "search") searchRef?.current?.focus();
  }, [focusClave]);

  useEffect(() => {
    const options: IOptions[] = [
      {
        value: "study",
        label: "Estudios",
        options: studyOptions,
      },
      {
        value: "pack",
        label: "Paquetes",
        options: packOptions,
      },
    ];

    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packOptions, studyOptions]);

  const showStudyDetails = async (id: number, estudio: string) => {
    openModal({
      title: <HeaderTitle image={"infoStudy"} title="Ficha técnica" />,
      body: (
        <InfoStudy id={id} originBranch={""} destinationBranch={""}></InfoStudy>
      ),
      width: 1000,
    });
  };

  const columns: IColumns<IAppointmentStudy | IAppointmentPack> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: 100,
      }),
      // render: (value) => <Link>{value}</Link>,
      render: (value, item) => {
        if (isStudy(item)) {
          return (
            <Link
              onClick={() => {
                showStudyDetails(item.estudioId!, item.nombre);
              }}
            >
              {value}
            </Link>
          );
        } else {
          return <Text>{value}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("nombre", "Estudios", {
        searchState,
        setSearchState,
        width: 200,
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (value, item) => {
        let content = "";
        if (isStudy(item)) {
          content = `${value} (${item.parametros
            .map((x) => x.clave)
            .join(", ")})`;
        } else {
          content = `${value} (${item.estudios
            .flatMap((x) => x.parametros)
            .map((x) => x.clave)
            .join(", ")})`;
        }

        content = content.replace(" ()", "");

        return (
          <Tooltip placement="topLeft" title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      ...getDefaultColumnProps("precio", "Precio", {
        searchable: false,
        width: 85,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("dias", "Días", {
        searchable: false,
        width: 70,
      }),
    },
    {
      ...getDefaultColumnProps("promocionId", "Promoción", {
        searchable: false,
        width: 200,
      }),
      render: (value, item) =>
        item.promociones && item.promociones.length > 0 ? (
          <Select
            options={item.promociones.map((x) => ({
              value: x.promocionId,
              label: `${x.promocion} (${x.descuentoPorcentaje}%)`,
            }))}
            disabled={readonly}
            value={value}
            bordered={false}
            style={{ width: "100%" }}
            allowClear
            placeholder="Seleccionar promoción"
            onChange={(promoId?: number) => {
              if (isStudy(item)) {
                changeStudyPromotion(item, promoId);
              } else {
                changePackPromotion(item, promoId);
              }
            }}
          />
        ) : (
          "Sin promociones disponibles"
        ),
    },
    {
      ...getDefaultColumnProps("precioFinal", "Precio Final", {
        searchable: false,
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    Table.SELECTION_COLUMN,
  ];

  const addStudy = async (option: IOptions) => {
    const value = parseInt(option.value.toString().split("-")[1]);

    if (isNaN(value)) return;

    if (option.group === "study") {
      await getPriceStudy(value, studyFilter);
    }

    if (option.group === "pack") {
      await getPricePack(value, studyFilter);
    }
  };

  const addStudyClick = async (option: string) => {
    const value = parseInt(option.toString().split("-")[1]);
    const type = option.toString().split("-")[0];

    if (isNaN(value)) return;

    if (type === "study") {
      await getPriceStudy(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }

    if (type === "pack") {
      await getPricePack(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }
  };

  const cancel = () => {
    if (appointment) {
      alerts.confirm(
        "Remover estudios",
        `¿Desea remover los registros seleccionados?`,
        async () => {
          const data: IAppointmentStudyUpdate = {
            citaId: appointment.citaId,
            estudios: selectedStudies.filter(
              (x) => x.type === "study"
            ) as IAppointmentStudy[],
            paquetes: selectedStudies.filter(
              (x) => x.type === "pack"
            ) as IAppointmentPack[],
          };
          const ok = await deleteStudies(data);
          if (ok) {
            setSelectedStudies([]);
            setSelectedRowKeys([]);
          }
        }
      );
    }
  };

  const selectRowCheckbox = (
    selectedRows: (IAppointmentStudy | IAppointmentPack)[]
  ) => {
    setSelectedStudies(selectedRows);
    setSelectedRowKeys(
      selectedRows.map((x) => `${x.type}-${x.id ?? x.identificador!}`)
    );
  };

  const handleSelect = (value: any, option: any) => {
    setFocusType("search");
    addStudyClick(value);
    searchRef.current.blur();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Row gutter={[8, 8]}>
      {!readonly && (
        <Col span={24} style={{ textAlign: "end" }}>
          <Select
            showSearch
            value={[]}
            mode="multiple"
            placeholder="Buscar Estudios"
            optionFilterProp="children"
            style={{ width: "90%", textAlign: "left" }}
            filterOption={(input: string, option: any) => {
              if (input.indexOf("-") > -1) {
                const value = input.split("-")[0];
                return (
                  option.label.toLowerCase().split("-")[0] ===
                  value.toLowerCase() + " "
                );
              }
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            options={options}
            onSelect={handleSelect}
            onInputKeyDown={handleKeyDown}
            ref={searchRef}
          />
        </Col>
      )}
      <Col span={24}>
        <Table<IAppointmentStudy | IAppointmentPack>
          size="small"
          rowKey={(record) =>
            `${record.type}-${record.id ?? record.identificador!}`
          }
          columns={columns}
          dataSource={[...studies, ...packs]}
          pagination={false}
          rowSelection={{
            onChange: (_selectedRowKeys, selectedRows) => {
              selectRowCheckbox(selectedRows);
            },
            getCheckboxProps: () => ({
              disabled: readonly,
            }),
            selectedRowKeys: selectedRowKeys,
          }}
          sticky
          scroll={{ x: "fit-content" }}
        />
      </Col>
      <Col span={24} style={{ textAlign: "end" }}>
        {!readonly && (
          <Button
            danger
            onClick={cancel}
            disabled={selectedStudies.length === 0}
          >
            Remover estudios
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default observer(AppointmentStudy);
