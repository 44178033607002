import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { useStore } from "../app/stores/store";
import QuotationFilter from "../components/quotation/list/QuotationFilter";
import QuotationHeader from "../components/quotation/list/QuotationHeader";
import QuotationTable from "../components/quotation/list/QuotationTable";
import { Navigate } from "react-router-dom";

const Quotation = () => {
  const { quotationStore } = useStore();
  const { scopes } = quotationStore;

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <QuotationHeader />
      <Divider className="header-divider" />
      <QuotationFilter />
      <QuotationTable />
    </Fragment>
  );
};

export default observer(Quotation);
