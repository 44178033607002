import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import { CloseOutlined, PrinterOutlined } from "@ant-design/icons";

import { verifyCancelPermission } from "../../../../app/common/administrator/cancelValidation";
import IconButton from "../../../../app/common/button/IconButton";
import NumberInput from "../../../../app/common/form/proposal/NumberInput";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { getDefaultColumnProps, IColumns, ISearch } from "../../../../app/common/table/utils";
import {
  IRequestInvoiceDownload,
  IRequestPayment,
  IRequestPaymentInvoice,
  RequestPaymentClass,
} from "../../../../app/models/request";
import { IFormError, IOptions, IValidationPermission } from "../../../../app/models/shared";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import { catalog, status } from "../../../../app/util/catalogs";
import { moneyFormatter } from "../../../../app/util/utils";
import RequestInvoiceTab from "./invoice/RequestInvoiceTab";

const { Text } = Typography;

const LSpreferredTermian = "lab-ramos-preferred-terminal";

const RequestRegister = () => {
  const { requestStore, optionStore, modalStore, profileStore, equipmentStore, procedingStore } =
    useStore();
  const { profile } = profileStore;
  const { getMonedero } = procedingStore;
  const {
    filteredPaymentOptions,
    receiptSeriesOptions,
    getPaymentOptions,
    getReceiptSeriesOptions,
  } = optionStore;
  const { getByBranch } = equipmentStore;
  const {
    readonly,
    request,
    payments,
    totals,
    createPayment,
    reprintPayment,
    cancelPayment,
    cancelPaymentInvoice,
    updateSeries,
    downloadInvoicesXml,
    downloadInvoicesPdf,
    scopes,
  } = requestStore;
  const { openModal } = modalStore;

  const [form] = Form.useForm<IRequestPayment>();

  const paymentForm = Form.useWatch("formaPagoId", form);
  const terminal = Form.useWatch("terminal", form);

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [terminals, setTerminals] = useState<IOptions[]>([]);
  const [selectedPayments, setSelectedPayments] = useState<IRequestPayment[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [canUseTerminal, setCanUseTerminal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    getPaymentOptions();
  }, [getPaymentOptions]);

  useEffect(() => {
    const readTerminals = async () => {
      const terminals = await getByBranch(profile!.sucursal, catalog.equipmentCategory.terminal);
      setTerminals(
        terminals.map((x) => ({
          value: `${x.clave} (${x.noSerie})`,
          label: `${x.clave} (${x.noSerie})`,
        }))
      );
    };

    readTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile!.sucursal]);

  useEffect(() => {
    setCanUseTerminal(
      paymentForm === catalog.paymentForms.tarjetaCredito ||
        paymentForm === catalog.paymentForms.tarjetaDebito
    );
  }, [paymentForm]);

  useEffect(() => {
    if (request && request.sucursalId) getReceiptSeriesOptions(request.sucursalId);
  }, [getReceiptSeriesOptions, request]);

  const columns: IColumns<IRequestPayment> = [
    {
      ...getDefaultColumnProps("id", "#", {
        searchable: false,
        width: 50,
      }),
      render: (_value, _record, index) => index + 1,
    },
    {
      ...getDefaultColumnProps("formaPago", "Forma de Pago", {
        searchState,
        setSearchState,
        width: 200,
      }),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      ...getDefaultColumnProps("cantidad", "Cantidad", {
        searchState,
        setSearchState,
        width: 125,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("usuarioRegistra", "Usuario", {
        searchState,
        setSearchState,
        width: 220,
      }),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      ...getDefaultColumnProps("fechaPago", "Fecha", {
        searchable: false,
        width: 100,
      }),
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      ...getDefaultColumnProps("facturas", "Factura", {
        searchState,
        setSearchState,
        width: 150,
      }),
      render(value?: IRequestPaymentInvoice[]) {
        return !value
          ? ""
          : value.map((x) => (
              <Text
                key={x.serieFactura}
                style={{ display: "block" }}
                type={x.estatus === status.invoice.facturado ? undefined : "danger"}
              >
                {x.serieFactura}
              </Text>
            ));
      },
    },
    {
      key: "reprint",
      dataIndex: "id",
      width: 30,
      align: "center",
      fixed: "right",
      render: (_value, item) => {
        const payment = new RequestPaymentClass(item);
        return !readonly && payment.esPagoNetPay ? (
          <Popconfirm
            title="¿Desea reimprimir el ticket de NetPay?"
            okText="Sí"
            cancelText="No"
            trigger="click"
            placement="left"
            onConfirm={() => reprint(item)}
            disabled={!scopes.imprimir}
          >
            <IconButton icon={<PrinterOutlined />} />
          </Popconfirm>
        ) : (
          ""
        );
      },
    },
    {
      key: "cancel",
      dataIndex: "estatusId",
      width: 30,
      align: "center",
      fixed: "right",
      render: (value, item) => {
        if (item.pagoCompañia) {
          return <Tag>C</Tag>;
        }

        const payed = value === status.requestPayment.pagado;
        const invoiced = value === status.requestPayment.facturado;

        if (readonly || (!payed && !invoiced)) return "";

        let invoice: IRequestPaymentInvoice | undefined;
        if (invoiced) {
          invoice = item.facturas?.find((x) => x.estatus === status.invoice.facturado);
        }
        const message = payed
          ? "¿Desea cancelar el pago?"
          : `¿Desea cancelar la factura ${invoice?.serieFactura}?`;

        return (
          <Popconfirm
            title={message}
            okText="Sí"
            cancelText="No"
            trigger="click"
            placement="left"
            onConfirm={() => cancel(item, invoiced)}
            disabled={!scopes.imprimir}
          >
            <IconButton icon={<CloseOutlined />} danger />
          </Popconfirm>
        );
      },
    },
    Table.SELECTION_COLUMN,
  ];

  const onFinish = async (values: IRequestPayment) => {
    if (buttonDisabled) return;

    if (values.cantidad === 0) {
      alerts.warning("Por favor ingresa la cantidad");
      return;
    }

    setButtonDisabled(true);
    setErrors([]);
    const payment: IRequestPayment = {
      ...values,
      expedienteId: request!.expedienteId,
      solicitudId: request!.solicitudId!,
    };
    payment.formaPago = filteredPaymentOptions
      .find((x) => x.value === payment.formaPagoId)!
      .label!.toString();
    payment.folio = !!values.folio
      ? values.folio
      : request!.clave +
        (payments.length + 1).toLocaleString("es-MX", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
    payment.esRecuperacion = !!values.folio;

    const ok = await createPayment(payment);
    setButtonDisabled(false);
    await getMonedero(request!.expedienteId ?? "");

    if (ok) {
      form.resetFields();
    }
  };

  const cancel = async (payment: IRequestPayment, isInvoice: boolean) => {
    if (buttonDisabled) return;

    setButtonDisabled(true);
    let permission: IValidationPermission | undefined;
    if (!profile) {
      setButtonDisabled(false);
      return;
    }
    if (!profile.cancelaPagos) {
      permission = await verifyCancelPermission();
      if (permission && !permission.permiso) {
        alerts.warning("No cuenta con los privilegios para realizar esta acción");
        setButtonDisabled(false);
        return;
      }
    }
    if (request && (profile.cancelaPagos || (permission && permission.permiso))) {
      payment.usuarioCancelaId = permission?.usuarioId;
      if (!isInvoice) {
        await cancelPayment(request.expedienteId, request.solicitudId!, payment);
        await getMonedero(request!.expedienteId ?? "");
      } else {
        await cancelPaymentInvoice(request.expedienteId, request.solicitudId!, payment);
      }
      setButtonDisabled(false);
    } else {
      setButtonDisabled(false);
    }
  };

  const reprint = async (payment: IRequestPayment) => {
    if (buttonDisabled) return;

    setButtonDisabled(true);
    if (request) {
      await reprintPayment(payment);
    }
    setButtonDisabled(false);
  };

  const checkin = () => {
    openModal({
      title: "Datos Fiscales",
      body: (
        <RequestInvoiceTab
          recordId={request!.expedienteId}
          requestId={request!.solicitudId!}
          branchId={request!.sucursalId}
          payments={selectedPayments.filter((x) => x.estatusId === status.requestPayment.pagado)}
        />
      ),
      width: 900,
    });
    setSelectedPayments([]);
  };

  const downloadXML = async () => {
    const payments: IRequestInvoiceDownload = {
      solicitudId: request!.solicitudId!,
      expedienteId: request!.expedienteId,
      pagos: selectedPayments.map((x) => x.id),
    };
    setLoading(true);
    await downloadInvoicesXml(payments);
    setLoading(false);
  };

  const downloadPDF = async () => {
    const payments: IRequestInvoiceDownload = {
      solicitudId: request!.solicitudId!,
      expedienteId: request!.expedienteId,
      pagos: selectedPayments.map((x) => x.id),
    };
    setLoading(true);
    await downloadInvoicesPdf(payments);
    setLoading(false);
  };

  const onSeriesChange = (value: string) => {
    alerts.confirm(
      "¿Desea actualizar la serie?",
      `Se cambiará la serie a ${value} y se actualizará el consecutivo`,
      async () => {
        if (!request) return;
        const req = { ...request };
        req.serie = value;
        await updateSeries(req);
      }
    );
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 12]} align="bottom" style={{ marginBottom: 8 }}>
        <Col span={4}>
          <div style={{ height: 24 }}>
            <label>Series</label>
          </div>
          <Select
            showSearch
            placeholder={"Serie"}
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={request?.serie}
            showArrow={true}
            options={receiptSeriesOptions}
            style={{ width: "100%" }}
            onChange={onSeriesChange}
            disabled={readonly}
          ></Select>
        </Col>
        <Col span={3}>
          <Input autoComplete="off" type={"text"} value={request?.serieNumero} disabled />
        </Col>
      </Row>
      <Row gutter={[8, 12]}>
        {!readonly && (scopes.crear || scopes.modificar) && (
          <Col span={24}>
            <Form<IRequestPayment>
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={({ errorFields }) => {
                const errors = errorFields.map((x) => ({
                  name: x.name[0].toString(),
                  errors: x.errors,
                }));
                setErrors(errors);
              }}
              initialValues={{
                cantidad: 0,
                terminal: localStorage.getItem(LSpreferredTermian) ?? undefined,
              }}
              size="small"
              disabled={totals.saldo <= 0}
            >
              <Row gutter={[8, 12]} align="bottom">
                <Col span={4}>
                  <SelectInput
                    formProps={{
                      name: "formaPagoId",
                      label: "Forma de Pago",
                    }}
                    options={filteredPaymentOptions.filter(
                      (o) => o.label !== "02 Cheque nominativo"
                    )}
                    errors={errors.find((x) => x.name === "formaPagoId")?.errors}
                    required
                  />
                </Col>
                <Col span={3}>
                  <NumberInput
                    formProps={{
                      name: "cantidad",
                      label: "Cantidad",
                    }}
                    // min={0}
                    max={totals.saldo}
                    formatter={(value) => {
                      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }}
                    parser={(value) => {
                      const val = value!.replace(/\$\s?|(,*)/g, "");
                      return Number(val);
                    }}
                    errors={errors.find((x) => x.name === "cantidad")?.errors}
                    required
                    precision={2}
                  />
                </Col>
                <Col span={4}>
                  <TextInput
                    formProps={{
                      name: "numeroCuenta",
                      label: "Número de cuenta",
                    }}
                    max={20}
                  />
                </Col>
                {canUseTerminal && (
                  <Col span={4}>
                    <SelectInput
                      formProps={{
                        name: "terminal",
                        label: "Terminal",
                      }}
                      options={terminals}
                      onChange={(value) => {
                        if (value) {
                          localStorage.setItem(LSpreferredTermian, value);
                          return;
                        }
                        localStorage.removeItem(LSpreferredTermian);
                      }}
                    />
                  </Col>
                )}
                {terminal?.toLowerCase()?.includes("netpay") && (
                  <Col span={3}>
                    <TextInput
                      formProps={{
                        name: "folio",
                        label: "Folio",
                      }}
                      max={15}
                    />
                  </Col>
                )}
                <Col
                  span={canUseTerminal ? (terminal?.toLowerCase()?.includes("netpay") ? 6 : 9) : 13}
                  style={{ textAlign: "right" }}
                >
                  <Button htmlType="submit" type="primary">
                    Registrar Pago
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
        {!readonly && (
          <Col span={24}>
            <Table<IRequestPayment>
              size="small"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={payments.filter(
                (x) =>
                  x.estatusId === status.requestPayment.pagado ||
                  x.estatusId === status.requestPayment.facturado
              )}
              pagination={false}
              rowSelection={{
                fixed: "right",
                onChange(_selectedRowKeys, selectedRows, info) {
                  if (info.type === "single") {
                    setSelectedPayments(toJS(selectedRows));
                    return;
                  }

                  let toUpdate: IRequestPayment[];

                  // prettier-ignore
                  if (selectedRows.every((x) => x.estatusId === status.requestPayment.facturado) ||
                    selectedRows.every((x) => x.estatusId === status.requestPayment.pagado && x.formaPagoId === catalog.paymentForms.monederoElectronico) ||
                    selectedRows.every((x) => x.estatusId === status.requestPayment.pagado && x.formaPagoId !== catalog.paymentForms.monederoElectronico)) {
                  toUpdate = toJS(selectedRows);
                  } else {
                    toUpdate = selectedRows.filter(x => x.estatusId === status.requestPayment.pagado && x.formaPagoId !== catalog.paymentForms.monederoElectronico);
                  }

                  // prettier-ignore
                  if(toUpdate.length === selectedPayments.length && toUpdate.every(x => selectedPayments.map(y => y.id).includes(x.id))){
                    toUpdate = []  
                  }

                  setSelectedPayments(toUpdate);
                },
                selectedRowKeys: selectedPayments.map((x) => x.id),
              }}
              sticky
              scroll={{ x: "fit-content" }}
            />
          </Col>
        )}
        {!readonly && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              disabled={
                scopes.modificar &&
                scopes.crear &&
                (selectedPayments.length === 0 ||
                  !selectedPayments.every(
                    (x) =>
                      x.estatusId === status.requestPayment.pagado &&
                      x.formaPagoId !== catalog.paymentForms.monederoElectronico
                  ))
              }
              onClick={checkin}
            >
              Facturar
            </Button>
            <Button
              type="primary"
              disabled={
                selectedPayments.length === 0 ||
                !selectedPayments.every((x) => x.estatusId === status.requestPayment.facturado)
              }
              onClick={downloadXML}
            >
              Descargar XML
            </Button>
            <Button
              type="primary"
              disabled={
                selectedPayments.length === 0 ||
                !selectedPayments.every((x) => x.estatusId === status.requestPayment.facturado)
              }
              onClick={downloadPDF}
            >
              Descargar PDF
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Divider orientation="left" className="register-cancelled-divider">
            Pagos Cancelados
          </Divider>
          <Table<IRequestPayment>
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={payments.filter((x) => x.estatusId === status.requestPayment.cancelado)}
            pagination={false}
            rowSelection={{
              fixed: "right",
              renderCell(value, record, index, originNode) {
                return null;
              },
              hideSelectAll: true,
            }}
            sticky
            scroll={{ x: "fit-content" }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(RequestRegister);
