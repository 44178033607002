import React, { Fragment, useEffect } from "react";
import SeriesDetailHeader from "./SeriesDetailHeader";
import SeriesForm from "./SeriesForm";
import { Divider } from "antd";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

type SeriesParams = {
  id: string;
  tipoSerie: string;
};

const SeriesDetail = () => {
  const { seriesStore } = useStore();
  const { scopes } = seriesStore;

  const { id, tipoSerie } = useParams<SeriesParams>();

  if (!scopes?.acceder) return <Navigate to="/forbidden" />;

  return (
    <Fragment>
      <SeriesDetailHeader tipoSerie={tipoSerie!} />
      <Divider className="header-divider" />
      <SeriesForm id={Number(id)} tipoSerie={Number(tipoSerie)} />
    </Fragment>
  );
};

export default observer(SeriesDetail);
