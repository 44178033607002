import { IInvoice } from "../models/invoice";
import { IInvoiceFreeFilter, IInvoiceFreeList } from "../models/invoiceFree";
import requests from "./agent";

const InvoiceFree = {
  getAll: (filter: IInvoiceFreeFilter): Promise<IInvoiceFreeList[]> =>
    requests.post("invoiceFree/filter", filter),
  create: (invoiceDto: IInvoice): Promise<IInvoiceFreeList> =>
    requests.post(`invoiceFree/create`, invoiceDto),
  downloadList: (filter: IInvoiceFreeFilter): Promise<void> =>
    requests.download(`invoiceFree/download/excel/list`, filter),
};

export default InvoiceFree;
