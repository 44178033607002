import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { resolve } from "path";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../../../app/stores/store";
import { guidPattern } from "../../../app/util/utils";
import LoyaltyForm from "./LoyaltyForm";
import LoyaltyFormHeader from "./LoyaltyFormHeader";

type UrlParams = {
  id: string;
};

const LoyaltyDetail = () => {
  const { loyaltyStore } = useStore();
  const { scopes, exportForm } = loyaltyStore;

  const [printing, setPrinting] = useState(false);

  const { id } = useParams<UrlParams>();
  const loyaltyId = !id ? "" : !guidPattern.test(id) ? undefined : id;

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      return new Promise((resolve: any) => {
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    if (loyaltyId) {
      setPrinting(true);
      await exportForm(loyaltyId);
      setPrinting(false);
    }
  };

  if (loyaltyId == null) return <Navigate to="/forbidden" />;

  if (!scopes?.acceder) return <Navigate to="/forbidden" />;

  return (
    <Fragment>
      <LoyaltyFormHeader
        id={loyaltyId}
        handlePrint={handlePrint}
        handleDownload={handleDownload}
      />
      <Divider className="header-divider" />
      <LoyaltyForm
        id={loyaltyId}
        componentRef={componentRef}
        printing={printing}
      />
    </Fragment>
  );
};

export default observer(LoyaltyDetail);
