import { Table, Spin, Row, Col, Button, Typography } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  ISearch,
  IColumns,
  getDefaultColumnProps,
} from "../../../../app/common/table/utils";
import {
  IRequestPack,
  IRequestStudy,
  IRequestStudyUpdate,
} from "../../../../app/models/request";
import { useStore } from "../../../../app/stores/store";
import { status } from "../../../../app/util/catalogs";
import { isUndefined } from "lodash";
const { Text } = Typography;

const RequestSampler = () => {
  const { requestStore } = useStore();
  const {
    readonly,
    request,
    allStudies,
    sendStudiesToSampling,
    studies,
    packs,
    isStudy,
    isPack,
    scopes,
  } = requestStore;

  const [selectedStudiesAll, setSelectedStudiesAll] = useState<IRequestStudy[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [selectedStudies, setSelectedStudies] = useState<
    { identificador: string }[]
  >([]);
  useEffect(() => {
    setSelectedStudiesAll([]);
    setSelectedStudies([]);
  }, []);

  const OnSelect = (
    request: IRequestStudy | IRequestPack,
    selected: boolean,
    subPackIgnore: boolean
  ) => {
    if (selected) {
      const exists =
        selectedStudies.findIndex(
          (x) =>
            x.identificador === request.identificador ?? request.id?.toString()
        ) > -1;
      if (exists) {
        setSelectedStudies((prev) =>
          prev.map((x) => {
            if (
              x.identificador === request.identificador ??
              request?.id?.toString()
            ) {
              return {
                ...x,
              };
            }
            return x;
          })
        );
      } else {
        setSelectedStudies((prev) => [
          ...prev,
          {
            identificador:
              request.identificador ?? request?.id?.toString() ?? "",
          },
        ]);
        if (isStudy(request)) {
          setSelectedStudiesAll((prev) => [request, ...prev]);
        } else {
          let currentPack: IRequestPack | undefined;
          currentPack = packs.find((o) => o.id === request.id);
          if (isUndefined(currentPack))
            currentPack = packs.find(
              (o) => o.identificador === request.identificador
            );

          currentPack?.estudios
            .filter(
              (es) =>
                es.estatusId === status.requestStudy.tomaDeMuestra ||
                es.estatusId === status.requestStudy.pendiente
            )
            .map((es) => {
              OnSelect(es, true, false);
            });
        }
      }
    } else {
      const id = request.identificador ?? request.id?.toString();
      setSelectedStudies((prev) => prev.filter((x) => x.identificador !== id));
      setSelectedStudiesAll((prev) =>
        prev.filter((x) => x.identificador !== id)
      );
      if (isPack(request)) {
        let currentPack: IRequestPack | undefined;
        currentPack = packs.find((o) => o.id === request.id);
        if (isUndefined(currentPack))
          currentPack = packs.find(
            (o) => o.identificador === request.identificador
          );
        if (!subPackIgnore) {
          currentPack?.estudios.map((es) => {
            OnSelect(es, false, true);
          });
        }
      } else {
        if (!subPackIgnore) {
          packs.forEach((pa) => {
            if (
              pa.estudios.findIndex(
                (o) => o.identificador === request.identificador
              ) > -1
            ) {
              return OnSelect(pa, false, true);
            }
          });
        }
      }
    }
  };

  const getColumns = (isSubTable: boolean) => {
    let columnsStudies: IColumns<IRequestStudy | IRequestPack> = [];
    if (isSubTable) {
      return (columnsStudies = [
        {
          key: "taponColor",
          dataIndex: "taponColor",
          title: "",
          width: 48,
          align: "center",
          render: (value) => <ContainerBadge color={value} />,
        },
        ...columns,
        {
          key: "taponColor",
          dataIndex: "taponColor",
          title: "",
          width: 8,
          align: "center",
          render: (value) => <ContainerBadge color={value} />,
        },
        Table.SELECTION_COLUMN,
      ]);
    } else {
      return (columnsStudies = [...columns, Table.SELECTION_COLUMN]);
    }
  };

  const columns: IColumns<IRequestStudy | IRequestPack> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "15%",
      }),
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.clave}
            </Text>
          );
        } else {
          return <Text>{item.clave}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("nombre", "Estudio", {
        searchState,
        setSearchState,
        width: "35%",
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.nombre}
            </Text>
          );
        } else {
          return <Text>{item.nombre}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => {
        if (value === "Cancelado") return <Text type="danger">{value}</Text>;
        else return <Text>{value}</Text>;
      },
    },
    {
      ...getDefaultColumnProps("dias", "Días", {
        searchable: false,
        width: "10%",
      }),
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.dias}
            </Text>
          );
        } else {
          return <Text>{item.dias}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("fechaTomaMuestra", "Fecha toma", {
        searchable: false,
        width: "15%",
      }),
      render: (value, item) => {
        return !value ? "" : moment(value).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      ...getDefaultColumnProps("fechaEntrega", "Fecha de entrega", {
        searchable: false,
        width: "15%",
      }),
      className: "no-padding-cell",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
  ];

  //Expandible
  const expandableConfig = () => {
    const isIRequestPack = (
      record: IRequestStudy | IRequestPack
    ): record is IRequestPack => {
      return (record as IRequestPack).type === "pack";
    };

    return {
      expandedRowRender: (
        item: IRequestStudy | IRequestPack,
        index: number
      ) => {
        if (isIRequestPack(item)) {
          return (
            <Table
              rowKey={(record) => `${record.identificador ?? record.id}`}
              columns={getColumns(true)}
              dataSource={item.estudios}
              pagination={false}
              className="tableEstudios"
              showHeader={false}
              style={{ padding: 0 }}
              rowSelection={{
                fixed: "right",
                onSelect(request, selected) {
                  OnSelect(request, selected, false);
                },

                selectedRowKeys: selectedStudies.map((x) => x.identificador),

                getCheckboxProps: (record) => ({
                  disabled:
                    (readonly ||
                      (record.estatusId !== status.requestStudy.tomaDeMuestra &&
                        record.estatusId !== status.requestStudy.pendiente)) &&
                    isStudy(record),
                }),
              }}
            />
          );
        }
        return null;
      },
      rowExpandable: (record: IRequestStudy | IRequestPack) => {
        return isIRequestPack(record);
      },
      defaultExpandAllRows: true,
    };
  };

  const updateStudies = async () => {
    if (request) {
      const data: IRequestStudyUpdate = {
        expedienteId: request.expedienteId,
        solicitudId: request.solicitudId!,
        estudios: selectedStudiesAll,
      };

      setLoading(true);
      const ok = await sendStudiesToSampling(data);
      if (ok) {
        setSelectedStudies([]);
        setSelectedStudiesAll([]);
      }
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 12]}>
        {!readonly && scopes.modificar && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="default"
              disabled={
                selectedStudiesAll.length === 0 ||
                !selectedStudiesAll.every(
                  (x) => x.estatusId === status.requestStudy.tomaDeMuestra
                )
              }
              onClick={updateStudies}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              disabled={
                selectedStudiesAll.length === 0 ||
                !selectedStudiesAll.every(
                  (x) => x.estatusId === status.requestStudy.pendiente
                )
              }
              onClick={updateStudies}
            >
              Toma de muestra
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Table<IRequestStudy | IRequestPack>
            size="small"
            rowKey={(record) => `${record.identificador ?? record.id}`}
            columns={getColumns(false)}
            dataSource={[...studies, ...packs]}
            className="tableEstudios"
            pagination={false}
            rowSelection={{
              fixed: "right",
              onSelect(request, selected) {
                OnSelect(request, selected, false);
              },
              onChange(_, selectedRows, info) {
                if (info.type === "all") {
                  if (selectedRows.length === 0) {
                    setSelectedStudies([]);
                    setSelectedStudiesAll([]);
                  } else {
                    let studiesList: IRequestStudy[] = [];
                    let list: { identificador: string }[] = [];

                    packs.forEach((pack) => {
                      const someStudyValid = pack.estudios.some(
                        (study) =>
                          study.estatusId === status.requestStudy.pendiente ||
                          study.estatusId === status.requestStudy.tomaDeMuestra
                      );

                      if (someStudyValid) {
                        list.push({
                          identificador:
                            pack.identificador ?? pack.id?.toString() ?? "",
                        });

                        pack.estudios.forEach((study) => {
                          if (
                            study.estatusId === status.requestStudy.pendiente ||
                            study.estatusId ===
                              status.requestStudy.tomaDeMuestra
                          ) {
                            list.push({
                              identificador:
                                study.identificador ??
                                study.id?.toString() ??
                                "",
                            });
                            studiesList.push(study);
                          }
                        });
                      }
                    });

                    studies.forEach((study) => {
                      if (
                        study.estatusId === status.requestStudy.pendiente ||
                        study.estatusId === status.requestStudy.tomaDeMuestra
                      ) {
                        list.push({
                          identificador:
                            study.identificador ?? study.id?.toString() ?? "",
                        });
                        studiesList.push(study);
                      }
                    });

                    setSelectedStudiesAll((prev) => [
                      ...prev.filter((x) =>
                        studiesList.some(
                          (y) => y.identificador === x.identificador
                        )
                      ),
                      ...studiesList,
                    ]);
                    setSelectedStudies(list);
                  }
                }
              },
              getCheckboxProps: (record) => ({
                disabled:
                  (readonly ||
                    (record.estatusId !== status.requestStudy.pendiente &&
                      record.estatusId !== status.requestStudy.tomaDeMuestra) ||
                    !record.asignado ||
                    !record.id) &&
                  scopes.modificar &&
                  isStudy(record),
              }),
              selectedRowKeys: selectedStudies.map((x) => x.identificador),
            }}
            sticky
            scroll={{ x: "fit-content" }}
            expandable={expandableConfig()}
          />
        </Col>
      </Row>
    </Spin>
  );
};
const ContainerBadge = ({ color }: { color: string }) => {
  return (
    <div className="badge-container" style={{ backgroundColor: color }}></div>
  );
};
export default observer(RequestSampler);
