import { Spin, Form, Row, Col, Button } from "antd";
import { FC, useEffect, useState } from "react";
import TextInput from "../../../app/common/form/proposal/TextInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import { useStore } from "../../../app/stores/store";
import { IMedicsForm } from "../../../app/models/medics";
import { observer } from "mobx-react-lite";
import MaskInput from "../../../app/common/form/proposal/MaskInput";

type Props = {
  url: string;
  getResult: (response: IMedicsForm | undefined) => void;
};

const MedicsForm: FC<Props> = ({ url, getResult }) => {
  const { medicsStore, optionStore } = useStore();
  const { createFromUrl, scopes } = medicsStore;
  const { fieldOptions, getfieldsOptions } = optionStore;

  const [form] = Form.useForm<IMedicsForm>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fieldOptions.length === 0) getfieldsOptions();
  }, [fieldOptions.length, getfieldsOptions]);

  const onFinish = async (newValues: IMedicsForm) => {
    const medics = { ...newValues };

    setLoading(true);
    const medic = await createFromUrl(url, medics);
    setLoading(false);

    getResult(medic);
  };

  const onValuesChange = async (_: any, values: IMedicsForm) => {
    const code =
      (values.nombre.substring(0, 3) ?? "") +
      (values.primerApellido?.substring(0, 1) ?? "") +
      (values.segundoApellido?.substring(0, 1) ?? "");

    form.setFieldsValue({ clave: code.toUpperCase() });
  };

  return (
    <Spin spinning={loading}>
      <Form<IMedicsForm>
        form={form}
        name="medics"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        scrollToFirstError
        labelWrap
        layout="vertical"
      >
        <Row justify="center" gutter={[12, 12]}>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Clave",
              }}
              max={100}
              required
              readonly={true}
              type="string"
            />
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "nombre",
                label: "Nombre",
              }}
              max={50}
              required
            />
          </Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "primerApellido",
                label: "Primer Apellido",
              }}
              max={50}
              required
            />
          </Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "segundoApellido",
                label: "Segundo Apellido",
              }}
              max={100}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              formProps={{
                name: "especialidadId",
                label: "Especialidad",
              }}
              options={fieldOptions}
            />
          </Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "correo",
                label: "Correo",
              }}
              max={100}
              type="email"
            />
          </Col>
          <Col span={12}>
            <MaskInput
              formProps={{
                name: "celular",
                label: "Celular",
              }}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
              ]}
              validator={(_, value: any) => {
                if (!value || value.indexOf("_") === -1) {
                  return Promise.resolve();
                }
                return Promise.reject("El campo debe contener 10 dígitos");
              }}
            />
          </Col>
          <Col span={12}>
            <MaskInput
              formProps={{
                name: "telefono",
                label: "Teléfono",
              }}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
              ]}
              validator={(_, value: any) => {
                if (!value || value.indexOf("_") === -1) {
                  return Promise.resolve();
                }
                return Promise.reject("El campo debe contener 10 dígitos");
              }}
            />
          </Col>
          <Col span={12}></Col>
          {scopes.crear && (
            <Col span={24} style={{ textAlign: "end" }}>
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(MedicsForm);
