import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, {
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ILoyaltyForm } from "../app/models/loyalty";
import { useStore } from "../app/stores/store";
import LoyaltyHeader from "../components/loyalty/LoyaltyHeader";
import LoyaltyTable from "../components/loyalty/LoyaltyTable";

const Loyalty = () => {
  const { loyaltyStore } = useStore();
  const { scopes, exportList } = loyaltyStore;

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    setLoading(true);
    await exportList(searchParams.get("search") ?? "all");
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <LoyaltyHeader
        handlePrint={handlePrint}
        handleDownload={handleDownload}
        // loyalty={[]} setFilteredContacts={function (value: SetStateAction<ILoyaltyForm[]>): void {
        //   throw new Error("Function not implemented."); } }
      />
      <Divider className="header-divider" />
      <LoyaltyTable id="" componentRef={componentRef} printing={loading} />
    </Fragment>
  );
};

export default observer(Loyalty);
