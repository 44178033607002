import { PageHeader, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import HeaderTitle from "../../app/common/header/HeaderTitle";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import { useStore } from "../../app/stores/store";

const { Text } = Typography;

type UserHeaderProps = {
  handleList: () => void;
};
const ClinicResultsHeader: FC<UserHeaderProps> = ({ handleList }) => {
  const { clinicResultsStore } = useStore();
  const { data, scopes } = clinicResultsStore;
  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title={`Captura de resultados (Clínicos)`} image="portapapeles" />}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
      className="header-container"
      extra={[
        <Text key="request">
          Solicitudes: <Text strong>{data.length <= 0 ? 0 : data.length}</Text>
        </Text>,
        <Text key="studies">
          Estudios: <Text strong>{data.flatMap((x) => x.estudios).length}</Text>
        </Text>,
        scopes.descargar && <DownloadIcon key="doc" onClick={handleList} />,
      ]}
    ></PageHeader>
  );
};

export default observer(ClinicResultsHeader);
