import { IGeneralForm } from "../models/general";
import {
  IAppointment,
  IAppointmentEvent,
  IAppointmentGeneral,
  IAppointmentInfo,
  IAppointmentStudyUpdate,
  IAppointmentTotal,
} from "../models/appointment";
import requests from "./agent";

const Appointment = {
  getAppointments: (filter: IGeneralForm): Promise<IAppointmentInfo[]> =>
    requests.post("appointment/filter", filter),
  getActive: (): Promise<IAppointmentInfo[]> =>
    requests.get("appointment/active"),
  getById: (appointmentId: string): Promise<IAppointment> =>
    requests.get(`appointment/${appointmentId}`),
  getSchedule: (filter: IGeneralForm): Promise<IAppointmentEvent[]> =>
    requests.post(`appointment/filter/schedule`, filter),
  getGeneral: (appointmentId: string): Promise<IAppointmentGeneral> =>
    requests.get(`appointment/general/${appointmentId}`),
  getStudies: (appointmentId: string): Promise<IAppointmentStudyUpdate> =>
    requests.get(`appointment/studies/${appointmentId}`),
  sendTestEmail: (appointmentId: string, emails: string[]): Promise<void> =>
    requests.post(`appointment/email/${appointmentId}`, emails),
  sendTestWhatsapp: (appointmentId: string, phones: string[]): Promise<void> =>
    requests.post(`appointment/whatsapp/${appointmentId}`, phones),
  create: (appointment: IAppointment): Promise<string> =>
    requests.post("appointment", appointment),
  schedule: (schedule: IAppointmentEvent): Promise<void> =>
    requests.post("appointment/schedule", schedule),
  convertToRequest: (appointmentId: string): Promise<string> =>
    requests.post(`appointment/convert/${appointmentId}`, {}),
  updateBranch: (appointment: IAppointment): Promise<string> =>
    requests.put("appointment/branch", appointment),
  updateGeneral: (appointment: IAppointmentGeneral): Promise<void> =>
    requests.put("appointment/general", appointment),
  reschedule: (schedule: IAppointmentEvent): Promise<void> =>
    requests.put("appointment/reschedule", schedule),
  updateStatus: (appointment: IAppointmentInfo): Promise<void> =>
    requests.put("appointment/update/status", appointment),
  assignRecord: (appointmentId: string, recordId?: string) =>
    requests.put(`appointment/assign/${appointmentId}/${recordId ?? ""}`, {}),
  updateTotals: (appointment: IAppointmentTotal): Promise<void> =>
    requests.put("appointment/totals", appointment),
  updateStudies: (
    appointment: IAppointmentStudyUpdate
  ): Promise<IAppointmentStudyUpdate> =>
    requests.post("appointment/studies", appointment),
  cancelAppointment: (appointmentId: string): Promise<void> =>
    requests.put(`appointment/cancel/${appointmentId}`, {}),
  deleteAppointment: (appointmentId: string): Promise<void> =>
    requests.delete(`appointment/delete/${appointmentId}`),
  deleteSchedule: (scheduleId: string): Promise<void> =>
    requests.delete(`appointment/delete/schedule/${scheduleId}`),
  deleteStudies: (appointment: IAppointmentStudyUpdate): Promise<void> =>
    requests.put("appointment/studies/cancel", appointment),
  printTicket: (appointmentId: string): Promise<void> =>
    requests.print(`appointment/ticket/${appointmentId}`),
  getQuotePdfUrl: (id: string): Promise<string> =>
    requests.getFileUrl(`appointment/quote/${id}`, "application/pdf"),
};

export default Appointment;
