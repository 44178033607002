import { PageHeader, Input } from "antd";
import React, { FC, useEffect, useState } from "react";
import { IContactForm } from "../../../app/models/contact";
import HeaderTitle from "../../../app/common/header/HeaderTitle";

const { Search } = Input;
//const contactos = contacts.filter(x => x.Nombre == value || x.activo == value || x.telefono == value);

type CompanyFormTableHeaderProps = {
  handleCompanyPrint: () => void;
  contacts: IContactForm[];
  setFilteredContacts: React.Dispatch<React.SetStateAction<IContactForm[]>>;
};

const CompanyFormTableHeader: FC<CompanyFormTableHeaderProps> = ({
  contacts,
  setFilteredContacts,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setFilteredContacts(
      contacts.filter(
        (x) =>
          x.nombre.toLowerCase().includes(searchValue.toLowerCase()) ||
          x.telefono?.toString()?.includes(searchValue)
      )
    );
  }, [contacts, searchValue, setFilteredContacts]);

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Contactos" image="contacto" />}
      className="header-container"
      extra={[
        <Search
          style={{ marginRight: 20, textAlign: "left" }}
          key="search"
          placeholder="Buscar"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />,
      ]}
    ></PageHeader>
  );
};

export default CompanyFormTableHeader;
