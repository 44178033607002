import { Divider, Tabs } from "antd";
import PriceListFormStudies from "./prices/PriceListFormStudies";
import PriceListFormPacks from "./prices/PriceListFormPacks";
import { IPriceListPack, IPriceListStudy } from "../../../app/models/priceList";
import { observer } from "mobx-react-lite";

type Props = {
  studies: IPriceListStudy[];
  setStudies: (studies: IPriceListStudy[]) => void;
  packs: IPriceListPack[];
  setPacks: (packs: IPriceListPack[]) => void;
  readonly: boolean;
};

const PriceListFormPrices = ({
  studies,
  packs,
  setStudies,
  setPacks,
  readonly,
}: Props) => {
  const tabItems = [
    {
      key: "studies",
      label: "Estudios",
      children: (
        <PriceListFormStudies
          readonly={readonly}
          studies={studies}
          setStudies={setStudies}
        />
      ),
    },
    {
      key: "packs",
      label: "Paquetes",
      children: (
        <PriceListFormPacks
          readonly={readonly}
          packs={packs}
          setPacks={setPacks}
        />
      ),
    },
  ];

  return (
    <div>
      <Divider style={{ marginBottom: 0 }} orientation="left">
        Estudios y paquetes
      </Divider>
      <Tabs defaultActiveKey="studies" items={tabItems} />
    </div>
  );
};

export default observer(PriceListFormPrices);
