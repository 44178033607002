import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { Navigate } from "react-router-dom";

import { useStore } from "../app/stores/store";
import DeliveryResultsForm from "../components/deliveryResults/DeliveryResultsForm";
import DeliveryResultsHeader from "../components/deliveryResults/DeliveryResultsHeader";
import DeliveryResultsTable from "../components/deliveryResults/DeliveryResultsTable";

const { useReactToPrint } = require("react-to-print");

const DeliveryResults = () => {
  const { deliveryResultsStore, generalStore } = useStore();
  const { generalFilter } = generalStore;
  const { exportListDeliverResult, loadingStudies, scopes } = deliveryResultsStore;
  const [loading, setLoading] = useState(false);
  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    await exportListDeliverResult(generalFilter);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <DeliveryResultsHeader handleDownload={handleDownload} handlePrint={handlePrint} />
        {/* <Divider className="header-divider" /> */}
        <DeliveryResultsForm />
      </div>
      <div style={{ flex: "1 1 0%" }}>
        <DeliveryResultsTable componentRef={componentRef} />
      </div>
    </div>
  );
};
export default observer(DeliveryResults);
