import { makeAutoObservable } from "mobx";
import Tag from "../api/tag";
import { ITagForm } from "../models/tag";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import history from "../util/history";
import messages from "../util/messages";
import responses from "../util/responses";
import { getErrors } from "../util/utils";

export default class TagStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  tags: ITagForm[] = [];

  getAll = async (search: string) => {
    try {
      const tags = await Tag.getAll(search);
      this.tags = tags;
      return tags;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.tags = [];
      return [];
    }
  };

  getById = async (id: number) => {
    try {
      const tag = await Tag.getById(id);
      return tag;
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };

  create = async (tag: ITagForm) => {
    try {
      const newTag = await Tag.create(tag);
      alerts.success(messages.created);
      this.tags = [...this.tags, newTag];
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  update = async (tag: ITagForm) => {
    try {
      const updatedTag = await Tag.update(tag);
      alerts.success(messages.updated);
      const id = this.tags.findIndex((x) => x.id === tag.id);
      if (id !== -1) {
        const tags = [...this.tags];
        tags[id] = updatedTag;
        this.tags = tags;
      }
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  exportList = async (search: string) => {
    try {
      await Tag.exportList(search);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportForm = async (id: number) => {
    try {
      await Tag.exportForm(id);
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };
}
