import { Button, PageHeader, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import views from "../../../app/util/view";
import { appointmentTypeOptions } from "../../../app/stores/optionStore";
import { ReactNode, useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";

type Props = {
  type: "L" | "D";
  setType: (type: "L" | "D") => void;
};

const AppointmentHeader = ({ type, setType }: Props) => {
  const { appointmentStore } = useStore();
  const { scopes } = appointmentStore;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [header, setHeader] = useState<ReactNode>();

  useEffect(() => {
    const type = searchParams.get("type");
    if (type && (type.toLowerCase() === "l" || type.toLowerCase() === "d")) {
      setType(type as "L" | "D");
    } else {
      setType("L");
    }
  }, [searchParams, setType]);

  useEffect(() => {
    const header =
      type === "L" ? (
        <HeaderTitle title={`Imagenología`} image="cita" />
      ) : (
        <HeaderTitle title={`Citas a domicilio`} image="domicilio" />
      );
    setHeader(header);
  }, [type]);

  const handleChange = async (value: string) => {
    if (value) {
      searchParams.set("type", value);
    } else {
      searchParams.delete("type");
    }
    setSearchParams(searchParams);
  };

  return (
    <PageHeader
      ghost={false}
      title={header}
      className="header-container"
      extra={[
        <Select
          key="type"
          showSearch
          placeholder="Tipo de cita"
          optionFilterProp="children"
          defaultValue={searchParams.get("type")}
          onChange={handleChange}
          filterOption={(input: string, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={type}
          allowClear={false}
          style={{ width: 180 }}
          options={appointmentTypeOptions}
        ></Select>,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate(`/${views.appointment}/new?type=${type}`);
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default AppointmentHeader;
