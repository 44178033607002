import {
  Button,
  Col,
  InputNumber,
  message,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { UploadOutlined } from "@ant-design/icons";

import {
  IInvoiceCredit,
  IInvoiceCreditCreate,
} from "../../../app/models/invoiceCredit";
import { useStore } from "../../../app/stores/store";
import { objectToFormData, uploadFakeRequest } from "../../../app/util/utils";
import Scopes from "../../../app/common/scopes/Scopes";

type Props = {
  invoice: IInvoiceCredit;
};

const CreditMonitoringInvoiceForm = ({ invoice }: Props) => {
  const { modalStore, invoiceCreditStore } = useStore();
  const { closeModal } = modalStore;
  const { create, scopes } = invoiceCreditStore;

  const [loading, setLoading] = useState(false);
  const [manualTotal, setManualTotal] = useState(0);
  const [xmlFile, setXmlFile] = useState<RcFile>();
  const [pdfFile, setPdfFile] = useState<RcFile>();

  const onChangeFile = (
    fileType: "xml" | "pdf",
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const { status } = info.file;

    if (status === "uploading") {
      return;
    } else if (status === "done") {
      if (fileType === "xml") {
        setXmlFile(info.file.originFileObj);
      } else {
        setPdfFile(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`Error al cargar el archivo ${info.file.name}.`);
    }
  };

  const onRemoveFile = (fileType: "xml" | "pdf") => {
    if (fileType === "xml") {
      setXmlFile(undefined);
    } else {
      setPdfFile(undefined);
    }
  };

  const props = (type: "xml" | "pdf"): UploadProps => {
    const config: UploadProps = {
      name: "file",
      showUploadList: true,
      customRequest: uploadFakeRequest,
      onChange: (info) => onChangeFile(type, info),
      onRemove: () => onRemoveFile(type),
      accept: `.${type}`,
      multiple: false,
      maxCount: 1,
    };
    return config;
  };

  const createPayment = async () => {
    if (manualTotal === 0 && (!xmlFile || xmlFile.name.slice(-4) !== ".xml")) {
      message.error("El archivo xml no es válido");
      return;
    }
    if (manualTotal === 0 && (!pdfFile || pdfFile.name.slice(-4) !== ".pdf")) {
      message.error("El archivo pdf no es válido");
      return;
    }

    const data: IInvoiceCreditCreate = {
      facturaId: invoice.facturaId,
      montoPago: manualTotal,
      xml: xmlFile,
      pdf: pdfFile,
    };
    const formData = objectToFormData(data);

    setLoading(true);
    const ok = await create(formData);
    setLoading(false);

    if (ok) {
      closeModal();
    }
  };

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <Space direction="vertical">
            <Upload {...props("xml")}>
              <Button icon={<UploadOutlined />}>
                Subir complemento de pago (.xml)
              </Button>
            </Upload>
            <Upload {...props("pdf")}>
              <Button icon={<UploadOutlined />}>
                Subir complemento de pago (.pdf)
              </Button>
            </Upload>
          </Space>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <InputNumber<number>
            key={"montoPago"}
            formatter={(value) =>
              `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
            value={manualTotal}
            onChange={(value) => {
              setManualTotal(value ?? 0);
            }}
            controls={false}
            min={0}
            max={99999999}
            style={{
              width: 120,
              marginRight: 12,
            }}
          />
          <Button
            type="primary"
            onClick={createPayment}
            disabled={loading || !scopes.crear}
          >
            Generar
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(CreditMonitoringInvoiceForm);
