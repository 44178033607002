import { Col, Descriptions, Row } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useStore } from "../../../../../app/stores/store";

type Props = {
  series: string | undefined;
  seriesNo: string | undefined;
};

const InvoiceConceptHeader = ({ series, seriesNo }: Props) => {
  const { profileStore } = useStore();
  const { profile } = profileStore;

  const [currentTime, setCurrentTime] = useState<string>();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().utcOffset(0, true).format("HH:mm:ss"));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Descriptions column={6} size="small" className="invoice-detail">
          <Descriptions.Item label="Documento">
            {series ?? "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Consecutivo">
            {seriesNo ?? "-"}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Usuario">
            {profile!.nombre}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha">
            {moment().utcOffset(0, true).format("L")}
          </Descriptions.Item>
          <Descriptions.Item label="Hora">{currentTime}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default observer(InvoiceConceptHeader);
