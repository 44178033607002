import { Typography } from "antd";

import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IReportData } from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";

const { Text } = Typography;

const getInvoiceColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: 120,
      }),
    },
    {
      dataIndex: "tipo",
      title: "",
      width: 40,
      align: "center",
    },
    {
      ...getDefaultColumnProps("fechaCreacion", "Fecha de creación", {
        width: 150,
      }),
    },
    {
      ...getDefaultColumnProps("serie", "Serie de factura", {
        searchState,
        setSearchState,
        width: 155,
      }),
      render: (_, invoice) => `${invoice.serie} ${invoice.serieNumero}`,
    },
    {
      ...getDefaultColumnProps("rfc", "RFC", {
        searchState,
        setSearchState,
        width: 140,
      }),
    },
    {
      ...getDefaultColumnProps("razonSocial", "Razón Social", {
        searchState,
        setSearchState,
        width: 300,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("formaPago", "Método de Pago", {
        searchState,
        setSearchState,
        width: 180,
      }),
    },
    {
      ...getDefaultColumnProps("subtotal", "Subtotal", {
        width: 110,
      }),
      align: "right",
      render: (subtotal, invoice) =>
        invoice.cancelado ? "-" : moneyFormatter.format(subtotal ?? 0),
    },
    {
      ...getDefaultColumnProps("iva", "IVA", {
        width: 90,
      }),
      align: "right",
      render: (taxes, invoice) =>
        invoice.cancelado ? "-" : moneyFormatter.format(taxes ?? 0),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        width: 110,
      }),
      align: "right",
      render: (total, invoice) =>
        invoice.cancelado ? "-" : moneyFormatter.format(total ?? 0),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        width: 110,
      }),
      align: "right",
      render: (outBalance, invoice) =>
        invoice.cancelado ? "-" : moneyFormatter.format(outBalance ?? 0),
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        width: 100,
      }),
      render: (_, invoice) => (
        <Text type={invoice.cancelado ? "danger" : undefined}>
          {!invoice.cancelado ? "Vigente" : "Cancelado"}
        </Text>
      ),
    },
    {
      ...getDefaultColumnProps("uuid", "UUID", {
        width: 320,
      }),
    },
  ];

  return columns;
};

export default getInvoiceColumns;
