import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { set } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import TextInput from "../../app/common/form/proposal/TextInput";
import {
  IInvoicesFreeFilter,
  InvoicesFreeFilterValues,
} from "../../app/models/invoiceLegacy";
import { IOptions } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";
import { formItemLayout } from "../../app/util/utils";
import { categoryType, creditEstatus, creditTimeEstatus } from "./utils";

const CreditMonitoringForm = () => {
  const { profileStore, optionStore, invoiceCompanyStoreLegacy: invoiceCompanyStore } = useStore();
  const { profile } = profileStore;
  const { isLoading, getInvoicesCreditNote, invoicesCreditNote, setInvoicePaymentAddonFilter } =
    invoiceCompanyStore;
  const { branchCityOptions, companyOptions, getCompanyOptions } = optionStore;

  const [form] = Form.useForm<IInvoicesFreeFilter>();
  const selectedCity = Form.useWatch("ciudad", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    getCompanyOptions();
  }, [getCompanyOptions]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    setBranchOptions(
      branchCityOptions
        .filter((x) => selectedCity?.includes(x.value as string))
        .flatMap((x) => x.options ?? [])
    );
  }, [branchCityOptions, form, selectedCity]);

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;
    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();
  }, [branchCityOptions]);

  const onFinish = (filters: IInvoicesFreeFilter) => {

    filters.fechaInicial = filters.fechas[0];
    filters.fechaFinal = filters.fechas[1];
    setInvoicePaymentAddonFilter(filters);
    getInvoicesCreditNote(filters)


  };

  return (
    <>
      <div className="status-container">
        <Form<IInvoicesFreeFilter>
          {...formItemLayout}
          form={form}
          name="invoiceFree"
          onFinish={onFinish}
          size="small"
          initialValues={{
            fechas: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
          }}
        >
          <Row gutter={[12, 12]} align="middle">
            <Col span={8}>
              <DateRangeInput
                formProps={{
                  name: "fechas",
                  label: "Fecha",
                }}
                required
                disableAfterDates
              />
            </Col>
            <Col span={8}>
              <TextInput
                formProps={{
                  name: "buscar",
                  label: "Buscar",
                }}
              />
            </Col>
            <Col span={8}>
              <SelectInput
                formProps={{
                  name: "compania",
                  label: "Compañia",
                }}
                options={companyOptions}
              />
            </Col>
            <Col span={8}>
              <Form.Item label="Sucursal" className="no-error-text" help="">
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      <SelectInput
                        form={form}
                        formProps={{
                          name: "ciudad",
                          label: "Ciudad",
                          noStyle: true,
                        }}
                        multiple
                        options={cityOptions}
                      />
                    </Col>
                    <Col span={12}>
                      <SelectInput
                        form={form}
                        formProps={{
                          name: "sucursal",
                          label: "Sucursales",
                          noStyle: true,
                        }}
                        multiple
                        options={branchOptions}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <SelectInput
                form={form}
                multiple
                formProps={{
                  name: "tipoFactura",
                  label: "Estatus",
                }}
                options={creditTimeEstatus}
              />
            </Col>
            <Col span={8}>
              <SelectInput
                form={form}
                multiple
                formProps={{
                  name: "categoria",
                  label: "Categoría",
                }}
                options={categoryType}
              />
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button onClick={() => {
                form.resetFields()
                getInvoicesCreditNote(new InvoicesFreeFilterValues())
                setInvoicePaymentAddonFilter(new InvoicesFreeFilterValues())
              }}>
                Limpiar
              </Button>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default observer(CreditMonitoringForm);
