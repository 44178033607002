import { Form, Row, Col, Checkbox, Input, Button, Typography } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import SelectTagInput from "../../../../app/common/form/proposal/SelectTagInput";
import TextAreaInput from "../../../../app/common/form/proposal/TextAreaInput";
import { IAppointmentGeneral } from "../../../../app/models/appointment";
import { IFormError, IOptions } from "../../../../app/models/shared";
import { originOptions } from "../../../../app/stores/optionStore";
import { useStore } from "../../../../app/stores/store";
import { catalog } from "../../../../app/util/catalogs";
import { validateEmail } from "../../../../app/util/utils";

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

const PARTICULAR = 2;

const sendOptions = [
  { label: "Mandar vía correo electrónico", value: "correo" },
  { label: "Mandar vía Whatsapp", value: "whatsapp" },
  { label: "Ambos", value: "ambos" },
];

type AppointmentGeneralProps = {
  branchId: string | undefined;
  form: FormInstance<IAppointmentGeneral>;
  onSubmit: (general: IAppointmentGeneral, showLoader: boolean) => void;
};

const AppointmentGeneral = ({
  branchId,
  form,
  onSubmit,
}: AppointmentGeneralProps) => {
  const { optionStore, appointmentStore } = useStore();
  const {
    companyOptions: CompanyOptions,
    medicOptions: MedicOptions,
    getCompanyOptions,
    getMedicOptions,
  } = optionStore;
  const {
    scopes,
    readonly,
    appointment,
    setStudyFilter,
    getGeneral,
    sendTestEmail,
    sendTestWhatsapp,
  } = appointmentStore;

  const sendings = Form.useWatch("metodoEnvio", form);
  const doctorId = Form.useWatch("medicoId", form);
  const companyId = Form.useWatch("compañiaId", form);

  const emails = Form.useWatch("correos", form);
  const whatsapps = Form.useWatch("whatsapps", form);

  const [errors, setErrors] = useState<IFormError[]>([]);
  const [previousSendings, setPreviousSendings] = useState<string[]>([]);
  const [appointmentGeneral, setAppointmentGeneral] =
    useState<IAppointmentGeneral>();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidWhatsapp, setIsValidWhatsapp] = useState(false);

  useEffect(() => {
    getCompanyOptions();
    getMedicOptions();
  }, [getCompanyOptions, getMedicOptions]);

  useEffect(() => {
    setStudyFilter(branchId, doctorId, companyId);
  }, [branchId, companyId, doctorId, setStudyFilter]);

  useEffect(() => {
    const getAppointmentGeneral = async () => {
      const appointmentGeneral = await getGeneral(appointment!.citaId);
      if (appointmentGeneral) {
        setAppointmentGeneral(appointmentGeneral);
        form.setFieldsValue(appointmentGeneral);
      }
    };

    if (appointment && appointment.citaId) {
      getAppointmentGeneral();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  useEffect(() => {
    setIsValidEmail(
      !!emails && emails.length > 0 && emails.every(validateEmail)
    );
    setIsValidWhatsapp(
      !!whatsapps &&
        whatsapps.length > 0 &&
        whatsapps.every(
          (whatsapp) =>
            (whatsapp ?? "").replaceAll("-", "").replaceAll("_", "").length ===
            10
        )
    );
  }, [emails, whatsapps]);

  const onValuesChange = (changedValues: any) => {
    const path = Object.keys(changedValues)[0];

    if (path === "metodoEnvio") {
      const sendings: string[] = changedValues[path];
      let metodoEnvio: string[] = [];

      if (previousSendings.includes("ambos") && !sendings.includes("ambos")) {
        metodoEnvio = [];
        form.setFieldsValue({ correo: undefined, whatsapp: undefined });
      } else if (
        !previousSendings.includes("ambos") &&
        sendings.includes("ambos")
      ) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else if (sendings.length === 2 && !sendings.includes("ambos")) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else {
        metodoEnvio = sendings.filter((x) => x !== "ambos");
      }

      if (!sendings.includes("correo")) {
        form.setFieldsValue({ correo: undefined });
      }
      if (!sendings.includes("whatsapp")) {
        form.setFieldsValue({ whatsapp: undefined });
      }

      form.setFieldsValue({ metodoEnvio });
      setPreviousSendings(metodoEnvio);
    }
  };

  const onFinish = (values: IAppointmentGeneral) => {
    setErrors([]);
    const appointment = { ...appointmentGeneral, ...values };
    appointment.correo = !isValidEmail ? undefined : emails?.join(",");
    appointment.whatsapp = !isValidWhatsapp ? undefined : whatsapps?.join(",");
    const autoSave = form.getFieldValue("guardadoAutomatico");

    onSubmit(appointment, autoSave);
  };

  const sendEmail = async () => {
    if (appointment && emails) {
      await sendTestEmail(appointment.citaId, emails);
    }
  };

  const sendWhatsapp = async () => {
    if (appointment && whatsapps) {
      await sendTestWhatsapp(appointment.citaId, whatsapps);
    }
  };

  const onCompanyChange = (_value: string, option: IOptions | IOptions[]) => {
    form.setFieldValue("procedencia", (option as IOptions).group);
  };

  return (
    <Form<IAppointmentGeneral>
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={({ errorFields }) => {
        const errors = errorFields.map((x) => ({
          name: x.name[0].toString(),
          errors: x.errors,
        }));
        setErrors(errors);
      }}
      initialValues={{
        metodoEnvio: [],
        companyId: catalog.company.particulares,
        procedencia: PARTICULAR,
        correos: [],
        whatsapps: [],
      }}
      onValuesChange={onValuesChange}
      size="small"
    >
      <Row gutter={[0, 12]}>
        {appointment && (
          <Col span={24}>
            <Form.Item
              label="Fecha de la cita"
              help=""
              className="no-error-text"
            >
              {appointment.citas && appointment.citas.length > 0 ? (
                appointment.citas.map((x) => (
                  <>
                    <Text>
                      {x.date.format("LLL")} |{" "}
                      {appointment.tipo === "L" ? "Equipo" : "Recolector"}:{" "}
                      {x.column}
                    </Text>
                    <br />
                  </>
                ))
              ) : (
                <Text>La cita aún no ha sido agendada</Text>
              )}
            </Form.Item>
          </Col>
        )}
        {appointment?.tipo === "D" && (
          <Col span={24}>
            <TextAreaInput
              formProps={{
                name: "direccion",
                label: "Dirección",
              }}
              required
              errors={errors.find((x) => x.name === "direccion")?.errors}
              autoSize={{ minRows: 2, maxRows: 4 }}
              rows={2}
              readonly={readonly}
            />
          </Col>
        )}
        <Col span={24}>
          <SelectInput
            formProps={{
              name: "compañiaId",
              label: "Compañía",
            }}
            options={CompanyOptions}
            required
            errors={errors.find((x) => x.name === "compañiaId")?.errors}
            onChange={onCompanyChange}
            readonly={readonly}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            formProps={{
              name: "procedencia",
              label: "Procedencia",
            }}
            options={originOptions}
            readonly
            required
          />
        </Col>
        <Col span={24}>
          <SelectInput
            formProps={{
              name: "medicoId",
              label: "Médico",
            }}
            options={MedicOptions}
            required
            readonly={readonly}
          />
        </Col>
        <Col span={24} style={{ textAlign: "start" }}>
          <Form.Item
            noStyle
            name="metodoEnvio"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group
              className={readonly ? "unclickable" : ""}
              options={sendOptions}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="E-Mail"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            className="no-error-text"
            help=""
            required={sendings?.includes("correo")}
          >
            <Input.Group>
              <Row>
                <Col span={12}>
                  <SelectTagInput
                    formProps={{
                      name: "correos",
                      label: "E-Mail",
                      noStyle: true,
                    }}
                    regex={
                      /^([A-Za-z0-9_.-]+)@([\dA-Za-z.-]+)\.([A-Za-z.]{2,6})$/
                    }
                    readonly={!sendings?.includes("correo") || readonly}
                    required={sendings?.includes("correo")}
                    errors={errors.find((x) => x.name === "correos")?.errors}
                  />
                </Col>
                <Col span={12}>
                  {scopes.modificar && appointment?.activo && (
                    <Button
                      type="primary"
                      disabled={!sendings?.includes("correo") || !isValidEmail}
                      onClick={sendEmail}
                    >
                      Prueba
                    </Button>
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Whatsapp"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            className="no-error-text"
            help=""
            required={sendings?.includes("whatsapp")}
          >
            <Input.Group>
              <Row>
                <Col span={12}>
                  <SelectTagInput
                    formProps={{
                      name: "whatsapps",
                      label: "Whatsapp",
                      noStyle: true,
                    }}
                    regex={/^([0-9]{3})-?([0-9]{3})-?([0-9]{2})-?([0-9]{2})$/}
                    readonly={!sendings?.includes("whatsapp") || readonly}
                    required={sendings?.includes("whatsapp")}
                    errors={errors.find((x) => x.name === "whatsapps")?.errors}
                  />
                </Col>
                <Col span={12}>
                  {scopes.modificar && appointment?.activo && (
                    <Button
                      type="primary"
                      disabled={
                        !sendings?.includes("whatsapp") || !isValidWhatsapp
                      }
                      onClick={sendWhatsapp}
                    >
                      Prueba
                    </Button>
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <TextAreaInput
            formProps={{
              name: "observaciones",
              label: "Observaciones",
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
            }}
            rows={3}
            readonly={readonly}
            errors={errors.find((x) => x.name === "observaciones")?.errors}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default observer(AppointmentGeneral);
