import { makeAutoObservable, toJS } from "mobx";
import { Moment } from "moment";

import RouteTracking from "../api/routetracking";
import Sampling from "../api/sampling";
import {
  IRouteTrackingHistoryList,
  IRouteTrackingList,
  IRouteTrackingRequest,
  ISearchTracking,
  ITagTrackingOrder,
  ITrackingTags,
  PendingTagsValues,
} from "../models/routeTracking";
import { IScopes, Scopes } from "../models/shared";
import { IRouteTrackingForm, IStudyTrackinOrder, ITagsDate } from "../models/trackingOrder";
import alerts from "../util/alerts";
import history from "../util/history";
import messages from "../util/messages";
import responses from "../util/responses";
import { getDistinct, getErrors } from "../util/utils";

export default class RouteTrackingStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();

  pendingTags: IRouteTrackingRequest[] = [];
  receiveTags: IRouteTrackingRequest[] = [];
  historyTags: IRouteTrackingRequest[] = [];

  originalRequestTags: IRouteTrackingRequest[] = [];

  requestTags: IRouteTrackingRequest[] = [];
  requestEditTags: IRouteTrackingRequest[] = [];

  tagData: ITrackingTags[] = [];
  tagEditData: ITrackingTags[] = [];
  tagsSelected: ITrackingTags[] = [];

  trackingOrders: IRouteTrackingList[] = [];
  tags: ITagTrackingOrder[] = [];

  historyOrders: IRouteTrackingHistoryList[] = [];

  routeTags: ITagTrackingOrder[] = [];
  routeStudies: IStudyTrackinOrder[] = [];

  receiveStudyTags: IRouteTrackingList[] = [];

  trackingStatus = { timeLine: false, trackingStatus: 0 };

  loadingRoutes: boolean = false;
  scan: boolean = false;

  filterPending: ISearchTracking = new PendingTagsValues();
  filterReceive: ISearchTracking = new PendingTagsValues();
  filterHistory: ISearchTracking = new PendingTagsValues();

  setLoadingRoutes = (loading: boolean) => {
    this.loadingRoutes = loading;
  };

  setRouteStudies = (routeStudies: IStudyTrackinOrder[]) => {
    this.routeStudies = routeStudies;
  };

  setTagData = (tagData: ITrackingTags[]) => {
    this.tagData = tagData;
  };

  setRequestTags = (requestTags: IRouteTrackingRequest[]) => {
    this.requestTags = requestTags;
  };

  setTagsSelected = (tagsSelected: ITrackingTags[]) => {
    this.tagsSelected = tagsSelected;
  };

  setFilterPending = (filter: ISearchTracking) => {
    this.filterPending = filter;
  };

  setFilterReceive = (filter: ISearchTracking) => {
    this.filterReceive = filter;
  };

  setFilterHistory = (filter: ISearchTracking) => {
    this.filterHistory = filter;
  };

  cleanTrackingOrder = () => {
    this.tagData = [];
    this.tagEditData = [];
    this.tagsSelected = [];
  };

  getStudyTrackingOrder = (record: ITrackingTags) => {
    const studyTrackingOrder: IStudyTrackinOrder = {
      id: record.ordenId,
      etiquetaId: record.id,
      solicitudId: record.solicitudId,
      claveRuta: record.ruta,
      escaneo: record.escaneo,
      extra: record.extra,
    };
    return studyTrackingOrder;
  };

  setScan = (scan: boolean) => {
    this.scan = scan;
  };

  setTrackingStatus = (timeLine: boolean, trackingStatus: number) => {
    this.trackingStatus = { timeLine, trackingStatus };
  };

  clearScopes = () => {
    this.scopes = new Scopes();
  };

  onScan = (value: boolean, id?: number) => {
    const tags = [...this.requestTags.flatMap((x) => x.etiquetas)];

    if (id) {
      const index = tags.findIndex((x) => x.id === id);
      if (index !== -1 && tags[index]) {
        tags[index].escaneo = value;
      }
    } else {
      tags.forEach((x) => {
        if (x) {
          x.escaneo = value;
        }
      });
    }

    this.setScan(tags.every((x) => x && x.escaneo));

    let studies = tags.map((x) => {
      let study = this.getStudyTrackingOrder(x);
      if (x) {
        study.escaneo = x.escaneo;
      }
      return study;
    });

    if (!value && !id) this.setRouteStudies([]);
    else this.setRouteStudies(studies);
  };

  getPendingTags = async (search: ISearchTracking) => {
    try {
      this.loadingRoutes = true;
      const study = await RouteTracking.getPendingTags(search);

      if (search.tipoPendiente === 1) this.pendingTags = study;
      if (search.tipoPendiente === 2) this.receiveTags = study;
      if (search.tipoPendiente === 3) this.historyTags = study;

      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.pendingTags = [];
      this.receiveTags = [];
      this.historyTags = [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  getTrackingHistory = async (search: ISearchTracking) => {
    try {
      this.loadingRoutes = true;
      const orders = await RouteTracking.getTrackingHistory(search);
      this.historyOrders = orders;
      return orders;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.historyOrders = [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  getById = async (id: string) => {
    try {
      this.loadingRoutes = true;
      const order = await RouteTracking.getById(id);
      this.requestTags = order.solicitudes;
      return order;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingRoutes = false;
    }
  };

  getAllTags = async (search: string, routeId: string) => {
    try {
      this.loadingRoutes = true;
      const tags = await RouteTracking.getAllTags(search, routeId);
      this.tags = tags;
      return tags;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.tags = [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  getFindTags = async (filter: ITagsDate) => {
    try {
      this.loadingRoutes = true;
      const tags = await RouteTracking.getFindTags(filter);
      this.requestTags = tags;
      this.originalRequestTags = tags;
      this.tagData = tags.flatMap((x) => x.etiquetas);
      return tags;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  getEditTags = async (filter: ITagsDate) => {
    try {
      this.loadingRoutes = true;
      const tags = await RouteTracking.getFindTags(filter);

      tags.forEach((tag) => {
        const existingRequestTag = this.requestTags.find(
          (requestTag) => requestTag.solicitudId === tag.solicitudId
        );

        if (existingRequestTag) {
          existingRequestTag.etiquetas = existingRequestTag.etiquetas
            .filter(
              (existingTag) =>
                existingTag.escaneo && tag.etiquetas.some((newTag) => newTag.id === existingTag.id)
            )
            .concat(
              tag.etiquetas.filter(
                (newTag) =>
                  newTag.escaneo &&
                  !existingRequestTag.etiquetas.some((existingTag) => existingTag.id === newTag.id)
              )
            );

          const filteredTags = tag.etiquetas.filter((newTag) =>
            existingRequestTag.etiquetas.every((existingTag) => existingTag.id !== newTag.id)
          );

          existingRequestTag.etiquetas = [...existingRequestTag.etiquetas, ...filteredTags];
        } else {
          const newRequestTag: IRouteTrackingRequest = {
            expedienteId: tag.expedienteId,
            solicitud: tag.solicitud,
            nombre: tag.nombre,
            solicitudId: tag.solicitudId,
            etiquetas: tag.etiquetas,
          };

          this.requestTags.push(newRequestTag);
        }
      });

      this.tagEditData = tags.flatMap((x) => x.etiquetas);
      return tags;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  tagExistsInRequests = (tag: ITrackingTags) => {
    return this.requestTags.some((requestTag) =>
      requestTag.etiquetas.flatMap((x) => x.id == tag.id)
    );
  };

  getActive = async () => {
    try {
      this.loadingRoutes = true;
      const trackingOrders = await RouteTracking.getActive();
      let filterTrackingOrders = getDistinct(trackingOrders);

      this.trackingOrders = filterTrackingOrders;
      return filterTrackingOrders;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingRoutes = false;
    }
  };

  createTrackingOrder = async (order: IRouteTrackingForm) => {
    try {
      this.loadingRoutes = true;
      await RouteTracking.createTrackingOrder(order);
      alerts.success(messages.created);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingRoutes = false;
    }
  };

  updateTrackingOrder = async (order: IRouteTrackingForm) => {
    try {
      this.loadingRoutes = true;
      await RouteTracking.updateTrackingOrder(order);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingRoutes = false;
    }
  };

  exportFormPending = async (id: ISearchTracking) => {
    try {
      await RouteTracking.exportReceiveForm(id);
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };

  exportForm = async (id: string) => {
    try {
      await RouteTracking.exportTrackingOrderForm(id);
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };

  printTicket = async (recordId: string, requestId: string) => {
    try {
      await Sampling.getOrderPdf(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportOrder = async (id: string) => {
    try {
      await RouteTracking.exportOrderForm(id);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportShipment = async (id: string) => {
    try {
      await RouteTracking.exportOrder(id);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportHistory = async (id: string) => {
    try {
      await RouteTracking.exportHistory(id);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportHistoryReport = async (id: string) => {
    try {
      await RouteTracking.exportHistoryReport(id);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportOrderReport = async (id: string) => {
    try {
      await RouteTracking.exportOrderReport(id);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
