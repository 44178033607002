import {
  Button,
  Col,
  Divider,
  Form,
  PageHeader,
  Pagination,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { isValidInputTimeValue } from "@testing-library/user-event/dist/utils";

import ImageButton from "../../../app/common/button/ImageButton";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import TextAreaInput from "../../../app/common/form/proposal/TextAreaInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import { getDefaultColumnProps, IColumns, ISearch } from "../../../app/common/table/utils";
import { IParameterForm, ItipoValorForm, ParameterFormValues } from "../../../app/models/parameter";
import { IReagentList } from "../../../app/models/reagent";
import { IOptions } from "../../../app/models/shared";
import { IStudyList } from "../../../app/models/study";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import messages from "../../../app/util/messages";
import { formItemLayout } from "../../../app/util/utils";
import views from "../../../app/util/view";
import useWindowDimensions, { resizeWidth } from "../../../app/util/window";
import { ParameterReagentModal } from "../ParameterReagentModal";
import { getParameterValues, isValidParamValues, ValueTypeEnum } from "./ValorType/utils";
import ValorType from "./ValorType/ValorType";

const { Link } = Typography;

type ParameterFormProps = { load: boolean };
type UrlParams = {
  id: string;
};

const functionOptions: IOptions[] = [
  { label: "ROUND()", value: "ROUND([VALOR])" },
  { label: "FORMAT()", value: "FORMAT([VALOR])" },
];

const ParameterForm: FC<ParameterFormProps> = ({ load }) => {
  const { parameterStore, optionStore } = useStore();
  const {
    getAll,
    parameters,
    getById,
    create,
    update,
    reagentsSelected,
    setReagentSelected,
    scopes,
  } = parameterStore;
  const {
    getDepartmentOptions,
    departmentOptions,
    getAreaOptions: getareaOptions,
    areaOptions: areas,
    getReagentOptions,
    getPrintFormatsOptions,
    getParameterOptions,
    getUnitOptions,
    unitOptions,
  } = optionStore;

  const [form] = Form.useForm<IParameterForm>();

  // const [flag, setFlag] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [parameterValues, setParameterValues] = useState<ItipoValorForm[]>([]);

  const { width: windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const [values, setValues] = useState<IParameterForm>(
    new ParameterFormValues()
  );
  const [valueType, setValueType] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cursorPosition, setCursorPosition] = useState(0);
  let { id } = useParams<UrlParams>();
  const tipodeValorList: IOptions[] = [
    { value: 0, label: "Sin valor" },
    { value: 1, label: "Numérico" },
    { value: 2, label: "Numérico por sexo" },
    { value: 3, label: "Numérico por edad" },
    { value: 4, label: "Numérico por edad y sexo" },
    { value: 5, label: "Opción múltiple" },
    { value: 6, label: "Numérico con una columna" },
    { value: 7, label: "Texto" },
    { value: 8, label: "Párrafo" },
    { value: 9, label: "Etiqueta" },
    { value: 10, label: "Observación" },
    { value: 11, label: "Numérico con dos columnas" },
    { value: 12, label: "Numérico con tres columnas" },
    { value: 13, label: "Numérico con cuatro columnas" },
    { value: 14, label: "Numérico con cinco columnas" },
  ];
  const [deltaCheck, setDeltaCheck] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    const readuser = async (idUser: string) => {
      setLoading(true);
      setLoading(true);

      try {
        const parameter = await getById(idUser);
        setReagentSelected(parameter!.reactivos);
        setParameterValues(parameter!.valoresReferencia ?? []);
        await getareaOptions(parameter?.departamentoId ?? 0);
        let val = parameter!.tipoValor | 0;
        setValueType(val);
        form.setFieldsValue(parameter!);
        setDeltaCheck(parameter?.deltaCheck!);
        setValues(parameter!);
      } catch (error) {
      } finally {
        setInitialLoad(false);
        setLoading(false);
      }
    };
    if (id) {
      readuser(id);
    } else {
      setReagentSelected([]);
    }
  }, [form, getById, id]);

  useEffect(() => {
    const readdepartments = async () => {
      await getDepartmentOptions();
    };
    readdepartments();
  }, [getDepartmentOptions]);

  useEffect(() => {
    const readdepartments = async () => {
      await getUnitOptions();
    };
    readdepartments();
  }, [getUnitOptions]);

  useEffect(() => {
    const readReagents = async () => {
      await getReagentOptions();
    };
    readReagents();
  }, [getareaOptions]);

  useEffect(() => {
    const readPrint = async () => {
      await getPrintFormatsOptions();
    };

    readPrint();
  }, [getPrintFormatsOptions]);

  useEffect(() => {
    const readUsers = async () => {
      setLoading(true);
      await getAll(searchParams.get("search") ?? "all");
      setLoading(false);
    };
    readUsers();
  }, [getAll, searchParams]);

  useEffect(() => {
    const read = async () => {
      await getParameterOptions();
    };
    read();
  }, [getParameterOptions]);

  const CheckReadOnly = () => {
    let result = false;
    const mode = searchParams.get("mode");
    if (mode == "ReadOnly") {
      result = true;
    }
    return result;
  };

  const onFinish = async (newValues: IParameterForm) => {
    const isValidValues = isValidParamValues(valueType, parameterValues);

    if (!isValidValues) {
      return;
    }

    setLoading(true);

    const Parameter = { ...values, ...newValues };
    Parameter.reactivos = [...reagentsSelected];
    Parameter.tipoValor = Parameter.tipoValor.toString();
    Parameter.valoresReferencia = parameterValues;
    let success = false;
    if (!Parameter.id) {
      success = await create(Parameter);
    } else {
      success = await update(Parameter);
    }
    setLoading(false);
    // if (success && flag == 0) {
    if (success) {
      setReagentSelected([]);
      navigate(`/parameters?search=${searchParams.get("search") || "all"}`);
    }
  };

  const onValuesChange = async (changeValues: any, values: any) => {
    const fields = Object.keys(changeValues)[0];
    if (fields === "tipoValor") {
      const value = changeValues[fields];
      // values.tipoValor = value;
      // values.id = id;
      setValueType(value);
      onTypeChange(value);

      // setValues((prev) => ({ ...prev, tipoValor: values.tipoValor }));
      // setFlag(1);
    }

    if (fields === "departamentoId") {
      const value = changeValues[fields];
      await getareaOptions(value);
    }

    if (fields === "funciones") {
      const value = changeValues[fields];
      const original = values.formula.split("");

      original.splice(cursorPosition, 0, value);
      const newString = original.join("");
      form.setFieldsValue({ formula: newString, funciones: undefined });
    }

    if (fields === "parametros") {
      const value = changeValues[fields];
      const original = values.formula.split("");
      original.splice(cursorPosition, 0, value);
      const newString = original.join("");
      form.setFieldsValue({ formula: newString, parametros: undefined });
    }
  };

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columns: IColumns<IStudyList> = [
    {
      ...getDefaultColumnProps("id", "Clave Estudio", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
      render: (value: any, row: any) => {
        return (
          <>
            <Link
              onClick={() => {
                navigate(
                  `/studies/${row?.clave}?mode=${searchParams.get(
                    "mode"
                  )}&search=${searchParams.get("search") ?? "all"}`
                );
              }}
            >
              {value}
            </Link>
          </>
        );
      },
    },
    {
      ...getDefaultColumnProps("nombre", "Estudio", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
  ];

  const columnsReagent: IColumns<IReagentList> = [
    {
      ...getDefaultColumnProps("nombre", "Reactivo", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("claveSistema", "Clave Contpaq", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombreSistema", "Nombre Contpaq", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const activeDeltaCheck = () => {
    setDeltaCheck((deltaCheck) => !deltaCheck);
  };

  const deleteReagent = () => {
    const filterList = reagentsSelected.filter(
      (x) => !selectedRowKeys.includes(x.id)
    );
    setReagentSelected(filterList);
    setSelectedRowKeys([]);
  };

  const onPageChange = (page: number) => {
    const parameter = parameters[page - 1];
    navigate(
      `/${views.parameters}/${parameter.id}?mode=${searchParams.get(
        "mode"
      )}&search=${searchParams.get("search") ?? "all"}`
    );
  };

  const getPage = (parameterId?: string) => {
    return parameters.findIndex((x) => x.id === parameterId) + 1;
  };

  const onTypeChange = (valueType: number) => {
    if (valueType === ValueTypeEnum.SinValor) {
      setParameterValues([]);
    } else if (
      valueType === ValueTypeEnum.Numerico ||
      valueType === ValueTypeEnum.NumericoSexo
    ) {
      setParameterValues([
        {
          id: undefined,
          orden: 1,
        },
      ]);
    } else {
      setParameterValues([]);
    }
  };

  return (
    <Spin spinning={loading || load}>
      <Row style={{ marginBottom: 24 }}>
        <Col md={12} sm={24} xs={24}>
          <Pagination
            size="small"
            pageSize={1}
            total={parameters.length}
            current={getPage(id)}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </Col>
        {!CheckReadOnly() && (scopes.crear || scopes.modificar) && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                navigate(`/parameters`);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {CheckReadOnly() && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={() => {
                navigate(
                  `/parameters/${id}?mode=edit&search=${
                    searchParams.get("search") ?? "all"
                  }`
                );
              }}
            />
          </Col>
        )}
      </Row>

      <Form<IParameterForm>
        {...formItemLayout}
        form={form}
        name="parameter"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={values}
        labelWrap
        onFieldsChange={() => {
          setDisabled(
            !form.isFieldsTouched() ||
              form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
          );
        }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between" gutter={[0, 24]}>
              <Col span={8}>
                <TextInput
                  formProps={{
                    name: "clave",
                    label: "Clave",
                  }}
                  max={100}
                  required
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}></Col>
              {id && (
                <>
                  <Col span={8}>
                    <SelectInput
                      formProps={{
                        name: "tipoValor",
                        label: "Tipo de valor",
                      }}
                      options={tipodeValorList}
                      readonly={CheckReadOnly()}
                      required
                    />
                  </Col>
                </>
              )}
              <Col span={8}>
                <TextInput
                  formProps={{
                    name: "nombre",
                    label: "Nombre",
                  }}
                  max={100}
                  required
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  formProps={{
                    name: "unidades",
                    label: "Unidades",
                  }}
                  options={unitOptions}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <TextInput
                  formProps={{
                    name: "fcsi",
                    label: "FCSI",
                  }}
                  max={100}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <TextInput
                  formProps={{
                    name: "nombreCorto",
                    label: "Nombre corto",
                  }}
                  max={100}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  formProps={{
                    name: "unidadSi",
                    label: "Unidad SI",
                  }}
                  options={unitOptions}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SwitchInput
                  name="activo"
                  label="Activo"
                  onChange={(value) => {
                    if (value) {
                      alerts.info(messages.confirmations.enable);
                    } else {
                      alerts.info(messages.confirmations.disable);
                    }
                  }}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  formProps={{
                    name: "departamentoId",
                    label: "Departamento",
                  }}
                  options={departmentOptions}
                  readonly={CheckReadOnly()}
                />
              </Col>
              {id && (
                <>
                  <Col span={8}>
                    <SelectInput
                      formProps={{ name: "funciones", label: "Funciones" }}
                      options={functionOptions}
                      readonly={CheckReadOnly()}
                    />
                  </Col>
                </>
              )}
              <Col span={8}>
                <SwitchInput
                  name="requerido"
                  label="Requerido"
                  onChange={(value) => {
                    if (value) {
                      alerts.info(messages.confirmations.required);
                    } else {
                      alerts.info(messages.confirmations.unrequired);
                    }
                  }}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  formProps={{ name: "areaId", label: "Área" }}
                  options={areas}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SwitchInput
                  name="deltaCheck"
                  label="Delta Check"
                  onChange={(value) => {
                    activeDeltaCheck();
                    if (value) {
                      alerts.info(messages.confirmations.deltaCheck);
                    } else {
                      alerts.info(messages.confirmations.deltaUncheck);
                    }
                  }}
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={16}>
                <TextAreaInput
                  formProps={{
                    name: "valorInicial",
                    label: "Valor Inicial",
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                  }}
                  rows={1}
                  autoSize
                  readonly={CheckReadOnly()}
                />
              </Col>
              <Col span={8}>
                <SwitchInput
                  name="ocultarSiNulo"
                  label="Ocultar si es nulo"
                  readonly={CheckReadOnly()}
                />
              </Col>
              {id && (
                <>
                  <Col span={16}>
                    <TextInput
                      formProps={{
                        name: "formula",
                        label: "Fórmula",
                        labelCol: {
                          xs: { span: 24 },
                          sm: { span: 4 },
                        },
                        wrapperCol: {
                          xs: { span: 24 },
                          sm: { span: 20 },
                        },
                      }}
                      max={1000}
                      readonly={CheckReadOnly()}
                      onClick={(e: any) => {
                        const position = e.target.selectionStart ?? 0;
                        setCursorPosition(position);
                      }}
                      onKeyUp={(e: any) => {
                        const position = e.target.selectionStart ?? 0;
                        setCursorPosition(position);
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
      <br />
      {!initialLoad && (
        <ValorType
          value={valueType}
          parameterValues={parameterValues}
          setParameterValues={setParameterValues}
        ></ValorType>
      )}
      <br />
      <Row>
        <Col md={24} sm={12} style={{ marginRight: 20, textAlign: "center" }}>
          <PageHeader
            ghost={false}
            title={<HeaderTitle title="Reactivos del parámetro" />}
            className="header-container"
            extra={[
              selectedRowKeys.length > 0 ? (
                <Button type="primary" danger onClick={deleteReagent}>
                  Eliminar
                </Button>
              ) : (
                ""
              ),
              <Button
                type="primary"
                onClick={async () => {
                  await ParameterReagentModal(reagentsSelected);
                }}
              >
                Buscar
              </Button>,
            ]}
          ></PageHeader>
          <Divider className="header-divider" />
          <Table<IReagentList>
            size="small"
            rowKey={(record) => record.id}
            columns={columnsReagent}
            pagination={false}
            dataSource={[...reagentsSelected]}
            scroll={{
              x: windowWidth < resizeWidth ? "max-content" : "auto",
            }}
            rowSelection={rowSelection}
          />
          <br />
          <PageHeader
            ghost={false}
            title={
              <HeaderTitle title="Estudios donde se encuentra el parámetro" />
            }
            className="header-container"
          ></PageHeader>
          <Divider className="header-divider" />
          <Table<IStudyList>
            size="small"
            rowKey={(record) => record.id}
            columns={columns.slice(0, 3)}
            pagination={false}
            dataSource={[...(values.estudios ?? [])]}
            scroll={{
              x: windowWidth < resizeWidth ? "max-content" : "auto",
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
};
export default observer(ParameterForm);
