import { Button, Spin, Table, Row, Col, Form, Switch, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import {
  defaultPaginationProperties,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import useWindowDimensions, { resizeWidth } from "../../app/util/window";
import { EditOutlined, PrinterOutlined, EyeOutlined } from "@ant-design/icons";
import IconButton from "../../app/common/button/IconButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import {
  IMantainList,
  ISearchMantain,
  SearchMantainValues,
} from "../../app/models/equipmentMantain";
import TextInput from "../../app/common/form/proposal/TextInput";
import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import DescriptionTitle from "../../app/common/display/DescriptionTitle";

const { Text } = Typography;

type EquipmentTableProps = {
  componentRef: React.MutableRefObject<any>;
  printing: boolean;
  id: number;
};

const EquipmentMantainData: FC<EquipmentTableProps> = ({
  componentRef,
  printing,
  id,
}) => {
  const { equipmentMantainStore, equipmentStore } = useStore();
  const {
    getAlls,
    getequip,
    equip,
    setSearch,
    search,
    setiD,
    idEq,
    printTicket,
    updateStatus,
    exportForm,
    scopes,
  } = equipmentMantainStore;
  const { equipment, getAll } = equipmentStore;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm<ISearchMantain>();

  const [values, setValues] = useState<ISearchMantain>(
    new SearchMantainValues()
  );
  let navigate = useNavigate();

  const { width: windowWidth } = useWindowDimensions();

  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState<IMantainList[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readEquipment = async () => {
      setLoading(true);
      var equipo = await getequip(id);
      search!.idEquipo = equipo?.id!;
      var data = await getAlls(search!);
      setEquipments(data!);
      await getAll("all");
      setiD(id);
      setLoading(false);
    };

    readEquipment();
  }, [getAll, searchParams, getequip, getAlls]);

  const onFinish = async (newValues: ISearchMantain) => {
    const equipment = { ...search, ...newValues };
    setSearch(equipment);
    var data = await getAlls(equipment);
    setEquipments(data!);
  };

  const UpdateStatusChange = async (id: string) => {
    var list = [...equipments];
    var equipment = list.find((x) => x.id === id);
    equipment!.activo = !equipment!.activo;
    var index = list.findIndex((x) => x.id === id);
    list[index] = equipment!;
    setEquipments(list);

    await updateStatus(id);
  };
  const columns: IColumns<IMantainList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "15%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
      render: (value, user) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/equipmentMantain/edit/${id}/${user.id}?mode=readonly`);
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha Programada", {
        searchState,
        setSearchState,
        width: "20%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Editar",
      align: "center",
      width: windowWidth < resizeWidth ? 100 : "10%",
      render: (value) => (
        <IconButton
          title="Editar equipo"
          icon={<EditOutlined />}
          onClick={() => {
            navigate(`/equipmentMantain/edit/${id}/${value}`);
          }}
        />
      ),
    },
    {
      key: "imprimir",
      dataIndex: "id",
      title: "Imprimir",
      align: "center",
      width: windowWidth < resizeWidth ? 100 : "10%",
      render: (value, item) =>
        scopes.imprimir && (
          <IconButton
            title=" Imprimir reporte"
            icon={<PrinterOutlined />}
            onClick={() => {
              exportForm(item.id);
            }}
          />
        ),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Ver",
      align: "center",
      width: windowWidth < resizeWidth ? 100 : "10%",
      render: (value) =>
        scopes.acceder &&
        scopes.modificar && (
          <IconButton
            title="Visualizar registro"
            icon={<EyeOutlined />}
            onClick={() => {
              printTicket(value);
            }}
          />
        ),
    },

    {
      key: "editar",
      dataIndex: "id",
      title: "Activo",
      align: "center",
      width: windowWidth < resizeWidth ? 100 : "10%",
      render: (value, item) =>
        scopes.modificar && (
          <Switch
            onChange={() => {
              UpdateStatusChange(value);
            }}
            checked={item.activo}
          ></Switch>
        ),
    },
  ];
  return (
    <Spin spinning={loading || printing} tip={printing ? "Imprimiendo" : ""}>
      <Row gutter={[24, 8]}>
        <Col span={16}>
          <Row gutter={[4, 0]}>
            <Col span={8}>
              <DescriptionTitle title="Clave" content={equip?.clave} />
            </Col>
            <Col span={8}>
              <DescriptionTitle title="Nombre" content={equip?.nombre} />
            </Col>
            <Col span={8}>
              <DescriptionTitle title="Serie" content={equip?.serie} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="status-container">
            <Form<ISearchMantain>
              form={form}
              name="equipment"
              initialValues={values}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row justify="space-between" gutter={[32, 24]}>
                <Col md={8} sm={24}>
                  <DateRangeInput
                    formProps={{
                      name: "fecha",
                      label: "Fecha",
                    }}
                  />
                </Col>
                <Col md={8} sm={24}>
                  <TextInput
                    formProps={{
                      name: "clave",
                      label: "Nombre",
                    }}
                    max={100}
                  />
                </Col>
                <Col md={8} sm={24} style={{ textAlign: "right" }}>
                  <Button type="primary" htmlType="submit">
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      <Table<IMantainList>
        loading={loading || printing}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...equipments]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: windowWidth < resizeWidth ? "max-content" : "auto" }}
      />
    </Spin>
  );
};

export default observer(EquipmentMantainData);
