import { Typography } from "antd";
import { uniqueId } from "lodash";
import { makeAutoObservable } from "mobx";
import moment from "moment";

import Branch from "../api/branch";
import Catalog from "../api/catalog";
import Company from "../api/company";
import Indication from "../api/indication";
import Location from "../api/location";
import Maquilador from "../api/maquilador";
import Medics from "../api/medics";
import Pack from "../api/pack";
import Parameter from "../api/parameter";
import PriceList from "../api/priceList";
import Profile from "../api/profile";
import Promotion from "../api/promotion";
import Reagent from "../api/reagent";
import RecordsCatalogs from "../api/recordsCatalogs";
import Role from "../api/role";
import Route from "../api/route";
import Series from "../api/series";
import Study from "../api/study";
import User from "../api/user";
import {
  ICatalogAreaList,
  ICatalogBudgetList,
  ICatalogDescriptionForm,
  ICatalogDescriptionList,
  ICatalogNormalList,
} from "../models/catalog";
import { IMedicsForm } from "../models/medics";
import { ISeriesFilter, ISeriesList } from "../models/series";
import { IOptions, IProfile } from "../models/shared";
import { IWorkList } from "../models/workList";
import { groupBy } from "../util/utils";
import { store } from "./store";

const { Text } = Typography;

export const taxOptions = [
  { label: "16%", value: 0.16 },
  { label: "8%", value: 0.08 },
  { label: "0%", value: 0 },
];

export const originOptions = [
  { label: "COMPAÑÍA", value: 1 },
  { label: "PARTICULAR", value: 2 },
];

export const urgencyOptions = [
  { label: "Normal", value: 1 },
  { label: "Urgencia", value: 2 },
  { label: "Urgencia con cargo", value: 3 },
];

export const requestedStudyOptions = [
  { label: "Toma de Muestra", value: 2 },
  { label: "Solicitado", value: 3 },
];

export const samplingStudyOptions = [
  { label: "Pendiente", value: 1 },
  { label: "Toma de Muestra", value: 2 },
];

export const appointmentTypeOptions = [
  { label: "Laboratorio", value: "L" },
  { label: "Domicilio", value: "D" },
];

export const studyStatusOptions = [
  { label: "Pendiente", value: 1 },
  { label: "Toma de muestra", value: 2 },
  { label: "Solicitado", value: 3 },
  { label: "Capturado", value: 4 },
  { label: "Validado", value: 5 },
  { label: "Liberado", value: 6 },
  { label: "Enviado", value: 7 },
  { label: "En ruta", value: 8 },
  { label: "Cancelado", value: 9 },
  { label: "Entregado", value: 10 },
];

export const seriesTypeOptions = [
  { label: "FACTURA", value: 1 },
  { label: "RECIBO", value: 2 },
];

export const valueTypeOptions: IOptions[] = [
  { value: 1, label: "Numérico" },
  { value: 2, label: "Numérico por sexo" },
  { value: 3, label: "Numérico por edad" },
  { value: 4, label: "Numérico por edad y sexo" },
  { value: 5, label: "Opción múltiple" },
  { value: 6, label: "Numérico con una columna" },
  { value: 7, label: "Texto" },
  { value: 8, label: "Párrafo" },
  { value: 9, label: "Etiqueta" },
  { value: 10, label: "Observación" },
  { value: 11, label: "Numérico con dos columnas" },
  { value: 12, label: "Numérico con tres columnas" },
  { value: 13, label: "Numérico con cuatro columnas" },
  { value: 14, label: "Numérico con cinco columnas" },
];

export default class OptionStore {
  constructor() {
    makeAutoObservable(this);
  }

  departmentOptions: IOptions[] = [];
  departmentOptions2: IOptions[] = [];

  getDepartmentOptions = async () => {
    try {
      const departments = await Catalog.getActive<ICatalogNormalList>(
        "department"
      );
      this.departmentOptions = departments.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
      this.departmentOptions2 = departments.map((x) => ({
        value: x.id,
        key: x.nombre,
        label: x.nombre,
      }));
      return departments.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.departmentOptions = [];
    }
  };

  unitOptions: IOptions[] = [];
  getUnitOptions = async () => {
    try {
      const departments = await Catalog.getActive<ICatalogNormalList>("units");
      this.unitOptions = departments.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
      return departments.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.unitOptions = [];
    }
  };
  clinicOptions: IOptions[] = [];

  getClinicOptions = async () => {
    try {
      const clinics = await Catalog.getActive<ICatalogNormalList>("clinic");
      this.clinicOptions = clinics.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.clinicOptions = [];
    }
  };
  reagents: IOptions[] = [];
  getReagentOptions = async () => {
    try {
      const reagent = await Reagent.getAll("all");
      this.reagents = reagent.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.reagents = [];
    }
  };

  areaOptions: IOptions[] = [];
  getAreaOptions = async (id?: number) => {
    try {
      let ruta = `area/department/${id}`;
      if (id === 0) {
        ruta = "area";
      }
      const area = await Catalog.getActive<ICatalogNormalList>(ruta);

      var areas = area.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
      this.areaOptions = areas;
      return areas;
    } catch (error) {
      this.areaOptions = [];
    }
  };

  printFormat: IOptions[] = [];
  getPrintFormatsOptions = async (id?: number) => {
    try {
      const department = await Catalog.getActive<ICatalogNormalList>("format");
      this.printFormat = department.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.printFormat = [];
    }
  };

  fieldOptions: IOptions[] = [];

  getfieldsOptions = async () => {
    try {
      const field = await Catalog.getActive<ICatalogNormalList>("field");
      this.fieldOptions = field.map((x) => ({ value: x.id, label: x.nombre }));
    } catch (error) {
      this.fieldOptions = [];
    }
  };

  bankOptions: IOptions[] = [];

  getbankOptions = async () => {
    try {
      const bank = await Catalog.getActive<ICatalogNormalList>("bank");
      this.bankOptions = bank.map((x) => ({ value: x.id, label: x.nombre }));
    } catch (error) {
      this.bankOptions = [];
    }
  };
  degreeOptions: IOptions[] = [];

  getDegreeOptions = async () => {
    try {
      const degrees = await Catalog.getActive<ICatalogNormalList>("degree");
      this.degreeOptions = degrees.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.bankOptions = [];
    }
  };

  invoiceConceptsOptions: IOptions[] = [];

  getInvoiceConceptsOptions = async () => {
    try {
      const invoiceConcepts = await Catalog.getActive<ICatalogDescriptionList>(
        "invoiceconcepts"
      );
      this.invoiceConceptsOptions = invoiceConcepts.map((x) => ({
        value: x.descripcion,
        label: x.nombre,
        key: uniqueId(),
      }));
    } catch (error) {
      this.bankOptions = [];
    }
  };

  addInvoiceConceptsOptions = (concept: ICatalogDescriptionForm) => {
    this.invoiceConceptsOptions = [
      ...this.invoiceConceptsOptions,
      {
        value: concept.descripcion,
        label: concept.nombre,
        key: uniqueId(),
      },
    ];
  };

  cfdiOptions: IOptions[] = [];

  getcfdiOptions = async () => {
    try {
      const cfdi = await Catalog.getActive<ICatalogDescriptionList>(
        "useOfCFDI"
      );
      this.cfdiOptions = cfdi.map((x) => ({
        value: x.id,
        label: x.clave + " " + x.descripcion,
      }));
    } catch (error) {
      this.cfdiOptions = [];
    }
  };

  get cfdiNameOptions() {
    return this.cfdiOptions.map<IOptions>((x) => ({
      value: x.label!.toString(),
      label: x.label,
    }));
  }

  paymentMethodOptions: IOptions[] = [];
  getpaymentMethodOptions = async () => {
    try {
      const paymentMethod = await Catalog.getActive<ICatalogNormalList>(
        "paymentMethod"
      );
      this.paymentMethodOptions = paymentMethod.map((x) => ({
        value: x.id,
        label: x.clave + " " + x.nombre,
      }));
    } catch (error) {
      this.paymentMethodOptions = [];
    }
  };

  get paymentMethodNameOptions() {
    return this.paymentMethodOptions.map<IOptions>((x) => ({
      value: x.label!.toString(),
      label: x.label,
    }));
  }

  paymentOptions: IOptions[] = [];
  filteredPaymentOptions: IOptions[] = [];

  getPaymentOptions = async () => {
    try {
      const allowedPayment = [1, 2, 3, 4, 5, 18];
      const payment = await Catalog.getActive<ICatalogDescriptionList>(
        "payment"
      );
      const filteredPayment = payment.filter((x) =>
        allowedPayment.includes(x.id)
      );

      this.paymentOptions = payment.map((x) => ({
        value: x.id,
        label: x.clave + " " + x.descripcion,
      }));
      this.filteredPaymentOptions = filteredPayment.map((x) => ({
        value: x.id,
        label: x.clave + " " + x.descripcion,
      }));
    } catch (error) {
      this.paymentOptions = [];
    }
  };

  get paymentNameOptions() {
    return this.paymentOptions.map<IOptions>((x) => ({
      value: x.label!.toString(),
      label: x.label,
    }));
  }

  provenanceOptions: IOptions[] = [];

  getprovenanceOptions = async () => {
    try {
      const procedencia = await Catalog.getActive<ICatalogNormalList>(
        "provenance"
      );
      this.provenanceOptions = procedencia.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.provenanceOptions = [];
    }
  };

  roleOptions: IOptions[] = [];

  getRoleOptions = async () => {
    try {
      const roles = await Role.getActive();
      this.roleOptions = roles.map((x) => ({ value: x.id, label: x.nombre }));
    } catch (error) {
      this.paymentOptions = [];
    }
  };

  parameterOptions: IOptions[] = [];
  parameterOptions2: IOptions[] = [];
  getParameterOptions = async () => {
    try {
      const parameter = await Parameter.getAll("all");
      this.parameterOptions = parameter.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));

      this.parameterOptions2 = parameter.map((x) => ({
        value: x.clave,
        label: x.nombre,
      }));
    } catch (error) {
      this.parameterOptions = [];
      this.parameterOptions2 = [];
    }
  };
  MaquiladorOptions: IOptions[] = [];
  ReportMaquiladorOptions: IOptions[] = [];
  getMaquiladorOptions = async () => {
    try {
      const maquilador = Maquilador.getActive();
      this.MaquiladorOptions = (await maquilador).map((x) => ({
        value: x.id,
        label: x.nombre,
      }));

      this.ReportMaquiladorOptions = (await maquilador).map((x) => ({
        value: x.id.toString(),
        label: x.nombre,
      }));
    } catch (error) {
      this.MaquiladorOptions = [];
    }
  };

  routeMaquilaOptions: IOptions[] = [];
  getRouteMaquilaOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      let activeProfileBranch = profile?.sucursal;

      if (activeProfileBranch) {
        const maquilas = await Route.getActiveRouteMaquila(activeProfileBranch);

        this.routeMaquilaOptions = maquilas.map((x) => ({
          value: x.id,
          label: x.nombre,
        }));
      }
    } catch (error) {
      this.routeMaquilaOptions = [];
    }
  };

  MethodOptions: IOptions[] = [];

  getMethodOptions = async () => {
    try {
      const Method = await Catalog.getActive<ICatalogNormalList>("Method");
      this.MethodOptions = Method.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.MethodOptions = [];
    }
  };

  indicationOptions: IOptions[] = [];
  getIndication = async () => {
    try {
      const indication = await Indication.getAll("");
      this.indicationOptions = indication.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.indicationOptions = [];
    }
  };

  workListOptions: IOptions[] = [];

  getworkListOptions = async () => {
    try {
      const workList = await Catalog.getActive<ICatalogNormalList>("workList");
      this.workListOptions = workList.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.workListOptions = [];
    }
  };
  workListOptions2: IWorkList[] = [];

  getworkListOptions2 = async () => {
    try {
      const workList = await Catalog.getActive<ICatalogNormalList>("workList");
      this.workListOptions2 = workList.map((x) => ({
        id: x.id,
        nombre: x.nombre,
        clave: x.clave,
        activo: x.activo,
      }));
    } catch (error) {
      this.workListOptions2 = [];
    }
  };
  sampleTypeOptions: IOptions[] = [];

  getsampleTypeOptions = async () => {
    try {
      const sampleType = await Catalog.getActive<ICatalogNormalList>(
        "sampleType"
      );
      this.sampleTypeOptions = sampleType.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.sampleTypeOptions = [];
    }
  };

  priceListOptions: IOptions[] = [];
  getPriceListOptions = async (type: "p" | "c" | "all" = "p") => {
    try {
      const priceListOptions = await PriceList.getOptions(type);
      this.priceListOptions = priceListOptions;
    } catch (error) {
      this.priceListOptions = [];
    }
  };

  sucursales: IOptions[] = [];
  sucursalesClave: any = [];

  getSucursalesOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      const priceListOptions1 = await Branch.getActive();
      this.sucursales = priceListOptions1
        .filter((x) => profile?.sucursales.includes(x.idSucursal))
        .map((x) => ({
          value: x.idSucursal,
          label: x.nombre,
        }));
      this.sucursalesClave = priceListOptions1
        .filter((x) => profile?.sucursales.includes(x.idSucursal))
        .map((x) => ({
          value: x.idSucursal,
          clave: x.clave,
        }));
    } catch (error) {
      this.sucursales = [];
    }
  };

  BranchOptions: IOptions[] = [];
  cityOptions: IOptions[] = [];
  getBranchOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      const branch = Branch.getActive();
      this.BranchOptions = (await branch)
        .filter((x) => profile?.sucursales.includes(x.idSucursal))
        .map((x) => ({
          value: x.idSucursal,
          label: x.nombre,
        }));

      this.cityOptions = (await branch)
        .filter((x) => profile?.sucursales.includes(x.idSucursal))
        .map((x) => ({
          value: x.ciudad,
          label: x.ciudad,
        }));
    } catch (error) {
      this.BranchOptions = [];
      this.cityOptions = [];
    }
  };

  routeBranchOptions: IOptions[] = [];
  getRouteBranchOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      let activeProfileBranch = profile?.sucursal;

      if (activeProfileBranch) {
        const branches = Route.getActiveRouteBranches(activeProfileBranch);

        this.routeBranchOptions = (await branches).map((x) => ({
          value: x.idSucursal,
          label: x.nombre,
        }));
      }
    } catch (error) {
      this.routeBranchOptions = [];
    }
  };

  getAllBranchOptions = async () => {
    try {
      const branch = Branch.getActive();
      this.BranchOptions = (await branch).map((x) => ({
        value: x.idSucursal,
        label: x.nombre,
      }));
      this.cityOptions = (await branch).map((x) => ({
        value: x.ciudad,
        label: x.ciudad,
      }));
    } catch (error) {
      this.BranchOptions = [];
    }
  };

  DeliveryOptions: IOptions[] = [];
  getDeliveryOptions = async () => {
    try {
      const Delivery = await Catalog.getActive<ICatalogNormalList>("Delivery");
      this.DeliveryOptions = Delivery.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.DeliveryOptions = [];
    }
  };

  promotionOptions: IOptions[] = [];

  getPromotionOptions = async (priceListId: string[]) => {
    try {
      const promotionOptions = await Promotion.getActive(priceListId);
      this.promotionOptions = promotionOptions.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
      return this.promotionOptions;
    } catch (error) {
      this.promotionOptions = [];
      return [];
    }
  };

  studyOptions: IOptions[] = [];

  getStudyOptions = async () => {
    try {
      const studyOptions = await Study.getActive();
      this.studyOptions = studyOptions.map((x) => ({
        key: "study-" + x.id,
        value: "study-" + x.id,
        label: x.clave + " - " + x.nombre,
        group: "study",
        extra: x.clave.trim(),
      }));
    } catch (error) {
      this.studyOptions = [];
    }
  };

  studyOptionsEx: IOptions[] = [];

  getStudyOptionsEx = async () => {
    try {
      const studyOptions = await Study.getActive();
      this.studyOptionsEx = studyOptions.map((x) => ({
        key: "study-" + x.id,
        value: "study-" + x.id,
        label: (
          <Text>
            {x.clave} - {x.nombre}
            {x.clavesExternas === null ? (
              ""
            ) : (
              <Text strong>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`(${x.clavesExternas})`}
              </Text>
            )}
          </Text>
        ),
        group: "study",
        extra: x.clave.trim(),
        info:
          x.clave +
          ", " +
          x.nombre +
          (x.clavesExternas === null ? "" : `,${x.clavesExternas}`),
      }));
    } catch (error) {
      this.studyOptionsEx = [];
    }
  };

  appointmentStudyOptions: IOptions[] = [];
  getAppointmentStudyOptions = async (department: string) => {
    try {
      const studyOptions = await Study.getActive();
      let filterStudyOptions = studyOptions.filter(
        (x) => x.departamento === department
      );
      this.appointmentStudyOptions = filterStudyOptions.map((x) => ({
        key: "study-" + x.id,
        value: "study-" + x.id,
        label: x.clave + " - " + x.nombre,
        group: "study",
      }));
    } catch (error) {
      this.appointmentStudyOptions = [];
    }
  };

  packOptions: IOptions[] = [];
  getPackOptions = async () => {
    try {
      const packOptions = await Pack.getActive();
      this.packOptions = packOptions.map((x) => ({
        key: "pack-" + x.id,
        value: "pack-" + x.id,
        label: x.clave + " - " + x.nombre,
        group: "pack",
        extra: x.clave.trim(),
        info: x.clave + ", " + x.nombre,
      }));
    } catch (error) {
      this.packOptions = [];
    }
  };

  companyOptions: IOptions[] = [];
  getCompanyOptions = async () => {
    try {
      const companyOptions = await Company.getActive();
      this.companyOptions = companyOptions.map((x) => ({
        value: x.id,
        label: x.nombreComercial,
        group: x.procedenciaId,
      }));
    } catch (error) {
      this.companyOptions = [];
    }
  };

  medicOptions: IOptions[] = [];

  getMedicOptions = async () => {
    try {
      const MedicOptions = await Medics.getActive();
      this.medicOptions = MedicOptions.map((x) => ({
        value: x.idMedico,
        label: x.nombreCompleto,
        extra: { correo: x.correo, celular: x.celular },
      }));
    } catch (error) {
      this.medicOptions = [];
    }
  };

  addMedicOption = (medic: IMedicsForm) => {
    this.medicOptions = [
      ...this.medicOptions,
      {
        value: medic.idMedico,
        label: `${medic.nombre} ${medic.primerApellido} ${
          medic.segundoApellido ?? ""
        }`,
        extra: { correo: medic.correo, celular: medic.celular },
      },
    ];
  };

  branchCityOptions: IOptions[] = [];
  getBranchCityOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      const branch = Branch.getBranchByCity();
      let branches = (await branch).map((x) => ({
        value: x.ciudad,
        label: x.ciudad,
        disabled: true,
        options: x.sucursales.map((y) => ({
          value: y.idSucursal,
          label: y.nombre,
        })),
      }));

      const branchesFiltered: IOptions[] = [];
      branches.forEach((bco) => {
        let sucursalesDisponibles = bco.options?.filter((x) =>
          profile?.sucursales.includes("" + x.value)
        );
        if (!!sucursalesDisponibles?.length) {
          let copy = {
            ...bco,
            options: sucursalesDisponibles,
          };
          branchesFiltered.push(copy);
        }
      });
      this.branchCityOptions = branchesFiltered;
    } catch (error) {
      this.branchCityOptions = [];
    }
  };

  userBranchesOptions: IOptions[] = [];
  getUserBranchesOptions = async (currentBranch: string) => {
    try {
      const branches = await RecordsCatalogs.getUserBranches(currentBranch);

      const groupedBranches = groupBy(branches, "ciudad");

      const finalBranches = groupedBranches.map((x) => ({
        value: x.key,
        label: x.key,
        disabled: true,
        options: x.items.map((y) => ({
          value: y.idSucursal,
          label: y.nombre,
        })),
      }));

      this.userBranchesOptions = finalBranches;
    } catch (error) {
      this.userBranchesOptions = [];
    }
  };

  userOptions: IOptions[] = [];
  getUsersOptions = async () => {
    try {
      const users = await User.getUsersOptions();

      this.userOptions = users.map((x) => ({
        value: x.id,
        label: x.nombre,
      }));
    } catch (error) {
      this.userOptions = [];
    }
  };

  routeBranchByCityOptions: IOptions[] = [];
  getRouteBranchByCityOptions = async () => {
    try {
      const profile = store.profileStore.profile;
      let activeProfileBranch = profile?.sucursal;

      if (activeProfileBranch) {
        const branches = Route.getRouteBranchByCity(activeProfileBranch);

        let branchOptions = (await branches).map((x) => ({
          value: x.ciudad,
          label: x.ciudad,
          disabled: true,
          options: x.sucursales.map((y) => ({
            value: y.idSucursal,
            label: y.nombre,
          })),
        }));

        this.routeBranchByCityOptions = branchOptions;
      }
    } catch (error) {
      this.routeBranchByCityOptions = [];
    }
  };

  setRouteBranchByCityOptions = (options: IOptions[]) => {
    this.routeBranchByCityOptions = options;
  };

  allBranchCityOptions: IOptions[] = [];
  getAllBranchCityOptions = async () => {
    try {
      const branch = Branch.getBranchByCity();
      let branches = (await branch).map((x) => ({
        value: x.ciudad,
        label: x.ciudad,
        disabled: true,
        options: x.sucursales.map((y) => ({
          value: y.idSucursal,
          label: y.nombre,
        })),
      }));
      this.allBranchCityOptions = branches;
    } catch (error) {
      this.allBranchCityOptions = [];
    }
  };

  studiesOptions: any[] = [];
  getStudiesOptions = async () => {
    try {
      const study = await Study.getActive();
      this.studiesOptions = study.map((x) => ({
        key: x.id,
        value: x.id,
        label: x.clave + " - " + x.nombre,
        area: x.areaId,
      }));
    } catch (error) {
      this.studiesOptions = [];
    }
  };

  departmentAreaOptions: IOptions[] = [];
  getDepartmentAreaOptions = async () => {
    try {
      const areas = await Catalog.getActive<ICatalogAreaList>("area");
      let groupby = areas.reduce((group: any, area) => {
        const { departamento } = area;
        group[departamento] = group[departamento] ?? [];
        group[departamento].push(area);
        return group;
      }, {});

      this.departmentAreaOptions = Object.keys(groupby).map((x) => ({
        value: x,
        label: x,
        disabled: true,
        options: groupby[x].map((a: ICatalogAreaList) => ({
          value: a.id,
          label: a.nombre,
        })),
      }));
    } catch (error) {
      this.departmentAreaOptions = [];
    }
  };

  CityOptions: IOptions[] = [];
  getCityOptions = async () => {
    try {
      const branch = Location.getCities();
      this.CityOptions = (await branch).map((x) => ({
        value: x.id,
        label: x.ciudad,
      }));
    } catch (error) {
      this.CityOptions = [];
    }
  };

  typeValue: IOptions[] = [];
  getTypeValues = async (id: string, tipo: string) => {
    try {
      const type = Parameter.getAllValues(id, tipo);
      this.typeValue = (await type).map((x) => ({
        value: x.id!,
        label:
          (x.descripcionTexto as string) || (x.descripcionParrafo as string),
      }));
    } catch (error) {
      this.typeValue = [];
    }
  };

  areaByDeparmentOptions: IOptions[] = [];
  getAreaByDeparmentOptions = async () => {
    try {
      const areas = await Catalog.getAreaByDeparment();
      this.areaByDeparmentOptions = areas.map((x) => ({
        value: x.departamentoId,
        label: x.departamento,
        disabled: true,
        options: x.areas.map((a) => ({
          value: a.id,
          label: a.nombre,
        })),
      }));
    } catch (error) {
      this.areaByDeparmentOptions = [];
    }
  };

  servicesOptions: IOptions[] = [];
  getServicesOptions = async () => {
    try {
      const service = await Catalog.getActive<ICatalogBudgetList>("costofijo");
      this.servicesOptions = service.map((x) => ({
        key: x.id,
        value: x.nombre,
        label: x.nombre,
      }));
    } catch (error) {
      this.servicesOptions = [];
    }
  };

  invoiceSeriesOptions: IOptions[] = [];
  getInvoiceSeriesOptions = async (branchId: string) => {
    try {
      const series = await Series.getByBranch(branchId, 1);
      this.invoiceSeriesOptions = series.map((x) => ({
        key: x.id,
        value: x.clave,
        label: x.clave,
      }));
    } catch (error) {
      this.invoiceSeriesOptions = [];
    }
  };

  getAllInvoiceSeriesOptions = async (branchId: string[]) => {
    try {
      const filter: ISeriesFilter = {
        sucursalId: branchId,
        tipoSeries: [1],
        año: moment("0001-01-01"),
        buscar: "",
      };
      const series = await Series.getByFilter(filter);
      const options = series.map((x) => ({
        key: x.id,
        value: x.clave,
        label: x.clave,
      }));
      this.invoiceSeriesOptions = options;
    } catch (error) {
      this.invoiceSeriesOptions = [];
    } finally {
      return this.invoiceSeriesOptions;
    }
  };
  invoiceSeriesList: ISeriesList[] = [];

  getAllInvoiceSeriesList = async (branchId: string[]) => {
    try {
      const filter: ISeriesFilter = {
        sucursalId: branchId,
        tipoSeries: [1],
        año: moment("0001-01-01"),
        buscar: "",
      };
      const series = await Series.getByFilter(filter);
      this.invoiceSeriesList = series;
      //  return series;
    } catch (error) {
      // return [];
      this.invoiceSeriesList = [];
    }
  };

  receiptSeriesOptions: IOptions[] = [];
  getReceiptSeriesOptions = async (branchId: string) => {
    try {
      const series = await Series.getByBranch(branchId, 2);
      this.receiptSeriesOptions = series.map((x) => ({
        key: x.id,
        value: x.clave,
        label: x.clave,
      }));
    } catch (error) {
      this.receiptSeriesOptions = [];
    }
  };

  seriesOptions: IOptions[] = [];
  getSeriesOptions = async (branchId: string) => {
    try {
      const series = await Series.getSeries(branchId);
      this.seriesOptions = series.map((x) => ({
        key: x.id,
        value: x.id,
        label: x.clave,
      }));
    } catch (error) {
      this.seriesOptions = [];
    }
  };

  profileOptions: IProfile | undefined;
  getProfileOptions = async () => {
    try {
      const profile = await Profile.getProfile();
      this.profileOptions = {
        ...profile,
      };
    } catch (error) {
      this.profileOptions = undefined;
    }
  };
}
