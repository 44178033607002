import { makeAutoObservable } from "mobx";
import {
  IInvoiceGlobalFilter,
  IInvoiceGlobalList,
  InvoiceGlobalFilterValues,
} from "../models/invoiceGlobal";
import { getErrors } from "../util/utils";
import alerts from "../util/alerts";
import InvoiceGlobal from "../api/invoiceGlobal";
import { status } from "../util/catalogs";
import { IScopes, Scopes } from "../models/shared";

export default class InvoiceGlobalStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  filter: IInvoiceGlobalFilter = new InvoiceGlobalFilterValues();
  invoices: IInvoiceGlobalList[] = [];
  creatingGlobalInvoices: boolean = false;

  setFilter = (filter: IInvoiceGlobalFilter) => {
    this.filter = filter;
  };

  setInvoices = (invoices: IInvoiceGlobalList[]) => {
    this.invoices = invoices;
  };

  getAll = async (filter: IInvoiceGlobalFilter) => {
    try {
      const invoices = await InvoiceGlobal.getAll(filter);
      return invoices;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  create = async (invoice: string[]) => {
    try {
      this.creatingGlobalInvoices = true;
      await InvoiceGlobal.create(invoice);
      const invoices = await this.getAll(this.filter);
      this.setInvoices(invoices);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.creatingGlobalInvoices = false;
    }
  };

  cancel = (invoiceId: string) => {
    this.invoices = this.invoices.map((x) => ({
      ...x,
      facturas: x.facturas.map((i) => {
        return {
          ...i,
          estatus:
            i.facturaId === invoiceId ? status.invoice.cancelado : i.estatus,
        };
      }),
    }));
  };

  downloadList = async (filter: IInvoiceGlobalFilter) => {
    try {
      await InvoiceGlobal.downloadList(filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
