import { Button, Checkbox, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import DateRangeInput from "../../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import {
  IInvoiceFreeFilter,
  InvoiceFreeFilterValues,
} from "../../../app/models/invoiceFree";
import { useStore } from "../../../app/stores/store";
import { formItemLayout } from "../../../app/util/utils";

const statusOptions = [
  {
    label: "Facturadas",
    value: "Facturado",
  },
  {
    label: "Canceladas",
    value: "Cancelado",
  },
];

const typeOptions = [
  { label: "Compañia", value: "company" },
  { label: "Paciente", value: "patient" },
];

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceFreeFilter = ({ setLoading }: Props) => {
  const { profileStore, optionStore, invoiceFreeStore } = useStore();
  const { profile } = profileStore;
  const {
    branchCityOptions,
    companyOptions,
    getBranchCityOptions,
    getCompanyOptions,
  } = optionStore;
  const { filter, setFilter, setInvoices, getAll } = invoiceFreeStore;

  const [form] = Form.useForm<IInvoiceFreeFilter>();

  useEffect(() => {
    if (branchCityOptions.length === 0) getBranchCityOptions();
    if (companyOptions.length === 0) getCompanyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const read = async (filter: IInvoiceFreeFilter) => {
    setLoading(true);
    const invoices = await getAll(filter);
    setInvoices(invoices);
    setLoading(false);
  };

  useEffect(() => {
    if (filter.cargaInicial) {
      const newFilter = {
        ...filter,
        sucursalId: profile!.sucursal,
        cargaInicial: false,
      };
      setFilter(newFilter);
      form.setFieldsValue(newFilter);
      read(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: IInvoiceFreeFilter) => {
    setFilter(values);
    read(values);
  };

  const resetFilter = () => {
    const clear = new InvoiceFreeFilterValues();
    clear.sucursalId = profile!.sucursal;
    clear.cargaInicial = false;
    setFilter(clear);
    form.setFieldsValue(clear);
  };

  return (
    <div className="status-container">
      <Form<IInvoiceFreeFilter>
        {...formItemLayout}
        form={form}
        name="invoiceFree"
        onFinish={onFinish}
        size="small"
        initialValues={filter}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <SelectInput
              formProps={{
                name: "sucursalId",
                label: "Sucursal",
              }}
              options={branchCityOptions}
            />
          </Col>
          <Col span={8}>
            <DateRangeInput
              formProps={{
                name: "fechas",
                label: "Fechas",
              }}
              disableAfterDates
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{
                name: "estatus",
                label: "Estatus",
              }}
              options={statusOptions}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              formProps={{
                name: "compañiaId",
                label: "Compañia",
              }}
              options={companyOptions}
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Buscar",
              }}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              label="Tipo"
              name="tipo"
              help=""
              className="no-error-text"
            >
              <Checkbox.Group options={typeOptions} />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "end" }}>
            <Button onClick={resetFilter}>Limpiar</Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(InvoiceFreeFilter);
