import { ISamplingForm, ISamplingList, IUpdate } from "../models/sampling";
import { IScopes } from "../models/shared";
import requests from "./agent";
import {
  IShipmentTracking,
  IReceiveTracking,
} from "../models/shipmentTracking";
import { IRouteTrackingForm } from "../models/trackingOrder";

const ShipmentTracking = {
  access: (): Promise<IScopes> => requests.get("scopes/ShipmentTracking"),
  getShipmentById: (id: string): Promise<IShipmentTracking> =>
    requests.get(`ShipmentTracking/order/${id}`),
  getHistoryById: (id: string): Promise<IShipmentTracking> =>
    requests.get(`ShipmentTracking/history/${id}`),
  exportList: (trackingOrder: IRouteTrackingForm): Promise<void> =>
    requests.download(`tracking-order/export/form`, trackingOrder),
  getById: (id: string): Promise<IRouteTrackingForm> =>
    requests.get(`ShipmentTracking/${id}`),

  getReceiveById: (id: string): Promise<IReceiveTracking> =>
    requests.get(`ShipmentTracking/receive/${id}`),
  createNewTrackingOrder: (data: IReceiveTracking): Promise<IRouteTrackingForm> =>
    requests.post(`ShipmentTracking/create/trackingOrder`, data),
  updateReceive: (data: IReceiveTracking): Promise<void> =>
    requests.put(`ShipmentTracking/receive/delivered`, data),
};

export default ShipmentTracking;
