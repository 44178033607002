import { Popconfirm, Select, Switch, Table } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import {
  IColumns,
  ISearch,
  defaultPaginationProperties,
  getDefaultColumnProps,
} from "../../../app/common/table/utils";
import {
  IReceiveTags,
  IReceiveTracking,
} from "../../../app/models/shipmentTracking";
import { studyStatus } from "../../../app/util/catalogs";
import useWindowDimensions from "../../../app/util/window";
import { CheckCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { toJS } from "mobx";

type RTTProps = {
  receive: IReceiveTracking | undefined;
  selectedRows: IReceiveTags[];
  setNotScanned: React.Dispatch<React.SetStateAction<string | undefined>>;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setNotRecolected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<IReceiveTags[]>>;
};

const ReceiveTrackingTable = ({
  receive,
  selectedRows,
  setNotScanned,
  setButtonDisabled,
  setNotRecolected,
  setSelectedRows,
}: RTTProps) => {
  const { shipmentTrackingStore } = useStore();
  const {
    receiveTags,
    updateReceiveTags,
    onScan,
    getStudyTrackingOrder,
    setReceiveTags,
    setReceiveScan,
    scopes,
  } = shipmentTrackingStore;
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    if (!receiveTags) return;
    const studies = receiveTags
      .filter((x) => x.escaneoEntrega)
      .map((y) => y.etiquetaId);
    const tags = receiveTags.map((x) => x.etiquetaId);

    const missingStudies = tags.filter((x) => !studies.includes(x));

    const getMissingStudiesName = missingStudies.map((x) => {
      const study = receiveTags.find((y) => y.etiquetaId === x);
      return study?.claveEtiqueta;
    });

    if (missingStudies.length > 0) {
      const studiesNotScanned = getMissingStudiesName.join(", ");
      setNotScanned(studiesNotScanned);
    }

    setButtonDisabled(
      receiveTags.every((x) => !x.escaneoEntrega) || missingStudies.length > 0
    );

    setNotRecolected(receiveTags.every((x) => !x.confirmacionOrigen));

    setReceiveScan(receiveTags.every((x) => x.escaneoEntrega));
  }, [receiveTags]);

  const onDestinationChange = (value: number, record: IReceiveTags) => {
    let study = getStudyTrackingOrder(record);
    const copyReceiveTags = [...receiveTags];

    let existingRecord = copyReceiveTags.find(
      (x) => x.etiquetaId === study.etiquetaId
    );

    if (existingRecord) {
      const updatedDestination = copyReceiveTags.map((x) => {
        if (x.etiquetaId === study.etiquetaId) {
          return {
            ...x,
            escaneoEntrega: true,
            tipoDestino: value,
          };
        }

        return {
          ...x,
        };
      });

      setReceiveTags(updatedDestination);
    } else {
      let studies = copyReceiveTags.map((x) => {
        x.tipoDestino = value;
        x.escaneoEntrega = true;
        return x;
      });

      updateReceiveTags(copyReceiveTags, true, record.etiquetaId);
      setReceiveTags(studies);
    }

    return record;
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: IReceiveTags[]
  ) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows.map((x) => x.id),
    onChange: onSelectChange,
    getCheckboxProps: () => ({
      disabled: receive?.etiquetas.every(
        (etiqueta) => !etiqueta.destinos || etiqueta.destinos.length === 0
      ),
    }),
  };

  const columns: IColumns<IReceiveTags> = [
    {
      ...getDefaultColumnProps("claveEtiqueta", "Clave muestra", {
        searchState,
        setSearchState,
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("recipiente", "Recipiente", {
        searchState,
        setSearchState,
        width: "8%",
      }),
    },
    {
      ...getDefaultColumnProps("estudios", "Estudios", {
        searchState,
        setSearchState,
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "8%",
      }),
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => {
        return studyStatus(value);
      },
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre de paciente", {
        searchState,
        setSearchState,
        width: "15%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
    {
      key: "confirmacionOrigen",
      dataIndex: "confirmacionOrigen",
      title: "Confirmación Recolección",
      align: "center",
      width: "12%",
      render: (value) =>
        value ? (
          <CheckCircleTwoTone twoToneColor="green" />
        ) : (
          <MinusCircleTwoTone twoToneColor="#FFCC00" />
        ),
    },
    {
      key: "escaneoEntrega",
      dataIndex: "escaneoEntrega",
      title: "Confirmación Recepción",
      width: "12%",
      align: "center",
      render: (value: boolean, record) =>
        !receive?.fechaReal ? (
          <Switch
            checked={value}
            onChange={(checked) => onScan(checked, record.etiquetaId)}
          />
        ) : (
          <CheckCircleTwoTone twoToneColor="green" />
        ),
    },
    {
      key: "tipoDestino",
      dataIndex: "tipoDestino",
      title: "Tipo de destino",
      width: "11%",
      align: "center",
      render: (value: number, record) => (
        <Select
          allowClear
          options={[
            { label: "Destino final", value: 1 },
            { label: "Destino intermedio", value: 2 },
          ]}
          value={value}
          style={{ width: "100%" }}
          onChange={(value) => {
            onDestinationChange(value, record);
          }}
          disabled={
            (!receive?.etiquetas ||
              receive.etiquetas.every(
                (etiqueta) =>
                  !etiqueta.destinos || etiqueta.destinos.length === 0
              )) &&
            scopes.modificar
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Table<IReceiveTags>
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...receiveTags]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: "max-content" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </Fragment>
  );
};

export default observer(ReceiveTrackingTable);
