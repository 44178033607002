import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

type Props = {
  showTitle?: boolean;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};

const DownloadFileIcon = ({ showTitle = true, onClick }: Props) => {
  const { reportStore } = useStore();
  const { loadingReport } = reportStore;
  return (
    <Tooltip title={showTitle ? "Descargar archivo" : undefined}>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        size="lg"
        icon={faFileArrowDown}
        onClick={(e: any) => {
          if (loadingReport) {
            return () => {};
          }
          if (!!onClick) {
            onClick(e);
          }
        }}
      />
    </Tooltip>
  );
};

export default observer(DownloadFileIcon);
