import {
  IProfile,
  IMenu,
  IValidationPermission,
  IScopes,
} from "../models/shared";
import { ILoginForm } from "../models/user";
import requests from "./agent";

const Profile = {
  getMenu: (): Promise<IMenu[]> => requests.get(`profile/menu`),
  getProfile: (): Promise<IProfile> => requests.get(`profile/me`),
  getScopes: (): Promise<IScopes[]> => requests.get(`profile/me/scopes`),
  login: (creds: ILoginForm): Promise<IProfile> =>
    requests.post("profile/login", creds),
  validateAdmin: (creds: ILoginForm): Promise<IValidationPermission> =>
    requests.post("profile/validateAdmin", creds),
  validateCancellation: (creds: ILoginForm): Promise<IValidationPermission> =>
    requests.post("profile/validateCancellation", creds),
  ValidateDiscount: (creds: ILoginForm): Promise<IValidationPermission> =>
    requests.post("profile/ValidateDiscount", creds),
  getPdfConsent: (): Promise<string> =>
    requests.getFileUrl(`profile/getPdfConsent`, "application/pdf", {}),
};

export default Profile;
