import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
} from "antd";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { IAppointmentGeneral } from "../../../app/models/appointment";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import AppointmentAssignment from "./content/AppointmentAssignment";
import AppointmentGeneral from "./content/AppointmentGeneral";
import AppointmentIndication from "./content/AppointmentIndication";
import AppointmentStudy from "./content/AppointmentStudy";
import AppointmentInvoice from "./AppointmentInvoice";
import AppointmentPrint from "./content/AppointmentPrint";
import { onSubmitGeneral, submitGeneral } from "./utils";
import views from "../../../app/util/view";
import { useNavigate } from "react-router";
import { status } from "../../../app/util/catalogs";
import SelectInput from "../../../app/common/form/proposal/SelectInput";

type AppointmentTabProps = {
  branchId: string | undefined;
  recordId: string | undefined;
  setRecordId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type keys = "general" | "studies" | "indications" | "assignment" | "report";

type Tab = {
  key: string;
  label: string;
  children: JSX.Element;
};

const showAutoSaveMessage = () => {
  notification.info({
    key: "auto-not",
    message: `Guardado automático`,
    placement: "bottomRight",
    icon: "",
  });
};

const AppointmentTab = ({
  branchId,
  recordId,
  setRecordId,
}: AppointmentTabProps) => {
  const { optionStore, appointmentStore } = useStore();
  const { BranchOptions, getBranchOptions } = optionStore;
  const {
    readonly,
    appointment,
    studyUpdate,
    loadingTabContent,
    allStudies,
    getStudies,
    updateBranch,
    updateGeneral,
    updateStudies,
    assignRecord,
    cancelAppointment,
    convertToRequest,
  } = appointmentStore;

  const navigate = useNavigate();

  const [formGeneral] = Form.useForm<IAppointmentGeneral>();

  const [tabs, setTabs] = useState<Tab[]>([]);
  const [currentKey, setCurrentKey] = useState<keys>("general");
  const [canUpdateBranch, setCanUpdateBranch] = useState(false);

  const onChangeTab = async (key: string) => {
    if (currentKey !== "indications" && currentKey !== "report" && !readonly) {
      submit(true);
    }

    setCurrentKey(key as keys);
  };

  const submit = async (autoSave: boolean = false) => {
    if (currentKey === "general") {
      const ok = await submitGeneral(formGeneral, autoSave);
      if (!ok) {
        setCurrentKey("general");
        return;
      }
    } else if (currentKey === "studies") {
      const ok = await updateStudies(studyUpdate, autoSave);
      if (!ok) {
        return;
      }
    } else if (currentKey === "assignment") {
      const ok = await assignRecord(appointment!.citaId, autoSave, recordId);
      if (!ok) {
        return;
      }
    }

    if (autoSave) {
      showAutoSaveMessage();
    }
  };

  const cancel = () => {
    if (!appointment) return;

    if (allStudies.length === 0) {
      navigate(`/${views.appointment}?type=${appointment.tipo}`);
    } else {
      alerts.confirm(
        "Cancelar cita",
        `¿Desea cancelar la cita?, esta acción no se puede deshacer`,
        async () => {
          if (appointment) {
            const ok = await cancelAppointment(appointment.citaId);
            if (ok) {
              navigate(`/${views.appointment}?type=${appointment.tipo}`);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    setCanUpdateBranch(
      allStudies.length === 0 &&
        appointment?.estatusId === status.appointment.generado
    );
  }, [allStudies.length, appointment?.estatusId]);

  useEffect(() => {
    getBranchOptions();
  }, [getBranchOptions]);

  useEffect(() => {
    const readData = () => {
      if (appointment) {
        setRecordId(appointment.expedienteId);
        getStudies(appointment.citaId);
      }
    };

    readData();
  }, [getStudies, appointment, setRecordId]);

  const tabRender = useCallback(
    (tabName: string) => {
      let component = (
        <AppointmentGeneral
          branchId={branchId}
          form={formGeneral}
          onSubmit={(appointment, showLoader) => {
            onSubmitGeneral(appointment, showLoader, updateGeneral).then(
              (ok) => {
                if (!ok) setCurrentKey("general");
              }
            );
          }}
        />
      );

      if (tabName === "studies") {
        component = <AppointmentStudy />;
      } else if (tabName === "indications") {
        component = <AppointmentIndication />;
      } else if (tabName === "assignment") {
        component = (
          <AppointmentAssignment
            recordId={recordId}
            setRecordId={setRecordId}
          />
        );
      } else if (tabName === "report") {
        component = <AppointmentPrint />;
      }

      return (
        <Row gutter={8}>
          <Col span={18}>{component}</Col>
          <Col span={6}>
            <AppointmentInvoice />
          </Col>
        </Row>
      );
    },
    [branchId, formGeneral, recordId, setRecordId, updateGeneral]
  );

  useEffect(() => {
    let tabs = [
      {
        key: "general",
        label: "Generales",
        children: tabRender("general"),
      },
      {
        key: "studies",
        label: "Estudios",
        children: tabRender("studies"),
      },
      {
        key: "indications",
        label: "Indicaciones",
        children: tabRender("indications"),
        disabled: allStudies.length === 0,
      },
      {
        key: "assignment",
        label: "Expediente",
        children: tabRender("assignment"),
        disabled: allStudies.length === 0,
      },
      {
        key: "report",
        label: "Recibo",
        children: tabRender("report"),
        disabled: allStudies.length === 0,
      },
    ];

    if (readonly) tabs = tabs.filter((x) => x.key !== "assignment");

    setTabs(tabs);
  }, [readonly, tabRender, allStudies.length]);

  const convert = async () => {
    const requestId = await convertToRequest(appointment!.citaId);
    if (requestId)
      navigate(`/${views.request}/${appointment!.expedienteId}/${requestId}`);
  };

  const onChangeBranch = async (branchId: string) => {
    if (!appointment) return;

    const app = { ...appointment };
    app.sucursalId = branchId;
    await updateBranch(app);
  };

  const operations = (
    <Space>
      {!readonly && (
        <>
          <Select
            key="type"
            showSearch
            placeholder="Sucursal"
            optionFilterProp="children"
            onChange={(value) => {
              onChangeBranch(value);
            }}
            disabled={!canUpdateBranch}
            filterOption={(input: string, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={appointment?.sucursalId}
            allowClear={false}
            style={{ width: 240 }}
            options={BranchOptions}
          ></Select>
          <Button key="cancel" size="small" ghost danger onClick={cancel}>
            Cancelar
          </Button>
          <Button
            key="save"
            size="small"
            type="primary"
            onClick={() => submit()}
          >
            Guardar
          </Button>
          <Button
            disabled={
              allStudies.length === 0 ||
              allStudies.some((x) => !x.id) ||
              !appointment?.expedienteId ||
              appointment.estatusId !== status.appointment.terminada
            }
            key="create"
            size="small"
            type="primary"
            onClick={convert}
          >
            Convertir en solicitud
          </Button>
        </>
      )}
    </Space>
  );

  if (!branchId) {
    return <p>Por favor selecciona una sucursal.</p>;
  }

  return (
    <Spin spinning={loadingTabContent}>
      <Tabs
        activeKey={currentKey}
        tabBarExtraContent={operations}
        onChange={onChangeTab}
        items={tabs}
      />
    </Spin>
  );
};

export default observer(AppointmentTab);
