import { Button, Col, Row, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { VList } from "virtual-table-ant-design";

import { IColumns } from "../../../../app/common/table/utils";
import { ICompanyForm, ICompanyList } from "../../../../app/models/company";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";

const columns: IColumns<ICompanyList> = [
  {
    dataIndex: "clave",
    title: "Clave",
    width: "25%",
  },
  {
    dataIndex: "nombreComercial",
    title: "Nombre",
    width: "55%",
  },
  {
    dataIndex: "procedencia",
    title: "Procedencia",
    width: "20%",
  },
];

type Props = {
  getResult: (response: ICompanyForm | undefined) => void;
};

const InvoiceFreeSearchCompany = ({ getResult }: Props) => {
  const { companyStore } = useStore();
  const { getActive, getById } = companyStore;

  const [companies, setCompanies] = useState<ICompanyList[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadCompanies = async () => {
      setLoading(true);
      const companies = await getActive();
      setCompanies(companies);
      setLoading(false);
    };

    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = async () => {
    const id = selectedRowKeys[0].toString();
    const selectedCompany = await getById(id);

    if (!selectedCompany || missingTaxData(selectedCompany)) {
      return;
    }

    getResult(selectedCompany);
  };

  const missingTaxData = (company: ICompanyForm) => {
    if (
      !company.rfc ||
      !company.razonSocial ||
      !company.regimenFiscal ||
      !company.codigoPostal
    ) {
      alerts.warning(
        "La compañía seleccionada no tiene todos los datos fiscales requeridos (RFC, Razón social, Régimen fiscal, CP)"
      );
      return true;
    }
    return false;
  };

  const virtual = useMemo(() => {
    return VList({
      height: 500,
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Table
            rowKey={(row) => row.id}
            columns={columns}
            dataSource={companies}
            bordered
            components={virtual}
            pagination={false}
            scroll={{
              x: "100%",
              y: 500,
            }}
            rowSelection={{
              type: "radio",
              selectedRowKeys: selectedRowKeys,
              onChange: async (newSelectedRowKeys: React.Key[]) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
            }}
          />
        </Col>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button
            type="primary"
            onClick={select}
            disabled={selectedRowKeys.length === 0}
          >
            Aceptar
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(InvoiceFreeSearchCompany);
