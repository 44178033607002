import { useState } from "react";
import { IAgenda, IAgendaColumn, IAgendaDetail } from "./utils";
import { Dropdown, MenuProps, Popover } from "antd";
import moment from "moment";

type AgendaCellProps<T extends IAgenda> = {
  time: moment.Moment;
  event?: T;
  column: IAgendaColumn;
  draggingEvent: T | undefined;
  deletable?: boolean | ((event: T) => boolean);
  showDetail?: IAgendaDetail<T>;
  setDragginEvent: React.Dispatch<React.SetStateAction<T | undefined>>;
  render: (event: T) => React.ReactNode;
  onClick?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDropEvent?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDelete?: (event: T) => void;
};

const AgendaCell = <T extends IAgenda>({
  time,
  event,
  column,
  draggingEvent,
  deletable,
  showDetail,
  setDragginEvent,
  render,
  onClick,
  onDropEvent,
  onDelete,
}: AgendaCellProps<T>) => {
  const [hovered, setHovered] = useState(false);

  const items: MenuProps["items"] = [
    {
      key: "delete",
      label: "Eliminar",
      onClick: () => {
        if (onDelete && event) onDelete!(event);
      },
    },
  ];

  const renderEvent = (event: T) => (
    <Dropdown
      disabled={
        !event ||
        (typeof deletable === "boolean" && !deletable) ||
        (typeof deletable === "function" && !deletable(event)) ||
        !onDelete
      }
      menu={{ items }}
      trigger={["contextMenu"]}
    >
      {render(event)}
    </Dropdown>
  );

  return (
    <div
      draggable={!!event}
      onClick={() => {
        if (onClick) {
          onClick(time, column, event);
        }
      }}
      onDragStart={() => {
        setDragginEvent(event);
      }}
      onDragOver={(e) => {
        return event || time.isBefore(moment()) ? false : e.preventDefault();
      }}
      onDragEnter={() => {
        if (!event) {
          setHovered(true);
        }
      }}
      onDragLeave={() => {
        setHovered(false);
      }}
      onDrop={() => {
        if (event) return false;
        setHovered(false);
        if (onDropEvent) {
          onDropEvent(time, column, draggingEvent);
          setDragginEvent(undefined);
        }
      }}
      className={`agenda-cell ${hovered ? "hovered" : ""}`}
      style={{
        height: "100%",
      }}
    >
      {(event && showDetail && (
        <Popover
          trigger={showDetail.trigger ?? "click"}
          content={showDetail.content(event)}
        >
          {renderEvent(event)}
        </Popover>
      )) ||
        (event && renderEvent(event))}
    </div>
  );
};

export default AgendaCell;
