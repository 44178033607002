import moment from "moment";
import React, { useState } from "react";
import AgendaCell from "./AgendaCell";
import { IAgendaColumn, IAgenda, flexCenterd, IAgendaDetail } from "./utils";

type AgendaBodyProps<T extends IAgenda> = {
  timeHours: moment.Moment[];
  columns: IAgendaColumn[];
  weekColumn?: IAgendaColumn;
  events: T[];
  cellHeight?: number;
  selectedDate: moment.Moment;
  selectedDates: moment.Moment[];
  calendarType: "date" | "week";
  timeFormat: "HH:mm" | "hh:mm a";
  deletable?: boolean | ((event: T) => boolean);
  showDetail?: IAgendaDetail<T>;
  render: (event: T) => React.ReactNode;
  onClick?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDropEvent?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDelete?: (event: T) => void;
};

const AgendaBody = <T extends IAgenda>({
  timeHours,
  columns,
  weekColumn,
  events,
  cellHeight,
  selectedDate,
  selectedDates,
  calendarType,
  timeFormat,
  deletable,
  showDetail,
  render,
  onClick,
  onDropEvent,
  onDelete,
}: AgendaBodyProps<T>) => {
  const [draggingEvent, setDragginEvent] = useState<T>();

  return (
    <tbody>
      {timeHours.map((hour) => {
        return (
          <tr draggable={false} key={hour.format("HH:mm")}>
            <td style={{ height: cellHeight ? cellHeight : "unset" }}>
              <div
                style={{
                  width: timeFormat === "HH:mm" ? 60 : 75,
                  ...flexCenterd,
                }}
              >
                {hour.format(timeFormat)}
              </div>
            </td>
            {calendarType === "date"
              ? columns.map((col) => {
                  const time = moment(
                    `${selectedDate.format("DD/MM/YYYY")} ${hour.format(
                      "HH:mm"
                    )}`,
                    "DD/MM/YYYY HH:mm"
                  );
                  const event = events.find(
                    (e) => time.isSame(e.date) && e.columnId === col.id
                  );
                  return (
                    <td key={col.id} draggable={false}>
                      <AgendaCell
                        time={time}
                        event={event}
                        column={col}
                        draggingEvent={draggingEvent}
                        deletable={deletable}
                        showDetail={showDetail}
                        setDragginEvent={setDragginEvent}
                        render={render}
                        onClick={onClick}
                        onDropEvent={onDropEvent}
                        onDelete={onDelete}
                      />
                    </td>
                  );
                })
              : selectedDates.map((date) => {
                  const time = moment(
                    `${date.format("DD/MM/YYYY")} ${hour.format("HH:mm")}`,
                    "DD/MM/YYYY HH:mm"
                  );
                  const event = events.find(
                    (e) =>
                      date.isSame(e.date, "date") &&
                      time.isSame(e.date, "hour") &&
                      time.isSame(e.date, "minute")
                  );

                  return (
                    <td key={date.toISOString()}>
                      <AgendaCell
                        time={time}
                        event={event}
                        column={weekColumn!}
                        draggingEvent={draggingEvent}
                        deletable={deletable}
                        showDetail={showDetail}
                        setDragginEvent={setDragginEvent}
                        render={render}
                        onClick={onClick}
                        onDropEvent={onDropEvent}
                        onDelete={onDelete}
                      />
                    </td>
                  );
                })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default AgendaBody;
