import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import TagHeader from "../components/tag/TagHeader";
import TagTable from "../components/tag/TagTable";

const Tag = () => {
  const { tagStore } = useStore();
  const { scopes, exportList } = tagStore;

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    setDownloading(true);
    await exportList(searchParams.get("search") ?? "all");
    setDownloading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  // prettier-ignore
  const message = printing ? "Imprimiendo" : downloading ? "Descargando": undefined;

  return (
    <Spin spinning={printing || downloading} tip={message}>
      <div ref={componentRef}>
        <TagHeader
          handlePrint={handlePrint}
          handleDownload={handleDownload}
          setLoading={setLoading}
        />
        <Divider className="header-divider" />
        <TagTable loading={loading} setLoading={setLoading} />
      </div>
    </Spin>
  );
};

export default observer(Tag);
