import { Button, Switch, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import IconButton from "../../app/common/button/IconButton";
import { EditOutlined } from "@ant-design/icons";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { useStore } from "../../app/stores/store";
import { INotificationsList } from "../../app/models/notifications";

const NotificationsTable = () => {
  const { notificationsStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const { getAllNotifications, updateStatus, scopes } = notificationsStore;
  const [notifications, setNotifications] = useState<INotificationsList[]>([]);

  useEffect(() => {
    const readAvisos = async () => {
      var notificaciones = await getAllNotifications(
        searchParams.get("search") || "all"
      );
      setNotifications(notificaciones!);
    };
    readAvisos();
  }, [getAllNotifications, searchParams]);

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const onchangeStatus = async (id: string) => {
    let notificationes = [...notifications!];
    var notificacion = notificationes.find((x) => x.id === id);
    notificacion!.activo = !notificacion!.activo;
    var notificacionIndex = notificationes.findIndex((x) => x.id === id);
    notificationes[notificacionIndex] = notificacion!;
    setNotifications(notificationes);
    await updateStatus(id);
  };

  const columns: IColumns<INotificationsList> = [
    {
      key: "titulo",
      dataIndex: "titulo",
      title: "Titulo",
      align: "center",
      width: 80,
    },
    {
      ...getDefaultColumnProps("contenido", "Contenido", {
        searchState,
        setSearchState,
        width: 200,
      }),
    },

    {
      ...getDefaultColumnProps("activo", "Activo", {
        searchState,
        setSearchState,
        width: 180,
      }),
      render: (value, item) => (
        <Switch
          checked={value}
          onChange={async () => {
            onchangeStatus(item.id);
          }}
          disabled={!scopes.modificar}
        />
      ),
    },
  ];

  return (
    <>
      <Table size="small" columns={columns} dataSource={[...notifications!]} />
    </>
  );
};
export default observer(NotificationsTable);
