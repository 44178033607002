import { makeAutoObservable, reaction, toJS } from "mobx";

import PriceList from "../api/priceList";
import Proceding from "../api/proceding";
import Quotation from "../api/quotation";
import { IGeneralForm } from "../models/general";
import { IPriceListInfoFilter } from "../models/priceList";
import {
  IQuotation,
  IQuotationGeneral,
  IQuotationInfo,
  IQuotationPack,
  IQuotationStudy,
  IQuotationStudyUpdate,
  IQuotationTotal,
  QuotationStudyUpdate,
  QuotationTotal,
} from "../models/quotation";
import { IRequestPack } from "../models/request";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import { status } from "../util/catalogs";
import history from "../util/history";
import messages from "../util/messages";
import { getDistinct, getErrors, toFixedNumber } from "../util/utils";

export default class QuotationStore {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.studies.slice(),
      () => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.packs.slice(),
      () => {
        this.calculateTotals();
      }
    );
  }

  scopes: IScopes = new Scopes();
  activeTabPane: string = "general";
  studyFilter: IPriceListInfoFilter = {};
  quotations: IQuotationInfo[] = [];
  quotation?: IQuotation;
  observation?: string;
  totals: IQuotationTotal = new QuotationTotal();
  studies: IQuotationStudy[] = [];
  packs: IQuotationPack[] = [];
  loadingQuotations: boolean = false;
  loadingTabContentCount: number = 0;

  disabledClave: boolean = false;
  focusClave: boolean = false;
  focusType: string = "";

  setDisabledClave = (disabled: boolean) => {
    this.disabledClave = disabled;
  };
  setFocusType = (type: string) => {
    this.focusType = type;
  };

  setActiveTab = (tab: string) => {
    this.activeTabPane = tab;
  };

  setFocusClave = (disabled: boolean) => {
    this.focusClave = disabled;
  };

  get loadingTabContent() {
    return this.loadingTabContentCount > 0;
  }

  get studyUpdate() {
    if (this.quotation) {
      const data: IQuotationStudyUpdate = {
        cotizacionId: this.quotation.cotizacionId,
        expedienteId: this.quotation.expedienteId,
        paquetes: this.packs,
        estudios: this.studies,
        total: {
          ...this.totals,
          cotizacionId: this.quotation.cotizacionId,
          expedienteId: this.quotation.expedienteId,
        },
      };
      return data;
    }
    return new QuotationStudyUpdate();
  }

  get allStudies() {
    const packStudies = this.packs
      .flatMap((x) => x.estudios)
      .map((x) => {
        x.type = "pack";
        return x;
      });
    const studies = this.studies.map((x) => {
      x.type = "study";
      return x;
    });

    return [...studies, ...packStudies];
  }

  get allActiveStudies() {
    return this.allStudies.filter(
      (x) => x.estatusId !== status.requestStudy.cancelado && x.asignado
    );
  }

  get readonly() {
    return !this.scopes.modificar || !this.quotation?.activo;
  }

  clearDetailData = () => {
    this.quotation = undefined;
    this.studies = [];
    this.packs = [];
    this.totals = new QuotationTotal();
  };

  isPack(obj: IQuotationStudy | IQuotationPack): obj is IQuotationPack {
    return obj.type === "pack";
  }

  isStudy(obj: IQuotationStudy | IQuotationPack): obj is IQuotationStudy {
    return obj.type === "study";
  }

  get discountModified() {
    return (
      this.studies.some((x) => x.descuentoModificado) ||
      this.packs.some((x) => x.descuentoModificado)
    );
  }

  setStudyFilter = (branchId?: string, doctorId?: string, companyId?: string) => {
    this.studyFilter = {
      sucursalId: branchId,
      medicoId: doctorId,
      compañiaId: companyId,
    };
  };

  setStudy = (study: IQuotationStudy) => {
    const index = this.studies.findIndex((x) => x.id === study.id);

    if (index > -1) {
      this.studies[index] = study;
    }

    this.packs = this.packs.map((x) => {
      const index = x.estudios.findIndex((x) => x.id === study.id);
      if (index > -1) {
        x.estudios[index] = study;
      }
      return x;
    });
  };

  setPack = (pack: IQuotationPack) => {
    const index = this.packs.findIndex((x) => x.id === pack.id);

    if (index > -1) {
      this.packs[index] = pack;
    }
  };

  getById = async (quotationId: string) => {
    try {
      const quotation = await Quotation.getById(quotationId);
      this.quotation = quotation;
    } catch (error) {
      alerts.warning(getErrors(error));
      history.push("/notFound");
    }
  };

  getQuotations = async (filter: IGeneralForm) => {
    try {
      this.loadingQuotations = true;
      const quotations = await Quotation.getQuotations(filter);
      this.quotations = quotations;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingQuotations = false;
    }
  };

  getLocalQuotations = async (filter: IGeneralForm) => {
    try {
      const quotations = await Quotation.getQuotations(filter);
      return quotations;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getGeneral = async (quotationId: string) => {
    try {
      this.loadingTabContentCount++;
      const quotation = await Quotation.getGeneral(quotationId);
      quotation.metodoEnvio = [];
      if (quotation.correo) {
        quotation.metodoEnvio.push("correo");
        quotation.correos = quotation.correo.split(",");
      }
      if (quotation.whatsapp) {
        quotation.metodoEnvio.push("whatsapp");
        quotation.whatsapps = quotation.whatsapp.split(",");
      }
      if (quotation.metodoEnvio.length === 2) quotation.metodoEnvio.push("ambos");
      return quotation;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getStudies = async (quotationId: string) => {
    try {
      this.loadingTabContentCount++;
      const data = await Quotation.getStudies(quotationId);
      if (data.paquetes && data.paquetes.length > 0) {
        this.packs = data.paquetes;
      }
      this.studies = data.estudios;
      this.totals = data.total ?? new QuotationTotal();
      this.calculateTotals();
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getPriceStudy = async (studyId: number, filter: IPriceListInfoFilter) => {
    try {
      filter.estudioId = studyId;
      const price = await PriceList.getPriceStudy(filter);
      // console.log(JSON.parse(JSON.stringify(price)));
      // console.log(JSON.parse(JSON.stringify(filter)));
      const study: IQuotationStudy = {
        ...price,
        type: "study",
        aplicaCargo: false,
        estatusId: status.requestStudy.tomaDeMuestra,
        asignado: true,
        descuentoManual: false,
      };

      const { isRepeated, repeatedMessage } = this.checkDuplicatesInStudies(study);

      if (isRepeated) {
        alerts.confirm(
          "Coincidencias en estudios",
          repeatedMessage,
          async () => {
            this.getPriceStudyAdd(study);
          },
          () => {
            this.disabledClave = false;
            this.setFocusClave(!this.focusClave);
          }
        );
      } else {
        this.getPriceStudyAdd(study);
      }

      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
      return false;
    }
  };

  private getPriceStudyAdd = async (study: IQuotationStudy) => {
    if (!this.studies.map((x) => x.identificador).includes(study.identificador)) {
      this.studies = [...this.studies, study];

      await this.updateStudies(this.studyUpdate, false);

      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
    }
  };

  getPricePack = async (packId: number, filter: IPriceListInfoFilter) => {
    try {
      filter.paqueteId = packId;
      const price = await PriceList.getPricePack(filter);

      const pack: IQuotationPack = {
        ...price,
        type: "pack",
        aplicaCargo: false,
        cancelado: false,
        descuentoManual: false,
        estudios: price.estudios.map((x) => ({
          ...x,
          type: "study",
          aplicaCargo: false,
          estatusId: status.requestStudy.tomaDeMuestra,
          asignado: true,
          descuentoManual: false,
        })),
      };
      const { isRepeated, repeatedMessage } = this.checkDuplicatesInPacks(pack);

      if (isRepeated) {
        alerts.confirm(
          "Coincidencias en paquetes",
          repeatedMessage,
          async () => {
            this.getPricePackAdd(pack);
          },
          () => {
            this.disabledClave = false;
            this.setFocusClave(!this.focusClave);
          }
        );
      } else {
        this.getPricePackAdd(pack);
      }

      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
      return false;
    }
  };

  private getPricePackAdd = async (pack: IQuotationPack) => {
    if (!this.packs.map((x) => x.identificador).includes(pack.identificador)) {
      this.packs = [...this.packs, pack];
      await this.updateStudies(this.studyUpdate, false);

      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
    }
  };

  refreshPromo = async () => {
    this.loadingTabContentCount++;
    let studiesAux: IQuotationStudy[] = this.studies;
    let packsAux: IQuotationPack[] = this.packs;

    this.studyFilter.estudiosIds = this.studies.map((x) => {
      return x.estudioId ?? 0;
    });
    const priceList = await PriceList.getPriceStudies(this.studyFilter);

    priceList.forEach(async (price) => {
      const list = this.studies.filter((o) => o.estudioId === price.estudioId);

      list.forEach((bs) => {
        const index = this.studies.findIndex(
          (x) => x.id === bs.id || x.identificador === bs.identificador
        );
        if (index > -1) {
          studiesAux[index].promocionId = price.promocionId;
          studiesAux[index].promocion = price.promocion;
          studiesAux[index].descuento = price.descuento;
          studiesAux[index].descuentoPorcentaje = price.descuentoPorcentaje;
          studiesAux[index].precioFinal = price.precio - (price.descuento ?? 0);
          studiesAux[index].promociones = price.promociones;
          this.studies = [...studiesAux];
        }
      });
    });
    this.studyFilter.paquetesIds = this.packs.map((x) => {
      return x.paqueteId ?? 0;
    });
    const pricePackList = await PriceList.getPricePackages(this.studyFilter);
    pricePackList.forEach(async (pack) => {
      const list = this.packs.filter((o) => o.paqueteId === pack.paqueteId);
      list.forEach((bp) => {
        const index = this.packs.findIndex(
          (x) => x.id === bp.id || x.identificador === bp.identificador
        );
        if (index > -1) {
          packsAux[index].promocionId = pack.promocionId;
          packsAux[index].promocion = pack.promocion;
          packsAux[index].descuento = pack.descuento;
          packsAux[index].descuentoPorcentaje = pack.descuentoPorcentaje;
          packsAux[index].precioFinal = pack.precio - (pack.descuento ?? 0);
          packsAux[index].promociones = pack.promociones;
          console.log(JSON.parse(JSON.stringify(this.packs[index].promociones)));
          this.packs = [...packsAux];
        }
      });
    });
    this.loadingTabContentCount--;
  };

  getRecords = async (filter: IGeneralForm) => {
    try {
      const records = await Proceding.getNow(filter);
      return records;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  sendTestEmail = async (quotationId: string, email: string[]) => {
    try {
      this.loadingTabContentCount++;
      await Quotation.sendTestEmail(quotationId, email);
      alerts.info("El correo se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  sendTestWhatsapp = async (quotationId: string, phone: string[]) => {
    try {
      this.loadingTabContentCount++;
      await Quotation.sendTestWhatsapp(quotationId, phone);
      alerts.info("El whatsapp se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  create = async (quotation: IQuotation) => {
    try {
      const id = await Quotation.create(quotation);
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  convertToRequest = async (quotationId: string) => {
    try {
      this.loadingTabContentCount++;
      const id = await Quotation.convertToRequest(quotationId);
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  updateGeneral = async (quotation: IQuotationGeneral, autoSave: boolean) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      await Quotation.updateGeneral(quotation);
      if (this.observation !== quotation.observaciones) {
        this.observation = quotation.observaciones;
      }
      if (!autoSave) alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  assignRecord = async (quotationId: string, autoSave: boolean, recordId?: string) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      await Quotation.assignRecord(quotationId, recordId);
      if (!autoSave) alerts.success(messages.updated);
      if (this.quotation) this.quotation.expedienteId = recordId;
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  updateTotals = async (quotation: IQuotationTotal) => {
    try {
      await Quotation.updateTotals(quotation);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateStudies = async (quotation: IQuotationStudyUpdate, autoSave: boolean) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      const response = await Quotation.updateStudies(quotation);
      this.packs = response.paquetes ?? [];
      this.studies = response.estudios;
      if (!autoSave) alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  changeStudyDiscount = (study: IQuotationStudy, discount: number) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
    );
    if (index > -1) {
      const _study: IQuotationStudy = toJS(study);
      _study.descuentoManual = true;
      if (!_study.descuentoModificado) {
        _study.descuentoOriginal = _study.descuento;
        _study.promocionOriginal = _study.promocion;
        _study.promocionIdOriginal = _study.promocionId;
      }
      _study.promocionId = undefined;
      _study.promocion = undefined;
      _study.descuento = Number((_study.precio * discount).toFixed(2));
      _study.descuentoPorcentaje = discount * 100;
      _study.precioFinal = _study.precio - (_study.descuento ?? 0);
      _study.descuentoModificado = true;

      this.studies[index] = _study;

      return _study.descuento;
    }

    return 0;
  };

  changeStudyDiscountSingle = (study: IQuotationStudy, discount: number) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
    );
    if (index > -1) {
      const _study: IQuotationStudy = toJS(study);
      _study.descuentoManual = true;
      if (!_study.descuentoModificado) {
        _study.descuentoOriginal = _study.descuento;
        _study.promocionOriginal = _study.promocion;
        _study.promocionIdOriginal = _study.promocionId;
      }
      _study.promocionId = undefined;
      _study.promocion = undefined;
      _study.descuento = Number(discount.toFixed(2)); // Number((_study.precio * discount).toFixed(2));
      _study.descuentoPorcentaje = Number(((discount * 100) / _study.precio).toFixed(2));
      _study.precioFinal = _study.precio - (_study.descuento ?? 0);
      _study.descuentoModificado = true;

      this.studies[index] = _study;

      return _study.descuento;
    }

    return 0;
  };

  changeStudyPromotion = (study: IQuotationStudy, promoId?: number) => {
    const index = this.studies.findIndex(
      (x) => x.id === study.id && x.identificador === study.identificador
    );
    if (index > -1) {
      const _study = { ...this.studies[index] };
      const promo = this.studies[index].promociones.find((x) => x.promocionId === promoId);
      this.studies[index] = {
        ..._study,
        promocionId: promoId,
        promocion: promo?.promocion,
        descuento: promo?.descuento,
        descuentoPorcentaje: promo?.descuentoPorcentaje,
        precioFinal: _study.precio - (promo?.descuento ?? 0),
        descuentoModificado: true,
      };
    }
  };

  changePackDiscount = (pack: IQuotationPack, discount: number) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );
    if (index > -1) {
      const _pack: IQuotationPack = toJS(pack);

      _pack.descuentoManual = true;
      if (!_pack.descuentoModificado) {
        _pack.descuentoOriginal = _pack.descuento;
        _pack.promocionOriginal = _pack.promocion;
        _pack.promocionIdOriginal = _pack.promocionId;
      }
      _pack.promocionId = undefined;
      _pack.promocion = undefined;
      _pack.descuento = Number((_pack.precio * discount).toFixed(2));
      _pack.descuentoPorcentaje = discount * 100;
      _pack.precioFinal = _pack.precio - (_pack.descuento ?? 0);
      _pack.descuentoModificado = true;

      this.packs[index] = _pack;

      return _pack.descuento;
    }

    return 0;
  };

  changePackDiscountSingle = (pack: IQuotationPack, discount: number) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );
    if (index > -1) {
      const _pack: IQuotationPack = toJS(pack);

      _pack.descuentoManual = true;
      if (!_pack.descuentoModificado) {
        _pack.descuentoOriginal = _pack.descuento;
        _pack.promocionOriginal = _pack.promocion;
        _pack.promocionIdOriginal = _pack.promocionId;
      }
      _pack.promocionId = undefined;
      _pack.promocion = undefined;
      _pack.descuento = Number(discount.toFixed(2)); // Number((_study.precio * discount).toFixed(2));
      _pack.descuentoPorcentaje = Number(((discount * 100) / _pack.precio).toFixed(2));
      _pack.precioFinal = _pack.precio - (_pack.descuento ?? 0);
      _pack.descuentoModificado = true;

      this.packs[index] = _pack;

      return _pack.descuento;
    }

    return 0;
  };

  changePackPromotion = (pack: IQuotationPack, promoId?: number) => {
    const index = this.packs.findIndex(
      (x) => x.id === pack.id && x.identificador === pack.identificador
    );
    if (index > -1) {
      const _pack = { ...this.packs[index] };
      const promo = this.packs[index].promociones.find((x) => x.promocionId === promoId);
      this.packs[index] = {
        ..._pack,
        promocionId: promoId,
        promocion: promo?.promocion,
        descuento: promo?.descuento,
        descuentoPorcentaje: promo?.descuentoPorcentaje,
        precioFinal: _pack.precio - (promo?.descuento ?? 0),
        descuentoModificado: true,
      };
    }
  };

  changeTotalsDiscount = async (totalDiscount: number) => {
    try {
      // const discounts = this.getDiscounts(totalDiscount);
      const { totalEstudios } = this.totals;

      const discountPercentage = Number((totalDiscount / totalEstudios).toFixed(4));

      let totalDiscounted = 0;

      const activeStudies = this.studies.filter(
        (x) => x.estatusId !== status.requestStudy.cancelado
      );
      const activePacks = this.packs.filter((x) => !x.cancelado);

      for (const study of activeStudies) {
        totalDiscounted += this.changeStudyDiscount(study, discountPercentage);
      }

      for (const pack of activePacks) {
        totalDiscounted += this.changePackDiscount(pack, discountPercentage);
      }

      const difference = Number((totalDiscount - totalDiscounted).toFixed(2));
      if (difference !== 0) {
        const maxStudy =
          activeStudies.length === 0
            ? undefined
            : activeStudies.reduce(
                (max, item) => (item.precio > max.precio ? item : max),
                activeStudies[0]
              );
        const maxPack =
          activePacks.length === 0
            ? undefined
            : activePacks.reduce(
                (max, item) => (item.precio > max.precio ? item : max),
                activePacks[0]
              );
        const max = (maxStudy?.precio ?? 0) > (maxPack?.precio ?? 0) ? maxStudy : maxPack;
        if (max && this.isStudy(max)) {
          const index = this.studies.findIndex(
            (x) => (x.id ?? x.identificador) === (max.id ?? max.identificador)
          );
          if (index > -1) {
            const maxStudy = toJS(max);

            maxStudy.descuento = Number(
              (maxStudy.precio * discountPercentage + difference).toFixed(2)
            );
            maxStudy.descuentoPorcentaje = Number(
              ((maxStudy.descuento * 100) / maxStudy.precio).toFixed(2)
            );
            maxStudy.precioFinal = maxStudy.precio - (maxStudy.descuento ?? 0);
            this.studies[index].descuento = maxStudy.descuento;
            this.studies[index].descuentoPorcentaje = maxStudy.descuentoPorcentaje;
            this.studies[index].precioFinal = maxStudy.precioFinal;
          }
        } else if (max && this.isPack(max)) {
          const index = this.packs.findIndex(
            (x) => (x.id ?? x.identificador) === (max.id ?? max.identificador)
          );
          if (index > -1) {
            const maxPack = toJS(max);
            maxPack.descuento = Number(
              (maxPack.precio * discountPercentage + difference).toFixed(2)
            );
            maxPack.descuentoPorcentaje = Number(
              ((maxPack.descuento * 100) / maxPack.precio).toFixed(2)
            );
            maxPack.precioFinal = maxPack.precio - (maxPack.descuento ?? 0);
            this.packs[index].descuento = maxPack.descuento;
            this.packs[index].descuentoPorcentaje = maxPack.descuentoPorcentaje;
            this.packs[index].precioFinal = maxPack.precioFinal;
          }
        }
      }

      const finalTotal = this.totals.totalEstudios - totalDiscount;

      const totals = {
        ...this.totals,
        expedienteId: this.quotation!.expedienteId,
        cotizacionId: this.quotation!.cotizacionId!,
        descuento: toFixedNumber(totalDiscount, 2),
        total: toFixedNumber(finalTotal, 2),
      };
      this.totals = totals;

      await this.updateStudies(this.studyUpdate, false);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  deleteStudy = async (id: string) => {
    this.studies = this.studies.filter((x) => x.identificador !== id);
  };

  deletePack = async (id: number | string) => {
    this.packs = this.packs.filter((x) => x.identificador !== id);
  };

  cancelQuotation = async (quotationId: string) => {
    try {
      await Quotation.cancelQuotation(quotationId);
      if (this.quotation) {
        this.quotation.estatusId = status.quotation.cancelado;
        this.quotation.activo = false;
      }
      const index = this.quotations.findIndex((x) => x.cotizacionId === quotationId);
      if (index > -1) {
        const app = [...this.quotations];
        app[index].activo = false;
        this.quotations = app;
      }
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteCurrentQuotation = async () => {
    try {
      if (this.quotation?.cotizacionId) {
        await Quotation.deleteQuotation(this.quotation.cotizacionId);
        this.quotations = this.quotations.filter(
          (x) => x.cotizacionId !== this.quotation?.cotizacionId
        );
        return true;
      }
      alerts.warning("No hay cotización por eliminar");
      return false;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteStudies = async (quotation: IQuotationStudyUpdate) => {
    try {
      await Quotation.deleteStudies(quotation);
      alerts.success(messages.updated);

      this.studies = this.studies.filter((x) => {
        const exists = quotation.estudios.find(
          (s) => s.id === x.id && s.identificador === x.identificador
        );
        if (exists) return false;
        return true;
      });

      this.packs = this.packs.filter((x) => {
        const exists = quotation.paquetes?.find(
          (s) => s.id === x.id && s.identificador === x.identificador
        );
        if (exists) return false;
        return true;
      });

      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  getQuotationPdf = async (id: string) => {
    try {
      const url = await Quotation.getQuotePdfUrl(id);
      return url;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  // prettier-ignore
  private checkDuplicatesInStudies(study: IQuotationStudy) {
    let isRepeated = false;
    let repeatedMessage = "";

    const activeStudies = toJS(
      this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado)
    );

    const isRepeatedStudy = activeStudies.some(
      (x) => x.estudioId === study.estudioId
    );
    if (isRepeatedStudy) {
      isRepeated = true;
      repeatedMessage = `El estudio ${study.clave} ya se encuentra agregado`;
    } else {
      const activePacks = toJS(this.packs.filter((x) => !x.cancelado));

      // prettier-ignore
      let repeatedInPacks = activePacks.filter((x) => x.estudios.some((s) => s.estudioId === study.estudioId)).map(x => x.clave);
      repeatedInPacks = getDistinct(repeatedInPacks);
      if (repeatedInPacks.length > 0) {
        isRepeated = true;
        // prettier-ignore
        repeatedMessage = `El estudio ${study.clave} ya se encuentra agregado en ${repeatedInPacks.length === 1 ? "el paquete" : "los paquetes"} ${repeatedInPacks.join(", ")}`;
      } else {
        const studyParams = study.parametros.map((x) => x.id);

        // prettier-ignore
        const repeatedParamsInStudies = activeStudies.filter((x) => x.parametros.some((p) => studyParams.includes(p.id)))
          .map((x) => ({
            ...x,
            parametros: x.parametros.filter((p) => studyParams.includes(p.id)),
          }));
        // prettier-ignore
        const repeatedParamsInPacks = activePacks.filter((x) => x.estudios.some((s) => s.parametros.some((p) => studyParams.includes(p.id))))
          .map((x) => ({
            ...x,
            estudios: x.estudios.filter((s) => s.parametros.some((p) => studyParams.includes(p.id)))
              .map((s) => ({
                ...s,
                parametros: s.parametros.filter((p) => studyParams.includes(p.id)),
              })),
          }));

        if (
          repeatedParamsInStudies.length > 0 ||
          repeatedParamsInPacks.length > 0
        ) {
          isRepeated = true;
          // prettier-ignore
          let repeatedParamsCodes = repeatedParamsInStudies.flatMap((x) => x.parametros).map((x) => x.clave);
          // prettier-ignore
          repeatedParamsCodes = repeatedParamsCodes.concat(repeatedParamsInPacks.flatMap((x) => x.estudios).flatMap((x) => x.parametros).map((x) => x.clave));
          repeatedParamsCodes = getDistinct(repeatedParamsCodes);

          const many = repeatedParamsCodes.length > 1;
          const manyStudies = repeatedParamsInStudies.length > 1;
          const manyPacks = repeatedParamsInPacks.length > 1;
          // prettier-ignore
          repeatedMessage = `${!many ? "El parametro" : "Los parametros"} ${repeatedParamsCodes.join(", ")} 
          ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""} 
          en ${repeatedParamsInStudies.length > 0 ? `estudio${manyStudies ? "s" : ""}: ${repeatedParamsInStudies.map(x => x.clave).join(",")}` : ""}
          ${repeatedParamsInStudies.length > 0 && repeatedParamsInPacks.length > 0 ? " y " : ""}
          ${repeatedParamsInPacks.length > 0 ? `paquete${manyPacks ? "s" : ""}: ${repeatedParamsInPacks.map(x => x.clave).join(",")}` : ""}`;
        }
      }
    }

    return { isRepeated, repeatedMessage };
  }

  private checkDuplicatesInPacks(pack: IQuotationPack) {
    let isRepeated = false;
    let repeatedMessage = "";

    const activePacks = toJS(this.packs.filter((x) => !x.cancelado));

    const isRepeatedPack = activePacks.some((x) => x.paqueteId === pack.paqueteId);
    if (isRepeatedPack) {
      isRepeated = true;
      repeatedMessage = `El paquete ${pack.clave} ya se encuentra agregado`;
    } else {
      // prettier-ignore
      let repeatedStudies = this.allActiveStudies.filter((x) => pack.estudios.some((s) => s.estudioId === x.estudioId)).map(x => x.clave);
      repeatedStudies = getDistinct(repeatedStudies);
      if (repeatedStudies.length > 0) {
        isRepeated = true;
        const many = repeatedStudies.length > 1;
        // prettier-ignore
        repeatedMessage = `${!many ? "El estudio" : "Los estudios"} ${repeatedStudies.join(",")} ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""}`;
      } else {
        // prettier-ignore
        const activeStudies = toJS(this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado));

        const packParams = pack.estudios.flatMap((x) => x.parametros.map((x) => x.id));

        // prettier-ignore
        const repeatedParamsInStudies = activeStudies.filter((x) => x.parametros.some((p) => packParams.includes(p.id)))
          .map((x) => ({
            ...x,
            parametros: x.parametros.filter((p) => packParams.includes(p.id)),
          }));
        // prettier-ignore
        const repeatedParamsInPacks = activePacks.filter((x) => x.estudios.some((s) => s.parametros.some((p) => packParams.includes(p.id))))
          .map((x) => ({
            ...x,
            estudios: x.estudios.filter((s) => s.parametros.some((p) => packParams.includes(p.id)))
              .map((s) => ({
                ...s,
                parametros: s.parametros.filter((p) => packParams.includes(p.id)),
              })),
          }));

        if (repeatedParamsInStudies.length > 0 || repeatedParamsInPacks.length > 0) {
          isRepeated = true;
          // prettier-ignore
          let repeatedParamsCodes = repeatedParamsInStudies.flatMap((x) => x.parametros).map((x) => x.clave);
          // prettier-ignore
          repeatedParamsCodes = repeatedParamsCodes.concat(repeatedParamsInPacks.flatMap((x) => x.estudios).flatMap((x) => x.parametros).map((x) => x.clave));
          repeatedParamsCodes = getDistinct(repeatedParamsCodes);

          const many = repeatedParamsCodes.length > 1;
          const manyStudies = repeatedParamsInStudies.length > 1;
          const manyPacks = repeatedParamsInPacks.length > 1;
          // prettier-ignore
          repeatedMessage = `${!many ? "El parametro" : "Los parametros"} ${repeatedParamsCodes.join(", ")} 
          ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""} 
          en ${repeatedParamsInStudies.length > 0 ? `estudio${manyStudies ? "s" : ""}: ${repeatedParamsInStudies.map(x => x.clave).join(",")}` : ""}
          ${repeatedParamsInStudies.length > 0 && repeatedParamsInPacks.length > 0 ? " y " : ""}
          ${repeatedParamsInPacks.length > 0 ? `paquete${manyPacks ? "s" : ""}: ${repeatedParamsInPacks.map(x => x.clave).join(",")}` : ""}`;
        }
      }
    }

    return { isRepeated, repeatedMessage };
  }

  private calculateTotals = () => {
    const studies = this.studies;
    const packs = this.packs;

    const studyAndPack = studies
      .map((x) => ({ descuento: x.descuento ?? 0, precio: x.precio, precioFinal: x.precioFinal }))
      .concat(
        packs.map((x) => ({
          descuento: x.descuento ?? 0,
          precio: x.precio,
          precioFinal: x.precioFinal,
        }))
      );

    const totalStudies = studyAndPack.reduce((acc, obj) => acc + obj.precio, 0);

    const discount =
      totalStudies === 0 ? 0 : studyAndPack.reduce((acc, obj) => acc + obj.descuento, 0);

    const finalTotal = totalStudies - discount;

    this.totals = {
      ...this.totals,
      totalEstudios: toFixedNumber(totalStudies, 2),
      descuento: toFixedNumber(discount, 2),
      total: toFixedNumber(finalTotal, 2),
    };
  };
}
