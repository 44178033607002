import { Button, Col, Form, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import { IInvoiceCancelation } from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import { cancelationReasonOptions } from "../utils/options";
import { InvoiceType } from "../utils/utils";

type Props = {
  invoiceId: string;
  type: InvoiceType;
  getResult: (response: boolean) => void;
};

const InvoiceCancelation = ({ invoiceId, type, getResult }: Props) => {
  const { invoiceStore } = useStore();
  const { cancel, scopes } = invoiceStore;

  const [form] = Form.useForm<IInvoiceCancelation>();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: IInvoiceCancelation) => {
    const invoiceCancelation: IInvoiceCancelation = {
      facturaId: invoiceId,
      motivo: values.motivo,
    };
    setLoading(true);
    const cancelled = await cancel(type, invoiceCancelation);
    setLoading(false);
    if (cancelled) {
      getResult(cancelled);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form<IInvoiceCancelation>
        layout="vertical"
        form={form}
        name="invoiceCancel"
        onFinish={onFinish}
        size="small"
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <SelectInput
              formProps={{
                label: "Selecciona motivo de cancelación",
                name: "motivo",
              }}
              options={cancelationReasonOptions}
              required
            />
          </Col>
          <Col span={24} style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!scopes.modificar}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default observer(InvoiceCancelation);
