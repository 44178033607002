import { IRequestPayment } from "../models/request";
import requests from "./agent";

const NetPay = {
  paymentCharge: (paymentInfo: IRequestPayment): Promise<void> =>
    requests.post("netpay/payment/charge", paymentInfo),
  paymentCancel: (paymentInfo: IRequestPayment): Promise<void> =>
    requests.post("netpay/payment/cancel", paymentInfo),
  paymentReprint: (paymentInfo: IRequestPayment): Promise<void> =>
    requests.post("netpay/payment/reprint", paymentInfo),
};

export default NetPay;
