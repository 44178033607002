import { Table } from "antd";
import { observer } from "mobx-react-lite";

import { IColumns } from "../../../app/common/table/utils";
import {
  IInvoiceRequestCompanyStudy,
} from "../../../app/models/invoiceCompany";
import { moneyFormatter } from "../../../app/util/utils";

type Props = {
  studies: IInvoiceRequestCompanyStudy[];
};

const InvoiceCompanyTable = ({ studies }: Props) => {
  const columns: IColumns<IInvoiceRequestCompanyStudy> = [
    {
      dataIndex: "clave",
      title: "Clave",
      width: "20%",
    },
    {
      dataIndex: "nombre",
      title: "Nombre",
      width: "55%",
    },
    {
      dataIndex: "cantidad",
      title: "Unidades",
      width: "5%",
    },
    {
      dataIndex: "precio",
      title: "Importe",
      width: "20%",
      render: (value) => `${moneyFormatter.format(value)}`,
    },
  ];

  return (
    <Table<IInvoiceRequestCompanyStudy>
      bordered
      showHeader={false}
      pagination={false}
      rowKey={(record) => record.clave}
      columns={columns}
      dataSource={studies}
      locale={{
        emptyText: "Sin facturas timbradas",
      }}
    />
  );
};

export default observer(InvoiceCompanyTable);
