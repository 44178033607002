import { PageHeader } from "antd";
import { observer } from "mobx-react-lite";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import { useStore } from "../../../app/stores/store";

type Props = {
  setLoading: (loading: boolean) => void;
};

const InvoiceCompanyHeader = ({ setLoading }: Props) => {
  const { invoiceCreditStore } = useStore();
  const { filter, downloadList } = invoiceCreditStore;

  const download = async () => {
    setLoading(true);
    await downloadList(filter);
    setLoading(false);
  };

  return (
    <PageHeader
      ghost
      title={
        <HeaderTitle
          title={"Crédito y cobranza (Seguimiento de créditos)"}
          image="invoice-company"
        />
      }
      className="header-container"
      extra={[<DownloadIcon key="doc" onClick={download} />]}
    />
  );
};
export default observer(InvoiceCompanyHeader);
