import { Button, Checkbox, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import DateRangeInput from "../../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import {
  IInvoiceRequestCompanyFilter,
  InvoiceRequestCompanyFilterValues,
} from "../../../app/models/invoiceCompany";
import { useStore } from "../../../app/stores/store";
import { formItemLayout } from "../../../app/util/utils";
import { InvoiceRequestCompanyType } from "../invoice/utils/utils";

const statusOptions = [
  {
    label: "Facturadas",
    value: "Facturado",
  },
  {
    label: "No Facturadas",
    value: "Pendiente",
  },
  {
    label: "Canceladas",
    value: "Cancelado",
  },
];

type Props = {
  type: InvoiceRequestCompanyType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceCompanyFilter = ({ type, setLoading }: Props) => {
  const {
    profileStore,
    optionStore,
    invoiceRequestCompanyStore: invoiceCompanyStore,
  } = useStore();
  const { profile } = profileStore;
  const {
    branchCityOptions,
    companyOptions,
    getBranchCityOptions,
    getCompanyOptions,
  } = optionStore;
  const { filter, setFilter, setInvoices, getAll } = invoiceCompanyStore;

  const [form] = Form.useForm<IInvoiceRequestCompanyFilter>();

  useEffect(() => {
    if (branchCityOptions.length === 0) getBranchCityOptions();
    if (companyOptions.length === 0) getCompanyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const read = async (filter: IInvoiceRequestCompanyFilter) => {
    setLoading(true);
    const invoices = await getAll(type, filter);
    setInvoices(invoices);
    setLoading(false);
  };

  useEffect(() => {
    if (filter.cargaInicial) {
      const newFilter = {
        ...filter,
        sucursales: [profile!.sucursal],
        cargaInicial: false,
      };
      setFilter(newFilter);
      form.setFieldsValue(newFilter);
      read(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filter.cargaInicial) {
      read(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const onFinish = async (values: IInvoiceRequestCompanyFilter) => {
    if (values.fechas) {
      values.fechas[0] = values.fechas[0].utcOffset(0, true);
      values.fechas[1] = values.fechas[1].utcOffset(0, true);
    }
    setFilter(values);
    read(values);
  };

  const resetFilter = () => {
    const clear = new InvoiceRequestCompanyFilterValues();
    clear.sucursales = [profile!.sucursal];
    clear.cargaInicial = false;
    setFilter(clear);
    form.setFieldsValue(clear);
  };

  return (
    <div className="status-container">
      <Form<IInvoiceRequestCompanyFilter>
        {...formItemLayout}
        form={form}
        name={`invoice${type}`}
        onFinish={onFinish}
        size="small"
        initialValues={filter}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <DateRangeInput
              formProps={{
                name: "fechas",
                label: "Fechas",
              }}
              disableAfterDates
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{
                name: "sucursales",
                label: "Sucursal",
              }}
              multiple
              options={branchCityOptions}
              selectOnlyChildren
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{
                name: "compañias",
                label: "Compañias",
              }}
              multiple
              options={companyOptions}
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Buscar",
              }}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              label="Estatus"
              name="estatus"
              help=""
              className="no-error-text"
            >
              <Checkbox.Group options={statusOptions} />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: "end" }}>
            <Button onClick={resetFilter}>Limpiar</Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(InvoiceCompanyFilter);
