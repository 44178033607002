import { Button, PageHeader } from "antd";
import { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import GoBackIcon from "../../app/common/icons/GoBackIcon";
import { IScopes } from "../../app/models/shared";

type EquipmentHeaderProps = {
  handlePrint: () => void;
  scopes: IScopes;
};

type UrlParams = {
  id: string;
};

const EquipmentMantainHeader: FC<EquipmentHeaderProps> = ({
  handlePrint,
  scopes,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<UrlParams>();
  const equipmentId = !id ? 0 : isNaN(Number(id)) ? undefined : parseInt(id);

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Mantenimiento de Equipo" image="equipo" />}
      className="header-container"
      extra={[
        <GoBackIcon
          key="back"
          onClick={() => {
            navigate(`/equipment`);
          }}
        />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate(`/equipmentMantain/new/${equipmentId}`);
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default EquipmentMantainHeader;
