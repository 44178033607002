import { makeAutoObservable } from "mobx";
import RequestedStudy from "../api/requestedStudy";
import {
  IRequestedStudyList,
  IRequestedStudy,
  IUpdate,
  IUpdateDate,
} from "../models/requestedStudy";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import messages from "../util/messages";
import { getErrors } from "../util/utils";
import { status } from "../util/catalogs";
import { IGeneralForm } from "../models/general";

export default class RequestedStudyStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  data: IRequestedStudyList[] = [];
  studies: IRequestedStudy[] = [];
  loadingStudies: boolean = false;

  clearStudy = () => {
    this.data = [];
  };

  getAll = async (search: IGeneralForm) => {
    try {
      this.loadingStudies = true;
      const study = await RequestedStudy.getAll(search);
      this.data = study;
      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.data = [];
    } finally {
      this.loadingStudies = false;
    }
  };

  update = async (study: IUpdate[]) => {
    try {
      await RequestedStudy.update(study);
      alerts.success(messages.updated);

      // const ids = study.estudioId;

      this.data = this.data.map((x) => {
        x.estudios = x.estudios.map((z) => {
          const updated = study.find(
            (y) => y.solicitudId === x.id && y.estudioId.includes(z.id)
          );
          if (updated) {
            z.estatus =
              z.estatus === status.requestStudy.tomaDeMuestra
                ? status.requestStudy.solicitado
                : status.requestStudy.tomaDeMuestra;
          }

          return z;
        });

        x.paquetes = x.paquetes.map((p) => {
          p.estudios = p.estudios.map((z) => {
            const updated = study.find(
              (y) => y.solicitudId === x.id && y.estudioId.includes(z.id)
            );
            if (updated) {
              z.estatus =
                z.estatus === status.requestStudy.tomaDeMuestra
                  ? status.requestStudy.solicitado
                  : status.requestStudy.tomaDeMuestra;
            }
  
            return z;
          });

       
          return p;
        });
        return x;
      });

      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  printOrder = async (recordId: string, requestId: string) => {
    try {
      await RequestedStudy.getOrderPdf(recordId, requestId);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  printOrderAsImage = async (recordId: string, requestId: string) => {
    try {
      return await RequestedStudy.getOrderAsImage(recordId, requestId);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  exportList = async (search: IGeneralForm) => {
    try {
      await RequestedStudy.exportList(search);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  updateDate = async (requestedStudy: IUpdateDate) => {
    try {
      await RequestedStudy.updateDate(requestedStudy);
      alerts.success(messages.updated);
      this.data = this.data.map((x) => {
        x.estudios = x.estudios.map((z) => {
          return z;
        });
        x.paquetes = x.paquetes.map((p) => {
          p.estudios = p.estudios.map((z) => {
            return z;
          });
          return p;
        });
        return x;
      });

      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };
}
