import { Table } from "antd";
import { IColumns, ISearch, getDefaultColumnProps } from "../../../app/common/table/utils";
import { IReportData, IStudiesData } from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";

const getDebtStatsColumns = (
    searchState: ISearch,
    setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
  ) => {
    const columns: IColumns<IReportData> = [
      {
        ...getDefaultColumnProps("solicitud", "Solicitud", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("paciente", "Nombre del Paciente", {
          searchState,
          setSearchState,
          width: "35%",
        }),
      },
      {
        ...getDefaultColumnProps("empresa", "Compañia", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("totalEstudios", "Total", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("pagado", "A cuenta", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("saldo", "Saldo", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      }
    ];
  
    return columns;
  };

  
export const expandableDebtConfig = () => {
    const nestedColumns: IColumns<IStudiesData> = [
      {
        ...getDefaultColumnProps("clave", "Clave", {
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("estudio", "Nombre del estudio", {
          width: "30%",
        }),
      },
    ];
  
    return {
      expandedRowRender: (item: IReportData, index: any) => (
        <Table
          columns={nestedColumns}
          dataSource={item.estudio}
          pagination={false}
          className="header-expandable-table"
          showHeader={index === 0}
        />
      ),
      rowExpandable: () => true,
      defaultExpandAllRows: true,
    };
  };
  
  export default getDebtStatsColumns;