import { makeAutoObservable } from "mobx";

import Study from "../api/study";
import { IIndicationList } from "../models/indication";
import { IParameterList } from "../models/parameter";
import { IScopes, Scopes } from "../models/shared";
import { IStudyForm, IStudyList, IStudyTag } from "../models/study";
import { IWorkList } from "../models/workList";
import alerts from "../util/alerts";
import history from "../util/history";
import messages from "../util/messages";
import responses from "../util/responses";
import { getErrors } from "../util/utils";

export default class StudyStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  study: IStudyList[] = [];
  parameterSelected: IParameterList[] = [];
  indicationSelected: IIndicationList[] = [];
  tagsSelected: IStudyTag[] = [];
  workListSelected: IWorkList[] = [];
  loading = false;
  setParameterSelected = (parametersSelected: IParameterList[]) => {
    this.parameterSelected = parametersSelected;
  };

  getParameterSelected = () => {
    return this.parameterSelected;
  };
  setIndicationSelected = (indicationsSelected: IIndicationList[]) => {
    this.indicationSelected = indicationsSelected;
  };

  getIndicationSelected = () => {
    return this.indicationSelected;
  };

  setTagsSelected = (tagsSelected: IStudyTag[]) => {
    this.tagsSelected = tagsSelected;
  };

  getTagsSelected = () => {
    return this.tagsSelected;
  };
  setWorkListSelected = (workListSelected: IWorkList[]) => {
    this.workListSelected = workListSelected;
  };

  getWorkListSelected = () => {
    return this.workListSelected;
  };

  clearStudy = () => {
    this.study = [];
  };

  getAll = async (search: string) => {
    try {
      this.loading = true;
      const study = await Study.getAll(search);
      this.loading = false;
      this.study = study;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.study = [];
      this.loading = false;
    } finally {
      this.loading = false;
    }
  };

  getById = async (id: number) => {
    try {
      const study = await Study.getById(id);
      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getTecInfoById = async (id: number) => {
    try {
      const study = await Study.getStudyInfoById(id);
      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  create = async (study: IStudyForm) => {
    try {
      await Study.create(study);
      alerts.success(messages.created);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };
  update = async (study: IStudyForm) => {
    try {
      await Study.update(study);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };
  exportList = async (search: string) => {
    try {
      await Study.exportList(search);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
  exportForm = async (id: number, clave?: string) => {
    try {
      await Study.exportForm(id, clave);
      return true;
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };
}
