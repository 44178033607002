import { Col, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { IInvoiceCredit } from "../../../app/models/invoiceCredit";

const { Text, Title } = Typography;

type Props = {
  invoice: IInvoiceCredit;
};

const CreditMonitoringInvoiceDetail = ({ invoice }: Props) => {
  return (
    <>
      <Title level={5}>Datos de la compañía</Title>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Compañía" content={invoice.compañia} />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <DescriptionItem title="RFC" content={invoice.rfc} />
        </Col>
        <Col span={18}>
          <DescriptionItem title="Razón social" content={invoice.razonSocial} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Régimen fiscal"
            content={invoice.regimenFiscal}
          />
        </Col>
      </Row>
      <Title level={5}>Datos de la factura</Title>
      <Row>
        <Col span={8}>
          <DescriptionItem
            title="Método de pago"
            content={invoice.metodoPago.split(" ")[0]}
          />
        </Col>
        <Col span={16}>
          <DescriptionItem title="Forma de pago" content={invoice.formaPago} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Uso de CFDI" content={invoice.usoCfdi} />
        </Col>
      </Row>
    </>
  );
};

export default observer(CreditMonitoringInvoiceDetail);

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div style={{ marginBottom: 12 }}>
    <Text style={{ color: "rgba(0, 0, 0, 0.85)", marginRight: 8 }}>
      {title}:
    </Text>
    <Text style={{ color: "rgba(0, 0, 0, 0.65)" }}>{content}</Text>
  </div>
);
