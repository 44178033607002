import { Button, PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import { useStore } from "../../../app/stores/store";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceCompanyHeader = ({ setLoading }: Props) => {
  const { invoiceFreeStore } = useStore();
  const { filter, downloadList, scopes } = invoiceFreeStore;

  let navigate = useNavigate();

  const createInvoice = () => {
    navigate("/invoices/free/new");
  };

  const download = async () => {
    setLoading(true);
    await downloadList(filter);
    setLoading(false);
  };

  return (
    <PageHeader
      ghost
      title={
        <HeaderTitle
          title={"Crédito y cobranza (Facturación libre)"}
          image="invoice-company"
        />
      }
      className="header-container"
      extra={[
        scopes.descargar && <DownloadIcon key="doc" onClick={download} />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={createInvoice}
            icon={<PlusOutlined />}
          >
            Generar
          </Button>
        ),
      ]}
    />
  );
};
export default observer(InvoiceCompanyHeader);
