import { Divider, Spin } from "antd";
import { Fragment, useState } from "react";
import { useStore } from "../app/stores/store";
import ReportHeader from "../components/reportRequest/list/ReportHeader";
import ReportFilter from "../components/reportRequest/list/ReportFilter";
import ReportTable from "../components/reportRequest/list/ReportTable";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";

const ReportStudy = () => {
  const { reportStudyStore, generalStore } = useStore();
  const { scopes, printPdf, downloadList } = reportStudyStore;
  const { generalFilter } = generalStore;

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    await printPdf(generalFilter);
    setLoading(false);
  };

  const handleDownloadList = async () => {
    setLoading(true);
    await downloadList(generalFilter);
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <ReportHeader
        handleDownload={handleDownload}
        handleDownloadList={handleDownloadList}
      />
      <Divider className="header-divider" />
      <Spin spinning={loading} tip="Descargando">
        <ReportFilter />
        <ReportTable />
      </Spin>
    </Fragment>
  );
};

export default observer(ReportStudy);
