import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Link, Text } = Typography;

type Props = {
  to: string;
  text: string;
  canRedirect: boolean;
};

const LinkTo = ({ to, text, canRedirect }: Props) => {
  const navigate = useNavigate();

  return canRedirect ? (
    <Link onClick={() => navigate(to)}>{text}</Link>
  ) : (
    <Text>{text}</Text>
  );
};

export default LinkTo;
