import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ItipoValorForm } from "../../../../app/models/parameter";
import { numberRules } from "../../../../app/util/utils";
import { useSearchParams } from "react-router-dom";

type Props = {
  description: string;
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const RangoEdad: FC<Props> = ({
  description,
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [formValue] = Form.useForm<ItipoValorForm[]>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "ReadOnly"
  );

  useEffect(() => {
    setReadonly(searchParams.get("mode") === "ReadOnly");
  }, [searchParams]);

  const onValuesChange = (_: any, values: any) => {
    setParameterValues(
      values.value
        .filter((x: any) => x != null)
        .map((x: ItipoValorForm, i: any) => ({
          ...x,
          nombre: valueType,
          orden: i + 1,
        }))
    );
  };

  return (
    <div>
      <Divider orientation="left">{description}</Divider>
      <Form<any[]>
        form={formValue}
        layout={"vertical"}
        name={`form-${valueType}`}
        style={{ marginTop: 20 }}
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{ value: parameterValues }}
      >
        <Form.List name="value">
          {(Fields, { add, remove }) => (
            <>
              {Fields.map(({ key, name, ...valuesValor }) => (
                <div key={key}>
                  <Row justify="space-between" gutter={[12, 4]}>
                    <Col span={3}>
                      <Form.Item hidden name={[name, "id"]}>
                        <InputNumber disabled={true} />
                      </Form.Item>
                      <Form.Item
                        {...valuesValor}
                        label="Rango edad inicial: "
                        name={[name, "rangoEdadInicial"]}
                        rules={[
                          {
                            required: true,
                            message: "Valor faltante",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          disabled={readonly}
                          placeholder={"Rango Edad"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...valuesValor}
                        label="Rango edad final: "
                        name={[name, "rangoEdadFinal"]}
                        rules={[
                          {
                            required: true,
                            message: "Rango de edad faltante",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          disabled={readonly}
                          placeholder="Rango Edad"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...valuesValor}
                        label="Unidad de tiempo: "
                        name={[name, "medidaTiempoId"]}
                        rules={[
                          {
                            required: true,
                            message: "Unidad de medida faltante",
                          },
                        ]}
                      >
                        <Select
                          defaultValue={0}
                          disabled={readonly}
                          options={[
                            { label: "Unidad de tiempo", value: 0 },
                            { label: "Días", value: 1 },
                            { label: "Meses", value: 2 },
                            { label: "Años", value: 3 },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name={[name, "valorInicialNumerico"]}
                        label="Valor númerico inicial"
                        rules={numberRules}
                      >
                        <Input
                          disabled={readonly}
                          autoComplete="off"
                          placeholder="Valor númerico inicial"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name={[name, "valorFinalNumerico"]}
                        label="Valor númerico final"
                        rules={numberRules}
                      >
                        <Input
                          disabled={readonly}
                          autoComplete="off"
                          placeholder="Valor númerico final"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name={[name, "criticoMinimo"]}
                        label="Valor crítrico mínimo"
                        rules={numberRules}
                      >
                        <Input
                          disabled={readonly}
                          autoComplete="off"
                          placeholder="Valor crítrico mínimo"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name={[name, "criticoMaximo"]}
                        label="Valor crítrico máximo"
                        rules={numberRules}
                      >
                        <Input
                          disabled={readonly}
                          autoComplete="off"
                          placeholder="Valor crítrico máximo"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Button
                        type="text"
                        disabled={readonly}
                        onClick={() => {
                          remove(name);
                        }}
                        icon={<MinusCircleOutlined />}
                      ></Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                  disabled={readonly}
                >
                  Agregar campo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default observer(RangoEdad);
