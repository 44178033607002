import { INotificationFilter } from "../models/notifications";
import { INotification } from "../models/shared";
import requests from "./agent";

const UserNotification = {
  getUserNotifications: (
    filter: INotificationFilter
  ): Promise<INotification[]> => requests.post(`notification`, filter),
  markAsSeen: (notification: INotification): Promise<void> =>
    requests.put(`notification`, notification),
};

export default UserNotification;
