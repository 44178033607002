import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../../../app/stores/store";
import TagForm from "./TagForm";
import TagFormHeader from "./TagFormHeader";

type UrlParams = {
  id: string;
};

const TagDetail = () => {
  const { tagStore } = useStore();
  const { scopes, exportForm } = tagStore;

  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { id } = useParams<UrlParams>();
  const tagId = id === "new" ? 0 : isNaN(Number(id)) ? -1 : Number(id);
  const isNew = !id;

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      return new Promise((resolve: any) => {
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    if (tagId) {
      setDownloading(true);
      await exportForm(tagId);
      setDownloading(false);
    }
  };

  // prettier-ignore
  const message = printing ? "Imprimiendo" : downloading ? "Descargando": undefined;

  if (!scopes.acceder || (!scopes.crear && isNew) || tagId === -1) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Spin spinning={printing || downloading} tip={message}>
      <div ref={componentRef}>
        <TagFormHeader
          id={tagId}
          handlePrint={handlePrint}
          handleDownload={handleDownload}
        />
        <Divider className="header-divider" />
        <TagForm id={tagId} />
      </div>
    </Spin>
  );
};

export default observer(TagDetail);
