import { Col, PageHeader, Row, Spin } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { FC, Fragment, useEffect, useState } from "react";

import HeaderTitle, { imagesType } from "../../app/common/header/HeaderTitle";
import { IColumns, ISearch } from "../../app/common/table/utils";
import { IReportData } from "../../app/models/report";
import { useStore } from "../../app/stores/store";
import CashBody from "../cashRegister/CashBody";
import IndicatorsBody from "../indicatorsReport/IndicatorsBody";
import ReportChartSelector from "./ReportChartSelector";
import ReportFilter from "./ReportFilter";
import ReportTable from "./ReportTable";
import {
  getColumns,
  getExpandableConfig,
  getInputs,
  getReportConfig,
} from "./utils";

type ReportDefaultProps = {
  printing: boolean;
};

const ReportBody: FC<ReportDefaultProps> = ({ printing }) => {
  const { reportStore } = useStore();
  const {
    reportData,
    chartData,
    currentReport,
    filter,
    loadingReport,
    detail,
  } = reportStore;
  const [dataChart, setDataChart] = useState<any[]>([]);

  const [inputs, setInputs] = useState<
    (
      | "sucursal"
      | "fecha"
      | "medico"
      | "metodoEnvio"
      | "compañia"
      | "urgencia"
      | "tipoCompañia"
      | "tipoFecha"
      | "origen"
      | "destino"
      | "buscar"
      | "estatus"
      | "hora"
      | "limpiar"
      | "serie"
    )[]
  >([]);
  const [title, setTitle] = useState<string>();
  const [image, setImage] = useState<imagesType | "">();
  const [expandable, setExpandable] = useState<ExpandableConfig<IReportData>>();
  const [summary, setSummary] = useState<boolean>();
  const [summaryByBranch, setsummaryByBranch] = useState<boolean>();
  const [hasFooterRow, setHasFooterRow] = useState<boolean>();
  const [columns, setColumns] = useState<IColumns<IReportData>>([]);
  const [showChar, setShowChart] = useState(false);

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    if (currentReport) {
      setInputs(getInputs(currentReport));
      const data = getReportConfig(currentReport);
      setTitle(data.title);
      setImage(data.image);
      setHasFooterRow(data.hasFooterRow);
      setSummary(data.summary);
      setsummaryByBranch(data.summaryByBranch);
    } else {
      setInputs([]);
      setTitle("");
      setImage("");
      setHasFooterRow(false);
      setSummary(false);
      setsummaryByBranch(false);
    }
  }, [currentReport]);

  useEffect(() => {
    if (currentReport) {
      setColumns(getColumns(currentReport, searchState, setSearchState));
      setExpandable(getExpandableConfig(currentReport, detail));
    } else {
      setColumns([]);
      setExpandable(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, searchState, filter]);

  useEffect(() => {
    if (
      currentReport === "contacto" ||
      currentReport === "estudios" ||
      currentReport === "urgentes" ||
      currentReport === "empresa" ||
      currentReport === "canceladas" ||
      currentReport === "presupuestos" ||
      currentReport === "cargo" ||
      currentReport === "maquila_interna" ||
      currentReport === "maquila_externa" ||
      currentReport === "medicos-desglosado"
    ) {
      setDataChart(chartData);
    } else {
      setDataChart(reportData);
    }
  }, [currentReport, chartData, reportData]);

  useEffect(() => {
    if (currentReport === "expediente") {
      if (!!reportData.length) {
        const sumNoSolicitudes = reportData.reduce(
          (a, b) => a + b.noSolicitudes,
          0
        );
        const footer = reportData.find((x) => x.id === "totalVisitas");
        if (!!footer) {
          reportData.pop();
        }
        reportData.push({
          id: "totalVisitas",
          expediente: "",
          paciente: "Total de visitas:",
          noSolicitudes: sumNoSolicitudes,
        } as IReportData);
      }
    }
  }, [currentReport, chartData, reportData]);

  if (!currentReport || !title || !image) return null;

  return (
    <Fragment>
      <Spin
        spinning={loadingReport || printing}
        tip={printing ? "Descargando" : ""}
      >
        <Row gutter={[12, 12]}>
          {currentReport === "corte_caja" ? (
            <Col span={24}>
              <CashBody printing={printing} />
            </Col>
          ) : currentReport === "indicadores" ? (
            <Col span={24}>
              <IndicatorsBody printing={printing} />
            </Col>
          ) : (
            <>
              <Col span={24}>
                <ReportFilter input={inputs} setShowChart={setShowChart} />
              </Col>
              <Col span={24}>
                <PageHeader
                  ghost={false}
                  title={<HeaderTitle title={title} image={image} />}
                  className="header-container"
                />
              </Col>
              <Col span={24}>
                {!showChar ? (
                  <ReportTable
                    columns={columns}
                    data={reportData}
                    loading={false}
                    hasFooterRow={hasFooterRow}
                    expandable={expandable}
                    summary={summary}
                    summaryByBranch={summaryByBranch}
                  />
                ) : (
                  <ReportChartSelector
                    report={currentReport}
                    data={dataChart}
                  />
                )}
              </Col>
            </>
          )}
        </Row>
      </Spin>
    </Fragment>
  );
};

export default observer(ReportBody);
