import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../../../app/stores/store";
import { guidPattern } from "../../../app/util/utils";
import RouteForm from "./RouteForm";
import RouteFormHeader from "./RouteFormHeader";

type UrlParams = {
  id: string;
};

const RouteDetail = () => {
  const { routeStore } = useStore();
  const { scopes, exportForm } = routeStore;

  const [printing, setPrinting] = useState(false);

  const { id } = useParams<UrlParams>();
  const routeId = !id ? "" : !guidPattern.test(id) ? undefined : id;

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve: any) => {
        setTimeout(() => {
          setPrinting(true);
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    if (routeId) {
      setPrinting(true);
      await exportForm(routeId);
      setPrinting(false);
    }
  };

  if (routeId == null) return <Navigate to="/forbidden" />;

  if (!scopes?.acceder) return <Navigate to="/forbidden" />;

  return (
    <div ref={componentRef}>
      <RouteFormHeader
        id={routeId}
        handlePrint={handlePrint}
        handleDownload={handleDownload}
      />
      <Divider className="header-divider" />
      <RouteForm id={routeId} printing={printing} />
    </div>
  );
};

export default observer(RouteDetail);
