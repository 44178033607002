import { Button, Divider, Form, InputNumber, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ItipoValorForm } from "../../../../app/models/parameter";
import { useSearchParams } from "react-router-dom";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const ReferenciaParrafo: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [formValue] = Form.useForm<ItipoValorForm[]>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "ReadOnly"
  );

  useEffect(() => {
    setReadonly(searchParams.get("mode") === "ReadOnly");
  }, [searchParams]);

  const onValuesChange = (_: any, values: any) => {
    setParameterValues(
      values.value
        .filter((x: any) => x != null)
        .map((x: ItipoValorForm, i: any) => ({
          ...x,
          nombre: valueType,
          orden: i + 1,
        }))
    );
  };

  return (
    <div>
      <Divider orientation="left">Valores de referencia (Párrafo):</Divider>
      <Form<any[]>
        form={formValue}
        name={`form-${valueType}`}
        style={{ marginTop: 20 }}
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{ value: parameterValues }}
      >
        <Form.List name="value">
          {(Fields, { add, remove }) => (
            <>
              {Fields.map(({ key, name, ...valuesValor }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item hidden name={[name, "id"]}>
                    <InputNumber disabled={true} />
                  </Form.Item>
                  <Form.Item
                    {...valuesValor}
                    label={"Párrafo " + (name + 1)}
                    name={[name, "descripcionParrafo"]}
                  >
                    <TextArea
                      disabled={readonly}
                      rows={5}
                      autoSize
                      placeholder={"Párrafo"}
                      style={{ width: 500 }}
                    />
                  </Form.Item>
                  <Button
                    type="text"
                    disabled={readonly}
                    onClick={() => remove(name)}
                    icon={<MinusCircleOutlined />}
                  ></Button>
                </Space>
              ))}
              <Form.Item>
                <Button
                  disabled={readonly}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar campo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};
export default observer(ReferenciaParrafo);
