import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";


type Props = {
  showTitle?: boolean;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};

const DownloadIcon = ({ showTitle = true, onClick }: Props) => {
  const { reportStore } = useStore();
  const {
    loadingReport
  } = reportStore;
  return (
    <Tooltip title={showTitle ? "Descargar" : undefined}>
      <FontAwesomeIcon style={{ cursor: "pointer" }} size="lg" icon={faCloudArrowDown} onClick={(e: any) => {
        if (loadingReport) {
          return () => { };
        }
        if (!!onClick) {

          onClick(e);
        }
      }} />
    </Tooltip>
  );
};

export default observer(DownloadIcon);
