import { Button, Col, Form, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import SelectTagInput from "../../../../app/common/form/proposal/SelectTagInput";
import { IInvoiceSend } from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import { InvoiceType } from "../utils/utils";

type Props = {
  type: InvoiceType;
  invoiceId: string;
  companyId?: string;
};

const InvoiceSend = ({ type, invoiceId, companyId }: Props) => {
  const { invoiceStore } = useStore();
  const { send, scopes } = invoiceStore;

  const [form] = Form.useForm<IInvoiceSend>();

  const [loading, setLoading] = useState(false);

  const emails = Form.useWatch("correo", form);
  const whatsapps = Form.useWatch("whatsapp", form);

  const onFinish = async (sendData: IInvoiceSend) => {
    sendData.facturaId = invoiceId;
    setLoading(true);
    await send(type, sendData);
    setLoading(false);
  };

  const atLeastOneRule = [
    {
      validator() {
        if (emails.length === 0 && whatsapps.length === 0) {
          return Promise.reject("Agrega al menos un correo o whatsapp");
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <Form<IInvoiceSend>
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ correo: [], whatsapp: [] }}
        onFinish={onFinish}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <SelectTagInput
              formProps={{
                label: "Correos",
                name: "correo",
                rules: atLeastOneRule,
              }}
              regex={/^([A-Za-z0-9_.-]+)@([\dA-Za-z.-]+)\.([A-Za-z.]{2,6})$/}
            />
          </Col>
          <Col span={24}>
            <SelectTagInput
              formProps={{
                label: "Whatsapps",
                name: "whatsapp",
                rules: atLeastOneRule,
              }}
              regex={/^([0-9]{3})-?([0-9]{3})-?([0-9]{2})-?([0-9]{2})$/}
            />
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!scopes.enviarWapp && !scopes.enviarCorreo}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(InvoiceSend);
