import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import AvisosTable from "../components/notifications/AvisosTable";
import NotificationsHeader from "../components/notifications/NotificationsHeader";
import NotificationsTable from "../components/notifications/NotificationsTable";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const Notifications = () => {
  const { notificationsStore } = useStore();
  const { scopes } = notificationsStore;
  const [type, setType] = useState<number>(1);
  const handleDownload = () => {};
  const handlePrint = () => {};

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <>
      <NotificationsHeader
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        type={type}
        setType={setType}
        scopes={scopes}
      />
      <Divider />
      {type === 1 ? <NotificationsTable /> : <AvisosTable />}
    </>
  );
};
export default observer(Notifications);
