import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { Checkbox, Divider, Table } from "antd";
import {
  IColumns,
  ISearch,
  getDefaultColumnProps,
} from "../../../app/common/table/utils";
import { IBranchInfo } from "../../../app/models/branch";

type Props = {
  readonly: boolean;
  selectedBranches: string[];
  setSelectedBranches: React.Dispatch<React.SetStateAction<string[]>>;
};

const PriceListFormBranches = ({
  readonly,
  selectedBranches,
  setSelectedBranches,
}: Props) => {
  const { branchStore } = useStore();
  const { getActive } = branchStore;

  const [branches, setBranches] = useState<IBranchInfo[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readBranches = async () => {
      const branches = await getActive();
      setBranches(branches);
    };

    readBranches();
  }, [getActive]);

  const onCheckBranch = (id: string, selected: boolean) => {
    if (selected) {
      setSelectedBranches((prev) => [...prev, id]);
    } else {
      setSelectedBranches((prev) => prev.filter((x) => x !== id));
    }
  };

  const columns: IColumns<IBranchInfo> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "25%",
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "65%",
      }),
    },
    {
      key: "check",
      dataIndex: "idSucursal",
      title: "Añadir",
      align: "center",
      width: "10%",
      render: (id, item) => (
        <Checkbox
          name="activo"
          checked={selectedBranches.includes(id)}
          onChange={(e) => {
            onCheckBranch(id, e.target.checked);
          }}
          className={readonly ? "unclickable" : ""}
        />
      ),
    },
  ];

  return (
    <div>
      <Divider orientation="left">Sucursales</Divider>
      <Table<IBranchInfo>
        rowKey={(record) => record.idSucursal}
        columns={columns}
        dataSource={branches}
        pagination={false}
        scroll={{
          x: "max-content",
          y: 200,
        }}
      />
    </div>
  );
};

export default PriceListFormBranches;
