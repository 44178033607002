import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { ICompanyList } from "../../../app/models/company";
import { Checkbox, Divider, Table } from "antd";
import {
  IColumns,
  ISearch,
  getDefaultColumnProps,
} from "../../../app/common/table/utils";
import { observer } from "mobx-react-lite";

type Props = {
  readonly: boolean;
  selectedCompanies: string[];
  setSelectedCompanies: React.Dispatch<React.SetStateAction<string[]>>;
};

const PriceListFormCompanies = ({
  readonly,
  selectedCompanies,
  setSelectedCompanies,
}: Props) => {
  const { companyStore } = useStore();
  const { getActive } = companyStore;

  const [companies, setCompanies] = useState<ICompanyList[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readCompanies = async () => {
      const companies = await getActive();
      setCompanies(companies);
    };

    readCompanies();
  }, [getActive]);

  const onCheckCompany = (id: string, selected: boolean) => {
    if (selected) {
      setSelectedCompanies((prev) => [...prev, id]);
    } else {
      setSelectedCompanies((prev) => prev.filter((x) => x !== id));
    }
  };

  const columns: IColumns<ICompanyList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "25%",
      }),
    },
    {
      ...getDefaultColumnProps("nombreComercial", "Nombre", {
        searchState,
        setSearchState,
        width: "40%",
      }),
    },
    {
      ...getDefaultColumnProps("procedencia", "Procedencia", {
        searchState,
        setSearchState,
        width: "25%",
      }),
    },
    {
      key: "check",
      dataIndex: "id",
      title: "Añadir",
      align: "center",
      width: "10%",
      render: (id) => (
        <Checkbox
          name="activo"
          checked={selectedCompanies.includes(id)}
          onChange={(e) => {
            onCheckCompany(id, e.target.checked);
          }}
          className={readonly ? "unclickable" : ""}
        />
      ),
    },
  ];

  return (
    <div>
      <Divider orientation="left">Compañías</Divider>
      <Table<ICompanyList>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={companies}
        pagination={false}
        scroll={{
          x: "max-content",
          y: 200,
        }}
      />
    </div>
  );
};

export default observer(PriceListFormCompanies);
