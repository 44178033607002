import moment from "moment";

export interface IInvoiceFreeList {
  facturaId: string;
  serie: string;
  cliente: string;
  fechaCreo: string;
  importeTotal: number;
  estatus: string;
}

export interface IInvoiceFreeFilter {
  cargaInicial: boolean;
  sucursalId?: string;
  fechas?: moment.Moment[];
  estatus: string[];
  compañiaId?: string;
  clave?: string;
  tipo: string[];
}

export class InvoiceFreeFilterValues implements IInvoiceFreeFilter {
  cargaInicial: boolean = true;
  sucursalId?: string = undefined;
  fechas?: moment.Moment[] = [
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ];
  estatus: string[] = [];
  compañiaId?: string = undefined;
  clave?: string = undefined;
  tipo: string[] = [];

  constructor(init?: IInvoiceFreeFilter) {
    Object.assign(this, init);
  }
}
