import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useKeyPress } from "../../../app/hooks/useKeyPress";
import { useStore } from "../../../app/stores/store";
import { guidPattern } from "../../../app/util/utils";
import ProceedingForm from "./ProceedingForm";
import ProceedingFormHeader from "./ProceedingFormHeader";

type UrlParams = {
  id: string;
};

const ProceedingDetail = () => {
  const { procedingStore } = useStore();
  const { scopes, clearTax, exportForm } = procedingStore;

  const navigate = useNavigate();

  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useKeyPress("V", () => navigate(`/requests/${id}`));

  const { id } = useParams<UrlParams>();
  const reagentId = !id ? "" : !guidPattern.test(id) ? undefined : id;
  const isNew = !id;

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      return new Promise((resolve: any) => {
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    if (reagentId) {
      setDownloading(false);
      await exportForm(reagentId);
      setDownloading(false);
    }
  };

  useEffect(() => {
    clearTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // prettier-ignore
  const message = printing ? "Imprimiendo" : downloading ? "Descargando": undefined;

  if (!scopes.acceder || (!scopes.crear && isNew)) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Spin spinning={printing || downloading} tip={message}>
      <div ref={componentRef}>
        <ProceedingFormHeader
          id={reagentId!}
          handlePrint={handlePrint}
          handleDownload={handleDownload}
        />
        <Divider className="header-divider" />
        <ProceedingForm id={reagentId!} />
      </div>
    </Spin>
  );
};

export default observer(ProceedingDetail);
