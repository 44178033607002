import { Divider, Spin } from "antd";
import { Fragment, useEffect, useState } from "react";
import AppointmentHeader from "./AppointmentHeader";
import RecordInfo from "../../../app/common/record/RecordInfo";
import AppointmentTab from "./AppointmentTab";
import "./css/index.less";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import views from "../../../app/util/view";
import Center from "../../../app/layout/Center";
import { IAppointment } from "../../../app/models/appointment";
import { useCallbackPrompt } from "../../../app/hooks/useCallbackPrompt";
import NavigationConfirm from "../../../app/common/navigation/NavigationConfirm";

const AppointmentDetailPermission = () => {
  const { appointmentStore } = useStore();
  const { scopes } = appointmentStore;

  const { appointmentId } = useParams();

  const isNew = !appointmentId;

  if (!scopes.acceder || (!scopes.crear && isNew)) {
    return <Navigate to="/forbidden" />;
  }

  return <AppointmentDetail />;
};

export default observer(AppointmentDetailPermission);

const AppointmentDetail = observer(() => {
  const { profileStore, appointmentStore } = useStore();
  const { profile } = profileStore;
  const {
    studies,
    packs,
    clearDetailData,
    getById,
    create,
    deleteCurrentAppointment,
  } = appointmentStore;

  const navigate = useNavigate();

  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();

  const [creating, setCreating] = useState(false);
  const [recordId, setRecordId] = useState<string>();
  const [branchId] = useState<string | undefined>(profile!.sucursal);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog,
    deleteCurrentAppointment
  );

  useEffect(() => {
    const createAppointment = async () => {
      const searchType = searchParams.get("type");
      const type = !searchParams.has("type")
        ? "L"
        : searchType !== "L" && searchType !== "D"
        ? "L"
        : searchType;
      const appointment: IAppointment = {
        citaId: "00000000-0000-0000-0000-000000000000",
        sucursalId: profile!.sucursal,
        estatusId: status.appointment.generado,
        tipo: type,
        expedienteId: searchParams.get("exp") ?? undefined,
      };
      setCreating(true);
      const id = await create(appointment);
      setCreating(false);
      setRecordId(searchParams.get("exp") ?? undefined);
      if (id) {
        navigate(`/${views.appointment}/${id}`, { replace: true });
      } else {
        navigate(`/${views.appointment}`, { replace: true });
      }
    };

    const getAppointmentById = async () => {
      await getById(appointmentId!);
    };

    clearDetailData();
    if (!appointmentId) {
      createAppointment();
    } else if (appointmentId) {
      getAppointmentById();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  useEffect(() => {
    setShowDialog(
      studies.filter((x) => x.id !== 0).length === 0 &&
        packs.filter((x) => x.id !== 0).length === 0 &&
        appointmentId != null
    );
  }, [packs, appointmentId, studies]);

  useEffect(() => {
    return () => {
      clearDetailData();
    };
  }, [clearDetailData]);

  if (creating) {
    return (
      <Center>
        <Spin spinning={creating} tip="Creando Cita..." size="large"></Spin>
      </Center>
    );
  }

  return (
    <Fragment>
      <NavigationConfirm
        title="Cancelar cita"
        body="La cita no contiene estudios ni paquetes, asegúrate de agregar al menos uno, en caso de continuar la cita será eliminada"
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <AppointmentHeader />
      <Divider className="header-divider" />
      {recordId && <RecordInfo recordId={recordId} />}
      <AppointmentTab
        branchId={branchId}
        recordId={recordId}
        setRecordId={setRecordId}
      />
    </Fragment>
  );
});
