import { Button, Col, Row, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";

import { IUpdate, IUpdateDate } from "../../app/models/requestedStudy";
import { useStore } from "../../app/stores/store";
import alerts from "../../app/util/alerts";
import { catalog } from "../../app/util/catalogs";
import RequestedStudyColumns, {
  RequestedStudyExpandable,
} from "./columnDefinition/requestedStudy";
import RequestedStudyFilter from "./RequestedStudyFilter";
import RequestedStudyTable from "./RequestedStudyTable";

type RSDefaultProps = {
  printing: boolean;
};

const RequestedStudyBody = ({ printing }: RSDefaultProps) => {
  const { requestedStudyStore, requestStore, procedingStore, generalStore } =
    useStore();
  const { scopes, data, update, updateDate, printOrder, getAll } =
    requestedStudyStore;
  const { scopes: requestScopes } = requestStore;
  const { scopes: recordScopes } = procedingStore;
  const { generalFilter } = generalStore;

  const [updateForm, setUpdateForm] = useState<IUpdate[]>([]);

  const [activity, setActivity] = useState<"register" | "cancel" | "">("");
  const [loading, setLoading] = useState(false);

  const onChangeDate = async (
    solicitud: string,
    id: number,
    fechaEntrega: moment.Moment
  ) => {
    let inPack = false;
    let requestIndex = data.findIndex(
      (o) => o.estudios.findIndex((x) => x.solicitudEstudioId === id) > -1
    );
    if (requestIndex == -1) {
      requestIndex = data.findIndex(
        (o) =>
          o.paquetes.findIndex(
            (x) =>
              x.estudios.findIndex((es) => es.solicitudEstudioId === id) > -1
          ) > -1
      );
      if (requestIndex > -1) inPack = true;
    }
    if (requestIndex! > -1) {
      var indexStudy = -1;
      var indexPack = -1;
      if (inPack) {
        indexPack = data[requestIndex!].paquetes.findIndex(
          (x) => x.estudios.findIndex((es) => es.solicitudEstudioId === id) > -1
        );
        indexStudy = data[requestIndex!].paquetes[indexPack].estudios.findIndex(
          (x) => x.solicitudEstudioId === id
        );
      } else {
        indexStudy = data[requestIndex!].estudios.findIndex(
          (x) => x.solicitudEstudioId === id
        );
      }
      if (indexStudy > -1) {
        const dateAfterUTC =
          inPack == true
            ? moment.utc(
                data[requestIndex].paquetes[indexPack].estudios[indexStudy]
                  .fechaEntrega
              )
            : moment.utc(data[requestIndex].estudios[indexStudy].fechaEntrega);
        const dateBeforeUTC = moment.utc(fechaEntrega);
        if (inPack)
          data[requestIndex].paquetes[indexPack].estudios[
            indexStudy
          ].fechaEntrega = fechaEntrega;
        else
          data[requestIndex].estudios[indexStudy].fechaEntrega = fechaEntrega;
        //Actualiza
        let requestStudy: IUpdateDate = {
          solicitudEstudioId: id,
          fechaEntrega: fechaEntrega,
          urgencia: false,
          solicitudId: solicitud,
        };

        if (inPack) {
          if (
            data[requestIndex].paquetes[indexPack].estudios[indexStudy]
              .urgencia === catalog.urgency.normal &&
            moment(dateAfterUTC).isAfter(dateBeforeUTC)
          ) {
            alerts.info("La solicitud se marcó como urgente");
            requestStudy.urgencia = true;
            data[requestIndex].paquetes[indexPack].estudios[
              indexStudy
            ].urgencia = 2;
          }
        } else {
          if (
            data[requestIndex].estudios[indexStudy].urgencia ===
              catalog.urgency.normal &&
            moment(dateAfterUTC).isAfter(dateBeforeUTC)
          ) {
            alerts.info("La solicitud se marcó como urgente");
            requestStudy.urgencia = true;
            data[requestIndex].estudios[indexStudy].urgencia = 2;
          }
        }

        setLoading(true);
        var success = await updateDate(requestStudy!);
        if (await success) setLoading(false);
      }
    }
    setLoading(false);
  };

  const onChange = (e: CheckboxChangeEvent, id: number, solicitud: string) => {
    const index = updateForm.findIndex((x) => x.solicitudId === solicitud);
    if (e.target.checked) {
      if (index > -1) {
        const request = { ...updateForm[index] };
        if (!request.estudioId.includes(id)) {
          request.estudioId.push(id);
        }
        setUpdateForm((prev) => [
          ...prev.filter((x) => x.solicitudId !== solicitud),
          request,
        ]);
      } else {
        const request = { solicitudId: solicitud, estudioId: [id] };
        setUpdateForm((prev) => [...prev, request]);
      }
    } else {
      if (index > -1) {
        const request = { ...updateForm[index] };
        request.estudioId = request.estudioId.filter((x) => x !== id);
        if (request.estudioId.length === 0) {
          setUpdateForm((prev) =>
            prev.filter((x) => x.solicitudId !== solicitud)
          );
        } else {
          setUpdateForm((prev) => [
            ...prev.filter((x) => x.solicitudId !== solicitud),
            request,
          ]);
        }
      }
    }
  };

  const updateData = async () => {
    setLoading(true);
    if (activity === "register") {
      alerts.confirm(
        "Estudio a Solicitado",
        `Se enviará(n) ${
          updateForm.flatMap((x) => x.estudioId).length
        } estudio(s) de ${
          updateForm.length
        } solicitud(es) a estatus Solicitado. ¿Deseas continuar?`,
        async () => {
          var success = await update(updateForm!);
          if (success) {
            setLoading(false);
            setUpdateForm([]);
            setActivity("");
            getAll(generalFilter);
          }
        },
        async () => {
          setLoading(false);
        }
      );
    } else {
      alerts.confirm(
        "Estudio a Toma de Muestra",
        `Se enviará(n)  ${
          updateForm.flatMap((x) => x.estudioId).length
        } estudio(s) de ${
          updateForm.length
        } solicitud(es) a estatus Toma de Muestra. ¿Deseas continuar?`,
        async () => {
          var success = await update(updateForm!);
          if (success) {
            setLoading(false);
            setUpdateForm([]);
            setActivity("");
            getAll(generalFilter);
          }
        },
        async () => {
          setLoading(false);
        }
      );
    }
    setLoading(false);
  };

  const register = () => {
    setActivity("register");
    setUpdateForm([]);
    if (activity === "register") {
      setActivity("");
    }
  };

  const cancel = () => {
    setActivity("cancel");
    setUpdateForm([]);
    if (activity === "cancel") {
      setActivity("");
    }
  };

  return (
    <Fragment>
      <RequestedStudyFilter />
      <Spin spinning={loading || printing} tip={printing ? "Descargando" : ""}>
        {scopes.modificar && (
          <Row style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Row justify="start" gutter={[0, 4]}>
                <Col span={8}>
                  <Button
                    type={activity === "register" ? "primary" : "ghost"}
                    onClick={register}
                  >
                    Solicitar Estudio
                  </Button>
                  <Button
                    type={activity === "cancel" ? "primary" : "ghost"}
                    onClick={cancel}
                  >
                    Cancelar Solicitud
                  </Button>
                </Col>
                {activity !== "" && (
                  <Col span={8} style={{ textAlign: "right" }} offset={8}>
                    <Button
                      type="primary"
                      disabled={updateForm.length <= 0}
                      onClick={() => updateData()}
                    >
                      {activity === "register"
                        ? "Aceptar Registro"
                        : "Cancelar Registro"}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
        <RequestedStudyTable
          data={data}
          columns={RequestedStudyColumns({
            printOrder,
            scopes,
            requestScopes,
            recordScopes,
          })}
          expandable={RequestedStudyExpandable({
            activity,
            onChange,
            updateForm,
            scopes,
            onChangeDate,
          })}
        />
      </Spin>
    </Fragment>
  );
};

export default observer(RequestedStudyBody);
