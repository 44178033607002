import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useStore } from "../app/stores/store";
import ShipmentTrackingDetail from "../components/routeTracking/shipment/ShipmentTrackingDetail";
import ShipmentTrackingHeader from "../components/routeTracking/shipment/ShipmentTrackingHeader";

type UrlParams = {
  id: string;
};

const ShipmentTracking = () => {
  const { shipmentTrackingStore: shipmentTracking, routeTrackingStore } = useStore();
  const { scopes, exportShipment, exportOrderReport } = routeTrackingStore;
  const { setLoadingOrder, scopes: shipmentScopes } = shipmentTracking;
  const { id } = useParams<UrlParams>();

  const [loading, setLoading] = useState(false);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    setLoadingOrder(true);
    await exportShipment(id!);
    setLoadingOrder(false);
  };

  const handleDownloadReport = async () => {
    setLoadingOrder(true);
    await exportOrderReport(id!);
    setLoadingOrder(false);
  };

  if (!scopes.acceder && !shipmentScopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <ShipmentTrackingHeader
        handlePrint={handlePrint}
        handleDownload={handleDownload}
        handleDownloadReport={handleDownloadReport}
      />
      <Divider className="header-divider" />
      <ShipmentTrackingDetail componentRef={componentRef} printing={loading} />
    </Fragment>
  );
};

export default observer(ShipmentTracking);
