import { PageHeader } from "antd";
import React, { FC, useEffect } from "react";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import ImageButton from "../../../app/common/button/ImageButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import PrintIcon from "../../../app/common/icons/PrintIcon";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import GoBackIcon from "../../../app/common/icons/GoBackIcon";
import { IScopes } from "../../../app/models/shared";
type UserFormHeaderProps = {
  handlePrint: () => void;
  handleDownload: () => void;
  scopes: IScopes;
};
type UrlParams = {
  id: string;
};
const RoleFormHeader: FC<UserFormHeaderProps> = ({
  handlePrint,
  handleDownload,
  scopes,
}) => {
  let { id } = useParams<UrlParams>();
  let navigate = useNavigate();
  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Nuevo rol" image="rol" />}
      className="header-container"
      extra={[
        id && scopes.imprimir ? (
          <PrintIcon key="print" onClick={handlePrint} />
        ) : null,
        id && scopes.descargar ? (
          <DownloadIcon key="download" onClick={handleDownload} />
        ) : null,
        <GoBackIcon
          key="back"
          onClick={() => {
            navigate("/roles"); 
          }}
        />,
      ]}
    ></PageHeader>
  );
};

export default RoleFormHeader;
