import { Tooltip, Transfer, Tree } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { IOptions, TreeData } from "../../models/shared";
import {
  convertToTreeDataBranch,
  onTreeSearch,
} from "../../../components/user/detail/utils";
import { useSearchParams } from "react-router-dom";
import { DataNode } from "antd/lib/tree";
import { useStore } from "../../stores/store";

type BranchesPermissionsTypes = {
  initialBranches: string[];
  setBranches: React.Dispatch<React.SetStateAction<string[]>>;
  allBranches?: boolean;
};

const BranchesTransfer = ({
  initialBranches,
  setBranches: setPropBranches,
  allBranches,
}: BranchesPermissionsTypes) => {
  const { optionStore } = useStore();
  const { branchCityOptions, allBranchCityOptions, getAllBranchCityOptions } =
    optionStore;

  const [searchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(true);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [branches, setBranches] = useState<IOptions[]>([]);

  const [permissionsAvailable, setPermissionsAvailable] = useState<TreeData[]>(
    []
  );
  const [permissionsAddedFiltered, setPermissionsAddedFiltered] = useState<
    TreeData[]
  >([]);
  const [permissionsAvailableFiltered, setPermissionsAvailableFiltered] =
    useState<TreeData[]>([]);
  const [permissionsAdded, setPermissionsAdded] = useState<TreeData[]>([]);

  const transform = useMemo(
    () =>
      convertToTreeDataBranch(
        targetKeys,
        setPermissionsAdded,
        setPermissionsAvailable,
        setPermissionsAddedFiltered,
        setPermissionsAvailableFiltered
      ),
    [targetKeys]
  );

  useEffect(() => {
    getAllBranchCityOptions();
  }, [getAllBranchCityOptions]);

  useEffect(() => {
    setBranches([
      ...(allBranches ? allBranchCityOptions : branchCityOptions).flatMap((x) =>
        x.options!.map((o) => ({
          key: o.key,
          value: o.value,
          label: o.label,
          extra: false,
          group: x.value,
        }))
      ),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBranchCityOptions, branchCityOptions]);

  useEffect(() => {
    let readonly = true;
    readonly = !searchParams.has("mode")
      ? false
      : searchParams.get("mode")?.toLowerCase() === "readonly";
    setReadonly(readonly);
  }, [searchParams]);

  useEffect(() => {
    if (
      initialBranches &&
      initialBranches.length > 0 &&
      initialBranches.length !== targetKeys.length
    ) {
      setTargetKeys(initialBranches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBranches]);

  useEffect(() => {
    setPropBranches(targetKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetKeys]);

  useEffect(() => {
    transform(branches);
  }, [branches, targetKeys, transform]);

  const onSearch = onTreeSearch(
    setPermissionsAvailableFiltered,
    permissionsAvailable,
    setPermissionsAddedFiltered,
    permissionsAdded
  );

  const filterOption = (inputValue: string, option: IOptions) => {
    return (
      option.group!.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >
        -1 ||
      option.label!.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >
        -1
    );
  };

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys.sort((a, b) => a.length - b.length));
  };

  const onDeselectParent = (key: string | number, children: DataNode[]) => {
    setSelectedKeys(
      selectedKeys.filter((x) => !children.map((y) => y.key).includes(x))
    );
  };

  const onSelectParent = (key: string | number, children: DataNode[]) => {
    setSelectedKeys([
      ...selectedKeys,
      ...children.map((y) => y.key.toString()),
    ]);
  };

  const onCheckOrSelect = (
    info: any,
    onItemSelect: (key: string, check: boolean) => void
  ) => {
    const {
      node: { key, children, checked },
    } = info;

    if (children && children.length > 0 && checked) {
      onDeselectParent(key, children);
    } else if (children && children.length > 0) {
      onSelectParent(key, children);
    } else {
      onItemSelect(key.toString(), !checked);
    }
  };

  const titles = [
    <Tooltip title="Sucursales que pueden ser asignadas">Disponibles</Tooltip>,
    <Tooltip title="Sucursales asignadas al tipo de usuario">
      Agregadas
    </Tooltip>,
  ];

  return (
    <>
      <Transfer<any>
        dataSource={branches}
        showSearch
        onSearch={onSearch}
        onChange={onChange}
        style={{ justifyContent: "flex-end" }}
        listStyle={{
          width: 300,
          height: 300,
        }}
        rowKey={(x) => x.value.toString()}
        titles={titles}
        filterOption={filterOption}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onSelectChange={(
          sourceSelectedKeys: string[],
          targetSelectedKeys: string[]
        ) => {
          setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        }}
        disabled={readonly}
      >
        {({ direction, onItemSelect, selectedKeys, filteredItems }) => {
          const data =
            direction === "left"
              ? permissionsAvailableFiltered
              : permissionsAddedFiltered;
          const checkedKeys = [...selectedKeys];
          return (
            <Tree
              virtual={false}
              checkable={!readonly}
              disabled={readonly}
              height={200}
              onCheck={(_, info) => {
                onCheckOrSelect(info, onItemSelect);
              }}
              onSelect={(_, info) => {
                onCheckOrSelect(info, onItemSelect);
              }}
              treeData={data}
              showIcon
              checkedKeys={checkedKeys}
            />
          );
        }}
      </Transfer>
    </>
  );
};

export default observer(BranchesTransfer);
