import { Divider } from "antd";
import { Fragment } from "react";
import RequestHeader from "../components/request/list/RequestHeader";
import RequestFilter from "../components/request/list/RequestFilter";
import RequestTable from "../components/request/list/RequestTable";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const Request = () => {
  const { requestStore } = useStore();
  const { scopes } = requestStore;

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <RequestHeader />
      <Divider className="header-divider" />
      <RequestFilter />
      <RequestTable />
    </Fragment>
  );
};

export default Request;
