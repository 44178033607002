import { Popconfirm, Space, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";

import {
  CloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import IconButton from "../../../app/common/button/IconButton";
import { IColumns } from "../../../app/common/table/utils";
import {
  IInvoiceCreditCancel,
  IInvoiceCreditPayment,
} from "../../../app/models/invoiceCredit";
import { useStore } from "../../../app/stores/store";
import { moneyFormatter } from "../../../app/util/utils";

const { Text } = Typography;

type Props = {
  payments: IInvoiceCreditPayment[];
};

const CreditMonitoringPaymentTable = ({ payments }: Props) => {
  const { invoiceCreditStore } = useStore();
  const { downloadXml, downloadPdf, cancel } = invoiceCreditStore;

  const cancelPayment = async (complementId: string) => {
    const data: IInvoiceCreditCancel = {
      complementoId: complementId,
    };

    await cancel(data);
  };

  const columns: IColumns<IInvoiceCreditPayment> = [
    {
      dataIndex: "uuid",
      align: "left",
      width: 290,
    },
    {
      dataIndex: "fechaPago",
      align: "left",
      width: 130,
    },
    {
      dataIndex: "monto",
      align: "right",
      width: 110,
      render: (total) => moneyFormatter.format(total),
    },
    {
      dataIndex: "usuario",
      align: "left",
      width: 200,
      ellipsis: true,
    },
    {
      dataIndex: "estatus",
      align: "center",
      width: 150,
      render: (status) => (
        <Text type={status === "Cancelado" ? "danger" : undefined}>
          {status}
        </Text>
      ),
    },
    {
      dataIndex: "complementoId",
      title: "Acciones",
      align: "center",
      width: 100,
      render: (paymentId: string, payment) => (
        <Space>
          {payment.estatus !== "Cancelado" && (
            <Popconfirm
              title="¿Desea cancelar el pago?"
              okText="Sí"
              cancelText="No"
              trigger="click"
              placement="left"
              onConfirm={() => cancelPayment(paymentId)}
            >
              <IconButton icon={<CloseOutlined />} danger />
            </Popconfirm>
          )}
          {payment.xml && (
            <IconButton
              icon={<FileExcelOutlined />}
              title="Archivo XML"
              onClick={() => downloadXml(paymentId)}
            />
          )}
          {payment.pdf && (
            <IconButton
              icon={<FilePdfOutlined />}
              title="Archivo PDF"
              onClick={() => downloadPdf(paymentId)}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table<IInvoiceCreditPayment>
      bordered
      rowKey={(record) => record.complementoId}
      columns={columns}
      pagination={false}
      dataSource={payments}
      showHeader={false}
      locale={{
        emptyText: "Sin pagos registrados",
      }}
    />
  );
};

export default observer(CreditMonitoringPaymentTable);
