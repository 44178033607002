import { Col, Form, FormInstance, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import CheckInput from "../../../../app/common/form/CheckInput";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { IInvoice } from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import { formItemLayout } from "../../../../app/util/utils";
import { InvoiceType } from "../utils/utils";

const { Title } = Typography;

const formInputStyle: React.CSSProperties = {
  marginBottom: 8,
};

type Props = {
  id: string | undefined;
  form: FormInstance<IInvoice>;
  invoice: IInvoice | undefined;
  type: InvoiceType;
};

const InvoiceData = ({ id, form, invoice, type }: Props) => {
  const { profileStore, optionStore } = useStore();
  const { profile } = profileStore;
  const {
    bankOptions,
    paymentNameOptions,
    paymentMethodNameOptions,
    cfdiNameOptions,
    invoiceSeriesOptions,
    getbankOptions,
    getpaymentMethodOptions,
    getcfdiOptions,
    getPaymentOptions,
    getInvoiceSeriesOptions,
  } = optionStore;

  const readonly = !!id;

  useEffect(() => {
    if (bankOptions.length === 0) getbankOptions();
    if (paymentMethodNameOptions.length === 0) getpaymentMethodOptions();
    if (cfdiNameOptions.length === 0) getcfdiOptions();
    if (paymentNameOptions.length === 0) getPaymentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInvoiceSeriesOptions(profile?.sucursal!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile!.sucursal]);

  return (
    <>
      <Form<IInvoice>
        {...formItemLayout}
        form={form}
        name="invoiceData"
        size="small"
        initialValues={invoice}
        labelWrap
      >
        <Row>
          <Col span={24}>
            <Title level={5}>Datos de la factura</Title>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <SelectInput
              formProps={{
                label: "Forma de pago",
                name: "formaPago",
                rules: [
                  {
                    validator(_rule, value, _callback) {
                      if (value && !value.toString().startsWith("05")) {
                        return Promise.resolve();
                      }
                      return Promise.reject("La forma de pago seleccionada no es válida");
                    },
                  },
                ],
              }}
              options={paymentNameOptions}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <SelectInput
              formProps={{ label: "Serie CFDI", name: "serie" }}
              options={invoiceSeriesOptions}
              required
              style={formInputStyle}
              readonly={readonly}
            />
            <TextInput
              formProps={{ label: "Número de cuenta", name: "numeroCuenta" }}
              style={formInputStyle}
              readonly={readonly}
            />
            {type === "request" && (
              <CheckInput label="Nombre de paciente" name="checkPaciente" readonly={readonly} />
            )}
          </Col>
          <Col span={8}>
            <SelectInput
              formProps={{ label: "Método de pago", name: "metodoPago" }}
              options={paymentMethodNameOptions}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <SelectInput
              formProps={{ label: "Uso CFDI", name: "usoCFDI" }}
              options={cfdiNameOptions}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <SelectInput
              formProps={{ label: "Banco", name: "bancoId" }}
              options={bankOptions}
              style={formInputStyle}
              readonly={readonly}
            />
          </Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    </>
  );
};

export default observer(InvoiceData);
