import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";
import { useStore } from "../app/stores/store";
import SamplingStudyBody from "../components/Sampling/SamplingStudyBody";
import SamplingStudyHeader from "../components/Sampling/SamplingStudyHeader";
import { Navigate } from "react-router-dom";

const SamplingStudy = () => {
  const { samplingStudyStore, generalStore } = useStore();
  const { scopes, exportList } = samplingStudyStore;
  const { generalFilter } = generalStore;

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    await exportList(generalFilter);
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <SamplingStudyHeader handleList={handleDownload} />
      <Divider className="header-divider" />
      <SamplingStudyBody printing={loading} />
    </Fragment>
  );
};

export default observer(SamplingStudy);
