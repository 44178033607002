import { Button, Table } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import {
  defaultPaginationProperties,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import views from "../../app/util/view";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import FontIconButton from "../../app/common/button/FontIconButton";
import { ITagForm } from "../../app/models/tag";

type Props = {
  loading: boolean;
  setLoading: (value: React.SetStateAction<boolean>) => void;
};

const TagTable = ({ loading, setLoading }: Props) => {
  const { tagStore } = useStore();
  const { tags, getAll, scopes } = tagStore;

  const [searchParams] = useSearchParams();

  let navigate = useNavigate();

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readTags = async () => {
      setLoading(true);
      await getAll(searchParams.get("search") ?? "all");
      setLoading(false);
    };

    if (tags.length === 0) {
      readTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToDetail = (id: number, type: "readonly" | "edit") => {
    navigate(`/${views.tag}/${id}?${searchParams}&mode=${type}`);
  };

  const columns: IColumns<ITagForm> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: 120,
      }),
      render: (value, tag) => (
        <Button type="link" onClick={() => goToDetail(tag.id, "readonly")}>
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: 360,
      }),
    },
    {
      ...getDefaultColumnProps("claveInicial", "Clave inicial", {
        searchState,
        setSearchState,
        width: 120,
      }),
    },
    {
      dataIndex: "activo",
      title: "Activo",
      align: "center",
      width: 80,
      render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Editar",
      align: "center",
      width: 80,
      render: (value) =>
        scopes.modificar && (
          <FontIconButton
            title="Editar reactivo"
            icon={faPencil}
            onClick={() => goToDetail(value, "edit")}
          />
        ),
    },
  ];

  return (
    <Fragment>
      <Table<ITagForm>
        loading={loading}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...tags]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: 700 }}
      />
    </Fragment>
  );
};

export default observer(TagTable);
