import { ISeriesList } from "./series";

export interface IBranchForm {
  idSucursal: string;
  clave: string;
  nombre: string;
  calle: string;
  correo: string;
  telefono?: number;
  numeroExt?: number;
  numeroInt?: number;
  activo: boolean;
  estado: string;
  ciudad: string;
  coloniaId?: number;
  codigoPostal: string;
  departamentos: number[];
  series: ISeriesList[];
  matriz?: boolean;
  usuarioFirmaLaboratorio: string;
  usuarioFirmaPatologia: string;
}

export interface IBranchCity {
  sucursales: IBranchInfo[];
  ciudad: string;
}
export interface IBranchInfo {
  idSucursal: string;
  clave: string;
  nombre: string;
  correo: string;
  telefono: Number;
  ubicacion: string;
  activo: boolean;
  codigoPostal: string;
  ciudad: string;
  codigo: string;
}

export interface IBranchPermission {
  id: string;
  sucursal: string;
  ciudad: string;
  asignado: boolean;
}

export interface IBranchDepartment {
  departamentoId: number;
  departamento: string;
}

export class BranchFormValues implements IBranchForm {
  idSucursal = "";
  clave = "";
  nombre = "";
  calle = "";
  correo = "";
  telefono = undefined;
  servicioId = "";
  activo = true;
  estado = "";
  ciudad = "";
  coloniaId = undefined;
  codigoPostal = "";
  numeroInt = undefined;
  numeroExt = undefined;
  departamentos: number[] = [];
  series: ISeriesList[] = [];
  usuarioFirmaLaboratorio: string = "";
  usuarioFirmaPatologia: string = "";

  constructor(init?: IBranchForm) {
    Object.assign(this, init);
  }
}
