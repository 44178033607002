import { Col, Divider, Form, Input, InputNumber, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";

import { ItipoValorForm } from "../../../../app/models/parameter";
import { numberRules } from "../../../../app/util/utils";
import { useSearchParams } from "react-router-dom";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const RangoEdadXSexo: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [formValue] = Form.useForm<ItipoValorForm>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "ReadOnly"
  );

  useEffect(() => {
    setReadonly(searchParams.get("mode") === "ReadOnly");
  }, [searchParams]);

  const onValuesChange = (property: string, value: string) => {
    setParameterValues([
      {
        ...parameterValues[0],
        [property]: value,
        nombre: valueType,
        orden: 1,
      },
    ]);
  };

  if (parameterValues.length === 0) return null;

  return (
    <div>
      <Divider orientation="left">
        Valores de referencia (Numérico por sexo):
      </Divider>
      <Form<ItipoValorForm>
        form={formValue}
        layout={"vertical"}
        style={{ marginTop: 20 }}
        autoComplete="off"
        name={`form-${valueType}`}
        initialValues={parameterValues[0]}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between" gutter={[12, 12]}>
              <Form.Item hidden name={"id"}>
                <InputNumber disabled={true} />
              </Form.Item>
              <Col span={6}>
                <Form.Item
                  name="hombreValorInicial"
                  label="Valor inicial masculino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor inicial masculino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].hombreValorInicial}
                    onChange={(e) =>
                      onValuesChange("hombreValorInicial", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="hombreValorFinal"
                  label="Valor final masculino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor final masculino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].hombreValorFinal}
                    onChange={(e) =>
                      onValuesChange("hombreValorFinal", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="mujerValorInicial"
                  label="Valor inicial femenino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor inicial femenino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].mujerValorInicial}
                    onChange={(e) =>
                      onValuesChange("mujerValorInicial", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="mujerValorFinal"
                  label="Valor final femenino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor final femenino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].mujerValorFinal}
                    onChange={(e) =>
                      onValuesChange("mujerValorFinal", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="hombreCriticoMinimo"
                  label="Valor critico mínimo masculino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor critico mínimo masculino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].hombreCriticoMinimo}
                    onChange={(e) =>
                      onValuesChange("hombreCriticoMinimo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="hombreCriticoMaximo"
                  label="Valor critico máximo masculino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor critico máximo masculino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].hombreCriticoMaximo}
                    onChange={(e) =>
                      onValuesChange("hombreCriticoMaximo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="mujerCriticoMinimo"
                  label="Valor critico mínimo femenino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor critico mínimo femenino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].mujerCriticoMinimo}
                    onChange={(e) =>
                      onValuesChange("mujerCriticoMinimo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="mujerCriticoMaximo"
                  label="Valor critico máximo femenino"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor critico máximo femenino"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].mujerCriticoMaximo}
                    onChange={(e) =>
                      onValuesChange("mujerCriticoMaximo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(RangoEdadXSexo);
