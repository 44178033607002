import { store } from "../../../../app/stores/store";
import { InvoiceType } from "../utils/utils";
import InvoiceCancelation from "./InvoiceCancelation";

export const cancelInvoice = (
  invoiceId: string,
  type: InvoiceType,
  serie: string
): Promise<boolean> => {
  const { openModal, closeModal } = store.modalStore;

  return new Promise((resolve) => {
    openModal({
      title: `Cancelar factura ${serie}`,
      body: (
        <InvoiceCancelation
          invoiceId={invoiceId}
          type={type}
          getResult={(result) => {
            resolve(result);
            closeModal();
          }}
        />
      ),
      onClose: () => {
        resolve(false);
      },
      width: 440,
    });
  });
};
