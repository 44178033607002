import moment from "moment";

export interface IInvoiceCredit {
  facturaId: string;
  serie: string;
  serieNumero: string;
  compañia: string;
  rfc: string;
  razonSocial: string;
  regimenFiscal: string;
  metodoPago: string;
  formaPago: string;
  usoCfdi: string;
  fechaCreacion: string;
  fechaLimiteCredito: string;
  total: number;
  montoPagado: number;
  saldo: number;
  estatus: string;
  pagos: IInvoiceCreditPayment[];
}

export interface IInvoiceCreditPayment {
  complementoId: string;
  uuid: string;
  serie: string;
  folio: string;
  fechaPago: string;
  monto: number;
  xml: boolean;
  pdf: boolean;
  estatus: string;
}

export interface IInvoiceCreditCreate {
  facturaId: string;
  montoPago: number;
  xml?: File;
  pdf?: File;
}

export interface IInvoiceCreditCancel {
  complementoId: string;
}

export interface IInvoiceCreditFilter {
  cargaInicial: boolean;
  fechas: moment.Moment[];
  clave: string;
  sucursales: string[];
  estatus: string[];
  categoria: string[];
  compañia?: string;
}

export class InvoiceCreditFilterValues implements IInvoiceCreditFilter {
  cargaInicial: boolean = true;
  fechas: moment.Moment[] = [
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ];
  clave: string = "";
  sucursales: string[] = [];
  estatus: string[] = [];
  categoria: string[] = [];
  compañia?: string = undefined;
}
