import { Table } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";
import {
  IColumns,
  ISearch,
  defaultPaginationProperties,
  getDefaultColumnProps,
} from "../../../app/common/table/utils";
import { IShipmentTags } from "../../../app/models/shipmentTracking";
import { studyStatus } from "../../../app/util/catalogs";
import useWindowDimensions from "../../../app/util/window";
import { CheckCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { useStore } from "../../../app/stores/store";

const HistoryTrackingTable = () => {
  const { shipmentTrackingStore } = useStore();
  const { historyTags } = shipmentTrackingStore;
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const { width: windowWidth } = useWindowDimensions();

  const columns: IColumns<IShipmentTags> = [
    {
      ...getDefaultColumnProps("claveEtiqueta", "Clave muestra", {
        searchState,
        setSearchState,
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("recipiente", "Recipiente", {
        searchState,
        setSearchState,
        width: "9%",
      }),
    },
    {
      ...getDefaultColumnProps("cantidad", "Cantidad", {
        width: "5%",
      }),
    },
    {
      ...getDefaultColumnProps("estudios", "Estudios", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "8%",
      }),
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchState,
        setSearchState,
        width: "8%",
      }),
      render: (value) => {
        return studyStatus(value);
      },
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre de paciente", {
        searchState,
        setSearchState,
        width: "15%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
    {
      key: "confirmacionOrigen",
      dataIndex: "confirmacionOrigen",
      title: "Confirmación Recolección",
      align: "center",
      width: "10%",
      render: (value) =>
        value ? (
          <CheckCircleTwoTone twoToneColor="green" />
        ) : (
          <MinusCircleTwoTone twoToneColor="#FFCC00" />
        ),
    },
    {
      key: "confirmacionDestino",
      dataIndex: "confirmacionDestino",
      title: "Confirmación Recepción",
      width: "10%",
      align: "center",
      render: (value) =>
        value ? (
          <CheckCircleTwoTone twoToneColor="green" />
        ) : (
          <MinusCircleTwoTone twoToneColor="#FFCC00" />
        ),
    },
  ];

  return (
    <Fragment>
      <Table<IShipmentTags>
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...historyTags]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: "max-content" }}
      />
    </Fragment>
  );
};

export default observer(HistoryTrackingTable);
