import { Checkbox, DatePicker, Table, Tooltip, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { isMoment } from "moment";
import { useState } from "react";

import { EyeOutlined } from "@ant-design/icons";

import PrintIcon from "../../../app/common/icons/PrintIcon";
import LinkTo from "../../../app/common/link/LinkTo";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import {
  IRequestedPack,
  IRequestedStudy,
  IRequestedStudyList,
  IUpdate,
} from "../../../app/models/requestedStudy";
import { IScopes } from "../../../app/models/shared";
import alerts from "../../../app/util/alerts";
import { status } from "../../../app/util/catalogs";

const { Text } = Typography;

type expandableProps = {
  activity: string;
  onChange: (e: CheckboxChangeEvent, id: number, solicitud: string) => void;
  updateForm: IUpdate[];
  scopes: IScopes;
  onChangeDate: (
    solicitud: string,
    id: number,
    fechaEntrega: moment.Moment
  ) => void;
};

type tableProps = {
  printOrder: (recordId: string, requestId: string) => Promise<void>;
  scopes: IScopes;
  requestScopes: IScopes;
  recordScopes: IScopes;
};

const RequestedStudyColumns = ({
  printOrder,
  scopes,
  requestScopes,
  recordScopes,
}: tableProps) => {
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columns: IColumns<IRequestedStudyList> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LinkTo
            to={`/requests/${item.expedienteId!}/${item.id}`}
            text={value}
            canRedirect={requestScopes.acceder}
          />
          <small>
            <Text type="secondary">{item.clavePatologica}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre del Paciente", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "bolder", marginBottom: -5 }}>
            {value}
          </Text>
          {item.sucursal} {item.edad} años {item.sexo}
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("expediente", "Expediente", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LinkTo
            to={`/expedientes/${item.expedienteId!}?&mode=readonly`}
            text={value}
            canRedirect={recordScopes.acceder}
          />
          <small>
            <Text type="secondary">{item.clavePatologica}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("registro", "Fecha Alta Solicitud", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },

    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      key: "observacion",
      dataIndex: "observacion",
      title: "Observación",
      align: "center",
      width: "15%",
      render: (_value, record) => {
        return (
          <>
            {record.observacion != null ? (
              <Tooltip title={record.observacion} color="#108ee9">
                <EyeOutlined style={{ cursor: "pointer" }} />
              </Tooltip>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      key: "imprimir",
      dataIndex: "imprimir",
      title: "Orden",
      align: "center",
      width: "5%",
      render: (_value, record) =>
        scopes.imprimir && (
          <PrintIcon
            key="imprimir"
            onClick={() => {
              printOrder(record.expedienteId!, record.id);
            }}
          />
        ),
    },
  ];
  return columns;
};

const getExpandableGeneralColumns = (
  activity: string,
  onChange: (e: CheckboxChangeEvent, id: number, solicitud: string) => void,
  updateForm: IUpdate[],
  scopes: IScopes,
  onChangeDate: (
    solicitud: string,
    id: number,
    fechaEntrega: moment.Moment
  ) => void
) => {
  const updateDate = (item: IRequestedStudy, value: moment.Moment | null) => {
    if (!value) {
      alerts.warning("Por favor selecciona una fecha");
      return;
    }

    value = value.utcOffset(0, true);
    item.fechaEntrega = moment(value.format("YYYY-MM-DDTHH:mm:ss"));
    if (value.isBefore(moment().utcOffset(0, true))) {
      alerts.warning("No es posible seleccionar una fecha anterior");
      return;
    }

    onChangeDate(item.solicitudId, item.solicitudEstudioId, value);
  };
  const isIRequestPack = (
    record: IRequestedStudy | IRequestedPack
  ): record is IRequestedPack => {
    return (record as IRequestedPack).type === "pack";
  };

  const nestedColumns: IColumns<IRequestedStudy | IRequestedPack> = [
    {
      ...getDefaultColumnProps("clave", "Estudio", {
        width: "50%",
      }),
      render: (_value, record) => record.clave + " - " + record.nombre,
    },
    {
      ...getDefaultColumnProps("nombreEstatus", "", {
        width: "1%",
      }),
      render: (_value, record) => {
        if (!isIRequestPack(record))
          return (
            <div style={{ display: "inline-block" }}>
              {scopes.modificar &&
                record.estatus === status.requestStudy.tomaDeMuestra && (
                  <Checkbox
                    onChange={(e) =>
                      onChange(e, record.solicitudEstudioId, record.solicitudId)
                    }
                    checked={
                      updateForm.find((x) =>
                        x.estudioId.includes(record.solicitudEstudioId)
                      ) != null
                    }
                    disabled={!(activity === "register")}
                  ></Checkbox>
                )}
              {scopes.modificar &&
                record.estatus === status.requestStudy.solicitado && (
                  <Checkbox
                    onChange={(e) =>
                      onChange(e, record.solicitudEstudioId, record.solicitudId)
                    }
                    checked={
                      updateForm.find((x) =>
                        x.estudioId.includes(record.solicitudEstudioId)
                      ) != null
                    }
                    disabled={!(activity === "cancel")}
                  ></Checkbox>
                )}
            </div>
          );
        else return null;
      },
    },
    {
      ...getDefaultColumnProps("nombreEstatus", "Estatus", {
        width: "10%",
      }),
      render: (_value, record) => {
        if (!isIRequestPack(record)) {
          return (
            <div style={{ display: "inline-block" }}>
              <Text>{record.nombreEstatus}</Text>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      ...getDefaultColumnProps("fechaActualizacion", "Fecha de Actualización", {
        width: "15%",
      }),
      render: (_value, record) => {
        if (!isIRequestPack(record)) {
          return record.fechaActualizacion == null
            ? " - "
            : record.fechaActualizacion +
                " - " +
                record.usuarioActualizacion +
                " (Actualización)";
        } else {
          return null;
        }
      },
    },
    {
      ...getDefaultColumnProps("fechaEntrega", "Fecha de Entrega", {
        width: "15%",
      }),

      render: (value, item) => {
        if (!isIRequestPack(item)) {
          const st = status.requestStudy;
          // prettier-ignore
          const validStatus =
            item.estatus === st.pendiente || item.estatus === st.tomaDeMuestra || item.estatus === st.solicitado || item.estatus === st.enRuta;
          return (
            <DatePicker
              getPopupContainer={(trigger: any) => {
                let parent = trigger.parentNode;
                while (parent) {
                  parent = parent.parentNode;
                  if (parent.classList.contains("table-parent-requested")) {
                    return parent;
                  }
                }
              }}
              bordered={false}
              value={
                value
                  ? isMoment(value)
                    ? value
                    : moment(value.replace("Z", ""))
                  : moment().utcOffset(0, true)
              }
              format="DD/MM/YYYY HH:mm"
              minuteStep={5}
              showTime
              disabled={!validStatus}
              allowClear={false}
              onChange={(value) => {
                updateDate(item, value);
              }}
              disabledDate={(current) =>
                current.isBefore(moment().utcOffset(0, true))
              }
            />
          );
        } else {
          return null;
        }
      },
    },
  ];

  return nestedColumns;
};

export const RequestedStudyExpandable = ({
  activity,
  onChange,
  updateForm,
  scopes,
  onChangeDate,
}: expandableProps) => {
  let nestedColumns = getExpandableGeneralColumns(
    activity,
    onChange,
    updateForm,
    scopes,
    onChangeDate
  );

  return {
    expandedRowRender: (item: IRequestedStudyList) => (
      <Table
        rowKey={(item) => item.id}
        columns={nestedColumns}
        dataSource={[...item.estudios, ...item.paquetes]}
        pagination={false}
        className="header-expandable-table"
        showHeader={false}
        expandable={expandableConfig(
          activity,
          onChange,
          updateForm,
          scopes,
          onChangeDate
        )}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

const expandableConfig = (
  activity: string,
  onChange: (e: CheckboxChangeEvent, id: number, solicitud: string) => void,
  updateForm: IUpdate[],
  scopes: IScopes,
  onChangeDate: (
    solicitud: string,
    id: number,
    fechaEntrega: moment.Moment
  ) => void
) => {
  let nestedColumns = getExpandableGeneralColumns(
    activity,
    onChange,
    updateForm,
    scopes,
    onChangeDate
  );

  const isIRequestPack = (
    record: IRequestedStudy | IRequestedPack
  ): record is IRequestedPack => {
    return (record as IRequestedPack).type === "pack";
  };

  return {
    expandedRowRender: (item: IRequestedStudy | IRequestedPack) => {
      if (isIRequestPack(item)) {
        return (
          <Table
            style={{ padding: 0 }}
            rowKey={(record) => record.id ?? record.id!}
            columns={nestedColumns}
            dataSource={item.estudios}
            pagination={false}
            showHeader={false}
            rowSelection={{
              getCheckboxProps: () => ({
                style: { display: "none" },
              }),
            }}
          />
        );
      }
      return null;
    },
    rowExpandable: (record: IRequestedStudy | IRequestedPack) => {
      return isIRequestPack(record);
    },
    defaultExpandAllRows: true,
  };
};

export default RequestedStudyColumns;
