import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import DateRangeInput from "../../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import {
  IInvoiceCreditFilter,
  InvoiceCreditFilterValues,
} from "../../../app/models/invoiceCredit";
import { useStore } from "../../../app/stores/store";
import { formItemLayout } from "../../../app/util/utils";
import { categoryType, creditTimeEstatus } from "./utils";

type Props = {
  setLoading: (loading: boolean) => void;
};

const CreditMonitoringFilter = ({ setLoading }: Props) => {
  const { profileStore, optionStore, invoiceCreditStore } = useStore();
  const { profile } = profileStore;
  const {
    branchCityOptions,
    companyOptions,
    getBranchCityOptions,
    getCompanyOptions,
  } = optionStore;
  const { filter, setFilter, getAll } = invoiceCreditStore;

  const [form] = Form.useForm<IInvoiceCreditFilter>();

  useEffect(() => {
    if (branchCityOptions.length === 0) getBranchCityOptions();
    if (companyOptions.length === 0) getCompanyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const read = async (filter: IInvoiceCreditFilter) => {
    setLoading(true);
    await getAll(filter);
    setLoading(false);
  };

  useEffect(() => {
    if (filter.cargaInicial) {
      const newFilter = {
        ...filter,
        sucursales: [profile!.sucursal],
        cargaInicial: false,
      };
      setFilter(newFilter);
      form.setFieldsValue(newFilter);
      read(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: IInvoiceCreditFilter) => {
    setFilter(values);
    read(values);
  };

  const resetFilter = () => {
    const clear = new InvoiceCreditFilterValues();
    clear.sucursales = [profile!.sucursal];
    clear.cargaInicial = false;
    setFilter(clear);
    form.setFieldsValue(clear);
  };

  return (
    <div className="status-container">
      <Form<IInvoiceCreditFilter>
        {...formItemLayout}
        form={form}
        name="invoiceCredit"
        onFinish={onFinish}
        size="small"
        initialValues={filter}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <DateRangeInput
              formProps={{
                name: "fechas",
                label: "Fecha",
              }}
              required
              disableAfterDates
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Buscar",
              }}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              formProps={{
                name: "compañia",
                label: "Compañia",
              }}
              options={companyOptions}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{
                name: "sucursales",
                label: "Sucursal",
              }}
              multiple
              options={branchCityOptions}
              selectOnlyChildren
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{
                name: "estatus",
                label: "Estatus",
              }}
              options={creditTimeEstatus}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{
                name: "categoria",
                label: "Categoría",
              }}
              options={categoryType}
            />
          </Col>
          <Col span={24} style={{ textAlign: "end" }}>
            <Button onClick={resetFilter}>Limpiar</Button>
            <Button type="primary" htmlType="submit">
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(CreditMonitoringFilter);
