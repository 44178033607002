import React from "react";

import { ITagForm } from "../../../../app/models/tag";
import { store } from "../../../../app/stores/store";
import TagStudyTable from "./TagStudyTable";

export const TagStudyModal = (): Promise<ITagForm[]> => {
  const { openModal, closeModal } = store.modalStore;

  return new Promise((resolve) => {
    openModal({
      title: "Agregar Etiquetas",
      body: (
        <TagStudyTable
          getResult={(data) => {
            resolve(data);
            closeModal();
          }}
        />
      ),
      onClose: () => {
        resolve([]);
      },
    });
  });
};
