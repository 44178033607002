import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import TrackingHistoryFilter from "./TrackingHistoryFilter";
import TrackingHistoryTable from "./TrackingHistoryTable";

type THProps = {
  activeTab: string;
};

const TrackingHistoryBody = ({ activeTab }: THProps) => {
  return (
    <Fragment>
      <TrackingHistoryFilter activeTab={activeTab} />
      <TrackingHistoryTable />
    </Fragment>
  );
};

export default observer(TrackingHistoryBody);
