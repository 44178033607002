import { IValidationPermission } from "../../models/shared";
import { store } from "../../stores/store";
import PermissionComponent from "./PermissionComponent";

export const verifyCancelPermission = (): Promise<
  IValidationPermission | undefined
> => {
  const { openModal, closeModal } = store.modalStore;
  const { validateCancellation } = store.profileStore;

  return new Promise((resolve) => {
    openModal({
      title: "Accesos administrativos",
      body: (
        <PermissionComponent
          validate={validateCancellation}
          getResult={(isAdmin) => {
            resolve(isAdmin);
            closeModal();
          }}
        />
      ),
      onClose: () => {
        resolve(undefined);
      },
    });
  });
};
