const messages = {
  forbidden: "No tiene permisos para realizar esta acción",
  created: "Los datos se crearon con éxito",
  updated: "Los datos se guardaron con éxito",
  deleted: "Registro eliminado con exito",
  serverError: "Error en el servidor, contacta a tu administrador de sistemas",
  invalidLink: "El enlace no es válido",
  login: "Favor de iniciar sesión",
  sessionExpired: "La sesión expiró, por favor inicia sesión nuevamente",
  networkError: "Error de red - Asegurate de tener conexión a Internet",
  systemError: "Error de sistema",
  emailFailed: "Hubo un error al enviar el correo. Revise la configuración",
  nothingToExport: "No se encontraron registros para exportar",
  passwordSent: "Se envió el correo para la configuración de la contraseña",
  logging: "Iniciando sesión",
  notFound: "El recurso que buscas no está disponible",
  emptyPermissions: "Debe seleccionar al menos un permiso",
  activeWallet: "El monedero electrónico ha sido activado exitosamente",
  inactiveWallet: "El monedero electrónico ha sido desactivado exitosamente",
  loyaltyWallet: "Se ha generado monedero electrónico",
  loyaltyWalletDeny: "No aplica para monedero electrónico",
  originNotFound: "Debe seleccionar un origen",
  destinationNotFound: "Debe seleccionar un destino",
  updatedTags: "Las etiquetas se guardaron con éxito",

  confirmations: {
    enableTitle: "¿Desea activar el registro?",
    enable: "El registro será activado",
    visible: "Visible",
    visibleweb: "Visible Web",
    disableTitle: "¿Desea desactivar el registro?",
    disable: "El registro podrá ser reactivado más tarde",
    duplicate: "El número de seríe ya se encuentra registrado",
    deleteTitle: "¿Desea eliminar el registro?",
    delete: "El registro será eliminado",
    passwordTitle: "¿Desea enviar el correo para cambio de contraseña?",
    password: "Se enviará el correo de configuración",
    graphic: "Mostrar Gráfica",
    nographic: "No se mostrará la gráfica",
    required: "El registro ahora será requerido",
    unrequired: "El registro ya no será requerido",
    deltaCheck: "Delta Check ha sido activado",
    deltaUncheck: "Delta Check ha sido desactivado",
    showFormato: "Mostrar Formato ha sido desactivado",
    unshownFormato: "Mostrar Formato ha sido desactivado",
    invoiceEnabled: "El estatus se encuentra en Vigente",
    invoiceDisabled: "El estatus se encuentra en Caducado",
  },

  warnings: {
    referenceValue: "Debe capturar los valores de referencia",
    initialValue: "El valor inicial no puede ser mayor a final",
    finalValue: "El valor inicial no puede ser igual a final",
    minimumValue: "El valor crítico mínimo no puede ser igual al máximo",
    maximumValue: "El valor crítico mínimo no puede ser mayor al máximo",
    initialMinimumValue:
      "El valor crítico mínimo no puede ser igual o mayor al valor inicial",
    finalMaximumValue:
      "El valor crítico máximo no puede ser igual o menor al valor final",
  },
};

export default messages;
