import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useStore } from "../../../app/stores/store";
import { guidPattern } from "../../../app/util/utils";
import PriceListForm from "./PriceListForm";
import PriceListFormHeader from "./PriceListFormHeader";

type UrlParams = {
  id: string;
};

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const PriceListDetail = () => {
  const { priceListStore } = useStore();
  const { scopes, exportForm } = priceListStore;

  const { id } = useParams<UrlParams>();

  const [priceListId, setPriceListId] = useState<string | undefined>(
    !id ? emptyGuid : guidPattern.test(id) ? id : undefined
  );
  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setPriceListId(!id ? emptyGuid : guidPattern.test(id) ? id : undefined);
  }, [id]);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      return new Promise((resolve: any) => {
        setTimeout(() => {
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const handleDownload = async () => {
    if (priceListId) {
      setDownloading(true);
      await exportForm(priceListId);
      setDownloading(false);
    }
  };

  if (priceListId == null) return <Navigate to="/forbidden" />;

  if (!scopes?.acceder) return <Navigate to="/forbidden" />;

  return (
    <Fragment>
      <PriceListFormHeader
        id={priceListId}
        handlePrint={handlePrint}
        handleDownload={handleDownload}
      />
      <Divider className="header-divider" />
      <PriceListForm
        id={priceListId}
        componentRef={componentRef}
        printing={printing}
        download={downloading}
      />
    </Fragment>
  );
};

export default observer(PriceListDetail);
