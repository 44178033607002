import { ICompanyForm } from "../../../../app/models/company";
import { store } from "../../../../app/stores/store";
import InvoiceSearchCompany from "./InvoiceSearchCompany";

export const getCompanyTaxData = (): Promise<ICompanyForm | undefined> => {
  const { openModal, closeModal } = store.modalStore;

  return new Promise((resolve) => {
    openModal({
      title: "Búsqueda de compañía",
      body: (
        <InvoiceSearchCompany
          getResult={(company) => {
            if (company) {
              resolve(company);
              closeModal();
            }
          }}
        />
      ),
      onClose: () => {
        resolve(undefined);
      },
      width: 800,
    });
  });
};
