import moment from "moment";
import { IInvoice } from "./invoice";

export interface IInvoiceGlobalList {
  solicitudId: string;
  expedienteId: string;
  clave: string;
  expediente: string;
  paciente: string;
  sucursalId: string;
  sucursal: string;
  compañia: string;
  estatusId: number;
  estatus: string;
  total: number;
  saldo: number;
  facturas: IInvoice[];
}

export interface IInvoiceGlobalFilter {
  cargaInicial: boolean;
  fechas?: moment.Moment[];
  clave?: string;
  urgencia?: string[];
  departamentos?: number[];
  sucursales?: string[];
  estatus?: string[];
  medicos?: string[];
}

export class InvoiceGlobalFilterValues implements IInvoiceGlobalFilter {
  cargaInicial: boolean = true;
  fechas: moment.Moment[] = [
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ];
  clave?: string;
  urgencia?: string[] = [];
  departamentos?: number[] = [];
  sucursales?: string[] = [];
  estatus?: string[] = [];
  medicos?: string[] = [];

  constructor(init?: IInvoiceGlobalFilter) {
    Object.assign(this, init);
  }
}
