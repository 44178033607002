import { Button, InputNumber, Popconfirm, Switch, Table } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  defaultPaginationProperties,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import {
  IRouteTrackingRequest,
  ITagTrackingOrder,
  ITrackingTags,
  ITrackingTagStudy,
} from "../../../app/models/routeTracking";
import { IStudyTrackinOrder } from "../../../app/models/trackingOrder";
import { useStore } from "../../../app/stores/store";
import { studyStatus } from "../../../app/util/catalogs";
import { getDistinct, groupBy } from "../../../app/util/utils";
import views from "../../../app/util/view";
import useWindowDimensions, { resizeWidth } from "../../../app/util/window";
import { MinusOutlined } from "@ant-design/icons";
import { toJS } from "mobx";

type UrlParams = {
  id: string;
};

type RTCTProps = {
  setNotScanned: React.Dispatch<React.SetStateAction<string | undefined>>;
  editable: boolean;
  isNew: boolean;
};

const RouteTrackingCreateTable = ({
  setNotScanned,
  editable,
  isNew,
}: RTCTProps) => {
  const { routeTrackingStore } = useStore();
  const { routeStudies, onScan, tagData, requestTags, scopes, setRequestTags } =
    routeTrackingStore;
  const { id } = useParams<UrlParams>();

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [expandedTagsRows, setExpandedTagsRows] = useState<number[]>([]);
  const [expandedStudyRows, setExpandedStudyRows] = useState<number[]>([]);

  let navigate = useNavigate();
  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    if (!tagData || id) return;
    const studies = tagData.flatMap((x) =>
      x.estudios.map((y) => ({
        ...y,
        escaneo: x.escaneo,
        nombre: x.claveEtiqueta,
        solicitudId: x.solicitudId,
        solicitud: x.claveSolicitud,
      }))
    );

    const groupStudies = groupBy(studies, "estudioId", "solicitud");
    const missingStudies = groupStudies.filter(
      (x) =>
        x.items.length > 1 &&
        x.items.filter((y) => y.escaneo).length > 0 &&
        x.items.some((y) => !y.escaneo)
    );

    if (missingStudies.length > 0 && routeStudies.length > 0) {
      const tagsNotScanned = missingStudies
        .map(
          (x) =>
            x.key.split(":")[1] +
            ": " +
            x.items
              .filter((z) => !z.escaneo)
              .map((y) => y.nombre)
              .join(", ")
        )
        .join(", ");
      setNotScanned(tagsNotScanned);
    } else {
      setNotScanned(undefined);
    }
  }, [routeStudies]);

  useEffect(() => {
    setExpandedRowKeys(requestTags.map((x) => x.solicitudId));
    setOpenRows(true);
  }, [requestTags]);

  const toggleRow = () => {
    if (openRows) {
      setOpenRows(false);
      setExpandedRowKeys([]);
    } else {
      setOpenRows(true);
      setExpandedRowKeys(requestTags.map((x) => x.solicitudId));
    }
  };

  const onExpand = (isExpanded: boolean, record: IRouteTrackingRequest) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.solicitudId);
    } else {
      const index = expandRows.findIndex((x) => x === record.solicitudId);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  const onExpandTags = (isExpanded: boolean, record: ITrackingTags) => {
    let expandRows: number[] = expandedTagsRows;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedTagsRows(expandRows);
  };

  const onExpandStudy = (isExpanded: boolean, record: ITrackingTagStudy) => {
    let expandRows: number[] = expandedStudyRows;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedStudyRows(expandRows);
  };

  const columns: IColumns<IRouteTrackingRequest> = [
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "50%",
      }),
      render: (value, record) => (
        <Button
          type="link"
          onClick={() => {
            navigate(
              `/${views.request}/${record.expedienteId}/${record.solicitudId}`
            );
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre paciente", {
        searchState,
        setSearchState,
        width: "50%",
      }),
    },
  ];

  const expandableTagsConfig = () => {
    const nestedColumns: IColumns<ITrackingTags> = [
      {
        ...getDefaultColumnProps("claveEtiqueta", "Clave muestra", {
          searchState,
          setSearchState,
          width: "30%",
        }),
        render: (value, record) => value + " - " + record.clave,
      },
      {
        ...getDefaultColumnProps("claveEstudios", "Estudios", {
          searchState,
          setSearchState,
          width: "30%",
        }),
      },
      {
        ...getDefaultColumnProps("ruta", "Ruta", {
          searchState,
          setSearchState,
          width: "30%",
        }),
      },
      {
        ...getDefaultColumnProps("escaneo", "Escaneo", {
          searchState,
          setSearchState,
          width: "10%",
        }),
        render: (value: boolean, record) => {
          return (
            <Fragment>
              <Switch
                checked={value}
                onChange={(checked) => onScan(checked, record.id)}
                disabled={
                  !editable ||
                  (!isNew && !scopes.modificar) ||
                  (isNew && !scopes.crear)
                }
              />
            </Fragment>
          );
        },
      },
      {
        dataIndex: "quitar",
        key: "quitar",
        title: "Quitar",
        width: "2%",
        align: "center",
        render: (value, fullRow) => (
          <>
            {
              <Button
                size="small"
                shape="circle"
                type="primary"
                icon={<MinusOutlined />}
                onClick={() => {
                  let filteredTagsByInRouteTags: IRouteTrackingRequest[] = [];
                  requestTags.forEach((requestTag) => {
                    let filteredTags = requestTag.etiquetas.filter(
                      (tag) => tag.id !== fullRow.id
                    );
                    if (!!filteredTags.length) {
                      requestTag.etiquetas = filteredTags;
                      filteredTagsByInRouteTags.push(requestTag);
                    }
                  });
                  setRequestTags(filteredTagsByInRouteTags);
                }}
              />
            }
          </>
        ),
      },
    ];

    return {
      expandedRowRender: (item: IRouteTrackingRequest, index: any) => (
        <Table
          columns={nestedColumns}
          rowKey={(record) => record.id}
          dataSource={item.etiquetas}
          pagination={false}
          className="header-expandable-table"
          expandable={{
            ...expandableStudyConfig(),
            onExpand: onExpandTags,
            expandedRowKeys: expandedTagsRows,
          }}
          showHeader={false}
        />
      ),
    };
  };

  const expandableStudyConfig = () => {
    const nestedColumns: IColumns<ITrackingTagStudy> = [
      {
        ...getDefaultColumnProps("clave", "Clave", {
          searchState,
          setSearchState,
          width: "50%",
        }),
        render: (value, record) => value + " - " + record.nombre,
      },
      {
        ...getDefaultColumnProps("estatus", "Estatus", {
          searchState,
          setSearchState,
          width: "25%",
        }),
        render: (value, record) => studyStatus(value),
      },
      {
        ...getDefaultColumnProps("entrega", "Fecha entrega", {
          searchState,
          setSearchState,
          width: "25%",
        }),
        render: (value, record) => value + " (Entrega)",
      },
    ];

    return {
      expandedRowRender: (item: ITrackingTags, index: any) => (
        <Table
          columns={nestedColumns}
          rowKey={(record) => record.id}
          dataSource={item.estudios}
          pagination={false}
          className="header-expandable-table"
          showHeader={false}
          expandable={{
            onExpand: onExpandStudy,
            expandedRowKeys: expandedStudyRows,
          }}
        />
      ),
    };
  };

  return (
    <Fragment>
      <Table<IRouteTrackingRequest>
        size="small"
        rowKey={(record) => record.solicitudId}
        columns={columns}
        dataSource={[...requestTags]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: windowWidth < resizeWidth ? "max-content" : "auto" }}
        expandable={{
          ...expandableTagsConfig(),
          defaultExpandAllRows: true,
          columnTitle: (
            <div style={{ textAlign: "center" }}>
              <button
                type="button"
                onClick={toggleRow}
                className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                  openRows ? "expanded" : "collapsed"
                }`}
                aria-label={openRows ? "Colapsar fila" : "Expandir fila"}
                aria-expanded={openRows ? "true" : "false"}
              ></button>
            </div>
          ),
          onExpand: onExpand,
          expandedRowKeys: expandedRowKeys,
        }}
        bordered
      />
    </Fragment>
  );
};

export default observer(RouteTrackingCreateTable);
