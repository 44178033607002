import { Col, Row, Spin, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import PrintIcon from "../../app/common/icons/PrintIcon";
import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { IParameter, IResult } from "../../app/models/massResultSearch";
import { useStore } from "../../app/stores/store";

const { Link, Text } = Typography;

type MSDefaultProps = {
  printing: boolean;
};

const MassSearchTable = ({ printing }: MSDefaultProps) => {
  const { massResultSearchStore } = useStore();
  const { parameters, results, loadingStudies, printOrder, scopes } = massResultSearchStore;
  const [loading] = useState(false);
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [columnas, setColumnas] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [resultsAgruped, setResultsAgruped] = useState<any[]>();

  const isOutOfRange = (parameterCaptura: IParameter) => {
    return (
      parameterCaptura.valorInicial &&
      parameterCaptura.valorFinal &&
      parameterCaptura.valor &&
      (parseFloat(parameterCaptura?.valor) <
        parseFloat(parameterCaptura.valorInicial) ||
        parseFloat(parameterCaptura?.valor) >
          parseFloat(parameterCaptura.valorFinal))
    );
  };

  useEffect(() => {
    const result = results.reduce<IResult[]>((acc, current) => {
      const existing = acc.find((item) => item.id === current.id);

      if (existing) {
        existing.nombreEstudio = `${existing.nombreEstudio}, ${current.nombreEstudio}`;
        existing.parameters = [...existing.parameters, ...current.parameters];
      } else {
        acc.push({ ...current });
      }

      return acc;
    }, []);

    result.sort((a, b) => a.clave.localeCompare(b.clave));

    setResultsAgruped(result);
  }, [results]);

  useEffect(() => {
    const cols = parameters.map((parameter: IParameter) => {
      return {
        ...getDefaultColumnProps("clave", "Clave", {
          searchState,
          setSearchState,
          width: 120,
        }),
        align: "center",
        key: uuid(),
        render: (clave: any, row: IResult) => {
          let params: IParameter[] | undefined = row.parameters.filter(
            (param: IParameter) => param.id === parameter.id && param.estudioId
          );

          let filteredParams = params.filter(
            (param) => param.valor !== null && param.valor !== ""
          );

          const groupedByIdAndValue = filteredParams.reduce((acc, param) => {
            const key = `${param.id}-${param.valor}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(param);
            return acc;
          }, {} as Record<string, IParameter[]>);

          const validGroups = Object.values(groupedByIdAndValue).filter(group => {
            const estudioIds = new Set(group.map(param => param.estudioId));
            return estudioIds.size > 1;
          });

          if (validGroups.length > 0) {
            const resultParam = validGroups[0][0];
            const isOutOfRangeParam = isOutOfRange(resultParam);
        
            return {
              props: {},
              children: (
                <span
                  style={{
                    color: isOutOfRangeParam ? "#ff5764" : "black",
                    fontWeight: "bold",
                  }}
                >
                  {resultParam.valor}
                </span>
              ),
            };
          }
        
          // Crear un array de elementos con valores separados por comas si no hay grupo válido
          const values = filteredParams.map((param, index) => {
            const isOutOfRangeParam = isOutOfRange(param);
            return (
              <Fragment key={param.id}>
                <span
                  style={{
                    color: isOutOfRangeParam ? "#ff5764" : "black",
                    fontWeight: "bold",
                  }}
                >
                  {param.valor}
                </span>
                {index < filteredParams.length - 1 && ", "}
              </Fragment>
            );
          });
        
          return {
            props: {},
            children: values,
          };
        },
        title: () => {
          return (
            <>
              <Row>
                <Col>
                  <Text>
                    {!!parameter.nombre ? parameter.nombre : parameter.clave}
                  </Text>
                </Col>
              </Row>
            </>
          );
        },
      };
    });
    setColumnas(cols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  const columns: IColumns<any> = [
    {
      ...getDefaultColumnProps("clave", "Solicitud", {
        searchState,
        setSearchState,
        width: 150,
      }),
      align: "left",
      key: uuid(),
      render: (value, row: IResult) => {
        return (
          <>
            <Row>
              <Col>
                <Link
                  onClick={() => {
                    navigate(
                      `/clinicResultsDetails/${row.expedienteId}/${row.id}`
                    );
                  }}
                >
                  {row.clave}
                </Link>
              </Col>
            </Row>
          </>
        );
      },

      fixed: "left",
    },
    ...columnas,
  ];

  return (
    <Spin spinning={loading || printing} tip={printing ? "Descargando" : ""}>
      <Table
        key={uuid()}
        rowKey={(row) => row.reuqestStudyId}
        loading={loadingStudies}
        size="small"
        columns={columns}
        bordered
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          columnWidth: 40,
          onChange: (selectedRow) => {
            setSelectedRowKeys([...selectedRow]);
          },
          renderCell: (cel, request: IResult) => {
            return (
              scopes.imprimir && (
                <>
                  <Col>
                    <PrintIcon
                      key="doc"
                      onClick={() => {
                        printOrder(request.expedienteId, request.id);
                      }}
                    />
                  </Col>
                </>
              )
            );
          },
        }}
        scroll={{
          x: "100%",
        }}
        sticky
        pagination={defaultPaginationPropertiesSmall}
        dataSource={resultsAgruped}
      ></Table>
    </Spin>
  );
};
export default observer(MassSearchTable);
