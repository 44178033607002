import {
  Button,
  Checkbox,
  Col,
  Divider,
  PageHeader,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import {
  FolderOpenOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import HeaderTitle from "../../app/common/header/HeaderTitle";
import PrintIcon from "../../app/common/icons/PrintIcon";
import ExpandAll from "../../app/common/table/ExpandAll";
import {
  defaultPaginationProperties,
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
} from "../../app/common/table/utils";
import { IRequestedStudyList } from "../../app/models/requestedStudy";
import { useStore } from "../../app/stores/store";
import alerts from "../../app/util/alerts";
import { status } from "../../app/util/catalogs";
import { moneyFormatter } from "../../app/util/utils";

import type { CheckboxValueType } from "antd/es/checkbox/Group";
const { Link, Text } = Typography;

type DeliveryResultsTableProps = {
  componentRef: React.MutableRefObject<any>;
};

const DeliveryResultsTable: FC<DeliveryResultsTableProps> = ({
  componentRef,
}) => {
  const navigate = useNavigate();
  const tableRef = useRef<HTMLDivElement>(null);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { deliveryResultsStore, generalStore, branchStore, profileStore } =
    useStore();
  const {
    requests,
    scopes,
    loadingStudies,
    getAllCaptureResults,
    sendResultFile,
    rollBackSentStatus,
    printResultFile,
    printResultFilePreview,
    printOrder,
  } = deliveryResultsStore;
  const { generalFilter } = generalStore;
  const { getById: getBranchById } = branchStore;
  const { profile } = profileStore;
  const [selectedStudies, setSelectedStudies] = useState<
    {
      solicitudId: string;
      estudiosId: {
        estudioId: number;
        tipo: number;
        estatusId: number;
        isDepartmentPat: boolean;
      }[];
    }[]
  >([]);
  const [selectSendMethods, setSelectSendMethods] = useState<
    CheckboxValueType[]
  >([]);

  // const [selectedRowKeysCheck, setSelectedRowKeysCheck] = useState<any[]>([]);
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [openPrint, setOpenPrint] = useState<boolean>(false);
  const [orderUrl, setOrderUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState(300);

  useEffect(() => {
    setExpandedRowKeys(requests.map((x) => x.solicitudId));
    setOpenRows(true);
    setSelectedStudies([]);
  }, [requests]);

  useEffect(() => {
    setSelectedStudies(selectedStudies);
  }, [selectedStudies]);

  const columns: IColumns<IRequestedStudyList> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        width: "10%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link
            onClick={() => {
              navigate(`/requests/${item.expedienteId}/${item.solicitudId}`);
            }}
          >
            {value}
          </Link>
          <small>
            <Text type="secondary">{item.clavePatologica}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre del Paciente", {
        width: "20%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "bolder", marginBottom: -5 }}>
            {value}
          </Text>
          {item.sucursal} {item.edad} años {item.sexo}
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("registro", "Registro", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("compania", "Compañía", {
        width: "20%",
      }),
    },
    {
      key: "solicitudId",
      dataIndex: "parcialidad",
      title: "Parcialidad",
      align: "center",
      width: "20%",
    },

    {
      key: "solicitudId",
      dataIndex: "orden",
      title: "Orden",
      align: "center",
      width: "10%",
      render: (data: any, row: any) => {
        return (
          scopes.imprimir && (
            <PrintIcon
              key="print"
              onClick={() => {
                printOrder(row.expedienteId!, row.solicitudId!);
              }}
            ></PrintIcon>
          )
        );
      },
    },
  ];

  const options = [
    { key: "logos", label: "Imprimir logos", value: "Logos" },
    { key: "mail", label: "Correo", value: "Correo" },
    { key: "whatsapp", label: "Whatsapp", value: "Whatsapp" },
    { key: "deliver", label: "Fisico", value: "Fisico" },
  ].filter(
    (x) =>
      x.key === "logos" ||
      x.key === "deliver" ||
      (x.key === "mail" && scopes.enviarCorreo) ||
      (x.key === "whatsapp" && scopes.enviarWapp)
  );

  const columnsStudyState = (estudios: any) => {
    const columnsStudy: any = [
      {
        dataIndex: "estudio",
        title: "Estudio",
        align: "center",
        width: "30%",
      },
      {
        dataIndex: "medioSolicitado",
        title: "Medio Disponibles/Enviados",
        align: "center",
        width: "15%",
        render: (value: any) => {
          const elements: any[] = [];
          if (!!estudios.envioCorreo) {
            if (!!value && value.split(",").includes("Correo")) {
              elements.push(
                <Fragment key={uuid()}>
                  <MailOutlined style={{ color: "#d11717" }} />
                </Fragment>
              );
            } else {
              elements.push(
                <Fragment key={uuid()}>
                  <MailOutlined />
                </Fragment>
              );
            }
          }
          if (!!estudios.envioWhatsapp) {
            if (!!value && value.split(",").includes("Whatsapp")) {
              elements.push(
                <Fragment key={uuid()}>
                  <WhatsAppOutlined
                    style={{ color: "#17d120", paddingLeft: 10 }}
                  />
                </Fragment>
              );
            } else {
              elements.push(
                <Fragment key={uuid()}>
                  <WhatsAppOutlined style={{ paddingLeft: 10 }} />
                </Fragment>
              );
            }
          }
          if (!!value && value.split(",").includes("Fisico")) {
            elements.push(
              <Fragment key={uuid()}>
                <FolderOpenOutlined
                  style={{ color: "#1774d1", paddingLeft: 10 }}
                />
              </Fragment>
            );
          } else {
            elements.push(
              <Fragment key={uuid()}>
                <FolderOpenOutlined style={{ paddingLeft: 10 }} />
              </Fragment>
            );
          }
          return <Fragment key={uuid()}>{elements}</Fragment>;
        },
      },
      {
        dataIndex: "estatus",
        title: "Estatus",
        align: "center",
        width: "15%",
      },
      {
        dataIndex: "fechaEntrega",
        title: "Fecha de Entrega",
        align: "center",
        width: "20%",
        render: (_value: any, record: any) => (
          <Typography>
            <Text style={record.urgencia > 1 ? { color: "red" } : {}}>
              {record.fechaEntrega} (Entrega)
            </Text>
          </Typography>
        ),
      },
      {
        dataIndex: "registro",
        title: "Registro",
        align: "center",
        width: "20%",
        render: (_value: any, record: any) =>
          record.registro === "" ? " - " : record.registro + " (Actualización)",
      },
    ];
    return columnsStudy;
  };

  const envio = async (sendFiles: any) => {
    // let branchInfo = await getBranchById(profile?.sucursal!);
    // sendFiles.userIdToSignLaboraty = branchInfo?.usuarioFirmaLaboratorio;
    // sendFiles.userIdToSignPathology = branchInfo?.usuarioFirmaPatologia;

    if (selectSendMethods.includes("Logos")) {
      //Se envia junto
      if (
        selectSendMethods.includes("Fisico") &&
        selectSendMethods.length === 2
      ) {
        const url = await printResultFile(sendFiles);
        // setOrderUrl(url);
        // setOpenPrint(true);
      } else {
        await sendResultFile(sendFiles);
      }
    } else {
      //se genera por separado el de whats y correo, esos siempre deben llevar logo
      if (
        selectSendMethods.includes("Fisico") &&
        selectSendMethods.length === 1
      ) {
        sendFiles.mediosEnvio = ["Fisico"];
        if (selectSendMethods.includes("Logos")) {
          sendFiles.mediosEnvio.push("Logos");
        }
        const url = await printResultFile(sendFiles);
        // setOrderUrl(url);
        // setOpenPrint(true);
      }
      if (
        selectSendMethods.includes("Whatsapp") ||
        selectSendMethods.includes("Correo")
      ) {
        sendFiles.mediosEnvio = ["Logos"];
        if (selectSendMethods.includes("Correo")) {
          sendFiles.mediosEnvio.push("Correo");
        }
        if (selectSendMethods.includes("Whatsapp")) {
          sendFiles.mediosEnvio.push("Whatsapp");
        }
        await sendResultFile(sendFiles);
      }
    }

    await getAllCaptureResults(generalFilter);
    setLoading(false);
  };

  const print = async () => {
    const sendFiles = {
      mediosEnvio: selectSendMethods,
      estudios: selectedStudies,
      // branchId: profile?.sucursal,
    };

    setLoading(true);
    const url = await printResultFilePreview(sendFiles, 900000);
    setOrderUrl(url);
    setOpenPrint(true);
    setLoading(false);

    setSelectedAll(false);
    setSelectSendMethods([]);
    setSelectedStudies([]);
  };

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectSendMethods(checkedValues);
  };
  //Expandir y contraer tabla---------
  // const toggleRow = () => {
  //   if (openRows) {
  //     setOpenRows(false);
  //     setExpandedRowKeys([]);
  //   } else {
  //     setOpenRows(true);
  //     setExpandedRowKeys(requests.map((x) => x.solicitudId));
  //   }
  // };

  const allExpanded = expandedRowKeys.length === requests.length;

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const height = tableRef.current.clientHeight;
        const finalHeight = height - 115;
        setTableHeight(finalHeight);
      }
    };

    // Set initial height
    handleResize();

    // Add event listener to update height on window resize
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(requests.map((x) => x.solicitudId));
    }
  };

  const onExpand = (isExpanded: boolean, record: any) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.solicitudId);
    } else {
      const index = expandRows.findIndex((x) => x === record.solicitudId);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  const onCancelSent = async () => {
    if (selectedStudies) {
      let studiesId = selectedStudies.flatMap((x) =>
        x.estudiosId.map((y) => y.estudioId)
      );

      let success = await rollBackSentStatus(studiesId);

      if (success) await getAllCaptureResults(generalFilter);
    } else {
      return;
    }
  };

  //END: Expandir y contraer tabla---------
  const DeliveryResultsTablePrint = () => {
    return (
      <div ref={componentRef}>
        <PageHeader
          ghost={false}
          title={<HeaderTitle title="Busqueda de captura de resultados" />}
          className="header-container"
        ></PageHeader>
        <Divider className="header-divider" />
        <Table<any>
          size="small"
          rowKey={(record) => record.solicitudId}
          columns={columns.slice(0, 7)}
          pagination={false}
          dataSource={[...requests]}
        />
      </div>
    );
  };
  return (
    <div ref={tableRef} style={{ width: "100%", height: "100%" }}>
      {/* <div> */}
      {openPrint == true ? (
        <div>
          <Divider />
          <Row justify="end" style={{ marginBottom: 10 }}>
            <Col>
              <Button
                onClick={async () => {
                  setOpenPrint(false);
                }}
                type="primary"
              >
                Cerrar
              </Button>
            </Col>
          </Row>

          <object
            data={orderUrl}
            type="application/pdf"
            width="100%"
            height="600"
          >
            alt : <a href={orderUrl}>test.pdf</a>
          </object>
        </div>
      ) : (
        ""
      )}
      {/* </div> */}
      <Row justify="center" style={{ marginBottom: 6 }}>
        <Col span={12}>
          <Checkbox.Group
            options={options}
            onChange={onChange}
            value={selectSendMethods}
          />
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
          {scopes.imprimir && (
            <Button onClick={print} disabled={selectedStudies.length === 0}>
              Imprimir
            </Button>
          )}
          {scopes.modificar && (
            <Button
              onClick={async () => {
                setLoading(true);

                const solicitudesId = selectedStudies.map(
                  (study) => study.solicitudId
                );

                let saldosPendientes: any[] = [];
                let solicitudesSinMedios: any[] = [];
                requests
                  .filter((x) => solicitudesId.includes(x.solicitudId))
                  .forEach((request) => {
                    if (request.saldoPendiente) {
                      saldosPendientes.push({
                        clave: request.solicitud,
                        saldo: moneyFormatter.format(request.saldo ?? 0),
                      });
                    }
                    if (
                      selectSendMethods.includes("Correo") &&
                      !request.envioCorreo
                    ) {
                      // if (!request.envioWhatsapp) {
                      solicitudesSinMedios.push({
                        clave: request.solicitud,
                        medio: `${
                          !request.envioWhatsapp && !request.envioCorreo
                            ? "WhatsApp/Correo"
                            : !request.envioWhatsapp
                            ? "WhatsApp"
                            : !request.envioCorreo
                            ? "Correo"
                            : ""
                        }`,
                      });
                      // }
                    }
                    if (
                      selectSendMethods.includes("Whatsapp") &&
                      !request.envioWhatsapp
                    ) {
                      // if (!request.envioCorreo) {
                      solicitudesSinMedios.push({
                        clave: request.solicitud,
                        medio: `${
                          !request.envioWhatsapp && !request.envioCorreo
                            ? "WhatsApp/Correo"
                            : !request.envioWhatsapp
                            ? "WhatsApp"
                            : !request.envioCorreo
                            ? "Correo"
                            : ""
                        }`,
                      });
                      // }
                    }
                  });

                if (!!solicitudesSinMedios.length) {
                  alerts.confirmInfo(
                    "Datos faltantes",
                    <>
                      <Col>
                        <div>Solicitudes con datos faltantes</div>
                        {solicitudesSinMedios.map((solicitudSinMedio) => {
                          return (
                            <div>
                              {solicitudSinMedio?.clave} -{" "}
                              {solicitudSinMedio?.medio}
                            </div>
                          );
                        })}
                      </Col>
                    </>,
                    async () => {
                      setLoading(false);
                    }
                  );
                  return;
                }
                if (!!saldosPendientes.length) {
                  alerts.confirm(
                    "Solicitudes con saldo pendiente",
                    <>
                      <Col>
                        <div>Solicitudes con saldo pendiente</div>
                        {saldosPendientes.map((saldoPendiente) => {
                          return (
                            <div>
                              {saldoPendiente?.clave} - {saldoPendiente?.saldo}
                            </div>
                          );
                        })}
                      </Col>
                    </>,
                    async () => {
                      const sendFiles = {
                        mediosEnvio: selectSendMethods,
                        estudios: selectedStudies,
                      };
                      await envio(sendFiles);
                      setSelectSendMethods([]);
                      setSelectedStudies([]);
                    },
                    () => true
                  );
                } else {
                  const sendFiles = {
                    mediosEnvio: selectSendMethods,
                    estudios: selectedStudies,
                  };
                  await envio(sendFiles);
                  setSelectSendMethods([]);
                  setSelectSendMethods([]);
                  setSelectedStudies([]);
                  setSelectedAll(false);
                }
              }}
              disabled={
                !selectedStudies.length ||
                !selectSendMethods.length ||
                (selectSendMethods.length === 1 &&
                  selectSendMethods.includes("Logos"))
              }
              type="primary"
            >
              Registrar
            </Button>
          )}
          {scopes.modificar &&
          selectedStudies.length > 0 &&
          selectedStudies.every((x) =>
            x.estudiosId.every(
              (y) => y.estatusId === status.requestStudy.enviado
            )
          ) ? (
            <Button danger onClick={onCancelSent}>
              Cancelar envío
            </Button>
          ) : (
            ""
          )}
          {/* <Button type="primary" onClick={toggleRow}>
            {!openRows ? "Expandir tabla" : "Contraer tabla"}
          </Button> */}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table<any>
            size="small"
            rowKey={(record) => record.solicitudId}
            columns={columns}
            dataSource={[...requests]}
            rowClassName="row-search"
            sticky
            // scroll={{ y: "50vh", x: "fit-content" }}
            scroll={{ y: tableHeight, x: "fit-content" }}
            loading={loading || loadingStudies}
            pagination={defaultPaginationPropertiesSmall}
            rowSelection={{
              type: "checkbox",
              columnWidth: 50,
              getCheckboxProps: (record: any) => {
                // prettier-ignore
                const currSelected = selectedStudies.find((x) => x.solicitudId === record.solicitudId);
                // prettier-ignore
                const allSelected = record.estudios.filter((x: any) => x.isActiveCheckbox).length === currSelected?.estudiosId?.length;
                return {
                  disabled: record.estudios.every(
                    (x: any) => !x.isActiveCheckbox
                  ),
                  indeterminate:
                    currSelected &&
                    currSelected.estudiosId.length > 0 &&
                    !allSelected,
                };
              },
              onSelect(request, selected) {
                if (selected) {
                  const exists =
                    selectedStudies.findIndex(
                      (x) => x.solicitudId === request.solicitudId
                    ) > -1;
                  if (exists) {
                    setSelectedStudies((prev) =>
                      prev.map((x) => {
                        if (x.solicitudId === request.solicitudId) {
                          return {
                            ...x,
                            estudiosId: request.estudios
                              .filter((s: any) => s.isActiveCheckbox)
                              .map((s: any) => ({
                                estudioId: s.estudioId,
                                estatusId: s.estatusId,
                                // tipo: s.isPathological ? 30 : -1,
                                tipo: s.isLab ? -1 : 30,
                                isDepartmentPat: s.isDepartmentPatology,
                              })),
                          };
                        }
                        return x;
                      })
                    );
                  } else {
                    setSelectedStudies((prev) => [
                      ...prev,
                      {
                        solicitudId: request.solicitudId,
                        estudiosId: request.estudios
                          .filter((s: any) => s.isActiveCheckbox)
                          .map((s: any) => ({
                            estudioId: s.estudioId,
                            estatusId: s.estatusId,
                            // tipo: s.isPathological ? 30 : -1,
                            tipo: s.isLab ? -1 : 30,
                            isDepartmentPat: s.isDepartmentPatology,
                          })),
                      },
                    ]);
                  }
                } else {
                  setSelectedStudies((prev) =>
                    prev.filter((x) => x.solicitudId !== request.solicitudId)
                  );
                }
              },
              onChange(_, selectedRows, info) {
                if (info.type === "all") {
                  if (selectedRows.length === 0 || selectedAll === true) {
                    setSelectedStudies([]);
                    setSelectedAll(false);
                  } else {
                    setSelectedAll(true);
                    setSelectedStudies(
                      requests.map((x) => ({
                        solicitudId: x.solicitudId,
                        estudiosId: x.estudios
                          .filter((s: any) => s.isActiveCheckbox)
                          .map((s: any) => ({
                            estudioId: s.estudioId,
                            estatusId: s.estatusId,
                            // tipo: s.isPathological ? 30 : -1,
                            tipo: s.isLab ? -1 : 30,
                            isDepartmentPat: s.isDepartmentPatology,
                          })),
                      }))
                    );
                  }
                }
              },
              selectedRowKeys: selectedStudies.map((x) => x.solicitudId),
            }}
            expandable={{
              onExpand: onExpand,
              expandedRowKeys: expandedRowKeys,
              rowExpandable: () => true,
              defaultExpandAllRows: true,
              columnTitle:
                requests.length === 0 ? (
                  <div></div>
                ) : (
                  <ExpandAll
                    allExpanded={allExpanded}
                    onExpandAll={onExpandAll}
                  />
                ),
              expandedRowRender: (data: any, index: number) => {
                const selected =
                  selectedStudies
                    .find((x) => x.solicitudId === data.solicitudId)
                    ?.estudiosId?.map((s) => s.estudioId) ?? [];
                return (
                  <Table<any>
                    size="small"
                    rowKey={(record) => record.estudioId}
                    columns={columnsStudyState(data)}
                    dataSource={[...data.estudios]}
                    bordered
                    className="no-padding-table header-expandable-table"
                    pagination={false}
                    showHeader={false}
                    rowSelection={{
                      type: "checkbox",
                      columnWidth: 50,
                      getCheckboxProps: (record: any) => ({
                        disabled: !record.isActiveCheckbox,
                      }),
                      onChange: (
                        _selectedRowKeys: React.Key[],
                        selectedRows: any,
                        _rowSelectedMethod: any
                      ) => {
                        const requestId = data.solicitudId;
                        let allSelected = [...selectedStudies];
                        // prettier-ignore
                        const index = allSelected.findIndex(x => x.solicitudId === requestId);

                        if (selectedRows.length === 0) {
                          allSelected = allSelected.filter(
                            (x) => x.solicitudId !== requestId
                          );
                        } else if (index === -1) {
                          allSelected.push({
                            solicitudId: requestId,
                            estudiosId: selectedRows.map((s: any) => ({
                              estudioId: s.estudioId,
                              estatusId: s.estatusId,
                              // tipo: s.isPathological ? 30 : -1,
                              tipo: s.isLab ? -1 : 30,
                              isDepartmentPat: s.isDepartmentPatology,
                            })),
                          });
                        } else {
                          allSelected[index].estudiosId = selectedRows.map(
                            (s: any) => ({
                              estudioId: s.estudioId,
                              estatusId: s.estatusId,
                              // tipo: s.isPathological ? 30 : -1,
                              tipo: s.isLab ? -1 : 30,
                              isDepartmentPat: s.isDepartmentPatology,
                            })
                          );
                        }

                        setSelectedStudies(allSelected);
                      },
                      selectedRowKeys: selected,
                    }}
                  ></Table>
                );
              },
            }}
            bordered
          ></Table>
        </Col>
      </Row>
      <div style={{ display: "none" }}>{<DeliveryResultsTablePrint />}</div>
    </div>
  );
};
export default observer(DeliveryResultsTable);
