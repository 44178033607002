import { Button, Col, Form, Input, Radio, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import { SettingFilled } from "@ant-design/icons";

import DateInput from "../../app/common/form/proposal/DateInput";
import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import { IReportIndicatorsFilter } from "../../app/models/indicators";
import { IOptions } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";
import { formItemLayout } from "../../app/util/utils";
import { IndicatorsModal } from "./modal/IndicatorsModal";

const IndicatorFilter = () => {
  const { optionStore, indicatorsStore, profileStore } = useStore();
  const { getByFilter, datePickerType, setDatePickerType, setFilter, filter } =
    indicatorsStore;
  const { branchCityOptions, getBranchCityOptions } = optionStore;
  const { profile } = profileStore;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<IReportIndicatorsFilter>();

  const selectedCity = Form.useWatch("ciudad", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    getBranchCityOptions();
  }, [getBranchCityOptions]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    setBranchOptions(
      branchCityOptions
        .filter((x) => selectedCity?.includes(x.value as string))
        .flatMap((x) => x.options ?? [])
    );
  }, [branchCityOptions, form, selectedCity]);

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;

    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();

    const newFilter = {
      ...filter,
      sucursalId: [profileBranch],
      ciudad: [userCity!],
      fechaInicial: moment().utcOffset(0, true),
      fechaFinal: moment().utcOffset(0, true),
      tipoFecha: "date",
    };

    form.setFieldsValue(newFilter);
    setFilter(newFilter);

    getByFilter(newFilter);
  }, [branchCityOptions, profile]);

  const servicesCosts = () => {
    return IndicatorsModal();
  };

  const onFinish = async (filter: IReportIndicatorsFilter) => {
    let newFilter = { ...filter };
    setLoading(true);

    if (datePickerType === "date") {
      let startingDate = moment(filter.fecha[0]);
      let endingDate = moment(filter.fecha[1]);

      newFilter = {
        ...filter,
        fechaInicial: startingDate,
        fechaFinal: endingDate,
        fecha: [startingDate, endingDate],
        tipoFecha: datePickerType,
        fechaIndividual: endingDate
      };
    } else if (datePickerType === "week") {
      let startingDate = moment(filter.fecha[0]).startOf("isoWeek");
      let endingDate = moment(filter.fecha[1]).endOf("isoWeek");

      newFilter = {
        ...filter,
        fechaInicial: startingDate,
        fechaFinal: endingDate,
        fecha: [startingDate, endingDate],
        tipoFecha: datePickerType,
        fechaIndividual: endingDate
      };
    } else if (datePickerType === "month") {
      let startingDate = moment(filter.fecha[0]).startOf("month");
      let endingDate =
        moment(filter.fecha[1]).month() === moment().utcOffset(0, true).month()
          ? moment().utcOffset(0, true)
          : moment(filter.fecha[1]).endOf("month");

      newFilter = {
        ...filter,
        fechaInicial: startingDate,
        fechaFinal: endingDate,
        fecha: [startingDate, endingDate],
        tipoFecha: datePickerType,
        fechaIndividual: endingDate
      };
    }

    await getByFilter(newFilter);
    setFilter(newFilter);

    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="status-container">
        <Form<IReportIndicatorsFilter>
          {...formItemLayout}
          form={form}
          name="indicators"
          initialValues={{
            fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
          }}
          onFinish={onFinish}
        >
          <Row justify="space-between" gutter={[16, 12]}>
            <Col span={12}>
              <Form.Item label="Fecha" className="no-error-text" help="">
                <Input.Group>
                  <Row gutter={[16, 18]}>
                    <Col span={13}>
                      <DateRangeInput
                        formProps={{
                          label: "",
                          name: "fecha",
                        }}
                        required
                        disableAfterDates
                        pickerType={datePickerType}
                      />
                    </Col>
                    <Col span={11}>
                      <Radio.Group
                        size="small"
                        defaultValue="date"
                        buttonStyle="solid"
                        onChange={(e) => {
                          setDatePickerType(e.target.value);
                        }}
                        value={datePickerType}
                      >
                        <Radio.Button value="date">Día</Radio.Button>
                        <Radio.Button value="week">Semana</Radio.Button>
                        <Radio.Button value="month">Mensual</Radio.Button>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Sucursal" className="no-error-text" help="">
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      <SelectInput
                        form={form}
                        formProps={{
                          name: "ciudad",
                          label: "Ciudad",
                          noStyle: true,
                        }}
                        options={cityOptions}
                        multiple
                      />
                    </Col>
                    <Col span={12}>
                      <SelectInput
                        form={form}
                        formProps={{
                          name: "sucursalId",
                          label: "Sucursales",
                          noStyle: true,
                        }}
                        multiple
                        options={branchOptions}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={4} className="filter-buttons">
              <Button key="modal" type="text" onClick={() => servicesCosts()}>
                <SettingFilled style={{ fontSize: 14 }} />
              </Button>
              <Button key="filter" type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default observer(IndicatorFilter);
