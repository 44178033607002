import {
  InvoiceRequestCompanyType,
} from "../../components/invoice/invoice/utils/utils";
import { IInvoice } from "../models/invoice";
import {
  IInvoiceRequestCompanyDetail,
  IInvoiceRequestCompanyFilter,
  IInvoiceRequestCompanyList,
} from "../models/invoiceCompany";
import requests from "./agent";

const InvoiceRequestCompany = {
  getAll: (
    type: InvoiceRequestCompanyType,
    filter: IInvoiceRequestCompanyFilter
  ): Promise<IInvoiceRequestCompanyList[]> =>
    requests.post(`invoice${type}N/filter`, filter),
  getDetailByRequests: (
    type: InvoiceRequestCompanyType,
    requestsIds: string[]
  ): Promise<IInvoiceRequestCompanyDetail> =>
    requests.post(`invoice${type}N/concept-detail`, requestsIds),
  create: (
    type: InvoiceRequestCompanyType,
    invoiceDto: IInvoice
  ): Promise<string> => requests.post(`invoice${type}N/create`, invoiceDto),
  printTicket: (type: "request", requestsIds: string[]): Promise<void> =>
    requests.print(`invoice${type}N/print/ticket`, requestsIds),
  downloadList: (
    type: InvoiceRequestCompanyType,
    filter: IInvoiceRequestCompanyFilter
  ): Promise<void> =>
    requests.download(`invoice${type}N/download/excel/list`, filter),
};

export default InvoiceRequestCompany;
