import { IMedicsForm } from "../../../app/models/medics";
import { store } from "../../../app/stores/store";
import MedicsFormUrl from "./MedicsFormUrl";

export const createMedic = (url: string): Promise<IMedicsForm | undefined> => {
  const { openModal, closeModal } = store.modalStore;

  return new Promise((resolve) => {
    openModal({
      title: "Crear médico",
      body: (
        <MedicsFormUrl
          url={url}
          getResult={(medic) => {
            if (medic) {
              resolve(medic);
              closeModal();
            }
          }}
        />
      ),
      onClose: () => {
        resolve(undefined);
      },
      width: 650,
    });
  });
};
