import { makeAutoObservable } from "mobx";
import { IGeneralForm } from "../models/general";
import { GeneralFormValues } from "../models/general";
import { IScopes, Scopes } from "../models/shared";

export default class GeneralStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  generalFilter: IGeneralForm = new GeneralFormValues();

  setGeneralFilter = (generalFilter: IGeneralForm) => {
    this.generalFilter = generalFilter;
  };
}
