import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import InvoiceCatalogFilter from "../components/invoiceCatalog/InvoiceCatalogFilter";
import InvoiceCatalogHeader from "../components/invoiceCatalog/InvoiceCatalogHeader";
import InvoiceCatalogTable from "../components/invoiceCatalog/InvoiceCatalogTable";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const InvoiceCatalog = () => {
  const { invoiceCatalogStore } = useStore();
  const { scopes } = invoiceCatalogStore;
  const [loading, setLoading] = useState(false);

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Spin spinning={loading}>
      <InvoiceCatalogHeader setLoading={setLoading} />
      <Divider className="header-divider" />
      <InvoiceCatalogFilter setLoading={setLoading} />
      <InvoiceCatalogTable setLoading={setLoading} />
    </Spin>
  );
};

export default observer(InvoiceCatalog);
