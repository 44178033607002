import {
  Spin,
  Row,
  Col,
  Pagination,
  Divider,
  PageHeader,
  Tag,
  Form,
  Button,
  Typography,
  Select,
  Space,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import DescriptionItem from "../../../app/common/display/DescriptionItem";
import moment from "moment";
import TrackingTimeline from "../content/TrackingTimeline";
import ShipmentTrackingTitle from "../shipment/content/ShipmentTrackingTitle";
import {
  IReceiveTags,
  IReceiveTracking,
  IShipmentTags,
  ReceiveTrackingFormValues,
} from "../../../app/models/shipmentTracking";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import { formItemLayout, getDistinct, groupBy } from "../../../app/util/utils";
import HistoryTrackingTable from "./HistoryTrackingTable";
import { IOptions } from "../../../app/models/shared";
import alerts from "../../../app/util/alerts";
import { IRouteTrackingForm } from "../../../app/models/trackingOrder";
import { toJS } from "mobx";
import { status } from "../../../app/util/catalogs";

const { Text } = Typography;

type StudyTableProps = {
  componentRef: React.MutableRefObject<any>;
  printing: boolean;
};
type UrlParams = {
  id: string;
};

const HistoryTrackingDetail: FC<StudyTableProps> = ({
  componentRef,
  printing,
}) => {
  const { routeTrackingStore, shipmentTrackingStore } = useStore();
  const { getActive, trackingOrders } = routeTrackingStore;
  const { getHistoryById, loadingOrders, setHistoryTags, shipment, scopes } =
    shipmentTrackingStore;

  const navigate = useNavigate();
  const [form] = Form.useForm<IReceiveTracking>();

  const { id } = useParams<UrlParams>();
  const [values, setValues] = useState<IReceiveTracking>(
    new ReceiveTrackingFormValues()
  );
  const [studies, setStudies] = useState<IShipmentTags[]>([]);

  useEffect(() => {
    let readshipment = async (id: string) => {
      let history = await getHistoryById(id);

      if (history) setHistoryTags(history.estudios);
    };

    if (id) {
      readshipment(id);
    }
  }, [getHistoryById, id]);

  return (
    <Fragment>
      <Spin spinning={loadingOrders}>
        <Row gutter={[24, 16]}>
          <Col md={8} sm={24} xs={12} style={{ textAlign: "left" }}></Col>
          <Col span={16}>
            <ShipmentTrackingTitle
              shipment={shipment}
              tipoEnvio="receive"
              scopes={scopes}
            />
          </Col>
          <Col md={12}>
            <div className="tracking-card">
              <Row gutter={[0, 4]}>
                <Col md={24}>
                  <PageHeader
                    title="Origen"
                    className="header-container-padding"
                    tags={<Tag color="blue">{"Entregado"}</Tag>}
                    avatar={{
                      src: `${process.env.REACT_APP_NAME}/assets/origen.png`,
                    }}
                  ></PageHeader>
                </Col>
                <Divider className="header-divider"></Divider>
                <Col md={8}>
                  <DescriptionItem
                    title="Sucursal"
                    content={shipment?.origen}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Responsable de envío"
                    content={shipment?.emisor}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Medio de entrega"
                    content={shipment?.paqueteria}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de envío"
                    content={moment(shipment?.fechaEnvio).format("DD/MM/YYYY")}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de envío"
                    content={moment(shipment?.fechaEnvio).format("h:mmA")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12}>
            <div className="tracking-card">
              <Row gutter={[0, 4]}>
                <Col md={24}>
                  <PageHeader
                    title="Destino"
                    className="header-container-padding"
                    avatar={{
                      src: `${process.env.REACT_APP_NAME}/assets/destino.png`,
                      shape: "square",
                    }}
                  ></PageHeader>
                </Col>
                <Divider className="header-divider"></Divider>
                <Col md={8}>
                  <DescriptionItem
                    title="Sucursal"
                    content={shipment?.destino}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Responsable de recibido"
                    content={shipment?.receptor ?? "N/A"}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de entrega estimada"
                    content={moment(shipment?.fechaEstimada).format(
                      "DD/MM/YYYY"
                    )}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de entrega estimada"
                    content={moment(shipment?.fechaEstimada).format("h:mmA")}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de entrega real"
                    content={
                      shipment?.fechaReal
                        ? moment(shipment?.fechaReal).format("DD/MM/YYYY")
                        : "N/A"
                    }
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de entrega real"
                    content={
                      shipment?.fechaReal
                        ? moment(shipment?.fechaReal).format("h:mmA")
                        : "N/A"
                    }
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <TrackingTimeline estatus={shipment?.estatus} />
          </Col>
        </Row>
        <br />
        <HistoryTrackingTable />
        <br />
      </Spin>
    </Fragment>
  );
};

export default observer(HistoryTrackingDetail);
