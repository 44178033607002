import { PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import { IScopes } from "../../app/models/shared";

type MassSearchHeaderProps = {
  handleDownload: () => void;
  scopes: IScopes;
};

const MassSearchHeader: FC<MassSearchHeaderProps> = ({
  handleDownload,
  scopes,
}) => {
  return (
    <>
      <PageHeader
        ghost={false}
        title={
          <HeaderTitle
            title={`Tablas de captura de resultados`}
            image="massSearch"
          />
        }
        className="header-container"
        extra={[
          scopes.descargar && (
            <DownloadIcon key="doc" onClick={handleDownload} />
          ),
        ]}
      />
    </>
  );
};

export default observer(MassSearchHeader);
