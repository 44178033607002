import { Button, Divider, PageHeader, Spin, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  defaultPaginationProperties,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { IParameterList } from "../../app/models/parameter";
import useWindowDimensions, { resizeWidth } from "../../app/util/window";
import { EditOutlined } from "@ant-design/icons";
import IconButton from "../../app/common/button/IconButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import HeaderTitle from "../../app/common/header/HeaderTitle";

type ParameterTableProps = {
  printing: boolean;
};

const ParameterTable = ({ printing }: ParameterTableProps) => {
  const { parameterStore } = useStore();
  const { getAll, parameters, scopes, loading } = parameterStore;

  let navigate = useNavigate();

  // const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readUsers = async () => {
      // setLoading(true);
      await getAll(searchParams.get("search") ?? "all");
      // setLoading(false);
    };

    readUsers();
  }, [getAll, searchParams]);
  const columns: IColumns<IParameterList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (value, parameter) => (
        <Button
          type="link"
          onClick={() => {
            navigate(
              `/parameters/${parameter.id}?mode=ReadOnly&search=${
                searchParams.get("search") ?? "all"
              }`
            );
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("tipo", "Tipo", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("nombreCorto", "Nombre corto", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      key: "activo",
      dataIndex: "activo",
      title: "Activo",
      align: "center",
      width: "10%",
      render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "requerido",
      dataIndex: "requerido",
      title: "Requerido",
      align: "center",
      width: "10%",
      render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Editar",
      align: "center",
      width: "10%",
      render: (value, parameter) =>
        scopes.modificar && (
          <IconButton
            title="Editar parámetro"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(
                `/parameters/${parameter.id}?search=${
                  searchParams.get("search") ?? "all"
                }`
              );
            }}
          />
        ),
    },
  ];

  return (
    <Table<IParameterList>
      loading={loading || printing}
      size="small"
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={[...parameters]}
      pagination={defaultPaginationProperties}
      sticky
    />
  );
};

export default observer(ParameterTable);
