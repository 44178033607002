import { Divider } from "antd";
import { Fragment, useEffect, useState } from "react";
import AppointmentCalendar from "../components/appointment/list/AppointmentCalendar";
import { Navigate, useSearchParams } from "react-router-dom";
import ApointmenHeader from "../components/appointment/list/AppointmentHeader";
import AppointmentFilter from "../components/appointment/list/AppointmentFilter";
import AppointmentTab from "../components/appointment/list/AppointmentTab";
import { IAppointmentInfo } from "../app/models/appointment";
import { observer } from "mobx-react-lite";
import { useStore } from "../app/stores/store";

const Appointment = () => {
  const { appointmentStore } = useStore();
  const { scopes, typeFilter, setType } = appointmentStore;

  const [searchParams, setSearchParams] = useSearchParams();

  const [draggingAppointment, setDraggingAppointment] =
    useState<IAppointmentInfo>();

  useEffect(() => {
    if (!searchParams.has("type")) {
      searchParams.set("type", "L");
    } else if (
      searchParams.has("type") &&
      searchParams.get("type") !== "L" &&
      searchParams.get("type") !== "D"
    ) {
      searchParams.set("type", "L");
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <ApointmenHeader type={typeFilter} setType={setType} />
      <Divider className="header-divider" />
      <AppointmentFilter />
      <AppointmentTab
        type={typeFilter}
        setDraggingAppointment={setDraggingAppointment}
      />
      <Divider className="header-divider" />
      <AppointmentCalendar
        type={typeFilter}
        draggingAppointment={draggingAppointment}
      />
    </Fragment>
  );
};

export default observer(Appointment);
