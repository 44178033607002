import "./css/index.less";

import { Col, Divider, Form, Row, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import NavigationConfirm from "../../../app/common/navigation/NavigationConfirm";
import RecordInfo from "../../../app/common/record/RecordInfo";
import { useCallbackPrompt } from "../../../app/hooks/useCallbackPrompt";
import Center from "../../../app/layout/Center";
import { IRequest, IRequestGeneral } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import views from "../../../app/util/view";
import RequestHeader from "./RequestHeader";
import RequestTab from "./RequestTab";
import RequestTokenValidation from "./RequestTokenValidation";

const { Link } = Typography;

const RequestDetail = () => {
  const { profileStore, requestStore, modalStore } = useStore();
  const { profile } = profileStore;
  const {
    readonly,
    studies,
    packs,
    clearDetailData,
    getById,
    create,
    totals,
    setOriginalTotal,
    request,
    setTabKey,
  } = requestStore;
  const { openModal, closeModal } = modalStore;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { recordId, requestId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formGeneral] = Form.useForm<IRequestGeneral>();

  const [creating, setCreating] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog,
    async () => {
      return true;
    }
  );

  useEffect(() => {
    setOriginalTotal(totals);
    setTabKey("general");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formGeneral.resetFields();
    clearDetailData();

    const createRequest = async () => {
      const req: IRequest = {
        expedienteId: recordId!,
        sucursalId: profile!.sucursal,
        parcialidad: false,
        esNuevo: true,
        estatusId: status.request.vigente,
        esWeeClinic: false,
        tokenValidado: false,
      };

      if (searchParams.has("weeFolio")) {
        req.folioWeeClinic = searchParams.get("weeFolio")!;
        req.esWeeClinic = true;
        searchParams.delete("weeFolio");
        setSearchParams(searchParams);

        if (state && (state as any).services) {
          req.servicios = (state as any).services;
        }
      }

      setCreating(true);
      const id = await create(req);
      // await modificarSaldo();
      setCreating(false);

      if (id) {
        navigate(`${id}`, { replace: true });
      } else {
        navigate(`/${views.request}`, { replace: true });
      }
    };

    const getRequestById = async () => {
      await getById(recordId!, requestId!, "requests");
    };

    if (recordId && !requestId) {
      createRequest();
    } else if (recordId && requestId) {
      getRequestById();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId, requestId]);

  useEffect(() => {
    setShowDialog(
      studies.filter((x) => x.id !== 0).length === 0 &&
        packs.filter((x) => x.id !== 0).length === 0 &&
        requestId != null
    );
  }, [packs, requestId, studies]);

  useEffect(() => {
    if (request && request.esWeeClinic && !request.tokenValidado) {
      openModal({
        title: (
          <Row justify="space-between">
            <Col>Token de verificación</Col>
            <Col>
              <Link
                onClick={() => {
                  closeModal();
                  navigate(`/${views.request}`);
                }}
                style={{ fontSize: 14, fontWeight: 400 }}
              >
                Regresar al listado
              </Link>
            </Col>
          </Row>
        ),
        body: (
          <RequestTokenValidation
            recordId={request.expedienteId}
            requestId={request.solicitudId!}
          />
        ),
        closable: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  useEffect(() => {
    return () => {
      closeModal();
      clearDetailData();
    };
  }, [closeModal, clearDetailData]);

  const onEmailChange = async (email: string, lastEmail?: string) => {
    let currentValue = formGeneral.getFieldValue("correos") as string[];
    currentValue = currentValue.filter((x) => x !== lastEmail);
    currentValue.push(email);

    formGeneral.setFieldsValue({
      metodoEnvio: getSendType("correo"),
      correos: currentValue,
    });
    formGeneral.submit();
  };

  const onPhoneChange = async (phone: string, lastPhone?: string) => {
    let currentValue = formGeneral.getFieldValue("whatsapps") as string[];
    currentValue = currentValue.filter((x) => x !== lastPhone);
    currentValue.push(phone);
    formGeneral.setFieldsValue({
      metodoEnvio: getSendType("whatsapp"),
      whatsapps: currentValue,
    });
    formGeneral.submit();
  };

  const getSendType = (type: "correo" | "whatsapp") => {
    const currentSendType = formGeneral.getFieldValue(
      "metodoEnvio"
    ) as string[];

    // prettier-ignore
    const sendType = currentSendType.length === 3 ? currentSendType : currentSendType.length === 0 ? [type] :
      currentSendType[0] === "correo" && type === "correo" ? currentSendType :
      currentSendType[0] === "whatsapp" && type === "whatsapp" ? currentSendType :
      ["correo", "whatsapp", "ambos"]

    return sendType;
  };

  if (!recordId) return null;

  if (creating) {
    return (
      <Center>
        <Spin
          spinning={creating}
          tip="Creando Solicitud..."
          size="large"
        ></Spin>
      </Center>
    );
  }

  if (!request) {
    return (
      <Center>
        <Spin spinning={!request} tip="Cargando..." size="large"></Spin>
      </Center>
    );
  }

  return (
    <Fragment>
      <NavigationConfirm
        title="Salir de la solicitud"
        body="Aún no se han agregado estudios o paquetes, ¿desea salir de la solicitud?"
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <RequestHeader />
      <Divider className="header-divider" />
      {recordId && (
        <RecordInfo
          recordId={recordId}
          readonly={readonly}
          onEmailChange={onEmailChange}
          onPhoneChange={onPhoneChange}
          muestraMonedero={true}
        />
      )}
      <RequestTab recordId={recordId} requestId={requestId} formGeneral={formGeneral} />
    </Fragment>
  );
};

export default observer(RequestDetail);
