import { Button, Col, Form, Row, Table, Typography } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { VList } from "virtual-table-ant-design";

import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";

import TextInput from "../../../../app/common/form/TextInput";
import {
	getDefaultColumnProps,
	IColumns,
	ISearch,
} from "../../../../app/common/table/utils";
import { ITagForm } from "../../../../app/models/tag";
import { useStore } from "../../../../app/stores/store";

const { Paragraph } = Typography;

type FormType = {
  search: string;
};

type Props = {
  getResult: (selectedTags: ITagForm[]) => void;
};

const TagStudyTable = ({ getResult }: Props) => {
  const { tagStore } = useStore();
  const { tags, getAll } = tagStore;

  const [form] = Form.useForm<FormType>();

  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const [selectedTags, setSelectedTags] = useState<ITagForm[]>([]);

  useEffect(() => {
    const readTags = async () => {
      setLoading(true);
      await getAll("all");
      setLoading(false);
    };

    readTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: IColumns<ITagForm> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "30%",
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "70%",
      }),
    },
  ];

  const onSearch = async ({ search }: FormType) => {
    await getAll(search ?? "all");
  };

  const rowSelection: TableRowSelection<ITagForm> = {
    selectedRowKeys: selectedTags.map((x) => x.id),
    onChange: (_, selectedRows) => {
      setSelectedTags(selectedRows);
    },
  };

  const onFinish = () => {
    getResult(selectedTags);
  };

  return (
    <Fragment>
      <Row gutter={[12, 12]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <PlusCircleOutlined style={{ color: "green", fontSize: 48 }} />
        </Col>
        <Col span={24}>
          <Paragraph style={{ textAlign: "center" }}>
            Favor de ingresar el nombre o clave de la etiqueta.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Form<FormType>
            form={form}
            name="form-tag"
            className="login-form"
            onFinish={onSearch}
          >
            <Row gutter={[12, 12]}>
              <Col md={12} xs={24}>
                <TextInput
                  formProps={{ name: "search" }}
                  max={200}
                  placeholder="Nombre / Clave"
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit">
                  Buscar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table<ITagForm>
        loading={loading}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...tags]}
        sticky
        rowSelection={rowSelection}
        scroll={{ y: "30vh", x: true }}
        components={VList({
          height: 500,
        })}
      />
      <Button type="primary" onClick={onFinish} style={{ marginLeft: "90%" }}>
        Aceptar
      </Button>
    </Fragment>
  );
};

export default observer(TagStudyTable);
