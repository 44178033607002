import { Space, Table, Tag, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { IInvoiceCatalog } from "../../app/models/invoiceCatalog";
import { useStore } from "../../app/stores/store";
import { moneyFormatter } from "../../app/util/utils";
import views from "../../app/util/view";
import DownloadFileIcon from "../../app/common/icons/DownloadFileIcon";

const { Link, Text } = Typography;

type Props = {
  setLoading: (loading: boolean) => void;
};

const InvoiceCatalogTable = ({ setLoading }: Props) => {
  const { invoiceCatalogStore } = useStore();
  const { invoices, downloadInvoice, printTicket, scopes } = invoiceCatalogStore;

  let navigate = useNavigate();

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const print = async (invoice: IInvoiceCatalog) => {
    setLoading(true);
    if (invoice.esFactura) {
      await downloadInvoice(invoice.facturaId!);
    } else {
      await printTicket(invoice.expedienteId!, invoice.solicitudId!);
    }
    setLoading(false);
  };

  const columns: IColumns<IInvoiceCatalog> = [
    {
      ...getDefaultColumnProps("fechaCreo", "Fecha", {
        searchState,
        setSearchState,
        width: "8%",
      }),
    },
    {
      ...getDefaultColumnProps("factura", "Clave", {
        searchState,
        setSearchState,
        width: "12%",
      }),
      render: (_, invoice) => {
        const cancelled = invoice.facturaCancelada || invoice.solicitudCancelada;
        return (
          scopes.imprimir && (
            <Space>
              <Link
                onClick={() => {
                  if (invoice.tipo === "FACTURA" && invoice.origen ) navigate(`/invoices/${invoice.origen}/${invoice.facturaId}`);
                  if (invoice.tipo === "RECIBO" && invoice.expedienteId && invoice.solicitudId)
                    navigate(`/${views.request}/${invoice.expedienteId}/${invoice.solicitudId}/register`);
                }}
                type={!cancelled ? undefined : "danger"}
              >
                {invoice.serieNumero} {invoice.serie} 
              </Link>
              {cancelled && <Tag color="error">C</Tag>}
              <DownloadFileIcon key="download" onClick={() => print(invoice)} />
            </Space>
          )
        );
      },
    },
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value, invoice) => {
        const cancelled = invoice.solicitudCancelada;
        return (
          <Space>
            <Link
              onClick={() => {
                if (invoice.expedienteId && invoice.solicitudId)
                  navigate(`/${views.request}/${invoice.expedienteId}/${invoice.solicitudId}`);
              }}
              type={!cancelled ? undefined : "danger"}
            >
              {value}
            </Link>
            {cancelled && <Tag color="error">C</Tag>}
          </Space>
        );
      },
    },
    {
      ...getDefaultColumnProps("razonSocial", "Razón Social", {
        searchState,
        setSearchState,
        width: "38%",
      }),
    },
    {
      ...getDefaultColumnProps("compañia", "Compañia", {
        searchState,
        setSearchState,
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("procedencia", "Proc", {
        searchState,
        setSearchState,
        width: "6%",
      }),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        searchState,
        setSearchState,
        width: "8%",
      }),
      align: "right",
      render: (total) => moneyFormatter.format(total),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        searchState,
        setSearchState,
        width: "8%",
      }),
      align: "right",
      render: (debt, invoice) =>
        invoice.esFactura ? (
          ""
        ) : (
          <Text type={debt === 0 ? undefined : "danger"}>{moneyFormatter.format(debt)}</Text>
        ),
    },
  ];

  return (
    <Table<IInvoiceCatalog>
      bordered
      sticky
      rowKey={(item) => item.facturaId ?? item.solicitudId!}
      columns={columns}
      pagination={defaultPaginationPropertiesSmall}
      dataSource={invoices}
      scroll={{ x: 1200 }}
    />
  );
};

export default observer(InvoiceCatalogTable);
