import {
  IRouteTrackingHistoryList,
  IRouteTrackingList,
  IRouteTrackingRequest,
  ISearchTracking,
  ITagTrackingOrder,
} from "../models/routeTracking";
import { IRouteTrackingForm, ITagsDate } from "../models/trackingOrder";
import requests from "./agent";

const RouteTracking = {
  access: (): Promise<void> => requests.get("/user/scopes"),
  getPendingTags: (search: ISearchTracking): Promise<IRouteTrackingRequest[]> =>
    requests.post(`RouteTracking/pendingTags`, search),
  getById: (id: string): Promise<IRouteTrackingForm> => requests.get(`RouteTracking/${id}`),
  getAllTags: (search: string, routeId: string): Promise<ITagTrackingOrder[]> =>
    requests.get(`RouteTracking/tags/all/${!search ? "all" : search}/${routeId}`),
  getFindTags: (filter: ITagsDate): Promise<IRouteTrackingRequest[]> =>
    requests.post(`RouteTracking/findTags`, filter),
  getActive: (): Promise<IRouteTrackingList[]> => requests.get(`RouteTracking/getActive`),

  createTrackingOrder: (order: IRouteTrackingForm): Promise<void> =>
    requests.post("RouteTracking/trackingOrder", order),
  updateTrackingOrder: (order: IRouteTrackingForm): Promise<void> =>
    requests.put("RouteTracking/trackingOrder", order),
  deleteTagFromOrder: (orderId: string, etiquetaId: number): Promise<void> =>
    requests.put(`RouteTracking/trackingOrder/DeleteTag/${orderId}/${etiquetaId}`, {}),
  exportTrackingOrderForm: (id: string): Promise<void> =>
    requests.download(`RouteTracking/export/form/${id}`),

  exportReceiveForm: (id: ISearchTracking): Promise<void> =>
    requests.download(`RouteTracking/report`, id),

  exportOrderForm: (id: string): Promise<void> =>
    requests.download(`RouteTracking/exportOrderForm/${id}`, id),
  exportOrder: (id: string): Promise<void> =>
    requests.download(`RouteTracking/exportOrder/${id}`, id),
  exportHistory: (id: string): Promise<void> =>
    requests.download(`RouteTracking/exportHistory/${id}`, id),
  exportHistoryReport: (id: string): Promise<void> =>
    requests.download(`RouteTracking/history-report/${id}`),
  exportOrderReport: (id: string): Promise<void> =>
    requests.download(`RouteTracking/order-report/${id}`),
  getTrackingHistory: (search: ISearchTracking): Promise<IRouteTrackingHistoryList[]> =>
    requests.post(`RouteTracking/history`, search),
};

export default RouteTracking;
