import { Button, Tooltip } from "antd";
import React, { FC } from "react";

interface IProps {
  icon: React.ReactNode;
  title?: string;
  danger?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  type?:
    | "link"
    | "text"
    | "default"
    | "ghost"
    | "primary"
    | "dashed"
    | undefined;
}

const IconButton: FC<IProps> = ({
  title,
  icon,
  danger,
  onClick,
  disabled,
  type,
}) => {
  return (
    <Tooltip title={title}>
      <Button
        type={type}
        danger={danger}
        shape="circle"
        icon={icon}
        size="small"
        onClick={onClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default IconButton;
