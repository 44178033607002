import { Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { DollarOutlined } from "@ant-design/icons";

import IconButton from "../../../app/common/button/IconButton";
import ExpandAll from "../../../app/common/table/ExpandAll";
import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IInvoiceCredit } from "../../../app/models/invoiceCredit";
import { useStore } from "../../../app/stores/store";
import { moneyFormatter } from "../../../app/util/utils";
import views from "../../../app/util/view";
import CreditMonitoringInvoice from "./CreditMonitoringInvoice";
import CreditMonitoringPaymentTable from "./CreditMonitoringPaymentTable";

const { Text, Link } = Typography;

const CreditMonitoringTable = () => {
  const { modalStore, invoiceCreditStore } = useStore();
  const { openModal } = modalStore;
  const { invoices } = invoiceCreditStore;

  const navigate = useNavigate();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const allExpanded = expandedRowKeys.length === invoices.length;

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(invoices.map((x) => x.facturaId));
    }
  };

  const onExpandedRowsChange = (expandedRows: readonly React.Key[]) => {
    setExpandedRowKeys(expandedRows as string[]);
  };

  useEffect(() => {
    setExpandedRowKeys(invoices.map((x) => x.facturaId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const addPayment = (invoice: IInvoiceCredit) => {
    openModal({
      title: `${invoice.serie} ${invoice.serieNumero}`,
      body: <CreditMonitoringInvoice invoice={invoice} />,
      width: 700,
    });
  };

  // prettier-ignore
  const getStatusColor = (status: string) =>
    status === "Vencida" ? "red"
      : status === "Vigente" ? "blue"
      : status === "Próxima a vencer" ? "orange" 
      : status === "Pagada" ? "green"
      : "";

  const columns: IColumns<IInvoiceCredit> = [
    {
      ...getDefaultColumnProps("serie", "Serie", {
        searchState,
        setSearchState,
        width: 120,
      }),
      render: (_, invoice) => {
        return (
          <Link onClick={() => navigate(`/invoices/company/${invoice.facturaId}`)}>
            {invoice.serie + " " + invoice.serieNumero}
          </Link>
        );
      },
    },
    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: 230,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("fechaCreacion", "Fecha de creación", {
        width: 150,
      }),
    },
    {
      ...getDefaultColumnProps("fechaLimiteCredito", "Fecha límite crédito", {
        width: 170,
      }),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        width: 110,
      }),
      align: "right",
      render: (total) => moneyFormatter.format(total),
    },
    {
      ...getDefaultColumnProps("montoPagado", "Monto pagado", {
        width: 130,
      }),
      align: "right",
      render: (payed) => moneyFormatter.format(payed),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        width: 110,
      }),
      align: "right",
      render: (balance) => moneyFormatter.format(balance),
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchState,
        setSearchState,
        width: 120,
      }),
      render: (status) => (
        <Text
          style={{
            color: getStatusColor(status),
          }}
        >
          {status}
        </Text>
      ),
    },
    {
      dataIndex: "facturaId",
      title: "Acciones",
      align: "center",
      width: 80,
      render: (_, invoice) => (
        <IconButton icon={<DollarOutlined />} onClick={() => addPayment(invoice)} />
      ),
    },
  ];

  return (
    <Table<IInvoiceCredit>
      bordered
      sticky
      rowClassName="row-search"
      rowKey={(record) => record.facturaId}
      columns={columns}
      pagination={defaultPaginationPropertiesSmall}
      dataSource={invoices}
      expandable={{
        defaultExpandAllRows: true,
        columnWidth: 40,
        columnTitle: <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />,
        onExpandedRowsChange: onExpandedRowsChange,
        expandedRowKeys: expandedRowKeys,
        expandedRowRender: (invoice) => <CreditMonitoringPaymentTable payments={invoice.pagos} />,
      }}
      scroll={{ x: "fit-content" }}
    ></Table>
  );
};

export default observer(CreditMonitoringTable);
