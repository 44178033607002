import { Button, Col, DatePicker, Form, Row, Spin, Typography } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { EditFilled, FlagFilled } from "@ant-design/icons";

import ImageButton from "../../../app/common/button/ImageButton";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import { IOptions } from "../../../app/models/shared";
import {
  IRouteTrackingForm,
  ITagsDate,
  TrackingOrderFormValues,
} from "../../../app/models/trackingOrder";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import { status } from "../../../app/util/catalogs";
import messages from "../../../app/util/messages";
import { formItemLayout } from "../../../app/util/utils";
import RouteTrackingCreateTable from "./RouteTrackingCreateTable";
import { TagTrackingModal } from "./modal/TagTrackingModal";
import {
  IRouteTrackingRequest,
  ISearchTracking,
} from "../../../app/models/routeTracking";
import { set } from "lodash";

const { Text } = Typography;

type UrlParams = {
  id: string;
};

const RouteTrackingCreateForm = () => {
  const {
    optionStore,
    profileStore,
    routeStore,
    routeTrackingStore,
    shipmentTrackingStore,
  } = useStore();
  const {
    getFindTags,
    getEditTags,
    tagEditData,
    setRequestTags,
    setTagsSelected,
    tagsSelected,
    setTagData,
    routeStudies,
    setRouteStudies,
    createTrackingOrder,
    updateTrackingOrder,
    scan,
    onScan,
    setScan,
    getById,
    getStudyTrackingOrder,
    getPendingTags,
    pendingTags,
    cleanTrackingOrder,
    requestTags: etiquetas,
    originalRequestTags,
    loadingRoutes: loading,
    scopes,
    getAllTags,
  } = routeTrackingStore;
  const { newRouteTrackingForm, clearNewRouteTrackingForm } =
    shipmentTrackingStore;
  const { getByOriginDestination, loadingRoutes } = routeStore;
  const { profile } = profileStore;
  const {
    routeBranchOptions,
    routeMaquilaOptions,
    getRouteBranchOptions,
    getRouteMaquilaOptions,
  } = optionStore;

  const navigate = useNavigate();
  const [form] = Form.useForm<IRouteTrackingForm>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [readOnly, setReadOnly] = useState(
    searchParams.get("mode") === "readonly"
  );

  const { id } = useParams<UrlParams>();
  const [values, setValues] = useState<IRouteTrackingForm>(
    new TrackingOrderFormValues()
  );

  const isNew = id === "new";

  const [initialTags, setInitialTags] = useState<number[]>([]);
  const [routeOptions, setRouteOptions] = useState<IOptions[]>([]);
  const [originBranch, setOriginBranch] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [editable, setEditable] = useState<boolean>(isNew);
  const [notScanned, setNotScanned] = useState<string>();
  const [disabledTags, setDisabledTags] = useState<boolean>(true);
  const [disableGlobal, setDisableGlobal] = useState<boolean>(false);
  const [routeSelected, setRouteSelected] = useState<string>("");
  const [showScanField, setShowScanField] = useState<boolean>(false);
  const [disableScan, setDisableScan] = useState<boolean>(true);
  const [loadingSearchTags, setLoadingSearchTags] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Moment[]>([
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ]);

  const [treeDataExtras, setTreeDataExtras] = useState<any[]>([]);
  const isModified =
    !isNew &&
    JSON.stringify(initialTags) !==
      JSON.stringify(
        toJS(routeStudies.filter((x) => x.escaneo).map((x) => x.etiquetaId))
      );

  const extraPendingTags = () => {
    if (!profile || !profile.sucursal) return;

    const profileBranch = profile.sucursal;
    const filter: ISearchTracking = {
      origen: profileBranch,
      destino: undefined,
      fecha: [
        moment().add(-5, "days").utcOffset(0, true),
        moment().utcOffset(0, true),
      ],
      tipoPendiente: 1,
    };
    getPendingTags(filter);
  };

  useEffect(() => {
    extraPendingTags();
  }, [etiquetas]);

  useEffect(() => {
    if (!!pendingTags && etiquetas) {
      let filteredTagsByInRouteTags: IRouteTrackingRequest[] = [];
      const allInRouteTags = etiquetas
        .flatMap((et) => et.etiquetas)
        .map((et) => et.clave);
      pendingTags.forEach((pendingTag) => {
        const filterTags = pendingTag.etiquetas.filter(
          (ft) => !allInRouteTags.includes(ft.clave) && ft.seguimiento === ""
        );
        if (!!filterTags.length) {
          filteredTagsByInRouteTags.push({
            ...pendingTag,
            etiquetas: filterTags,
          });
        }
      });

      setTreeDataExtras(
        filteredTagsByInRouteTags.map((x) => ({
          label: x.solicitud,
          value: x.solicitudId,
          disabled: true,
          options: x.etiquetas.map((y) => ({
            label: `${y.claveEtiqueta} - ${y.clave}`,
            value: y.id,
          })),
        }))
      );
    }
  }, [etiquetas, pendingTags]);

  useEffect(() => {
    getRouteBranchOptions();
    getRouteMaquilaOptions();
  }, [getRouteBranchOptions, getRouteMaquilaOptions]);
  useEffect(() => {
    getRouteBranchOptions();
    getRouteMaquilaOptions();
  }, []);

  useEffect(() => {
    const readTrackingOrder = async (id: string) => {
      const order = await getById(id);

      if (order) {
        readStateTrackingOrder(order);
      }
    };

    const readStateTrackingOrder = async (state: IRouteTrackingForm) => {
      state.diaRecoleccion = moment(state.diaRecoleccion);
      let idTags = state.solicitudes.flatMap((y) =>
        y.etiquetas.map((x) => {
          let study = getStudyTrackingOrder(x);
          return study;
        })
      );

      setRouteStudies(idTags);

      const dateTag = [moment(), moment()];
      const filter: ITagsDate = {
        fecha: dateTag,
        ruta: state.rutaId,
      };

      const requestTags = state.solicitudes.flatMap((x) => x.etiquetas);
      const editTags = await getEditTags(filter);

      const filterEditTags = editTags.flatMap((x) => x.etiquetas);

      const filteredTags = filterEditTags.filter((filterTag) => {
        return !requestTags.some(
          (requestTag) => requestTag.id === filterTag.id
        );
      });

      setTagData([...requestTags, ...filteredTags]);
      setTagsSelected(requestTags);
      setInitialTags(requestTags.map((x) => x.id));

      setRouteSelected(state.rutaId);
      await getRoutes(state.destino, state.diaRecoleccion, state.origenId);

      if (filterEditTags.length > 0) {
        state.escaneo = false;
        setDisabledTags(state.escaneo);
      } else {
        state.escaneo = true;
        setDisabledTags(state.escaneo);
      }

      setValues(state);
      form.setFieldsValue(state);
      setDisabledTags(false);
    };

    if (id && !isNew) {
      readTrackingOrder(id);
    } else if (newRouteTrackingForm) {
      readStateTrackingOrder(newRouteTrackingForm);
    } else {
      setValues({ ...values, estatus: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getById, id]);

  useEffect(() => {
    const profileBranch = profile?.sucursal;
    if (profileBranch && !newRouteTrackingForm) {
      form.setFieldValue("origenId", profileBranch);
      setOriginBranch(profileBranch);
    } else {
      setOriginBranch(newRouteTrackingForm?.origenId || "");
    }

    form.setFieldValue("escaneo", scan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, scan, destination]);

  const treeData = [
    {
      label: "Sucursales",
      value: "destinoId",
      disabled: true,
      options: routeBranchOptions
        .filter((x) => x.value !== originBranch)
        .map((x) => ({
          label: x.label,
          value: x.value,
        })),
    },
    {
      label: "Maquiladores",
      value: "maquiladorId",
      disabled: true,
      options: routeMaquilaOptions.map((x) => ({
        label: x.label,
        value: x.value.toString(),
      })),
    },
  ];

  const goBack = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate("/segRutas");
  };

  const getRoutes = async (
    value: string,
    dateForm?: moment.Moment | null,
    origin?: string
  ) => {
    setDestination(value);
    dateForm ??= form.getFieldValue("diaRecoleccion");
    let originRoute = origin || originBranch;

    if (!value) {
      setRouteOptions([]);
      setTagData(tagsSelected);
      form.setFieldValue("rutaId", undefined);
      return alerts.warning(messages.destinationNotFound);
    }
    if (!originRoute) {
      setRouteOptions([]);
      setTagData(tagsSelected);
      form.setFieldValue("rutaId", undefined);
      return alerts.warning(messages.originNotFound);
    }

    const routes = await getByOriginDestination(value, originRoute);

    if (routes) {
      const routesByDate = routes;

      const options = routesByDate.map((x) => ({
        label: x.nombre,
        value: x.id,
      }));

      if (options.length > 0 && isNew && !newRouteTrackingForm) {
        form.setFieldValue("rutaId", options[0].value);
        await findTagsByRoute(options[0].value);
        setDisabledTags(false);
      }

      setRouteOptions(options);
    }
  };

  const isReadOnly = () => {
    if (
      readOnly ||
      (searchParams.get("mode") === "readonly" &&
        !scopes.crear &&
        !scopes.modificar)
    ) {
      return true;
    }
    return false;
  };

  const onDateChange = async (value: moment.Moment | null) => {
    getRoutes(destination, value);
  };

  const findTagsByRoute = async (routeId: string) => {
    if (!routeId) {
      form.setFieldsValue({ escaneos: false });
      setShowScanField(false);
      setTagData(tagsSelected);
      return;
    }
    setDisableScan(false);
    const route = routeId === undefined ? "" : routeId;
    setRouteSelected(route);

    const filter: ITagsDate = {
      fecha: dateSelected,
      ruta: route,
      id: id,
    };

    const requestTags = await getFindTags(filter);
    const tags = requestTags.flatMap((x) => x.etiquetas);

    if (tags) {
      setTagData([...tags, ...tagsSelected]);
    }
  };

  const addExtraTagsToEtiquetas = (tagId: number) => {
    const foundTag = pendingTags.find((pendingTag) =>
      pendingTag.etiquetas.some((etiqueta) => etiqueta.id === tagId)
    );
    if (!!foundTag) {
      const findFoundTagInEtiquetas = etiquetas.find(
        (etiqueta) => etiqueta.solicitudId === foundTag.solicitudId
      );
      if (!!findFoundTagInEtiquetas) {
        const newAddedTag = etiquetas.map((etiqueta) => {
          if (etiqueta.solicitudId === foundTag.solicitudId) {
            etiqueta.etiquetas = [
              ...etiqueta.etiquetas,
              ...foundTag.etiquetas.filter(
                (etiqueta) => etiqueta.id === tagId
              )!,
            ];
          }
          return etiqueta;
        });
        setRequestTags(newAddedTag);
      } else {
        foundTag.etiquetas = foundTag.etiquetas.filter(
          (etiqueta) => etiqueta.id === tagId
        )!;
        setRequestTags([...etiquetas, foundTag]);
      }
    }
  };
  const addExtraTagToOrder = (tag: IRouteTrackingRequest) => {
    const existRequest = etiquetas.find(
      (x) => x.solicitudId === tag.solicitudId
    );

    const allInRouteTags = etiquetas
      .flatMap((et) => et.etiquetas)
      .map((et) => et.clave);
    console.log("TAG", tag);
    if (tag.existInOrder) {
      alerts.warning(
        `La muestra ${tag.etiquetas[0].clave} ya se encuentra escaneada previamente dentro de la orden ${tag.etiquetas[0].ruta}`
      );
      return;
    }

    if (!allInRouteTags.includes(tag.etiquetas[0].clave)) {
      alerts.confirm(
        "Etiqueta no configurada",
        "La etiqueta escaneada no se encuentra configurada para esta ruta, ¿Desea agregarla?",
        async () => {
          addNewTag(existRequest!, tag);
          onScan(true, tag.etiquetas[0].id);
        }
      );
      return;
    } else {
      addNewTag(existRequest!, tag);
      onScan(true, tag.etiquetas[0].id);
    }
  };

  const addNewTag = (
    existRequest: IRouteTrackingRequest,
    tag: IRouteTrackingRequest
  ) => {
    if (existRequest) {
      const existTag = existRequest.etiquetas.find(
        (x) => x.id === tag.etiquetas[0].id
      );
      if (!existTag) {
        existRequest.etiquetas.push(tag.etiquetas[0]);
        const newRequestTag = {
          ...existRequest,
          etiquetas: [...existRequest.etiquetas],
        };

        const newTags = etiquetas.map((x) =>
          x.solicitudId === existRequest.solicitudId ? newRequestTag : x
        );

        setRequestTags([...newTags]);
      }
    } else {
      etiquetas.push(tag);
      setRequestTags([...etiquetas]);
    }
  };

  useEffect(() => {
    disableConfirmTracking();
  });

  const disableConfirmTracking = () => {
    let areScanned = routeStudies.some((x) => x.escaneo);

    if (areScanned) {
      setDisableGlobal(true);
      return true;
    }
    setDisableGlobal(false);
    return false;
  };

  const onConfirmTracking = () => {
    if (id && !isNew && !editable) {
      alerts.confirm(
        "Confirmar recolección",
        "¿Está seguro que desea confirmar la recolección de muestras?",
        async () => {
          form.submit();
        },
        async () => {}
      );
    } else {
      form.submit();
    }
  };

  const onCancelTrackng = () => {
    if (!isNew) {
      alerts.confirm(
        "Cancelar orden de seguimiento",
        "¿Está seguro que desea cancelar la orden de seguimiento?",
        async () => {
          onFinish(values, true);
        },
        async () => {}
      );
    } else {
      navigate(`/segRutas`);
      setDisabledTags(true);
    }
  };

  const onChangeDate: RangePickerProps["onChange"] = (dates) => {
    if (dates) {
      const startDate: Moment = dates[0] as Moment;
      const endDate: Moment = dates[1] as Moment;
      var dateTag = [startDate.utcOffset(0, true), endDate.utcOffset(0, true)];

      setDateSelected(dateTag);

      const filter: ITagsDate = {
        fecha: dateTag,
        ruta: routeSelected,
        id: id,
      };

      if (id) {
        getEditTags(filter);
        setTagData([...tagsSelected, ...tagEditData]);
      } else {
        getFindTags(filter);
      }
    }
  };

  const tagsDateDisabled = () => {
    if (routeOptions.length === 0 || !editable) {
      return true;
    }

    return false;
  };

  const onFinish = async (
    newOrder: IRouteTrackingForm,
    isCancelation?: boolean
  ) => {
    const order = { ...values, ...newOrder };

    order.estudios = routeStudies.filter((x) => x.escaneo);
    order.destino = destination.toString();
    order.origenId = originBranch;
    order.rutaId = form.getFieldValue("rutaId");
    order.diaRecoleccion = moment(order.diaRecoleccion).utcOffset(0, true);
    order.muestra = `${order.muestra}`;

    if (isCancelation) {
      order.cancelacion = true;
    }
    if (editable) {
      order.editable = true;
    }

    let success = false;
    if (isNew) {
      success = await createTrackingOrder(order);
    } else {
      success = await updateTrackingOrder(order);
    }

    if (success) {
      setScan(false);
      clearNewRouteTrackingForm();
      cleanTrackingOrder();
      goBack();
    }
  };

  return (
    <Spin spinning={loading} tip={"Cargando"}>
      <Row gutter={[4, 16]}>
        {!readOnly &&
          values.estatus! <= status.shipment.recolectado &&
          scopes.modificar &&
          scopes.crear && (
            <Col md={24} sm={24} xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onCancelTrackng}>
                {!isNew ? "Cancelar orden de seguimiento" : "Cancelar"}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onConfirmTracking}
                disabled={!disableGlobal || (isModified && !editable)}
              >
                {isNew || editable
                  ? "Guardar orden de seguimiento"
                  : "Confirmar recolección"}
              </Button>
              {!isNew && !editable && (
                <Button
                  title="Editar"
                  key="edit"
                  onClick={() => setEditable(true)}
                >
                  Editar <EditFilled />
                </Button>
              )}
              {!isNew && editable && (
                <Button
                  type="default"
                  disabled={isModified}
                  onClick={() => {
                    setEditable(false);

                    const deselectedTags = tagEditData.filter(
                      (x) => !tagsSelected.some((y) => y.id === x.id)
                    );
                    deselectedTags.forEach((x) => {
                      onScan(false, x.id);
                    });

                    setTagData([...tagsSelected]);
                  }}
                >
                  Recolectar <FlagFilled />
                </Button>
              )}
            </Col>
          )}
        {readOnly && scopes.modificar && (
          <Col md={12} sm={24} style={{ textAlign: "right" }}>
            {readOnly && (
              <ImageButton
                key="edit"
                title="Editar"
                image="editar"
                onClick={() => {
                  setReadOnly(false);
                }}
              />
            )}
          </Col>
        )}
        <Col md={24} sm={12}>
          <Form<IRouteTrackingForm>
            {...formItemLayout}
            form={form}
            name="trackingOrder"
            onFinish={onFinish}
            scrollToFirstError
            labelWrap
          >
            <Row>
              <Col md={6} sm={12}>
                <SelectInput
                  formProps={{
                    name: "destino",
                    label: "Destino",
                  }}
                  required
                  placeholder="Destino"
                  options={treeData}
                  onChange={(value) => {
                    extraPendingTags();
                    getRoutes(value);
                    if (!value) {
                      setShowScanField(!!value);
                      setDisableScan(true);
                      form.setFieldsValue({ escaneos: !!value });
                    } else {
                      setDisableScan(false);
                    }
                  }}
                  readonly={!isNew || isReadOnly() || !!newRouteTrackingForm}
                />
              </Col>
              <Col md={6} sm={12}>
                <SelectInput
                  formProps={{
                    name: "origenId",
                    label: "Origen",
                  }}
                  onChange={(value) => setOriginBranch(value)}
                  options={routeBranchOptions}
                  readonly={true}
                  required
                />
              </Col>
              <Col md={6} sm={12}>
                <Form.Item label="Recolección" name="diaRecoleccion" required>
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment().utcOffset(0, true)}
                    format="DD/MM/YYYY HH:mm"
                    minuteStep={1}
                    showTime
                    allowClear
                    disabledDate={(current) =>
                      current.isBefore(
                        moment().utcOffset(0, true).startOf("day")
                      )
                    }
                    disabledTime={(current) => {
                      const startOfToday = moment()
                        .utcOffset(0, true)
                        .startOf("day");
                      if (current && current.isSame(startOfToday, "day")) {
                        return {
                          disabledHours: () =>
                            Array.from(
                              { length: moment().utcOffset(0, true).hour() },
                              (_, i) => i
                            ),
                          disabledMinutes: () =>
                            Array.from(
                              { length: moment().utcOffset(0, true).minute() },
                              (_, i) => i
                            ),
                        };
                      }
                      return {};
                    }}
                    onChange={(value) => {
                      if (!value) {
                        setShowScanField(!!value);
                        setDisableScan(true);
                        form.setFieldsValue({ escaneos: !!value });
                      } else {
                        setDisableScan(false);
                      }
                      onDateChange(value);
                    }}
                    disabled={!isNew || isReadOnly()}
                  />
                </Form.Item>
              </Col>
              <Col md={6} sm={12}>
                <SelectInput
                  formProps={{
                    name: "rutaId",
                    label: "Ruta",
                  }}
                  onChange={findTagsByRoute}
                  loading={loadingRoutes}
                  options={routeOptions ?? []}
                  readonly={!isNew || isReadOnly() || !!newRouteTrackingForm}
                  required
                />
              </Col>
              <Col md={6} sm={12} style={{ textAlign: "left" }}>
                <SelectInput
                  formProps={{
                    name: "muestra",
                    label: "Muestra",
                  }}
                  // required
                  placeholder="Muestra"
                  options={treeDataExtras}
                  onChange={(value) => {
                    addExtraTagsToEtiquetas(value);
                  }}
                  readonly={
                    disabledTags ||
                    readOnly ||
                    !!newRouteTrackingForm ||
                    !editable
                  }
                />
              </Col>
              <Col md={6} sm={12} style={{ textAlign: "left" }}>
                <NumberInput
                  formProps={{
                    name: "temperatura",
                    label: "Temperatura",
                  }}
                  type="number"
                  suffix="°C"
                  readonly={false}
                  controls={false}
                />
              </Col>
              {!newRouteTrackingForm ? (
                <Col md={6} sm={12}>
                  <Form.Item label="Fecha muestra" name="fechaMuestra">
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      defaultValue={[
                        moment().utcOffset(0, true),
                        moment().utcOffset(0, true),
                      ]}
                      format="DD/MM/YYYY"
                      minuteStep={1}
                      allowClear
                      disabledDate={(current) =>
                        current.isAfter(
                          moment().utcOffset(0, true).startOf("day")
                        )
                      }
                      onChange={onChangeDate}
                      disabled={tagsDateDisabled()}
                    />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}

              <Col md={6} sm={12}>
                <SwitchInput
                  name="escaneos"
                  label="Escaneo"
                  // readonly={readOnly || !editable}
                  readonly={disableScan}
                  onChange={(value) => {
                    setShowScanField(value);
                    // onScan(value);
                  }}
                />
              </Col>
              {showScanField ? (
                <>
                  <Col md={6} sm={12} style={{ textAlign: "left" }}>
                    <TextInput
                      formProps={{
                        name: "scanLabel",
                        label: "Etiqueta escaneada",
                      }}
                      readonly={loadingSearchTags}
                      autoFocus={true}
                      onChange={async (e) => {
                        let etiqueta = e.target.value;

                        if (!!etiqueta.length && etiqueta.length >= 12) {
                          setLoadingSearchTags(true);
                          const tags = await getAllTags(etiqueta, "tag");
                          console.log("TAGS", toJS(tags));
                          if (!!tags && tags.length > 0) {
                            //itagtrackingorder to iroutetrackinrequest
                            let tag = tags[0];

                            let tagRequest: IRouteTrackingRequest = {
                              solicitudId: tag.solicitudId,
                              solicitud: tag.solicitud,
                              nombre: tag.paciente,
                              expedienteId: "aaaa",
                              existInOrder: tag.existInOrder,
                              etiquetas: [
                                {
                                  id: tag.etiquetaId,
                                  seguimiento: "",
                                  seguimientoId: tag.solicitudId,
                                  claveSolicitud: tag.solicitud,
                                  solicitudId: tag.solicitudId,
                                  ordenId: tag.solicitudId,
                                  claveEtiqueta: tag.claveEtiqueta,
                                  nombreEtiqueta: "",
                                  claveEstudios: tag.estudios,
                                  ruta: tag.orderId ?? "",
                                  entrega: "",
                                  extra: false,
                                  estatusSeguimiento: 0,
                                  clave: tag.recipiente,
                                  escaneo: true,
                                  recolectado: false,
                                  usuarioReceptor: "",
                                  estudios: [],
                                },
                              ],
                            };

                            addExtraTagToOrder(tagRequest);
                            form.resetFields(["scanLabel"]);
                            setLoadingSearchTags(false);
                          } else {
                            alerts.warning("No se encontró la etiqueta");
                            form.resetFields(["scanLabel"]);
                            setLoadingSearchTags(false);
                          }
                        }
                      }}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Col>
        {notScanned ? (
          <Col span={24}>
            <Text type="danger">{`Muestras pendientes: ${notScanned}`}</Text>
          </Col>
        ) : (
          ""
        )}

        <Col md={24} sm={12}>
          <RouteTrackingCreateTable
            setNotScanned={setNotScanned}
            editable={editable}
            isNew={isNew}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(RouteTrackingCreateForm);
