import {
  Button,
  Col,
  FormInstance,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Tab } from "rc-tabs/lib/interface";
import { useCallback, useEffect, useState } from "react";

import { InfoCircleTwoTone } from "@ant-design/icons";

import { verifyDiscountPermission } from "../../../app/common/administrator/discountValidation";
import { useKeyPress } from "../../../app/hooks/useKeyPress";
import { IRequestGeneral } from "../../../app/models/request";
import { IValidationPermission } from "../../../app/models/shared";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import ProceedingObservations from "../../proceedings/details/ProceedingObservations";
import RequestGeneral from "./content/RequestGeneral";
import RequestImage from "./content/RequestImage";
import RequestIndication from "./content/RequestIndication";
import RequestPrint from "./content/RequestPrint";
import RequestRegister from "./content/RequestRegister";
import RequestRequest from "./content/RequestRequest";
import RequestSampler from "./content/RequestSampler";
import RequestStudy from "./content/RequestStudy";
import RequestInvoice from "./RequestInvoice";
import { onSubmitGeneral, submitGeneral } from "./utils";
import { useParams, useNavigate } from "react-router-dom";
import views from "../../../app/util/view";

const { Text, Title } = Typography;

type RequestTabProps = {
  recordId: string;
  requestId?: string;
  formGeneral: FormInstance<IRequestGeneral>;
};

type keys =
  | "general"
  | "studies"
  | "indications"
  | "register"
  | "sampler"
  | "print"
  | "request"
  | "images"
  | "observaciones";

const showAutoSaveMessage = () => {
  notification.info({
    key: "auto-not",
    message: `Guardado automático`,
    placement: "bottomRight",
    icon: "",
  });
};

const RequestTab = ({ recordId, requestId, formGeneral }: RequestTabProps) => {
  let navigate = useNavigate();
  const { activeTab } = useParams();
  const {
    optionStore,
    profileStore,
    requestStore,
    procedingStore,
    loyaltyStore,
    packStore,
  } = useStore();
  const { BranchOptions, getBranchOptions } = optionStore;
  const { profile } = profileStore;
  const {
    readonly,
    request,
    studyUpdate,
    loadingTabContent,
    loadingNetPay,
    allStudies,
    studyFilter,
    totals,
    totalsOriginal,
    discountModified,
    clearLoadingNetPay,
    getStudies,
    getPayments,
    getTags,
    updateBranch,
    updateGeneral,
    updateStudies,
    updateTags,
    cancelRequest,
    setOriginalTotal,
    updateStudiesDates,
    tabKey,
    setTabKey,
    scopes,
    revertDiscountPack,
    revertDiscountStudy,
    getById: getStudyById,
  } = requestStore;
  const { activateWallet, getById } = procedingStore;
  const { getActive, getByDate } = loyaltyStore;

  const [tabs, setTabs] = useState<Tab[]>([]);
  const [currentKey, setCurrentKey] = useState<keys>(tabKey as keys);
  const [canUpdateBranch, setCanUpdateBranch] = useState(false);

  const onChangeTab = async (key: string) => {
    if (
      (currentKey === "general" ||
        currentKey === "studies" ||
        currentKey === "request" ||
        currentKey === "print") &&
      !readonly
    ) {
      submit(scopes.modificar);
    }

    setCurrentKey(key as keys);
    setTabKey(key as keys);
    // navigate(`/${views.request}/${recordId}/${requestId}/${key}`);
  };

  useEffect(() => {
    getActive();
  }, [getActive]);

  useEffect(() => {
    if (tabKey) {
      setCurrentKey(tabKey as keys);
    } else {
      setCurrentKey("general" as keys);
    }
  }, [tabKey]);

  const modificarSaldo = async () => {
    const loyal = await getByDate(moment().utcOffset(0, true).toDate());
    const contieneMedico = loyal?.precioLista.some((l) => l === "Medicos");
    const expediente = await getById(request?.expedienteId!);
    const fechaCreaccionSolicitud = moment(request?.registro);
    if (
      expediente?.hasWallet &&
      !studyFilter.compañiaId &&
      contieneMedico &&
      fechaCreaccionSolicitud.isSameOrAfter(fechaCreaccionSolicitud)
    ) {
      if (loyal?.tipoDescuento === "Porcentaje") {
        const bonoActual = (loyal?.cantidadDescuento! * totals.total) / 100;
        const bonoAnterior =
          (loyal?.cantidadDescuento! * totalsOriginal.total) / 100;

        let bonoFinal: number;
        bonoFinal =
          expediente.wallet > 0
            ? expediente.wallet - bonoAnterior + bonoActual
            : expediente.wallet + bonoActual;

        setOriginalTotal(totals);

        await activateWallet(request?.expedienteId!, bonoFinal);
      } else {
        await activateWallet(
          request?.expedienteId!,
          expediente.wallet - loyal?.cantidadDescuento!
        );
      }
    }
  };

  const submit = async (autoSave: boolean = false) => {
    if (currentKey === "general") {
      const ok = await submitGeneral(formGeneral, autoSave);
      if (!ok) {
        setCurrentKey("general");
        setTabKey("general");
        return;
      }
    } else if (currentKey === "studies") {
      if (!discountModified) return;

      let permission: IValidationPermission | undefined;
      let canContinueAction: boolean = true;
      if (!profile) return;
      if (!profile.aplicaDescuentos && discountModified) {
        permission = await verifyDiscountPermission();
        if (!permission) return;
        if (permission && !permission.permiso) {
          alerts.warning(
            "No cuenta con los privilegios para realizar esta acción"
          );
          canContinueAction = false;
          revertDiscountPack();
          revertDiscountStudy();
          return;
        }
      }
      if (!canContinueAction) {
        return;
      }
      if (
        request &&
        (profile.aplicaDescuentos || (permission && permission.permiso))
      ) {
        const ok = await updateStudies(studyUpdate, false);

        if (!ok) {
          return;
        }
      }
      // let permission: IValidationPermission | undefined;
      // if (!profile) return;
      // if (!profile.aplicaDescuentos && discountModified) {
      //   permission = await verifyDiscountPermission();
      //   if (!permission) return;
      //   if (permission && !permission.permiso) {
      //     alerts.warning(
      //       "No cuenta con los privilegios para realizar esta acción"
      //     );
      //     return;
      //   }
      // }
      // if (
      //   request &&
      //   (profile.aplicaDescuentos ||
      //     (permission && permission.permiso) ||
      //     !discountModified)
      // ) {
      //   const ok = await updateStudies(studyUpdate, autoSave);
      //   // let okTags = false;
      //   // if (ok) {
      //   //   okTags = await updateTags(
      //   //     request!.expedienteId,
      //   //     request!.solicitudId!,
      //   //     true
      //   //   );
      //   // }
      //   // if (!ok || !okTags) {
      //   //   return;
      //   // }
      //   if (!ok) {
      //     return;
      //   }
      // }
    } else if (currentKey === "request") {
      let res = [
        ...studyUpdate.estudios.map((x) => ({
          id: x.id,
          fechaEntrega: x.fechaEntrega.toString(),
        })),
        ...(studyUpdate.paquetes?.flatMap((p) =>
          p.estudios.map((x) => ({
            id: x.id,
            fechaEntrega: x.fechaEntrega.toString(),
          }))
        ) ?? []),
      ];

      const ok = await updateStudiesDates(request?.solicitudId, res);
      if (!ok) {
        return;
      }
    }
    if (currentKey === "print") {
      const ok = await updateTags(
        request!.expedienteId,
        request!.solicitudId!,
        autoSave
      );
      if (!ok) {
        return;
      }
    }

    if (autoSave) {
      showAutoSaveMessage();
    }
  };

  useKeyPress("L", submit);

  const cancel = () => {
    alerts.confirm(
      "Cancelar solicitud",
      `¿Desea cancelar la solicitud?, esta acción no se puede deshacer`,
      async () => {
        if (request) {
          const ok = await cancelRequest(
            request.expedienteId,
            request.solicitudId!
          );
          // if (ok) {
          //   await modificarSaldo();
          // }
        }
      }
    );
  };

  useEffect(() => {
    setCanUpdateBranch(allStudies.length === 0);
  }, [allStudies.length]);

  useEffect(() => {
    getBranchOptions();
  }, [getBranchOptions]);

  useEffect(() => {
    const readData = async () => {
      if (request) {
        await getStudies(request.expedienteId, request.solicitudId!);
        getPayments(request.expedienteId, request.solicitudId!);
        getTags(request.expedienteId, request.solicitudId!);
      }
    };

    readData();
  }, [getStudies, getPayments, request, getTags]);

  const onChangeBranch = async (branchId: string) => {
    if (!request) return;

    const req = { ...request };
    req.sucursalId = branchId;
    await updateBranch(req);
  };

  const operations = (
    <Space>
      {!canUpdateBranch || readonly ? (
        <Select
          key="request-branch"
          disabled={true}
          value={request?.sucursal}
          style={{ width: 240 }}
          options={[{ value: request?.sucursal, label: request?.sucursal }]}
        ></Select>
      ) : (
        <Select
          key="type"
          showSearch
          placeholder="Sucursal"
          optionFilterProp="children"
          onChange={(value) => {
            onChangeBranch(value);
          }}
          disabled={!canUpdateBranch || readonly}
          filterOption={(input: string, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={request?.sucursalId}
          allowClear={false}
          style={{ width: 240 }}
          options={BranchOptions}
        ></Select>
      )}
      {!readonly && (scopes.crear || scopes.modificar) && (
        <>
          <Button key="cancel" size="small" ghost danger onClick={cancel}>
            Cancelar
          </Button>
          <Button
            key="save"
            size="small"
            type="primary"
            onClick={() => submit()}
          >
            Guardar
          </Button>
        </>
      )}
    </Space>
  );

  const tabRender = useCallback(
    (tabName: string) => {
      let component = (
        <RequestGeneral
          form={formGeneral}
          onSubmit={async (request, showLoader) => {
            const ok = await onSubmitGeneral(
              request,
              showLoader,
              updateGeneral
            );
            if (!ok) {
              setCurrentKey("general");
              setTabKey("general");
            }
          }}
        />
      );

      if (tabName === "studies") {
        component = <RequestStudy />;
      } else if (tabName === "indications") {
        component = <RequestIndication />;
      } else if (tabName === "register") {
        component = <RequestRegister />;
      } else if (tabName === "sampler") {
        component = <RequestSampler />;
      } else if (tabName === "print") {
        component = <RequestPrint />;
      } else if (tabName === "request") {
        component = <RequestRequest formGeneral={formGeneral} />;
      } else if (tabName === "images") {
        component = <RequestImage />;
      } else if (tabName === "observaciones") {
        component = (
          <ProceedingObservations readonly={readonly} proceedingId={recordId} />
        );
      }

      return (
        <Row gutter={16}>
          <Col span={18}>{component}</Col>
          <Col span={6} style={{ borderLeft: "1px solid lightgray" }}>
            <RequestInvoice />
          </Col>
        </Row>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formGeneral, updateGeneral]
  );

  useEffect(() => {
    setTabs([
      {
        key: "general",
        label: "Generales",
        children: tabRender("general"),
      },
      {
        key: "studies",
        label: "Estudios",
        children: tabRender("studies"),
      },
      {
        key: "indications",
        label: "Indicaciones",
        children: tabRender("indications"),
        disabled: allStudies.length === 0,
      },
      {
        key: "register",
        label: "Caja",
        children: tabRender("register"),
        disabled: allStudies.length === 0,
      },
      {
        key: "sampler",
        label: "Registro de toma",
        children: tabRender("sampler"),
        disabled: allStudies.length === 0,
      },
      {
        key: "print",
        label: "Imprimir",
        children: tabRender("print"),
        disabled: allStudies.length === 0,
      },
      {
        key: "request",
        label: "Solicitar Estudio",
        children: tabRender("request"),
        disabled: allStudies.length === 0,
      },
      {
        key: "images",
        label: "Imágenes",
        children: tabRender("images"),
      },
      {
        key: "observaciones",
        label: "Observaciones expediente",
        children: tabRender("observaciones"),
      },
    ]);
  }, [allStudies.length, tabRender]);

  const NetPayTip = () => (
    <Space direction="vertical" size={"large"}>
      <Text>Esperando respuesta de terminal...</Text>
      <Button danger type="text" onClick={clearLoadingNetPay}>
        Cancelar
      </Button>
    </Space>
  );

  if (!request?.sucursalId) {
    return <p>Por favor selecciona una sucursal.</p>;
  }

  return (
    <Spin
      spinning={loadingTabContent || loadingNetPay}
      wrapperClassName="fullscreen-spinner"
      tip={loadingNetPay && <NetPayTip />}
    >
      {(loadingTabContent || loadingNetPay) && currentKey === "studies" ? (
        <Modal open={true} footer={null} closable={false}>
          <Title level={5}>
            <InfoCircleTwoTone style={{ fontSize: 16 }} /> Cargando estudios
          </Title>
          <Text>Por favor espere.</Text>
        </Modal>
      ) : (
        ""
      )}
      <Tabs
        activeKey={currentKey}
        tabBarExtraContent={operations}
        onChange={onChangeTab}
        items={tabs}
      />
    </Spin>
  );
};

export default observer(RequestTab);
