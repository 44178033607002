export const creditEstatus = [
  {
    label: "Facturadas",
    value: "facturadas",
  },
  {
    label: "Canceladas",
    value: "canceladas",
  },
];

export const creditTimeEstatus = [
  {
    label: "Pagada",
    value: "pagada",
  },
  {
    label: "Próxima a vencer",
    value: "proxima",
  },
  {
    label: "Vigente",
    value: "vigente",
  },
  {
    label: "Vencida",
    value: "vencida",
  },
];

export const categoryType = [
  {
    label: "Libre a crédito",
    value: 1,
  },
  {
    label: "Compañía a crédito",
    value: 2,
  },
  {
    label: "Todas",
    value: 3,
  },
];
