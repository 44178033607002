import { Button, PageHeader, Input } from "antd";
import React, { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ImageButton from "../../app/common/button/ImageButton";
import views from "../../app/util/view";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import PrintIcon from "../../app/common/icons/PrintIcon";
import { IScopes } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";

const { Search } = Input;
type StudyHeaderProps = {
  handlePrint: () => void;
  handleList: () => void;
  scopes: IScopes;
};
const StudyHeader: FC<StudyHeaderProps> = ({
  handlePrint,
  handleList,
  scopes,
}) => {
  let navigate = useNavigate();
  const { studyStore } = useStore();
  const { loading } = studyStore;

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Catálogo Estudios" image="estudio" />}
      className="header-container"
      extra={[
        scopes.imprimir && <PrintIcon key="print" onClick={handlePrint} />,
        scopes.descargar && <DownloadIcon key="doc" onClick={handleList} />,
        <Search
          key="search"
          placeholder="Buscar"
          onSearch={(value) => {
            navigate(`/${views.study}?search=${value}`);
          }}
          loading={loading}
        />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate("new-study");
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};
export default StudyHeader;
