import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import CreditMonitoringFilter from "../components/invoice/creditMonitoring/CreditMonitoringFilter";
import CreditMonitoringHeader from "../components/invoice/creditMonitoring/CreditMonitoringHeader";
import CreditMonitoringTable from "../components/invoice/creditMonitoring/CreditMonitoringTable";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const CreditMonitoring = () => {
  const { invoiceCompanyStoreLegacy: invoiceCompanyStore } = useStore();
  const { exportForm, scopes } = invoiceCompanyStore;
  const [loading, setLoading] = useState(false);

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Spin spinning={loading}>
      <CreditMonitoringHeader setLoading={setLoading} />
      <Divider className="header-divider" />
      <CreditMonitoringFilter setLoading={setLoading} />
      <CreditMonitoringTable />
    </Spin>
  );
};

export default observer(CreditMonitoring);
