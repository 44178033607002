import { Button, PageHeader } from "antd";
import { observer } from "mobx-react-lite";

import { PlusOutlined } from "@ant-design/icons";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import { useStore } from "../../../app/stores/store";

type Props = {
  selectedRequests: string[];
  setSelectedRequests: React.Dispatch<React.SetStateAction<string[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceGlobalHeader = ({
  selectedRequests,
  setSelectedRequests,
  setLoading,
}: Props) => {
  const { invoiceGlobalStore } = useStore();
  const { filter, downloadList, create, scopes } = invoiceGlobalStore;

  const createInvoices = async () => {
    setLoading(true);
    const ok = await create(selectedRequests);
    setLoading(false);

    if (ok) {
      setSelectedRequests([]);
    }
  };

  const download = async () => {
    setLoading(true);
    await downloadList(filter);
    setLoading(false);
  };

  return (
    <PageHeader
      ghost
      title={
        <HeaderTitle
          title={"Crédito y cobranza (Facturación global)"}
          image="invoice-company"
        />
      }
      className="header-container"
      extra={[
        scopes.descargar && <DownloadIcon key="doc" onClick={download} />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={createInvoices}
            icon={<PlusOutlined />}
            disabled={selectedRequests.length === 0}
          >
            Generar
          </Button>
        ),
      ]}
    />
  );
};
export default observer(InvoiceGlobalHeader);
