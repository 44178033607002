import {
  IInvoiceCredit,
  IInvoiceCreditCancel,
  IInvoiceCreditFilter,
} from "../models/invoiceCredit";
import requests from "./agent";

const InvoiceCredit = {
  getAll: (filter: IInvoiceCreditFilter): Promise<IInvoiceCredit[]> =>
    requests.post("creditMonitoring/filter", filter),
  create: (creditDto: FormData): Promise<void> =>
    requests.post(`creditMonitoring/create`, creditDto),
  cancel: (creditDto: IInvoiceCreditCancel): Promise<void> =>
    requests.put(`creditMonitoring/cancel`, creditDto),
  downloadPdf: (complementId: string): Promise<void> =>
    requests.download(`creditMonitoring/download/pdf/${complementId}`),
  downloadXml: (complementId: string): Promise<void> =>
    requests.download(`creditMonitoring/download/xml/${complementId}`),
  downloadList: (filter: IInvoiceCreditFilter): Promise<void> =>
    requests.download("creditMonitoring/download/excel/list", filter),
};

export default InvoiceCredit;
