import { Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import LinkTo from "../../../app/common/link/LinkTo";
import {
	getDefaultColumnProps,
	IColumns,
} from "../../../app/common/table/utils";
import { IRequestInfo } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";
import { moneyFormatter } from "../../../app/util/utils";
import views from "../../../app/util/view";

type ProceedingRequestsProps = {
  loading: boolean;
  recordId: string;
};

const ProceedingRequests = ({ loading, recordId }: ProceedingRequestsProps) => {
  const { procedingStore, requestStore } = useStore();
  const { getRequests } = procedingStore;
  const { scopes: requestScopes } = requestStore;

  const [requests, setRequests] = useState<IRequestInfo[]>([]);

  useEffect(() => {
    const readRequests = async () => {
      const requests = await getRequests(recordId);
      setRequests(requests);
    };

    readRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  const columns: IColumns<IRequestInfo> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        width: 150,
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LinkTo
            to={`/${views.request}/${item.expedienteId}/${item.solicitudId}`}
            text={value}
            canRedirect={requestScopes.acceder}
          />
          <small>{item.clavePatologica}</small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("afiliacion", "Afiliación", {
        width: 180,
      }),
    },
    {
      ...getDefaultColumnProps("paciente", "Paciente", {
        width: 240,
      }),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      ...getDefaultColumnProps("compañia", "Compañia", {
        width: 180,
      }),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      ...getDefaultColumnProps("procedencia", "Procedencia", {
        width: 180,
      }),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      ...getDefaultColumnProps("importe", "Importe", {
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("descuento", "Descuento", {
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
  ];

  return (
    <>
      <Divider orientation="left">Solicitud</Divider>
      <Table<IRequestInfo>
        loading={loading}
        size="small"
        rowKey={(record) => record.solicitudId}
        columns={columns}
        dataSource={requests}
        pagination={false}
        sticky
        scroll={{
          x: "max-content",
          y: 400,
        }}
      />
    </>
  );
};

export default observer(ProceedingRequests);
