import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import InvoiceRequestCompanyFilter from "../components/invoice/invoiceRequestCompany/InvoiceRequestCompanyFilter";
import InvoiceRequestCompanyHeader from "../components/invoice/invoiceRequestCompany/InvoiceRequestCompanyHeader";
import InvoiceRequestCompanyTable from "../components/invoice/invoiceRequestCompany/InvoiceRequestCompanyTable";
import { useStore } from "../app/stores/store";

type UrlParams = {
  type: string;
};

const InvoiceCompany = () => {
  const { type } = useParams<UrlParams>();
  const { invoiceRequestCompanyStore } = useStore();
  const { scopes } = invoiceRequestCompanyStore;
  
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState<string>();
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);

  useEffect(() => {
    setGroupId(undefined);
    setSelectedRequests([]);
  }, [type]);

  if (type !== "company" && type !== "request" || !scopes.acceder) {
    return <Navigate to="/notFound" />;
  }

  return (
    <Spin spinning={loading}>
      <InvoiceRequestCompanyHeader
        type={type}
        groupId={groupId}
        selectedRequests={selectedRequests}
        setLoading={setLoading}
      />
      <Divider className="header-divider" />
      <InvoiceRequestCompanyFilter type={type} setLoading={setLoading} />
      <InvoiceRequestCompanyTable
        type={type}
        groupId={groupId}
        setGroupId={setGroupId}
        selectedRowKeys={selectedRequests}
        setSelectedRowKeys={setSelectedRequests}
      />
    </Spin>
  );
};

export default observer(InvoiceCompany);
