import { Button, Col, Form, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import DateRangeInput from "../../../app/common/form/proposal/DateRangeInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import { IGeneralForm } from "../../../app/models/general";
import { IAppointmentFilter } from "../../../app/models/appointment";
import { useStore } from "../../../app/stores/store";
import { formItemLayout } from "../../../app/util/utils";
import "./css/index.css";

const AppointmentFilter = () => {
  const { appointmentStore, optionStore, profileStore, generalStore } =
    useStore();
  const { branchCityOptions } = optionStore;
  const { getAppointments } = appointmentStore;
  const { setGeneralFilter, generalFilter } = generalStore;
  const { profile } = profileStore;

  const [form] = useForm<IAppointmentFilter>();

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;
    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options?.some((y) => y.value === profileBranch))
      ?.value.toString();

    const filter = {
      ...generalFilter,
      ciudad: !generalFilter.cargaInicial ? generalFilter.ciudad : [userCity!],
      sucursalId: [profileBranch],
    };
    form.setFieldsValue(filter);
    filter.cargaInicial = false;

    setGeneralFilter(filter);
    getAppointments(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchCityOptions]);

  const onFinish = (values: IGeneralForm) => {
    const profileBranch = profile!.sucursal;
    const filter = { ...values, sucursalId: [profileBranch] };

    setGeneralFilter(filter);
    getAppointments(filter);
  };

  return (
    <div className="status-container" style={{ marginBottom: 12 }}>
      <Form<IGeneralForm>
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        size="small"
        initialValues={{
          fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
        }}
      >
        <Row gutter={[0, 12]}>
          <Col span={8}>
            <DateRangeInput
              formProps={{ name: "fecha", label: "Fecha de alta" }}
              disableAfterDates
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "expediente",
                label: "Buscar",
              }}
              autoFocus
              placeholder="Clave cita / Nombre"
            />
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button key="clean" htmlType="reset">
              Limpiar
            </Button>
            <Button key="filter" type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(AppointmentFilter);
