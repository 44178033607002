import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import InvoiceGlobalTable from "../components/invoice/invoiceGlobal/InvoiceGlobalTable";
import InvoiceGlobalFilter from "../components/invoice/invoiceGlobal/InvoiceGlobalFilter";
import InvoiceGlobalHeader from "../components/invoice/invoiceGlobal/InvoiceGlobalHeader";
import { Navigate } from "react-router-dom";
import { useStore } from "../app/stores/store";

const InvoiceGlobal = () => {
  const { invoiceGlobalStore } = useStore();
  const { scopes } = invoiceGlobalStore;
  const [loading, setLoading] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }
  return (
    <Spin spinning={loading}>
      <InvoiceGlobalHeader
        selectedRequests={selectedRequests}
        setSelectedRequests={setSelectedRequests}
        setLoading={setLoading}
      />
      <Divider className="header-divider" />
      <InvoiceGlobalFilter setLoading={setLoading} />
      <InvoiceGlobalTable
        selectedRowKeys={selectedRequests}
        setSelectedRowKeys={setSelectedRequests}
      />
    </Spin>
  );
};

export default observer(InvoiceGlobal);
