import moment from "moment";

import { IInvoiceDetail } from "./invoice";

export interface IInvoiceRequestCompanyList {
  solicitudId: string;
  solicitud: string;
  expedienteId: string;
  expediente: string;
  paciente: string;
  sucursalId: string;
  sucursal: string;
  compañiaId: string;
  compañia: string;
  estatusId: number;
  estatus: string;
  total: number;
  saldo: number;
  estudios: IInvoiceRequestCompanyStudy[];
  facturas: IInvoiceRequestCompanyInvoice[];
}

export interface IInvoiceRequestCompanyStudy {
  clave: string;
  nombre: string;
  area: string;
  cantidad: number;
  precio: number;
}

export interface IInvoiceRequestCompanyInvoice {
  facturaId: string;
  serie: string;
  serieNumero: string;
  estatus: string;
}

export interface IInvoiceRequestCompanyDetail {
  desglozado: IInvoiceDetail[];
  sucursal: IInvoiceDetail[];
  paciente: IInvoiceDetail[];
  total: IInvoiceDetail[];
}

export interface IInvoiceRequestCompanyFilter {
  cargaInicial: boolean;
  fechas?: moment.Moment[];
  sucursales?: string[];
  compañias?: string[];
  clave?: string;
  estatus?: string[];
}

export class InvoiceRequestCompanyFilterValues
  implements IInvoiceRequestCompanyFilter
{
  cargaInicial: boolean = true;
  fechas: moment.Moment[] = [
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ];
  sucursales?: string[] = [];
  compañias?: string[] = [];
  clave?: string;
  estatus?: string[] = [];

  constructor(init?: IInvoiceRequestCompanyFilter) {
    Object.assign(this, init);
  }
}
