import { Button, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  ISearch,
  IColumns,
  getDefaultColumnProps,
  defaultPaginationProperties,
} from "../../../../app/common/table/utils";
import {
  IRouteTrackingHistoryList,
  IRouteTrackingRequest,
  ITagTrackingOrder,
  ITrackingTagStudy,
  ITrackingTags,
} from "../../../../app/models/routeTracking";
import { useStore } from "../../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { studyStatus } from "../../../../app/util/catalogs";
import { ExpandableConfig } from "antd/lib/table/interface";
import views from "../../../../app/util/view";

const { Text, Link } = Typography;

const TrackingHistoryTable = () => {
  const { routeTrackingStore } = useStore();
  const { historyTags, loadingRoutes } = routeTrackingStore;

  let navigate = useNavigate();
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [expandedTagsRows, setExpandedTagsRows] = useState<number[]>([]);
  const [expandedStudyRows, setExpandedStudyRows] = useState<number[]>([]);

  useEffect(() => {
    setExpandedRowKeys(historyTags.map((x) => x.solicitudId));
    setOpenRows(true);
  }, [historyTags]);

  const toggleRow = () => {
    if (openRows) {
      setOpenRows(false);
      setExpandedRowKeys([]);
    } else {
      setOpenRows(true);
      setExpandedRowKeys(historyTags.map((x) => x.solicitudId));
    }
  };

  const renderExtraRoute = (value: any, record: ITrackingTags) => {
    if (value) {
      const rutaItems = value.split(",");

      return (
        <div>
          {rutaItems.map((item: string) => (
            <div key={uuid()}>{item}</div>
          ))}
        </div>
      );
    }

    return null;
  };

  const onExpand = (isExpanded: boolean, record: IRouteTrackingRequest) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.solicitudId);
    } else {
      const index = expandRows.findIndex((x) => x === record.solicitudId);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  const onExpandTags = (isExpanded: boolean, record: ITrackingTags) => {
    let expandRows: number[] = expandedTagsRows;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedTagsRows(expandRows);
  };

  const onExpandStudy = (isExpanded: boolean, record: ITrackingTagStudy) => {
    let expandRows: number[] = expandedStudyRows;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedStudyRows(expandRows);
  };

  const columns: IColumns<IRouteTrackingRequest> = [
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        width: "50%",
      }),
      render: (value, record) => (
        <Button
          type="link"
          onClick={() => {
            navigate(
              `/${views.request}/${record.expedienteId}/${record.solicitudId}`
            );
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre paciente", {
        width: "50%",
      }),
    },
  ];

  const expandableTagsConfig = () => {
    const nestedColumns: IColumns<ITrackingTags> = [
      {
        ...getDefaultColumnProps("claveEtiqueta", "Clave muestra", {
          width: "20%",
        }),
        render: (value, record) => value + " - " + record.clave,
      },
      {
        ...getDefaultColumnProps("claveEstudios", "Estudios", {
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("ruta", "Ruta", {
          width: "20%",
        }),
        render: (value, record) => renderExtraRoute(value, record),
      },
      {
        ...getDefaultColumnProps("seguimiento", "No. seguimiento", {
          width: "20%",
          minWidth: 150,
        }),
        render: (value, record) => (
          <Fragment>
            {value ? (
              <Link
                onClick={() => {
                  navigate(`/historytracking/${record.seguimientoId}`);
                }}
              >
                {value}
              </Link>
            ) : (
              <Text>-</Text>
            )}
          </Fragment>
        ),
      },
      {
        ...getDefaultColumnProps("usuarioReceptor", "Recibió", {
          width: "20%",
        }),
        render: (value) => "Recibe: " + value,
      },
    ];

    return {
      expandedRowRender: (item: IRouteTrackingRequest, index: any) => (
        <Table
          columns={nestedColumns}
          rowKey={(record) => record.id}
          dataSource={item.etiquetas}
          pagination={false}
          className="header-expandable-table"
          expandable={{
            ...expandableStudyConfig(),
            onExpand: onExpandTags,
            expandedRowKeys: expandedTagsRows,
          }}
          showHeader={false}
        />
      ),
    };
  };

  const expandableStudyConfig = () => {
    const nestedColumns: IColumns<ITrackingTagStudy> = [
      {
        ...getDefaultColumnProps("clave", "Clave", {
          width: "50%",
        }),
        render: (value, record) => value + " - " + record.nombre,
      },
      {
        ...getDefaultColumnProps("estatus", "Estatus", {
          width: "50%",
        }),
        render: (value, record) => studyStatus(value),
      },
    ];

    return {
      expandedRowRender: (item: ITrackingTags, index: any) => (
        <Table
          columns={nestedColumns}
          rowKey={(record) => record.id}
          dataSource={item.estudios}
          pagination={false}
          className="header-expandable-table"
          showHeader={false}
          expandable={{
            onExpand: onExpandStudy,
            expandedRowKeys: expandedStudyRows,
          }}
        />
      ),
    };
  };

  return (
    <Fragment>
      <Table<IRouteTrackingRequest>
        loading={loadingRoutes}
        size="small"
        rowKey={(record) => record.solicitudId}
        columns={columns}
        dataSource={[...historyTags]}
        pagination={defaultPaginationProperties}
        expandable={{
          ...expandableTagsConfig(),
          defaultExpandAllRows: true,
          columnTitle: (
            <div style={{ textAlign: "center" }}>
              <button
                type="button"
                onClick={toggleRow}
                className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                  openRows ? "expanded" : "collapsed"
                }`}
                aria-label={openRows ? "Colapsar fila" : "Expandir fila"}
                aria-expanded={openRows ? "true" : "false"}
              ></button>
            </div>
          ),
          onExpand: onExpand,
          expandedRowKeys: expandedRowKeys,
        }}
        rowClassName={"row-search"}
        bordered
      ></Table>
    </Fragment>
  );
};

export default observer(TrackingHistoryTable);
