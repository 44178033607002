import { IIndicationList } from "./indication";
import { IParameterList } from "./parameter";
import { IStudyTag } from "./study";

export interface IPriceList {
  id: string;
  clave: string;
  nombre: string;
  compañias: string;
  sucursales: string;
  activo: boolean;
}

export interface IPriceListForm {
  id: string;
  clave?: string;
  nombre?: string;
  activo: boolean;
  visible: boolean;
  compañias: string[];
  sucursales: string[];
  estudios: IPriceListStudy[];
  paquetes: IPriceListPack[];
}

export class PriceListFormValues implements IPriceListForm {
  id: string = "00000000-0000-0000-0000-000000000000";
  clave?: string | undefined;
  nombre?: string | undefined;
  activo: boolean = true;
  visible: boolean = false;
  compañias: string[] = [];
  sucursales: string[] = [];
  estudios: IPriceListStudy[] = [];
  paquetes: IPriceListPack[] = [];

  constructor(init?: IPriceListForm) {
    Object.assign(this, init);
  }
}

export interface IPriceListStudy {
  id: number;
  estudioId: number;
  clave: string;
  nombre: string;
  departamentoId?: number;
  departamento?: string;
  areaId?: number;
  area?: string;
  activo: boolean;
  precio: number;
}

export interface IPriceListPack {
  paqueteId: number;
  clave: string;
  nombre: string;
  activo: boolean;
  precio: number;
  descuentoPorcentaje: number;
  descuentoCantidad: number;
  precioFinal: number;
  estudiosId: number[];
  estudios?: IPriceListStudy[];
}

export interface IPriceListInfoFilter {
  estudioId?: number;
  paqueteId?: number;
  sucursalId?: string;
  medicoId?: string;
  compañiaId?: string;
  estudiosIds?: number[];
  paquetesIds?: number[];
}

export interface IPriceListInfoStudy {
  listaPrecioId: string;
  listaPrecio: string;
  estudioId: number;
  clave: string;
  nombre: string;
  taponId?: number;
  taponColor?: string;
  taponClave?: string;
  taponNombre?: string;
  departamentoId: number;
  areaId: number;
  dias: number;
  horas: number;
  fechaEntrega: moment.Moment;
  precio: number;
  promocionId?: number;
  promocion?: string;
  descuento?: number;
  descuentoPorcentaje?: number;
  precioFinal: number;
  promociones: IPriceListInfoPromo[];
  parametros: IParameterList[];
  indicaciones: IIndicationList[];
  destinoTipo: number;
  destinoId: string;
  destino: string;
  ordenEstudio: number;
  metodo?: string;
  etiquetas: IStudyTag[];
}

export interface IPriceListInfoPack {
  listaPrecioId: string;
  listaPrecio: string;
  paqueteId: number;
  clave: string;
  nombre: string;
  departamentoId: number;
  areaId: number;
  dias: number;
  horas: number;
  precioEstudios: number;
  paqueteDescuento: number;
  paqueteDescuentoPorcentaje: number;
  precio: number;
  descuento: number;
  descuentoPorcentaje: number;
  promocionId?: number;
  promocion?: string;
  precioFinal: number;
  promociones: IPriceListInfoPromo[];
  estudios: IPriceListInfoStudy[];
}

export interface IPriceListInfoPromo {
  promocionId: number;
  promocion: string;
  descuento: number;
  descuentoPorcentaje: number;
}
