import { makeAutoObservable } from "mobx";

import InvoiceCatalog from "../api/invoiceCatalog";
import {
  IInvoiceCatalog,
  IInvoiceCatalogFilter,
  InvoiceCatalogFilterValues,
} from "../models/invoiceCatalog";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";
import { IScopes, Scopes } from "../models/shared";

export default class InvoiceCatalogStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  filter: IInvoiceCatalogFilter = new InvoiceCatalogFilterValues();
  invoices: IInvoiceCatalog[] = [];

  setFilter = (filter: IInvoiceCatalogFilter) => {
    this.filter = filter;
  };

  setInvoices = (invoices: IInvoiceCatalog[]) => {
    this.invoices = invoices;
  };

  getAll = async (filter: IInvoiceCatalogFilter) => {
    try {
      const invoices = await InvoiceCatalog.getAll(filter);
      return invoices;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  downloadInvoice = async (invoiceId: string) => {
    try {
      await InvoiceCatalog.downloadInvoice(invoiceId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  printTicket = async (recordId: string, requestId: string) => {
    try {
      await InvoiceCatalog.printTicket(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  downloadList = async (filter: IInvoiceCatalogFilter) => {
    try {
      await InvoiceCatalog.downloadList(filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
