import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useStore } from "../app/stores/store";
import ReceiveTrackingDetail from "../components/routeTracking/receive/ReceiveTrackingDetail";
import ReceiveTrackingHeader from "../components/routeTracking/receive/ReceiveTrackingHeader";

type UrlParams = {
  id: string;
};

const ReceiveTracking = () => {
  const { shipmentTrackingStore: shipmentTracking, routeTrackingStore } = useStore();
  const { scopes, exportShipment, exportOrderReport } = routeTrackingStore;
  const { printTicket, setLoadingOrder, scopes: receiveScopes } = shipmentTracking;
  const { id } = useParams<UrlParams>();

  const [loading, setLoading] = useState(false);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    setLoadingOrder(true);
    await exportShipment(id!);
    setLoadingOrder(false);
  };

  const handleDownloadReport = async () => {
    setLoadingOrder(true);
    await exportOrderReport(id!);
    setLoadingOrder(false);
  };

  if (!scopes.acceder && !receiveScopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <ReceiveTrackingHeader
        handlePrint={handlePrint}
        handleDownload={handleDownload}
        handleDownloadReport={handleDownloadReport}
      />
      <Divider className="header-divider" />
      <ReceiveTrackingDetail componentRef={componentRef} printing={loading} />
    </Fragment>
  );
};

export default observer(ReceiveTracking);
