import { observer } from "mobx-react-lite";
import CreditMonitoringForm from "../components/CreditMonitoring/CreditMonitoringForm";
import CreditMonitoringHeader from "../components/CreditMonitoring/CreditMonitoringHeader";
import CreditMonitoringTable from "../components/CreditMonitoring/CreditMonitoringTable";
import { Fragment } from "react";
import { Divider } from "antd";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const CreditMonitoring = () => {
  const { invoiceCompanyStoreLegacy: invoiceCompanyStore, modalStore } =
    useStore();
  const { exportForm, scopes } = invoiceCompanyStore;
  const handleDownload = () => {
    exportForm();
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <CreditMonitoringHeader handleDownload={handleDownload} scopes={scopes} />
      <Divider className="header-divider" />
      <CreditMonitoringForm />
      <CreditMonitoringTable />
    </Fragment>
  );
};

export default observer(CreditMonitoring);
