import { Checkbox, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ExpandAll from "../../../app/common/table/ExpandAll";
import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IInvoiceGlobalList } from "../../../app/models/invoiceGlobal";
import { useStore } from "../../../app/stores/store";
import { moneyFormatter } from "../../../app/util/utils";
import views from "../../../app/util/view";
import InvoiceGlobalTableDetail from "./InvoiceGlobalTableDetail";

const { Link } = Typography;

type Props = {
  selectedRowKeys: string[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
};

const InvoiceGlobalTable = ({ selectedRowKeys, setSelectedRowKeys }: Props) => {
  const { invoiceGlobalStore } = useStore();
  const { invoices, scopes } = invoiceGlobalStore;

  const navigate = useNavigate();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const allExpanded = expandedRowKeys.length === invoices.length;

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(invoices.map((x) => x.solicitudId));
    }
  };

  const onExpandedRowsChange = (expandedRows: readonly React.Key[]) => {
    setExpandedRowKeys(expandedRows as string[]);
  };

  useEffect(() => {
    setExpandedRowKeys(invoices.map((x) => x.solicitudId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices.length]);

  const columns: IColumns<IInvoiceGlobalList> = [
    {
      ...getDefaultColumnProps("clave", "Solicitud", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (code, request) => {
        return (
          <Link
            onClick={() =>
              navigate(`/${views.request}/${request.expedienteId}/${request.solicitudId}`)
            }
          >
            {code}
          </Link>
        );
      },
    },
    {
      ...getDefaultColumnProps("paciente", "Paciente", {
        searchState,
        setSearchState,
        width: "30%",
      }),
    },
    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("sucursal", "Sucursal", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
  ];

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === invoices.length ? [] : invoices.map((r) => r.solicitudId)
    );
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length > 0}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < invoices.length}
      onChange={toggleSelectAll}
    />
  );

  return (
    <Table<IInvoiceGlobalList>
      bordered
      sticky
      rowClassName="row-search"
      rowKey={(record) => record.solicitudId}
      columns={columns}
      dataSource={invoices}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys as string[]);
        },
        columnTitle: headerCheckbox,
      }}
      expandable={{
        defaultExpandAllRows: true,
        columnTitle: <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />,
        onExpandedRowsChange: onExpandedRowsChange,
        expandedRowKeys: expandedRowKeys,
        expandedRowRender: (request) => <InvoiceGlobalTableDetail invoices={request.facturas} />,
      }}
      pagination={defaultPaginationPropertiesSmall}
    />
  );
};

export default observer(InvoiceGlobalTable);
