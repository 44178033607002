import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import AppointmentTable from "./AppointmentTable";
import { status } from "../../../app/util/catalogs";
import { IAppointmentInfo } from "../../../app/models/appointment";

type Tab = {
  key: string;
  label: React.ReactNode;
  children: JSX.Element;
};

type Props = {
  type: "L" | "D" | undefined;
  setDraggingAppointment: React.Dispatch<
    React.SetStateAction<IAppointmentInfo | undefined>
  >;
};

const AppointmentTab = ({ type, setDraggingAppointment }: Props) => {
  const { appointmentStore } = useStore();
  const { appointments } = appointmentStore;

  const [filteredAppointments, setFilteredAppointments] = useState<
    IAppointmentInfo[]
  >([]);
  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    setFilteredAppointments(appointments.filter((x) => x.tipo === type));
  }, [appointments, type]);

  useEffect(() => {
    let tabs = [
      {
        key: "generated",
        label: (
          <span>
            <PlusCircleOutlined /> Generadas
          </span>
        ),
        children: (
          <AppointmentTable
            type={type}
            nextStatusId={status.appointment.agendado}
            appointments={filteredAppointments.filter(
              (x) => x.estatusId === status.appointment.generado
            )}
            setDraggingAppointment={setDraggingAppointment}
          />
        ),
      },
      {
        key: "agenda",
        label: (
          <span>
            <CalendarOutlined /> Agendadas
          </span>
        ),
        children: (
          <AppointmentTable
            type={type}
            nextStatusId={
              type === "L"
                ? status.appointment.listaScan
                : status.appointment.terminada
            }
            appointments={filteredAppointments.filter(
              (x) => x.estatusId === status.appointment.agendado
            )}
            setDraggingAppointment={setDraggingAppointment}
          />
        ),
      },
      {
        key: "scan",
        label: (
          <span>
            <CalendarOutlined /> Listas para scan
          </span>
        ),
        children: (
          <AppointmentTable
            type={type}
            nextStatusId={status.appointment.terminada}
            appointments={filteredAppointments.filter(
              (x) => x.estatusId === status.appointment.listaScan
            )}
            setDraggingAppointment={setDraggingAppointment}
          />
        ),
      },
      {
        key: "ready",
        label: (
          <span>
            <CheckSquareOutlined /> Terminadas
          </span>
        ),
        children: (
          <AppointmentTable
            type={type}
            appointments={filteredAppointments.filter(
              (x) =>
                x.estatusId === status.appointment.terminada ||
                x.estatusId === status.appointment.convertida
            )}
            setDraggingAppointment={setDraggingAppointment}
          />
        ),
      },
      {
        key: "cancelled",
        label: (
          <span>
            <CloseCircleOutlined /> Canceladas
          </span>
        ),
        children: (
          <AppointmentTable
            type={type}
            nextStatusId={status.appointment.generado}
            appointments={filteredAppointments.filter(
              (x) => x.estatusId === status.appointment.cancelado
            )}
            setDraggingAppointment={setDraggingAppointment}
          />
        ),
      },
    ];

    if (type === "D") tabs = tabs.filter((x) => x.key !== "scan");

    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAppointments, type]);

  return (
    <Tabs tabBarStyle={{ width: 180 }} tabPosition="left" items={tabs}></Tabs>
  );
};

export default observer(AppointmentTab);
