import moment from "moment";
import { useState, useEffect } from "react";
import AgendaBody from "./AgendaBody";
import AgendaDatePicker from "./AgendaDatePicker";
import AgendaHeader from "./AgendaHeader";
import {
  IAgenda,
  IAgendaColumn,
  Days,
  getTimeHours,
  getWeekDates,
  IAgendaDetail,
} from "./utils";
import "./agenda.less";
import { Select } from "antd";
import { IOptions } from "../../models/shared";

type AgendaProps<T extends IAgenda> = {
  startTime: moment.Moment;
  endTime: moment.Moment;
  interval: moment.Moment;
  columns: IAgendaColumn[];
  events: T[];
  excludeDays?: Days[];
  cellHeight?: number;
  calendarHeight?: number | string;
  defaultType?: "date" | "week";
  timeFormat?: "HH:mm" | "hh:mm a";
  showDatePicker?: boolean;
  type?: "date" | "week";
  deletable?: boolean | ((event: T) => boolean);
  columnsTitle?: string;
  showDetail?: IAgendaDetail<T>;
  render: (event: T) => React.ReactNode;
  renderDate?: (date: moment.Moment, column: IAgendaColumn) => React.ReactNode;
  renderHeader?: (
    date: moment.Moment,
    column: IAgendaColumn
  ) => React.ReactNode;
  onTypeChange?: (type: "date" | "week") => void;
  onDateChange?: (date: moment.Moment[]) => void;
  onClick?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDropEvent?: (date: moment.Moment, column: IAgendaColumn, event?: T) => void;
  onDelete?: (event: T) => void;
};

const Agenda = <T extends IAgenda>({
  startTime,
  endTime,
  interval,
  columns,
  events,
  excludeDays,
  cellHeight,
  calendarHeight,
  defaultType,
  timeFormat,
  showDatePicker = true,
  type = "date",
  deletable,
  columnsTitle,
  showDetail,
  render,
  renderDate,
  renderHeader,
  onTypeChange,
  onDateChange,
  onClick,
  onDropEvent,
  onDelete,
}: AgendaProps<T>) => {
  const timeHours = getTimeHours(interval, startTime, endTime);

  const [calendarEvents, setCalendarEvents] = useState<T[]>(events);
  const [calendarType, setCalendarType] = useState<"week" | "date">(
    defaultType ?? "date"
  );
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment().utcOffset(0, true));
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<IAgendaColumn>();

  useEffect(() => {
    const startDate = selectedDate.clone().startOf("week");
    const endDate = selectedDate.clone().endOf("week");

    const dates = getWeekDates(startDate, endDate, excludeDays ?? []);
    setSelectedDates(dates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    setCalendarType(type);
  }, [type]);

  useEffect(() => {
    if (onDateChange && selectedDates.length > 0) {
      onDateChange(calendarType === "date" ? [selectedDate] : selectedDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, calendarType]);

  useEffect(() => {
    setCalendarEvents(
      events.filter(
        (x) => x.columnId === selectedColumn?.id || selectedColumn == null
      )
    );
  }, [events, selectedColumn]);

  useEffect(() => {
    setSelectedColumn(undefined);
  }, [columns.length]);

  if (selectedDates.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
      <div style={{ width: "min-content" }}>
        {calendarType === "week" && (
          <Select
            key="data"
            showSearch
            placeholder={`Seleccionar ${columnsTitle ?? ""}`}
            optionFilterProp="children"
            onChange={(value, option: IOptions | IOptions[]) => {
              option = option as IOptions;
              if (value) {
                setSelectedColumn({
                  id: option.value,
                  title: option.label!.toString(),
                });
              } else {
                setSelectedColumn(undefined);
              }
            }}
            filterOption={(input: string, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedColumn?.id}
            allowClear
            style={{ width: 252, marginBottom: 12 }}
            options={columns.map((x) => ({ value: x.id, label: x.title }))}
          ></Select>
        )}
        {showDatePicker && (
          <AgendaDatePicker
            calendarType={calendarType}
            excludeDays={excludeDays ?? []}
            selectedDate={selectedDate}
            selectedDates={selectedDates}
            setCalendarType={setCalendarType}
            setSelectedDate={setSelectedDate}
            onTypeChange={onTypeChange}
          />
        )}
      </div>
      {calendarType === "week" && selectedColumn == null ? (
        `Por favor, seleccione un ${columnsTitle}`
      ) : (
        <div
          className="agenda-table"
          style={{ height: calendarHeight, overflow: "auto" }}
        >
          <table>
            <AgendaHeader
              columns={columns}
              weekColumn={selectedColumn}
              selectedDate={selectedDate}
              selectedDates={selectedDates}
              calendarType={calendarType}
              renderDate={renderDate}
              renderHeader={renderHeader}
            />
            <AgendaBody
              timeHours={timeHours}
              columns={columns}
              weekColumn={selectedColumn}
              events={calendarEvents}
              cellHeight={cellHeight}
              selectedDate={selectedDate}
              selectedDates={selectedDates}
              calendarType={calendarType}
              timeFormat={timeFormat ?? "HH:mm"}
              deletable={deletable}
              showDetail={showDetail}
              render={render}
              onClick={onClick}
              onDropEvent={onDropEvent}
              onDelete={onDelete}
            />
          </table>
        </div>
      )}
    </div>
  );
};

export default Agenda;
