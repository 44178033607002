import { Divider } from "antd";
import React, { Fragment } from "react";
import WorkListFilter from "../components/workList/WorkListFilter";
import WorkListHeader from "../components/workList/WorkListHeader";
import WorkListPdf from "../components/workList/WorkListPdf";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const WorkList = () => {
  const { workListStore } = useStore();
  const { scopes } = workListStore;

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <WorkListHeader />
      <Divider className="header-divider" />
      <WorkListFilter />
      <WorkListPdf />
    </Fragment>
  );
};

export default WorkList;
