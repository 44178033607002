import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";
import { useStore } from "../app/stores/store";
import RequestedStudyBody from "../components/requestedStudy/RequestedStudyBody";
import RequestedStudyHeader from "../components/requestedStudy/RequestedStudyHeader";
import { Navigate } from "react-router-dom";

const RequestedStudy = () => {
  const { requestedStudyStore, generalStore } = useStore();
  const { scopes, exportList } = requestedStudyStore;
  const { generalFilter } = generalStore;

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    await exportList(generalFilter);
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <RequestedStudyHeader handleList={handleDownload} />
      <Divider className="header-divider" />
      <RequestedStudyBody printing={loading} />
    </Fragment>
  );
};

export default observer(RequestedStudy);
