import { FormInstance } from "antd";

import { ICompanyForm } from "../../../../app/models/company";
import { IInvoiceClient } from "../../../../app/models/invoice";
import { IProceedingForm } from "../../../../app/models/Proceeding";

export type InvoiceType = "free" | "global" | "company" | "request";

export type InvoiceRequestCompanyType = "company" | "request";

export const isValidType = (type?: string): type is InvoiceType =>
  ["free", "global", "company", "request"].includes(type ?? "");

export const isRequestCompanyType = (
  type?: string
): type is InvoiceRequestCompanyType =>
  ["company", "request"].includes(type ?? "");

export const setInvoiceCompany = (
  form: FormInstance<IInvoiceClient>,
  company: ICompanyForm
) => {
  form.setFieldsValue({
    compañiaId: company.id,
    clave: company.clave,
    nombre: company.nombreComercial,
    rfc: company.rfc,
    razonSocial: company.razonSocial,
    regimenFiscal: company.regimenFiscal,
    codigoPostal: company.codigoPostal,
    estado: company.estado?.toUpperCase(),
    ciudad: company.ciudad?.toUpperCase(),
  });
};

export const setInvoicePatient = (
  form: FormInstance<IInvoiceClient>,
  patient: IProceedingForm
) => {
  form.setFieldsValue({
    clave: patient.expediente,
    nombre: `${patient.nombre} ${patient.apellido}`,
    rfc: patient.taxData![0].rfc,
    razonSocial: patient.taxData![0].razonSocial,
    regimenFiscal: (patient.taxData![0].regimenFiscal ?? " ").split(" ")[0],
    codigoPostal: patient.taxData![0].cp,
    estado: patient.taxData![0].estado?.toUpperCase(),
    ciudad: patient.taxData![0].municipio?.toUpperCase(),
  });
};
