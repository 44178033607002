import { Button, Col, Form, Row } from "antd";
import React from "react";

import { PlusOutlined } from "@ant-design/icons";

import NumberInput from "../../../../../app/common/form/proposal/NumberInput";
import SelectInput from "../../../../../app/common/form/proposal/SelectInput";
import { IInvoiceDetail } from "../../../../../app/models/invoice";
import { taxOptions } from "../../../../../app/stores/optionStore";

type Props = {
  addDetail: (values: IInvoiceDetail) => void;
};

const InvoiceConceptForm = ({ addDetail }: Props) => {
  const [form] = Form.useForm<IInvoiceDetail>();

  const onFinish = (values: IInvoiceDetail) => {
    addDetail(values);
    form.resetFields();
  };

  return (
    <Form<IInvoiceDetail>
      form={form}
      layout="vertical"
      name="invoice"
      onFinish={onFinish}
      initialValues={{
        tasaIva: 0.16,
        unidades: 1,
      }}
    >
      <Row gutter={[8, 12]} align="bottom" style={{ marginBottom: 8 }}>
        <Col span={3}>
          <NumberInput
            formProps={{
              name: "importe",
              label: "Cantidad",
            }}
            min={0}
            formatter={(value) => {
              return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }}
            parser={(value) => {
              const val = value!.replace(/\$\s?|(,*)/g, "");
              return Number(val);
            }}
            required
          />
        </Col>
        <Col span={2}>
          <NumberInput
            formProps={{ label: "Unidades", name: "unidades" }}
            parser={(value) => {
              const val = value!.replace(/\$\s?|(,*)/g, "");
              return Number(val);
            }}
          />
        </Col>
        <Col span={2}>
          <SelectInput
            formProps={{ label: "IVA", name: "tasaIva" }}
            options={taxOptions}
            required
          />
        </Col>
        <Col span={17} style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
            Agregar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceConceptForm;
