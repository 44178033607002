import React from "react";

type Props = {
  allExpanded: boolean;
  onExpandAll: () => void;
};

const ExpandAll = ({ allExpanded, onExpandAll }: Props) => {
  return (
    <div style={{ textAlign: "center" }}>
      <button
        type="button"
        onClick={onExpandAll}
        className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
          allExpanded ? "expanded" : "collapsed"
        }`}
        aria-label={allExpanded ? "Colapsar fila" : "Expandir fila"}
        aria-expanded={allExpanded ? "true" : "false"}
      ></button>
    </div>
  );
};

export default ExpandAll;
