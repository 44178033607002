import { Button, PageHeader, Input } from "antd";
import React, { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageButton from "../../app/common/button/ImageButton";
import { useStore } from "../../app/stores/store";
import PrintIcon from "../../app/common/icons/PrintIcon";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import { IScopes } from "../../app/models/shared";

const { Search } = Input;
type UserHeaderProps = {
  handlePrint: () => void;
  handleList: () => void;
  scopes: IScopes;
};
const UserHeader: FC<UserHeaderProps> = ({
  handlePrint,
  handleList,
  scopes,
}) => {
  let navigate = useNavigate();
  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Catálogo usuarios" image="usuario" />}
      className="header-container"
      extra={[
        scopes.imprimir && <PrintIcon key="print" onClick={handlePrint} />,
        scopes.descargar && <DownloadIcon key="doc" onClick={handleList} />,
        <Search
          key="search"
          placeholder="Buscar"
          onSearch={(value) => {
            navigate(`/users?search=${value}`);
          }}
        />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate("/new-user");
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default UserHeader;
