import { Button, PageHeader, Input } from "antd";
import { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PrintIcon from "../../app/common/icons/PrintIcon";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import { IScopes } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";

const { Search } = Input;
type ParameterHeaderProps = {
  handleList: () => void;
  handlePrint: () => void;
  scopes: IScopes;
};
const ParameterHeader: FC<ParameterHeaderProps> = ({
  handleList,
  handlePrint,
  scopes,
}) => {
  let navigate = useNavigate();
  const { parameterStore } = useStore();
  const { loading } = parameterStore;
  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Catálogo Parámetros" image="parametro" />}
      className="header-container"
      extra={[
        scopes.imprimir && <PrintIcon key="print" onClick={handlePrint} />,
        scopes.descargar && <DownloadIcon key="doc" onClick={handleList} />,
        <Search
          key="search"
          placeholder="Buscar"
          onSearch={(value) => {
            navigate(`/parameters?search=${value}`);
          }}
          loading={false}
        />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate("new-parameter");
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};
export default ParameterHeader;
