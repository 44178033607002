import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { makeAutoObservable, toJS } from "mobx";
import { INotificationFilter } from "../models/notifications";
import { INotification } from "../models/shared";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";
import { store } from "./store";
import { notification } from "antd";
import UserNotification from "../api/userNotification";

export default class NotificationStore {
  constructor() {
    makeAutoObservable(this);
  }

  hubConnection: HubConnection | null = null;
  notifications: INotification[] = [];

  markAsSeen = async (notification: INotification) => {
    try {
      await UserNotification.markAsSeen(notification);
      this.notifications = this.notifications.filter(
        (x) => x.id !== notification.id
      );
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getNotifications = async (filter: INotificationFilter) => {
    try {
      const notifications = await UserNotification.getUserNotifications(filter);
      this.notifications = notifications;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.notifications = [];
    }
  };

  createHubConnection = async () => {
    try {
      const hubUrl = process.env.REACT_APP_NOTIFICATION_URL;
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(hubUrl!, {
          accessTokenFactory: () => store.profileStore.token!,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      await this.hubConnection.start();

      if (this.hubConnection.state === "Connected") {
        this.hubConnection.invoke("Subscribe");
        this.hubConnection.invoke("SubscribeWithName", "all");
        this.hubConnection.invoke(
          "SubscribeWithName",
          `${store.profileStore.profile?.rol}-${store.profileStore.profile?.sucursal}`
        );
      }

      this.hubConnection.onreconnected(() => {
        this.hubConnection!.invoke("Subscribe");
        this.hubConnection!.invoke("SubscribeWithName", "all");
        this.hubConnection!.invoke(
          "SubscribeWithName",
          `${store.profileStore.profile?.rol}-${store.profileStore.profile?.sucursal}`
        );
      });

      this.hubConnection.on("Notify", (newNotification: INotification) => {
        if (newNotification.esAlerta) {
          alerts.info(newNotification.mensaje);
        } else {
          var notifications = toJS(this.notifications);
          notifications.unshift(newNotification);
          this.notifications = notifications;
          notification.open({
            key: "notification",
            message: "Notificación",
            description: newNotification.mensaje,
          });
        }
      });
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };
}
