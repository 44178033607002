import { configure } from "mobx";
import { createContext, useContext } from "react";

import AppointmentStore from "./appointmentStore";
import BranchStore from "./brancStore";
import CashRegisterStore from "./cashRegisterStore";
import CatalogStore from "./catalogStore";
import ClinicResultsStore from "./clinicResultsStore";
import CompanyStore from "./companyStore";
import ConfigurationStore from "./configurationStore";
import DeliveryResultsStore from "./deliveryResultsStore";
import DrawerStore from "./drawerStore";
import EquipmentMantainStore from "./EquipentMantainStore";
import EquipmentStore from "./equipmentStore";
import GeneralStore from "./generalStore";
import IndicationStore from "./indicationStore";
import IndicatorStore from "./indicatorStore";
import InvoiceCatalogStore from "./InvoiceCatalogStore";
import { InvoiceCompanyStoreLegacy } from "./invoiceCompanyStoreLegacy";
import InvoiceCreditStore from "./invoiceCreditStore";
import InvoiceFreeStore from "./invoiceFreeStore";
import InvoiceFreeStoreLegacy from "./invoiceFreeStoreLegacy";
import InvoiceGlobalStore from "./invoiceGlobalStore";
import InvoiceLegacyStore from "./invoiceLegacyStore";
import InvoiceRequestCompanyStore from "./invoiceRequestCompanyStore";
import InvoiceStore from "./invoiceStore";
import LocationStore from "./locationStore";
import LoyaltyStore from "./loyaltyStore";
import MaquiladorStore from "./maquiladorStore";
import MassResultSearchStore from "./massResultSearch";
import MedicsStore from "./medicsStore";
import ModalStore from "./modalStore";
import NotificationsStore from "./notificationsStore";
import NotificationStore from "./notificationStore";
import OptionStore from "./optionStore";
import PackStore from "./packStore";
import ParameterStore from "./parameterStore";
import PriceListStore from "./priceListStore";
import ProcedingStore from "./procedingStore";
import ProfileStore from "./profileStore";
import PromotionStore from "./promotionStore";
import QuotationStore from "./quotationStore";
import ReagentStore from "./reagentStore";
import RelaseResultStore from "./RelaseResultStore";
import ReportStore from "./reportStore";
import ReportStudyStore from "./reportStudyStore";
import RequestedStudyStore from "./requestedStudyStore";
import RequestStore from "./requestStore";
import ResultValidationStore from "./resultValidationStore";
import RoleStore from "./roleStore";
import RouteStore from "./routeStore";
import RouteTrackingStore from "./routeTrackingStore";
import SamplingStore from "./samplingStore";
import SeriesStore from "./seriesStore";
import shipmentTrackingStore from "./shipmentTrackingStore";
import StudyStore from "./studyStore";
import TagStore from "./tagStore";
import TrackingOrderStore from "./trackingOrderStore";
import UserStore from "./userStore";
import WeeClinicStore from "./weeClinicStore";
import WorkListStore from "./workListStore";

configure({
  enforceActions: "never",
});

interface Store {
  modalStore: ModalStore;
  drawerStore: DrawerStore;
  profileStore: ProfileStore;
  optionStore: OptionStore;
  configurationStore: ConfigurationStore;
  userStore: UserStore;
  reagentStore: ReagentStore;
  tagStore: TagStore;
  medicsStore: MedicsStore;
  companyStore: CompanyStore;
  indicationStore: IndicationStore;
  equipmentStore: EquipmentStore;
  trackingOrderStore: TrackingOrderStore;
  catalogStore: CatalogStore;
  roleStore: RoleStore;
  locationStore: LocationStore;
  branchStore: BranchStore;
  parameterStore: ParameterStore;
  maquiladorStore: MaquiladorStore;
  studyStore: StudyStore;
  priceListStore: PriceListStore;
  packStore: PackStore;
  promotionStore: PromotionStore;
  loyaltyStore: LoyaltyStore;
  routeStore: RouteStore;
  procedingStore: ProcedingStore;
  quotationStore: QuotationStore;
  reportStore: ReportStore;
  cashRegisterStore: CashRegisterStore;
  indicatorsStore: IndicatorStore;
  requestStore: RequestStore;
  appointmentStore: AppointmentStore;
  notificationStore: NotificationStore;
  samplingStudyStore: SamplingStore;
  requestedStudyStore: RequestedStudyStore;
  clinicResultsStore: ClinicResultsStore;
  equipmentMantainStore: EquipmentMantainStore;
  weeClinicStore: WeeClinicStore;
  routeTrackingStore: RouteTrackingStore;
  shipmentTrackingStore: shipmentTrackingStore;
  workListStore: WorkListStore;
  massResultSearchStore: MassResultSearchStore;
  deliveryResultsStore: DeliveryResultsStore;
  resultValidationStore: ResultValidationStore;
  relaseResultStore: RelaseResultStore;
  invoiceLegacyStore: InvoiceLegacyStore;
  invoiceCompanyStoreLegacy: InvoiceCompanyStoreLegacy;
  invoiceFreeStoreLegacy: InvoiceFreeStoreLegacy;
  invoiceStore: InvoiceStore;
  invoiceFreeStore: InvoiceFreeStore;
  invoiceGlobalStore: InvoiceGlobalStore;
  invoiceRequestCompanyStore: InvoiceRequestCompanyStore;
  invoiceCreditStore: InvoiceCreditStore;
  seriesStore: SeriesStore;
  invoiceCatalogStore: InvoiceCatalogStore;
  reportStudyStore: ReportStudyStore;
  notificationsStore: NotificationsStore;
  generalStore: GeneralStore;
}

export const store: Store = {
  modalStore: new ModalStore(),
  drawerStore: new DrawerStore(),
  profileStore: new ProfileStore(),
  optionStore: new OptionStore(),
  configurationStore: new ConfigurationStore(),
  userStore: new UserStore(),
  reagentStore: new ReagentStore(),
  tagStore: new TagStore(),
  medicsStore: new MedicsStore(),
  companyStore: new CompanyStore(),
  indicationStore: new IndicationStore(),
  equipmentStore: new EquipmentStore(),
  trackingOrderStore: new TrackingOrderStore(),
  catalogStore: new CatalogStore(),
  roleStore: new RoleStore(),
  locationStore: new LocationStore(),
  branchStore: new BranchStore(),
  parameterStore: new ParameterStore(),
  maquiladorStore: new MaquiladorStore(),
  studyStore: new StudyStore(),
  priceListStore: new PriceListStore(),
  packStore: new PackStore(),
  promotionStore: new PromotionStore(),
  loyaltyStore: new LoyaltyStore(),
  routeStore: new RouteStore(),
  procedingStore: new ProcedingStore(),
  quotationStore: new QuotationStore(),
  reportStore: new ReportStore(),
  cashRegisterStore: new CashRegisterStore(),
  indicatorsStore: new IndicatorStore(),
  requestStore: new RequestStore(),
  appointmentStore: new AppointmentStore(),
  notificationStore: new NotificationStore(),
  samplingStudyStore: new SamplingStore(),
  requestedStudyStore: new RequestedStudyStore(),
  clinicResultsStore: new ClinicResultsStore(),
  equipmentMantainStore: new EquipmentMantainStore(),
  weeClinicStore: new WeeClinicStore(),
  routeTrackingStore: new RouteTrackingStore(),
  shipmentTrackingStore: new shipmentTrackingStore(),
  workListStore: new WorkListStore(),
  massResultSearchStore: new MassResultSearchStore(),
  deliveryResultsStore: new DeliveryResultsStore(),
  resultValidationStore: new ResultValidationStore(),
  relaseResultStore: new RelaseResultStore(),
  invoiceLegacyStore: new InvoiceLegacyStore(),
  invoiceCompanyStoreLegacy: new InvoiceCompanyStoreLegacy(),
  invoiceFreeStoreLegacy: new InvoiceFreeStoreLegacy(),
  invoiceStore: new InvoiceStore(),
  invoiceFreeStore: new InvoiceFreeStore(),
  invoiceGlobalStore: new InvoiceGlobalStore(),
  invoiceRequestCompanyStore: new InvoiceRequestCompanyStore(),
  invoiceCreditStore: new InvoiceCreditStore(),
  seriesStore: new SeriesStore(),
  invoiceCatalogStore: new InvoiceCatalogStore(),
  reportStudyStore: new ReportStudyStore(),
  notificationsStore: new NotificationsStore(),
  generalStore: new GeneralStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
