import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { PlusOutlined } from "@ant-design/icons";

import TextAreaInput from "../../../../../app/common/form/proposal/TextAreaInput";
import TextInput from "../../../../../app/common/form/proposal/TextInput";
import { IInvoiceDetail } from "../../../../../app/models/invoice";
import { IOptions } from "../../../../../app/models/shared";
import { IConcept } from "./InvoiceConcept";

const { TextArea } = Input;

const noPaddingStyle = { margin: 0, paddingTop: 0, paddingBottom: 0 };

type ConceptRenderProps = {
  data: IInvoiceDetail;
  conceptOptions: IOptions[];
  onChangeConcept: (id: string, selectedOptions: string[]) => void;
  onChangeConceptManual: (id: string, text: string) => void;
  addConcept: (values: IConcept) => Promise<void>;
};

const ConceptRender = ({
  data,
  conceptOptions,
  onChangeConcept,
  onChangeConceptManual,
  addConcept,
}: ConceptRenderProps) => {
  const [form] = Form.useForm<IConcept>();

  const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);

  const onConceptSelectChange = (selected: string[]) => {
    setSelectedConcepts(selected);
    onChangeConcept(data.id, selected);
  };

  const addNewConcept = async (values: IConcept) => {
    await addConcept(values);
    form.resetFields();
  };

  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <Select
          size="small"
          mode="multiple"
          showArrow={true}
          bordered={false}
          style={{ width: "100%" }}
          placeholder="Agregar Concepto"
          value={selectedConcepts}
          onChange={onConceptSelectChange}
          options={conceptOptions}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Form<IConcept> form={form} onFinish={addNewConcept}>
                <Divider style={{ margin: "8px 0" }} />
                <Row style={{ padding: "0 8px 4px" }} gutter={[8, 8]}>
                  <Col>
                    <TextInput
                      formProps={{ name: "alias" }}
                      placeholder="Alias"
                      required
                    />
                  </Col>
                  <Col flex={1}>
                    <TextAreaInput
                      formProps={{ name: "descripcion" }}
                      placeholder="Descripción"
                      required
                      style={{ ...noPaddingStyle, minHeight: 24, height: 24 }}
                      autoSize
                      rows={1}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        />
      </Col>
      <Col span={24}>
        <TextArea
          value={data.concepto}
          autoSize
          bordered={false}
          rows={1}
          onChange={(e) => {
            onChangeConceptManual(data.id, e.target.value);
          }}
        />
      </Col>
    </Row>
  );
};

export default observer(ConceptRender);
