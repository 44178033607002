import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import {
  IReceiveTracking,
  IShipmentTracking,
} from "../../../../app/models/shipmentTracking";
import { Card, Col, Row, Statistic } from "antd";
import DescriptionTitle from "../../../../app/common/display/DescriptionTitle";
import { EditOutlined } from "@ant-design/icons";
import IconButton from "../../../../app/common/button/IconButton";
import { useNavigate } from "react-router-dom";
import { IScopes } from "../../../../app/models/shared";

type STTProps = {
  shipment: IShipmentTracking | IReceiveTracking | undefined;
  tipoEnvio: string;
  scopes: IScopes;
};

const ShipmentTrackingTitle = ({ shipment, tipoEnvio, scopes }: STTProps) => {
  let navigate = useNavigate();

  return (
    <Fragment>
      <Row gutter={[4, 0]} justify="space-between">
        <Col span={6}>
          <DescriptionTitle
            title="No. de seguimiento"
            content={shipment?.seguimiento}
          />
        </Col>
        <Col span={6}>
          <DescriptionTitle title="Ruta" content={shipment?.ruta} />
        </Col>
        <Col span={6}>
          <DescriptionTitle title="Nombre" content={shipment?.nombre} />
        </Col>
        {tipoEnvio !== "receive" && scopes.modificar ? (
          <Col span={6}>
            <DescriptionTitle
              title="Editar"
              content={
                <IconButton
                  title="Editar ruta"
                  icon={<EditOutlined />}
                  onClick={() => {
                    navigate(`/trackingOrder/${shipment?.id}`);
                  }}
                />
              }
            />
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Fragment>
  );
};

export default observer(ShipmentTrackingTitle);
