import { toJS } from "mobx";
import IconSelector from "../../../app/common/icons/IconSelector";
import { IOptions, TreeData } from "../../../app/models/shared";
import { IUserForm, IUserPermission } from "../../../app/models/user";

export const filterTree = (keys: any, halfKeys: any, rootNode: any) =>
  rootNode
    ? rootNode
        .filter(
          // (node: any) => keys.includes(node.key) || halfKeys.includes(node.key)
          (node: any) => keys.includes(node.key)
        )
        .map((nodeRoot: any) => ({
          ...nodeRoot,
          children: filterTree(keys, halfKeys, nodeRoot.children),
        }))
    : [];

export const convertToTreeDataBranch = (
  targetKeys: String[],
  setPermissionsAdded: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAvailable: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAddedFiltered: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAvailableFiltered: React.Dispatch<
    React.SetStateAction<TreeData[]>
  >
): ((branches: IOptions[]) => void) => {
  return (branches: IOptions[]) => {
    ("convertToTreeDataBranch");
    const selectedNodes: TreeData[] = [];
    const notSelectedNodes: TreeData[] = [];
    branches = branches.map((x) => ({
      ...x,
      extra: targetKeys.includes(x.value.toString()),
    }));

    const menusAdded = branches
      .filter((x) => x.extra)
      .filter((v, i, a) => a.findIndex((x) => x.group === v.group) === i)
      .map((x) => ({
        title: x.group!.toString(),
        key: x.group!.toString(),
        children: [],
      }));

    const menusAvailable = branches
      .filter((x) => !x.extra)
      .filter((v, i, a) => a.findIndex((x) => x.group === v.group) === i)
      .map((x) => ({
        title: x.group!.toString(),
        key: x.group!.toString(),
        children: [],
      }));

    selectedNodes.push(...menusAdded);
    notSelectedNodes.push(...menusAvailable);

    selectedNodes.forEach((x) => {
      x.children = branches
        .filter((p) => p.group === x.key && p.extra)
        .map((p) => ({ title: p.label!.toString(), key: p.value.toString() }));
    });

    notSelectedNodes.forEach((x) => {
      x.children = branches
        .filter((p) => p.group === x.key && !p.extra)
        .map((p) => ({ title: p.label!.toString(), key: p.value.toString() }));
    });

    setPermissionsAdded(selectedNodes);
    setPermissionsAvailable(notSelectedNodes);
    setPermissionsAddedFiltered(selectedNodes);
    setPermissionsAvailableFiltered(notSelectedNodes);
  };
};

export const convertToTreeData = (
  targetKeys: String[],
  setPermissionsAdded: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAvailable: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAddedFiltered: React.Dispatch<React.SetStateAction<TreeData[]>>,
  setPermissionsAvailableFiltered: React.Dispatch<
    React.SetStateAction<TreeData[]>
  >
): ((permissions: IUserPermission[]) => void) => {
  return (permissions: IUserPermission[]) => {
    const selectedNodes: TreeData[] = [];
    const notSelectedNodes: TreeData[] = [];
    permissions = permissions.map((x) => ({
      ...x,
      asignado: targetKeys.includes(x.id.toString()),
    }));

    const menusAdded = permissions
      .filter((x) => x.asignado)
      .filter((v, i, a) => a.findIndex((x) => x.menu === v.menu) === i)
      .map((x) => ({
        title: x.menu,
        key: x.menu,
        icon: <IconSelector name={x.menu} />,
        children: [],
      }));

    const menusAvailable = permissions
      .filter((x) => !x.asignado)
      .filter((v, i, a) => a.findIndex((x) => x.menu === v.menu) === i)
      .map((x) => ({
        title: x.menu,
        key: x.menu,
        icon: <IconSelector name={x.menu} />,
        children: [],
      }));

    selectedNodes.push(...menusAdded);
    notSelectedNodes.push(...menusAvailable);

    selectedNodes.forEach((x) => {
      x.children = permissions
        .filter((p) => p.menu === x.key && p.asignado)
        .map((p) => ({ title: p.permiso, key: p.id.toString() }));
    });

    notSelectedNodes.forEach((x) => {
      x.children = permissions
        .filter((p) => p.menu === x.key && !p.asignado)
        .map((p) => ({ title: p.permiso, key: p.id.toString() }));
    });

    setPermissionsAdded(selectedNodes);
    setPermissionsAvailable(notSelectedNodes);
    setPermissionsAddedFiltered(selectedNodes);
    setPermissionsAvailableFiltered(notSelectedNodes);
  };
};

export const onTreeSelectChange = (
  permissionsAvailableFiltered: TreeData[],
  permissionsAddedFiltered: TreeData[],
  current: IUserForm | undefined,
  targetKeys: string[],
  setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>
) => {
  return (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    const availableIds = permissionsAvailableFiltered
      .flatMap((x) => x.children)
      .map((x) => x?.key);
    const addedIds = permissionsAddedFiltered
      .flatMap((x) => x.children)
      .map((x) => x?.key);

    const available =
      current?.permisos?.filter(
        (x) =>
          availableIds.includes(x.id.toString()) &&
          !targetKeys.includes(x.id.toString()) &&
          !sourceSelectedKeys.includes(x.id.toString())
      ) ?? [];
    const added =
      current?.permisos?.filter(
        (x) =>
          addedIds.includes(x.id.toString()) &&
          targetKeys.includes(x.id.toString()) &&
          !targetSelectedKeys.includes(x.id.toString())
      ) ?? [];

    const finalSourse: string[] = [];
    sourceSelectedKeys.forEach((x) =>
      isNaN(parseInt(x))
        ? finalSourse.push(
            ...available.filter((y) => y.menu === x).map((y) => y.id.toString())
          )
        : finalSourse.push(x)
    );

    const finalTarget: string[] = [];
    targetSelectedKeys.forEach((x) =>
      isNaN(parseInt(x))
        ? finalTarget.push(
            ...added.filter((y) => y.menu === x).map((y) => y.id.toString())
          )
        : finalTarget.push(x)
    );
    setSelectedKeys([...finalSourse, ...finalTarget]);
  };
};

export const onTreeSearch = (
  setNotAddedFiltered: React.Dispatch<React.SetStateAction<TreeData[]>>,
  notAdded: TreeData[],
  setAddedFiltered: React.Dispatch<React.SetStateAction<TreeData[]>>,
  added: TreeData[]
) => {
  return (direction: string, value: string) => {
    if (direction === "left") {
      let notAddedFiltered = notAdded.filter(
        (x) => !value || x.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
      if (notAddedFiltered.length === 0) notAddedFiltered = notAdded;
      setNotAddedFiltered(
        notAddedFiltered
          .map((x) => {
            let children = x.children;
            if (notAddedFiltered.length === notAdded.length) {
              children = x.children?.filter(
                (y) => y.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
              );
            }
            return {
              ...x,
              children: children,
            };
          })
          .filter((x) => x.children && x.children.length > 0)
      );
    } else {
      let addedFiltered = added.filter(
        (x) => !value || x.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
      if (addedFiltered.length === 0) addedFiltered = added;
      setAddedFiltered(
        addedFiltered
          .map((x) => {
            let children = x.children;
            if (addedFiltered.length === added.length) {
              children = x.children?.filter(
                (y) => y.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
              );
            }
            return {
              ...x,
              children: children,
            };
          })
          .filter((x) => x.children && x.children.length > 0)
      );
    }
  };
};
