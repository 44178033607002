import {
  InvoiceType,
  isRequestCompanyType,
} from "../../components/invoice/invoice/utils/utils";
import { IInvoice, IInvoiceCancelation, IInvoiceSend } from "../models/invoice";
import requests from "./agent";

const Invoice = {
  getById: (type: InvoiceType, invoiceId: string): Promise<IInvoice> =>
    requests.get(
      `invoice${type}${isRequestCompanyType(type) ? "N" : ""}/${invoiceId}`
    ),
  cancel: (type: InvoiceType, invoiceDto: IInvoiceCancelation): Promise<void> =>
    requests.put(
      `invoice${type}${isRequestCompanyType(type) ? "N" : ""}/cancel`,
      invoiceDto
    ),
  send: (type: InvoiceType, invoiceDto: IInvoiceSend): Promise<void> =>
    requests.post(
      `invoice${type}${isRequestCompanyType(type) ? "N" : ""}/send`,
      invoiceDto
    ),
  printPDF: (type: InvoiceType, invoiceId: string): Promise<void> =>
    requests.print(
      `invoice${type}${
        isRequestCompanyType(type) ? "N" : ""
      }/print/pdf/${invoiceId}`
    ),
  downloadXML: (type: InvoiceType, invoiceId: string): Promise<void> =>
    requests.download(
      `invoice${type}${
        isRequestCompanyType(type) ? "N" : ""
      }/download/xml/${invoiceId}`
    ),
  downloadPDF: (type: InvoiceType, invoiceId: string): Promise<void> =>
    requests.download(
      `invoice${type}${
        isRequestCompanyType(type) ? "N" : ""
      }/download/pdf/${invoiceId}`
    ),
};

export default Invoice;
