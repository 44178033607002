import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import RoleHeader from "../components/role/RoleHeader";
import RoleTable from "../components/role/RoleTable";
import alerts from "../app/util/alerts";
import messages from "../app/util/messages";
const Role = () => {
  const { roleStore } = useStore();
  const { exportList, scopes } = roleStore;
  const [printing, setPrinting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const componentRef = useRef<any>();
  const handleDownload = async () => {
    setPrinting(true);

    if (!scopes.descargar) {
      alerts.warning(messages.forbidden);
      setPrinting(false);
      return;
    }
    var succes = await exportList(searchParams.get("search") ?? "all");
    setPrinting(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      if (!scopes.imprimir) {
        alerts.warning(messages.forbidden);
        return;
      }
      setPrinting(true);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <RoleHeader handlePrint={handlePrint} handleList={handleDownload} scopes={scopes} />
      <Divider className="header-divider" />
      <RoleTable componentRef={componentRef} printing={printing} />
    </Fragment>
  );
};
export default observer(Role);
