import { Space, Table, Tag, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IInvoiceFreeList } from "../../../app/models/invoiceFree";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import { moneyFormatter } from "../../../app/util/utils";

const { Link } = Typography;

const InvoiceFreeTable = () => {
  const { invoiceFreeStore } = useStore();
  const { invoices } = invoiceFreeStore;

  const navigate = useNavigate();

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columns: IColumns<IInvoiceFreeList> = [
    {
      ...getDefaultColumnProps("serie", "Documento", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (_, invoice) => {
        const cancelled = invoice.estatus === status.invoice.cancelado;
        return (
          <Space>
            <Link
              onClick={() => navigate(`/invoices/free/${invoice.facturaId}`)}
              type={!cancelled ? undefined : "danger"}
            >
              {invoice.serie}
            </Link>
            {cancelled && <Tag color="error">C</Tag>}
          </Space>
        );
      },
    },
    {
      ...getDefaultColumnProps("cliente", "Cliente", {
        searchState,
        setSearchState,
        width: "40%",
      }),
    },
    {
      ...getDefaultColumnProps("fechaCreo", "Fecha Creación", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("importeTotal", "Monto", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (value) => `${moneyFormatter.format(value)} (IVA Incluido)`,
    },
  ];

  return (
    <Table<IInvoiceFreeList>
      bordered
      sticky
      rowKey={(item) => item.facturaId}
      columns={columns}
      pagination={defaultPaginationPropertiesSmall}
      dataSource={invoices}
    />
  );
};

export default observer(InvoiceFreeTable);
