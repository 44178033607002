import { Button, Col, Popconfirm, Row, Table, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { IColumns } from "../../app/common/table/utils";
import { moneyFormatter } from "../../app/util/utils";
import {
  SendOutlined,
  DollarOutlined,
  EyeOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import IconButton from "../../app/common/button/IconButton";
import { useStore } from "../../app/stores/store";

type CreditMonitoringPaymentProps = {
  indice: number;
  payments: any[];
};

const CreditMonitoringPayment = ({
  indice,
  payments,
}: CreditMonitoringPaymentProps) => {
  const { invoiceCompanyStoreLegacy: invoiceCompanyStore, modalStore } =
    useStore();
  const { isLoading, cancelPaymentAddon, printPaymentAddon, scopes } =
    invoiceCompanyStore;

  const columns: IColumns<any> = [
    {
      key: "id",
      dataIndex: "documento",
      title: "Documento",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "cliente",
      title: "Cliente",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "fechaCreacion",
      title: "Fecha Creación",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "usuario",
      title: "Usuario",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "monto",
      title: "Monto",
      align: "center",
      width: "11%",
      render: (value: any) => `${moneyFormatter.format(value)} `,
    },
    {
      key: "id",
      dataIndex: "metodoPago",
      title: "Metodo de pago",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "estatus",
      title: "Estatus",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "Acciones",
      align: "center",
      width: "11%",
      render: (value: any, fullRow) => (
        <>
          <Row justify="center">
            {scopes.modificar && (
              <Col span={12}>
                <Tooltip title="Cancelar">
                  <Popconfirm
                    title="¿Desea cancelar el pago?"
                    okText="Sí"
                    cancelText="No"
                    trigger="click"
                    placement="left"
                    onConfirm={() => cancelPaymentAddon(value)}
                  >
                    <IconButton
                      disabled={fullRow.estatus === "Cancelado"}
                      icon={<CloseOutlined />}
                      danger
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            )}
            {scopes.imprimir && (
              <Col span={12}>
                <Tooltip title="Ver archivo">
                  <Button
                    shape="circle"
                    size="middle"
                    icon={<EyeOutlined />}
                    onClick={() => printPaymentAddon(value)}
                  ></Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Table<any>
        bordered
        rowKey={(record) => record.id}
        columns={columns}
        pagination={false}
        loading={false}
        dataSource={payments}
        // showHeader={indice === 0}
        locale={{
          emptyText: "Sin pagos registrados",
        }}
      ></Table>
    </>
  );
};

export default observer(CreditMonitoringPayment);
