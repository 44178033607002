import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import ErrorComponent from "../../app/layout/ErrorComponent";
import { useStore } from "../../app/stores/store";
import messages from "../../app/util/messages";
import ConfigurationTab from "./ConfigurationTab";

const Configuration = () => {
  const { configurationStore } = useStore();
  const { scopes } = configurationStore;
  
  return !scopes?.acceder ? (
    <ErrorComponent status={403} message={messages.forbidden} hideButton />
  ) : (
    <ConfigurationTab />
  );
};

export default observer(Configuration);
