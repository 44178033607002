import { Table, Typography } from "antd";
import { IColumns, ISearch, getDefaultColumnProps } from "../../../app/common/table/utils";
import { IReportData, IStudiesData } from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";
const { Text } = Typography;
const getDiscountStatsColumns = (
    searchState: ISearch,
    setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
  ) => {
    const columns: IColumns<IReportData> = [
      {
        ...getDefaultColumnProps("solicitud", "Clave", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("sucursal", "Sucursal", {
          searchState,
          setSearchState,
          width: "25%",
        }),
      },
      {
        ...getDefaultColumnProps("paciente", "Nombre", {
          searchState,
          setSearchState,
          width: "35%",
        }),
      },
      {
        ...getDefaultColumnProps("empresa", "Compañia", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("medico", "Médico", {
          searchState,
          setSearchState,
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("cantidadEstudios", "#", {
          searchState,
          setSearchState,
          width: "10%",
        }),
      },
      {
        ...getDefaultColumnProps("precioEstudios", "Estudios", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("descuentoPorcentual", "Desc. %", {
          width: "20%",
        }), 
        render: (value, item) => {
            if (value == 0) {
                var descuento = (item.descuento*100)/item.precioEstudios;

                return <Text>{descuento?.toFixed(2)}%</Text>;
            } else {
              return <Text>{value?.toFixed(2)}%</Text>;
            }
          },
      },
      {
        ...getDefaultColumnProps("descuento", "Desc.", {
          width: "20%",
        }),
        render: (value, item) => {
            if (value == 0) {
                var descuento = ((item.descuentoPorcentual*item.precioEstudios)/100).toFixed(2);

                return <Text>{moneyFormatter.format(parseFloat(descuento))}</Text>;
            } else {
              return <Text>{moneyFormatter.format(value)}</Text>;
            }
          },
      },
      {
        ...getDefaultColumnProps("totalEstudios", "Total", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
    ];
  
    return columns;
  };

  
export const expandableDiscountConfig = () => {
    const nestedColumns: IColumns<IStudiesData> = [
      {
        ...getDefaultColumnProps("clave", "Clave", {
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("estudio", "Nombre del estudio", {
          width: "30%",
        }),
      },
      {
        ...getDefaultColumnProps("precio", "Precio", {
          width: "10%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
    ];
  
    return {
      expandedRowRender: (item: IReportData, index: any) => (
        <Table
          columns={nestedColumns}
          dataSource={item.estudio}
          pagination={false}
          className="header-expandable-table"
          showHeader={index === 0}
        />
      ),
      rowExpandable: () => true,
      defaultExpandAllRows: true,
    };
  };
  
  export default getDiscountStatsColumns;