import { Radio } from "antd";

import { IOptions } from "../../../../../app/models/shared";
import { InvoiceType } from "../../utils/utils";

const settingsCompanyOptions: IOptions[] = [
  { label: "Desglosado por estudio", value: "detailed" },
  { label: "Por sucursal", value: "branch" },
  { label: "Por concepto", value: "concept" },
];

const settingsRequestOptions: IOptions[] = [
  { label: "Desglosado por estudio", value: "detailed" },
  { label: "Por sucursal", value: "branch" },
  { label: "Por paciente", value: "patient" },
  { label: "Total", value: "total" },
];

type Props = {
  type: InvoiceType;
  validDetailed: boolean;
  detailType: string;
  setDetailType: (type: string) => void;
};

const InvoiceConceptType = ({
  type,
  validDetailed,
  detailType,
  setDetailType,
}: Props) => {
  const onTypeChange = (type: string) => {
    setDetailType(type);
  };

  return (
    <Radio.Group
      style={{ marginBottom: 12 }}
      options={
        type === "company"
          ? settingsCompanyOptions
          : settingsRequestOptions.filter(
              (x) => x.value !== "detailed" || validDetailed
            )
      }
      value={detailType}
      onChange={(e) => onTypeChange(e.target.value)}
    />
  );
};

export default InvoiceConceptType;
