import { makeAutoObservable, reaction } from "mobx";

import Profile from "../api/profile";
import { IMenu, IProfile, IScopes } from "../models/shared";
import { ILoginForm } from "../models/user";
import alerts from "../util/alerts";
import history from "../util/history";
import { compareStrings, getErrors, tokenName } from "../util/utils";
import { store } from "./store";

export default class ProfileStore {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem(tokenName, token);
        } else {
          window.localStorage.removeItem(tokenName);
        }
      }
    );
  }

  menu: IMenu[] = [];
  profile: IProfile | undefined;
  scopes: IScopes[] = [];
  token?: string = window.localStorage.getItem(tokenName) ?? undefined;
  logoImg?: string;

  get isLoggedIn() {
    return !!this.profile && this.menu.length > 0 && this.scopes.length > 0;
  }

  getPdfConsent = async () => {
    try {
      const pdf = await Profile.getPdfConsent();
      return pdf;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  setToken = (token?: string) => {
    this.token = token;
  };

  setProfile = (profile?: IProfile) => {
    this.profile = profile;
  };

  setLogoImg = (image: string) => {
    this.logoImg = image;
  };

  getMenu = async () => {
    try {
      this.menu = await Profile.getMenu();
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getProfile = async () => {
    try {
      this.profile = await Profile.getProfile();
      this.profile.token = this.token;
    } catch (error) {
      this.profile = undefined;
      alerts.warning(getErrors(error));
    }
  };

  // prettier-ignore
  getScopes = async () => {
    try {
      const scopes = await Profile.getScopes();
      
      store.roleStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "role"))!;
      store.userStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "user"))!;
      store.branchStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "branch"))!;
      store.companyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "company"))!;
      store.medicsStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "medic"))!;
      store.studyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "study"))!;
      store.reagentStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "reagent"))!;
      store.indicationStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "indication"))!;
      store.parameterStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "parameter"))!;
      store.catalogStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "catalog"))!;
      store.generalStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "catalog"))!;
      store.priceListStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "price"))!;
      store.packStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "pack"))!;
      store.promotionStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "promo"))!;
      store.loyaltyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "loyalty"))!;
      store.routeStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "route"))!;
      store.maquiladorStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "maquila"))!;
      store.notificationsStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "notifications"))!;
      store.procedingStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "medicalRecord"))!;
      store.configurationStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "configuration"))!;
      store.quotationStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "quotation"))!;
      store.reportStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "report"))!;
      store.appointmentStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "appointment"))!;
      store.samplingStudyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "sampling"))!;
      store.requestedStudyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "requestedstudy"))!;
      store.requestStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "request"))!;
      store.equipmentStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "equipment"))!;
      store.equipmentMantainStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "mantain"))!;
      store.clinicResultsStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "clinicResults"))!;
      store.massResultSearchStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "massSearch"))!;
      store.deliveryResultsStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "deliveryResults"))!;
      store.routeTrackingStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "routeTracking"))!;
      store.shipmentTrackingStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "shipmentTracking"))!;
      store.workListStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "workList"))!;
      store.resultValidationStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "resultValidation"))!;
      store.invoiceCompanyStoreLegacy.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoiceCompany"))!;
      store.invoiceCatalogStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoiceCatalog"))!;
      store.seriesStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "series"))!;
      store.invoiceRequestCompanyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoiceRequest"))!;
      store.reportStudyStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "reportstudy"))!;
      store.invoiceFreeStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoiceFree"))!;
      store.invoiceGlobalStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoiceGlobal"))!;
      store.invoiceCreditStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "creditMonitoring"))!;
      store.resultValidationStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "resultValidation"))!;
      store.relaseResultStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "relaseResult"))!;
      store.tagStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "tag"))!;
      store.invoiceStore.scopes = scopes.find((x) => compareStrings(x.pantalla, "invoice"))!;
      
      this.scopes = scopes;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  validateAdmin = async (creds: ILoginForm) => {
    try {
      const admin = await Profile.validateAdmin(creds);
      return admin;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  validateCancellation = async (creds: ILoginForm) => {
    try {
      const hasPermission = await Profile.validateCancellation(creds);
      return hasPermission;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  validateDiscount = async (creds: ILoginForm) => {
    try {
      const hasPermission = await Profile.ValidateDiscount(creds);
      return hasPermission;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  login = async (creds: ILoginForm) => {
    try {
      const profile = await Profile.login(creds);
      this.setToken(profile.token);
      this.setProfile(profile);

      history.push("/");
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  logout = () => {
    this.setToken(undefined);
    this.setProfile(undefined);
    history.push("/login");
  };
}
