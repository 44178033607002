import { Button, PageHeader, Input } from "antd";
import { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import views from "../../app/util/view";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import PrintIcon from "../../app/common/icons/PrintIcon";

const { Search } = Input;

type TagHeaderProps = {
  handlePrint: () => void;
  handleDownload: () => Promise<void>;
  setLoading: (value: React.SetStateAction<boolean>) => void;
};

const TagHeader: FC<TagHeaderProps> = ({
  handlePrint,
  handleDownload,
  setLoading,
}) => {
  const { tagStore } = useStore();
  const { scopes, getAll } = tagStore;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const search = async (search: string | undefined) => {
    search = search === "" ? undefined : search;

    setLoading(true);
    await getAll(search ?? "all");
    setLoading(false);

    if (search) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }

    setSearchParams(searchParams);
  };

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Catálogo de Etiquetas" image="etiquetas" />}
      className="header-container"
      extra={[
        scopes.imprimir && <PrintIcon key="print" onClick={handlePrint} />,
        scopes.descargar && (
          <DownloadIcon key="download" onClick={handleDownload} />
        ),
        <Search
          key="search"
          placeholder="Buscar"
          defaultValue={searchParams.get("search") ?? ""}
          onSearch={search}
        />,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate(`/${views.tag}/new?${searchParams}&mode=edit`);
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default TagHeader;
