import { Button, Input, PageHeader, Select } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import HeaderTitle from "../../app/common/header/HeaderTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import views from "../../app/util/view";
import { IScopes } from "../../app/models/shared";
const { Search } = Input;

type NotificationsHeaderProps = {
  handleDownload: () => void;
  handlePrint: () => void;
  type: number;
  setType: React.Dispatch<React.SetStateAction<number>>;
  scopes: IScopes;
};
const NotificationsHeader: FC<NotificationsHeaderProps> = ({
  type,
  setType,
  scopes,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        ghost
        title={
          <HeaderTitle
            title={`Catálogo de ${type === 1 ? "notificaciones" : "avisos"}`}
            image="notifications"
          />
        }
        className="header-container"
        extra={[
          <Search
            key="search"
            placeholder="Buscar"
            onSearch={(value) => {
              navigate(`/${views.notifications}?search=${value}`);
            }}
          />,
          <Select
            key="catalogo"
            showSearch
            placeholder="Catálogo"
            optionFilterProp="children"
            defaultValue={1}
            onChange={(e) => setType(e)}
            allowClear
            style={{ width: 180, textAlign: "left" }}
            options={[
              { value: 1, label: "Notificaciones" },
              { value: 2, label: "Avisos" },
            ]}
          ></Select>,
          scopes.crear && (
            <Button
              key="new"
              type="primary"
              icon={<PlusOutlined />}
              disabled={type != 2}
              onClick={() => {
                navigate(`/notifications/new`);
              }}
            >
              Nuevo
            </Button>
          ),
        ]}
      ></PageHeader>
    </>
  );
};
export default observer(NotificationsHeader);
