import {
  Button,
  Col,
  Descriptions,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

import { useStore } from "../../../app/stores/store";
import { moneyFormatter, toFixedNumber } from "../../../app/util/utils";

const { Text } = Typography;

const QuotationInvoice = () => {
  const { quotationStore } = useStore();
  const { totals, changeTotalsDiscount } = quotationStore;

  const [loading, setLoading] = useState(false);
  const [editingDiscount, setEditingDiscount] = useState(true);
  const [editingCharge, setEditingCharge] = useState(true);
  const [discountValue, setDiscountValue] = useState(totals.descuento);
  const [discountValuePorcent, setDiscountValuePorcent] = useState(0);
  const [discountValueRadio, setDiscountValueRadio] = useState(2);
  const [chargeValuePorcent, setChargeValuePorcent] = useState(0);
  const [chargeValueRadio, setChargeValueRadio] = useState(2);

  useEffect(() => {
    setDiscountValue(totals.descuento);
    var cantidadDescuento =
      Math.round(((totals.descuento * 100) / totals.totalEstudios) * 100) / 100;
    setDiscountValuePorcent(cantidadDescuento);
  }, [totals.descuento, totals.totalEstudios]);

  const onChangeDiscountRadio = (e: RadioChangeEvent) => {
    setDiscountValueRadio(e.target.value);
  };

  const onChangeChargeRadio = (e: RadioChangeEvent) => {
    setChargeValueRadio(e.target.value);
  };

  const onChangeDiscount = async (discount: number, type: number) => {
    var cantidad = 0;
    setLoading(true);
    if (type === 1) {
      //Porcent
      cantidad = Math.round(((discount * totals.totalEstudios) / 100) * 100) / 100;
      setDiscountValue(Math.round(cantidad * 100) / 100);
    } else {
      //Cantidad
      cantidad = discount;
      setDiscountValuePorcent(Math.round(((cantidad * 100) / totals.totalEstudios) * 100) / 100);
    }

    await changeTotalsDiscount(cantidad);
    //setEditingDiscount(false);
    setLoading(false);
  };

  return (
    <Descriptions
      labelStyle={{ width: "60%" }}
      className="quotation-description"
      bordered
      column={1}
      size="small"
    >
      <Descriptions.Item label="Concepto">Total</Descriptions.Item>
      <Descriptions.Item label="Estudio" className="number-desc">
        {moneyFormatter.format(totals.totalEstudios)}
      </Descriptions.Item>
      <Descriptions.Item label="Descuento" className="number-desc">
        {!editingDiscount ? (
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>{moneyFormatter.format(totals.descuento)}</Text>
          </Space>
        ) : (
          <Space style={{ width: "100%", display: "flex" }}>
            <Radio.Group onChange={onChangeDiscountRadio} value={discountValueRadio}>
              <Space direction="vertical" style={{ width: "100%", display: "flex" }}>
                <Radio value={1}>
                  <Row>
                    <Col span={12} style={{ textAlign: "start" }}>
                      <InputNumber<number>
                        key={"desc-num"}
                        className="no-padding-input disabledInputInvoice"
                        style={{
                          paddingLeft: 0,
                          backgroundColor: "white",
                        }}
                        formatter={(value) => `%${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        value={discountValuePorcent}
                        onChange={(value) => {
                          setDiscountValuePorcent(value ?? 0);
                          // onChangeDiscount(value??0, 1);
                        }}
                        controls={false}
                        bordered={false}
                        min={0}
                        max={100}
                        maxLength={4}
                        disabled={discountValueRadio === 1 ? false : true}
                      />
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      {discountValueRadio === 1 && (
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() => {
                            onChangeDiscount(discountValuePorcent ?? 0, 1);
                          }}
                          icon={<CheckOutlined />}
                        />
                      )}
                    </Col>
                  </Row>
                </Radio>
                <Radio value={2}>
                  <Row>
                    <Col span={12} style={{ textAlign: "start" }}>
                      <InputNumber<number>
                        key={"desc-num"}
                        className="no-padding-input disabledInputInvoice"
                        style={{
                          paddingLeft: 0,
                          backgroundColor: "white",
                        }}
                        formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
                        value={discountValue}
                        onChange={(value) => {
                          setDiscountValue(toFixedNumber(value ?? 0, 2));
                          // onChangeDiscount(value??0, 2);
                        }}
                        controls={false}
                        bordered={false}
                        min={0}
                        max={totals.totalEstudios}
                        disabled={discountValueRadio === 2 ? false : true}
                      />
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      {discountValueRadio === 2 && (
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() => {
                            onChangeDiscount(discountValue ?? 0, 2);
                          }}
                          icon={<CheckOutlined />}
                        />
                      )}
                    </Col>
                  </Row>
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Total" className="number-desc">
        {moneyFormatter.format(totals.total)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default observer(QuotationInvoice);
