import { IColumns, ISearch } from "../../../app/common/table/utils";
import { IReportData, IStudiesData } from "../../../app/models/report";
import { getDefaultColumnProps } from "../../../app/common/table/utils";
import { Descriptions, Table, Typography } from "antd";
import "../css/report.less";
import { ITagRouteList } from "../../../app/models/trackingOrder";
import { ITagTrackingOrder } from "../../../app/models/routeTracking";

const { Text } = Typography;

const getMaquilaInternColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("seguimiento", "No. Seguimiento", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("origen", "Origen", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("destino", "Destino", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("ruta", "Clave ruta", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("entregado", "Fecha de Entrega", {
        searchState,
        setSearchState,
        width: "40%",
      }),
    },
  ];

  return columns;
};

export const expandableMaquilaInternConfig = () => {
  const renderExtra = (
    value: any,
    record: ITagTrackingOrder,
    isKey?: boolean,
    type?: string
  ) => {
    if (record.extra && isKey) {
      return (
        <Text style={{ color: "#253B65" }} strong>
          {value + type + "(E)"}
        </Text>
      );
    } else if (record.extra) {
      return (
        <Text style={{ color: "#253B65" }} strong>
          {value + type}
        </Text>
      );
    } else {
      return <Text>{value + type}</Text>;
    }
  };

  const nestedColumns: IColumns<ITagTrackingOrder> = [
    {
      ...getDefaultColumnProps("claveEtiqueta", "Clave muestra", {
        width: "15%",
      }),
      render: (value, record) => renderExtra(value, record, true, ""),
    },
    {
      ...getDefaultColumnProps("recipiente", "Recipiente", {
        width: "15%",
      }),
      render: (value, record) =>
        renderExtra(value, record, false, " (Muestra)"),
    },
    {
      ...getDefaultColumnProps("cantidad", "Cantidad", {
        width: "15%",
      }),
      render: (value, record) => renderExtra(value, record, false, ""),
    },
    {
      ...getDefaultColumnProps("estudios", "Estudios", {
        width: "15%",
      }),
      render: (value, record) => renderExtra(value, record, false, ""),
    },
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        width: "20%",
      }),
      render: (value, record) =>
        renderExtra(value, record, false, " (Solicitud)"),
    },
    {
      ...getDefaultColumnProps("paciente", "Paciente", {
        width: "20%",
      }),
      render: (value, record) => renderExtra(value, record, false, ""),
    },
  ];

  return {
    expandedRowRender: (item: IReportData, index: any) => (
      <Table
        columns={nestedColumns}
        dataSource={item.etiquetas}
        pagination={false}
        className="header-expandable-table"
        showHeader={false}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

export default getMaquilaInternColumns;
