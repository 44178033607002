import { Button, Col, Row, Table, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { IColumns } from "../../app/common/table/utils";
import { moneyFormatter } from "../../app/util/utils";
import { useEffect, useState } from "react";
import CreditMonitoringPayment from "./CreditMonitoringPayment";
import {
  PlusOutlined,
  EyeOutlined,
  SendOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useStore } from "../../app/stores/store";
import {
  IInvoicesFreeFilter,
  InvoicesFreeFilterValues,
} from "../../app/models/invoiceLegacy";
import { toJS } from "mobx";
import CreditMonitoringCompanyData from "./CreditMonitoringCompanyData";
import alerts from "../../app/util/alerts";
import "./styles.css";

const CreditMonitoringTable = () => {
  const { invoiceCompanyStoreLegacy: invoiceCompanyStore, modalStore } =
    useStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [filters, setFilters] = useState<IInvoicesFreeFilter>(
    new InvoicesFreeFilterValues()
  );
  const { isLoading, getInvoicesCreditNote, invoicesCreditNote, scopes } =
    invoiceCompanyStore;
  const { openModal } = modalStore;

  useEffect(() => {
    getInvoicesCreditNote(filters);
  }, []);

  useEffect(() => {
    if (invoicesCreditNote) {

      setExpandedRowKeys(invoicesCreditNote.map((x: any) => x.id));
      setOpenRows(true);
    }
  }, [invoicesCreditNote]);
  const registrarPago = (invoice = null) => {
    // if (selectedRowKeys.length > 0) {
    let selectedInvoices;

    if (invoice !== null) {
      selectedInvoices = [invoice];
    } else {
      selectedInvoices = invoicesCreditNote.filter((x) =>
        selectedRowKeys.includes(x.id)
      );
    }
    // const primerRfc = selectedInvoices[0].rfc;
    // const rfcDiferentes = selectedInvoices.some(x => x.rfc !== primerRfc);
    // if (rfcDiferentes) {
    //   alerts.error("No se puede registrar el pago, las facturas seleccionadas pertenecen a diferentes compañías");
    //   return;
    // }

    openModal({
      width: 1200,
      title: "Datos de la compañía",
      body: (
        <>
          <CreditMonitoringCompanyData invoicesList={selectedInvoices} />
        </>
      ),
    });
    // }
  };

  const columns: IColumns<any> = [
    {
      key: "id",
      dataIndex: "documento",
      title: "Documento",
      align: "left",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "cliente",
      title: "Cliente",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "fechaCreacion",
      title: "Fecha Creación",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "fechaLimiteCredito",
      title: "Fecha límite crédito",
      align: "center",
      width: "11%",
    },
    {
      key: "id",
      dataIndex: "monto",
      title: "Monto",
      align: "center",
      width: "11%",
      render: (value: any) => `${moneyFormatter.format(value)} `,
    },
    {
      key: "id",
      dataIndex: "montoPagado",
      title: "Monto pagado",
      align: "center",
      width: "11%",
      render: (value: any) => `${moneyFormatter.format(value)} `,
    },
    {
      key: "id",
      dataIndex: "saldo",
      title: "Saldo",
      align: "center",
      width: "11%",
      render: (value: any) => `${moneyFormatter.format(value)} `,
    },
    {
      key: "id",
      dataIndex: "estatus",
      title: "Estatus",
      align: "center",
      width: "11%",
      render: (text, record) => (
        <span
          style={{
            color:
              text === "Vencida"
                ? "red"
                : text === "Vigente"
                  ? "blue"
                  : text === "Próxima a vencer"
                    ? "orange"
                    : text === "Pagada"
                      ? "green"
                      : "",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      key: "id",
      dataIndex: "acciones",
      title: "Acciones",
      align: "center",
      width: "11%",
      render: (value: any, fullRow: any) =>
        scopes.modificar && (
          <>
            <Row justify="center">
              <Col span={12}>
                <Tooltip title="Registrar pago">
                  <Button
                    shape="circle"
                    size="middle"
                    icon={<DollarOutlined />}
                    disabled={
                      fullRow.estatus === "Pagada" ||
                      fullRow.estatus === "Pagado"
                    }
                    onClick={() => registrarPago(fullRow)}
                  ></Button>
                </Tooltip>
              </Col>
            </Row>
          </>
        ),
    },
  ];

  const onExpand = (isExpanded: boolean, record: any) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };
  return (
    <>
      {/* <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <div style={{ textAlign: 'right' }}>
            <Button
              key="new"
              type="primary"
              htmlType="submit"
              icon={<PlusOutlined />}
              onClick={registrarPago}
              disabled={selectedRowKeys && selectedRowKeys.length === 0}
            >
              Registrar
            </Button>

          </div>
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>
          <Table<any>
            bordered
            rowKey={(record) => record.id}
            columns={columns}
            pagination={false}
            loading={isLoading}
            dataSource={invoicesCreditNote}
            rowClassName="row-search"
            size="middle"
            // rowSelection={{
            //   type: "checkbox",
            //   selectedRowKeys,
            //   onChange: (newSelectedRowKeys) => {
            //     setSelectedRowKeys(newSelectedRowKeys);
            //   },
            // }}
            expandable={{
              onExpand: onExpand,
              expandedRowKeys: expandedRowKeys,
              rowExpandable: () => true,
              defaultExpandAllRows: true,
              expandedRowRender: (data, index) => {
                return (
                  <>
                    <CreditMonitoringPayment
                      indice={index}
                      payments={data.complementosPago ?? []}
                    />
                  </>
                );
              },
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default observer(CreditMonitoringTable);
