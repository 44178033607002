import { ITagForm } from "../models/tag";
import requests from "./agent";

const Tag = {
  getAll: (search: string): Promise<ITagForm[]> =>
    requests.get(`tag/all/${!search ? "all" : search}`),
  getActive: (): Promise<ITagForm[]> => requests.get("tag/active"),
  getById: (id: number): Promise<ITagForm> => requests.get(`tag/${id}`),
  create: (tag: ITagForm): Promise<ITagForm> => requests.post("tag", tag),
  update: (tag: ITagForm): Promise<ITagForm> => requests.put("tag", tag),
  exportList: (search: string): Promise<void> =>
    requests.download(`tag/export/list/${!search ? "all" : search}`),
  exportForm: (id: number): Promise<void> =>
    requests.download(`tag/export/form/${id}`),
};

export default Tag;
