import { makeAutoObservable } from "mobx";
import moment from "moment";

import DeliveryResults from "../api/deliveryResults";
import { IGeneralForm } from "../models/general";
import { IParameter, IResult } from "../models/massResultSearch";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import messages from "../util/messages";
import { getErrors } from "../util/utils";

export default class DeliveryResultsStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  area: string = "";
  results: IResult[] = [];
  parameters: IParameter[] = [];
  loadingStudies: boolean = false;

  formDeliverResult: IGeneralForm = {
    fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
  };

  setFormDeliverResult = (form: IGeneralForm) => {
    this.formDeliverResult = form;
  };

  setAreas = (area: string) => {
    this.area = area;
  };

  requests: any[] = [];
  getAllCaptureResults = async (search: IGeneralForm) => {
    try {
      this.loadingStudies = true;
      const result = await DeliveryResults.getAllCaptureResults(search);
      this.requests = result;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingStudies = false;
    }
  };

  sendResultFile = async (listResult: any) => {
    // updateResultPathological = async (result: IResultPathological) => {
    try {
      const success = await DeliveryResults.sendResultFile(listResult);
      return success;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  rollBackSentStatus = async (studiesId: number[]) => {
    try {
      this.loadingStudies = true;
      await DeliveryResults.rollBackSentStatus(studiesId);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingStudies = false;
    }
  };

  printResultFile = async (listResult: any) => {
    try {
      const success = await DeliveryResults.printResultFile(listResult);
      return success;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  printResultFilePreview = async (listResult: any, milliseconds: number | null = null) => {
    try {
      const url = await DeliveryResults.printResultFilePreview(listResult, milliseconds);
      return url;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  exportListDeliverResult = async (search: IGeneralForm) => {
    try {
      this.loadingStudies = true;
      await DeliveryResults.exportListDeliverResult(search);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingStudies = false;
    }
  };

  printOrder = async (recordId: string, requestId: string) => {
    try {
      await DeliveryResults.getOrderPdf(recordId, requestId);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };
}
