import { Button, PageHeader, Radio } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import { useStore } from "../../../app/stores/store";
import { InvoiceRequestCompanyType } from "../invoice/utils/utils";

type Props = {
  type: InvoiceRequestCompanyType;
  groupId: string | undefined;
  selectedRequests: string[];
  setLoading: (loading: boolean) => void;
};

const InvoiceCompanyHeader = ({
  type,
  groupId,
  selectedRequests,
  setLoading,
}: Props) => {
  const { invoiceRequestCompanyStore } = useStore();
  const { filter, printTicket, downloadList, scopes } =
    invoiceRequestCompanyStore;

  let navigate = useNavigate();

  const [creationType, setCreationType] = useState<"invoice" | "receipt">(
    "invoice"
  );

  const titleName =
    type === "company" ? "compañía" : type === "request" ? "solicitud" : "";

  const generate = async () => {
    if (creationType === "invoice") {
      create();
    } else {
      print();
    }
  };

  const create = () => {
    navigate(`/invoices/${type}/new`, {
      state: {
        groupId,
        requests: selectedRequests,
      },
    });
  };

  const print = async () => {
    setLoading(true);
    await printTicket(selectedRequests);
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    await downloadList(type, filter);
    setLoading(false);
  };

  const changeType = (value: "invoice" | "receipt") => {
    setCreationType(value);
  };

  return (
    <PageHeader
      ghost
      title={
        <HeaderTitle
          title={`Crédito y cobranza (Facturación por ${titleName})`}
          image="invoice-company"
        />
      }
      className="header-container"
      extra={[
        scopes.descargar && <DownloadIcon key="doc" onClick={download} />,
        <Radio.Group
          key="type"
          onChange={(e) => changeType(e.target.value as any)}
          value={creationType}
        >
          <Radio value="invoice">Factura</Radio>
          <Radio value="receipt">Recibo</Radio>
        </Radio.Group>,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={generate}
            icon={<PlusOutlined />}
            disabled={selectedRequests.length === 0}
          >
            Generar
          </Button>
        ),
      ].filter((x) => type === "request" || !x || !x.key || x.key !== "type")}
    />
  );
};
export default observer(InvoiceCompanyHeader);
