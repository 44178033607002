import {
  Button,
  Col,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  IColumns,
  getDefaultColumnProps,
} from "../../../../../app/common/table/utils";
import {
  IRequestTag,
  IRequestTagStudy,
} from "../../../../../app/models/request";
import { useStore } from "../../../../../app/stores/store";
import { DeleteTwoTone } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { toJS } from "mobx";

const { Option } = Select;
const { Text } = Typography;

const RequestPrintTag = () => {
  const { requestStore } = useStore();
  const {
    readonly,
    request,
    availableTags,
    missingTagStudy,
    printTags,
    tags,
    setTags,
    deleteTag,
    scopes,
  } = requestStore;

  const [selectedKeys, setSelectedKeys] = useState<(string | number)[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const changeQty = (qty: number, record: IRequestTag) => {
    let index = tags.findIndex((x) => x.id === record.id);
    if (index > -1) {
      const lbls = [...tags];
      lbls[index] = { ...lbls[index], cantidad: qty };
      setTags(lbls);
    }
  };

  const print = async () => {
    if (request) {
      const toPrint = tags.filter((x) => selectedKeys.includes(x.id));

      setLoading(true);
      await printTags(request.expedienteId, request.solicitudId!, toPrint);
      setLoading(false);
    }
  };

  const onChangeObservation = (observation: string, record: IRequestTag) => {
    let index = tags.findIndex((x) => x.id === record.id);
    if (index > -1) {
      const lbls = toJS(tags);
      lbls[index] = { ...lbls[index], observaciones: observation };
      setTags(lbls);
    }
  };

  const onChangeStudies = (values: (string | number)[], tag: IRequestTag) => {
    const newTags = toJS(tags);
    const index = newTags.findIndex((x) => x.id === tag.id);

    if (index === -1) return;

    const selectedStudyTag =
      availableTags
        .find((x) => x.id === tag.id)
        ?.estudios?.filter((x) => values.includes(x.id)) ?? [];

    newTags[index].estudios = selectedStudyTag;
    setTags(newTags);
  };

  function orderTagStudies(a: IRequestTagStudy, b: IRequestTagStudy) {
    if (a.nombreEstudio < b.nombreEstudio) return -1;
    if (a.nombreEstudio > b.nombreEstudio) return 1;
    if (a.cantidad < b.cantidad) return -1;
    if (a.cantidad > b.cantidad) return 1;
    return 0;
  }

  const columns: IColumns<IRequestTag> = [
    {
      ...getDefaultColumnProps("claveEtiqueta", "Clave", {
        searchable: false,
        width: "8%",
      }),
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.claveEtiqueta.localeCompare(b.claveEtiqueta),
    },
    {
      ...getDefaultColumnProps("nombreEtiqueta", "Tapón", {
        searchable: false,
        width: "25%",
      }),
    },
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchable: false,
        width: "12%",
      }),
    },
    {
      ...getDefaultColumnProps("estudios", "Estudios", {
        searchable: false,
        width: "25%",
      }),
      render(value: IRequestTagStudy[], record) {
        return (
          <RequestTag
            tags={value}
            onChange={(values) => onChangeStudies(values, record)}
            studyOptions={
              availableTags
                .find((x) => x.id === record.id)
                ?.estudios?.sort(orderTagStudies) ?? []
            }
          />
        );
      },
    },
    {
      ...getDefaultColumnProps("observaciones", "Observación", {
        searchable: false,
        width: "25%",
      }),
      render: (value, record) => (
        <TextArea
          value={record.observaciones}
          bordered={record ? true : false}
          rows={3}
          onChange={(e) => onChangeObservation(e.target.value, record)}
          autoSize
        />
      ),
    },
    {
      key: "borrado",
      dataIndex: "borrado",
      title: "",
      width: "5%",
      align: "center",
      render: (value, item) =>
        item.manual && (
          <Popconfirm
            title="¿Desea eliminar la etiqueta?"
            okText="Sí"
            cancelText="No"
            trigger="hover"
            onConfirm={() => deleteTag(item.id)}
          >
            <DeleteTwoTone twoToneColor="red" />
          </Popconfirm>
        ),
    },
    Table.SELECTION_COLUMN,
  ];

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 12]}>
        {missingTagStudy.length > 0 && (
          <Col span={24}>
            <Text type="danger">
              Pendientes por asignar:{" "}
              {missingTagStudy.map((x) => x.clave).join(", ")}
            </Text>
          </Col>
        )}
        <Col span={24}>
          <Table<IRequestTag>
            size="small"
            rowKey={(x) => x.id}
            columns={columns}
            dataSource={[...tags]}
            pagination={false}
            rowSelection={{
              fixed: "right",
              selectedRowKeys: selectedKeys,
              onChange: (keys) => {
                setSelectedKeys(keys);
              },
              getCheckboxProps: (record) => ({
                disabled: typeof record.id === "string",
              }),
            }}
            sticky
            scroll={{ x: "fit-content" }}
          />
        </Col>
        {scopes.imprimir && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="default" onClick={print}>
              Imprimir
            </Button>
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default observer(RequestPrintTag);

type RequestTagProps = {
  tags: IRequestTagStudy[];
  onChange: (values: (string | number)[]) => void;
  studyOptions: IRequestTagStudy[];
};

const RequestTag = ({ tags, onChange, studyOptions }: RequestTagProps) => {
  return (
    <Select<(string | number)[]>
      bordered={false}
      onChange={onChange}
      style={{ width: "100%" }}
      mode="multiple"
      value={tags.map((x) => x.id)}
    >
      {studyOptions.map((x) => (
        <Option key={x.id} value={x.id}>
          <span className={x.asignado ? "" : "option-not-assigned"}>
            {x.nombreEstudio}
          </span>
        </Option>
      ))}
    </Select>
  );
};
