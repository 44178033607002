import { Divider } from "antd";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ParameterHeaderForm from "./ParameterHeaderForm";
import ParameterForm from "./ParameterForm";

type UrlParams = {
  id: string;
};

const ParameterDetail = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef<any>();
  const { parameterStore } = useStore();
  const { getById, exportForm, scopes } = parameterStore;
  let { id } = useParams<UrlParams>();

  useEffect(() => {
    const readuser = async (idUser: string) => {
      await getById(idUser);
    };
    if (id) {
      readuser(id);
    }
  }, [getById, id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve: any) => {
        setTimeout(() => {
          setLoading(true);
          resolve();
        }, 200);
      });
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    setLoading(true);
    const succes = await exportForm(id!);

    if (succes) {
      setLoading(false);
    }
  };
  return (
    <div ref={componentRef}>
      <ParameterHeaderForm
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        scopes={scopes}
      />
      <Divider className="header-divider" />
      <ParameterForm load={loading} />
    </div>
  );
};
export default observer(ParameterDetail);
