import { Divider, Form, Space, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import Center from "../../../../app/layout/Center";
import { IInvoice, IInvoiceClient, IInvoiceDetail } from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import { InvoiceType, isValidType, setInvoiceCompany, setInvoicePatient } from "../utils/utils";
import InvoiceConcept from "./concepts/InvoiceConcept";
import InvoiceData from "./InvoiceData";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceInfo from "./InvoiceInfo";

type UrlParams = {
  id: string;
  type: string;
};

const InvoiceDetail = () => {
  const { procedingStore, companyStore, invoiceStore } = useStore();
  const { getById: getPatient } = procedingStore;
  const { getById: getCompany } = companyStore;
  const { loadingDetail, setLoadingDetail, getById } = invoiceStore;

  const { id, type } = useParams<UrlParams>();
  const { state }: { state: any } = useLocation();

  const groupId = state && state.groupId ? state.groupId : undefined;
  const requests = state && state.requests ? state.requests : [];

  const [formInvoice] = Form.useForm<IInvoice>();
  const [formTaxData] = Form.useForm<IInvoiceClient>();

  const series = Form.useWatch("serie", formInvoice);

  const invoiceId = id === "new" ? undefined : id;
  // prettier-ignore
  const titleName = type === "free" ? "libre" : type === "global" ? "global" : type === "company" ? "por compañía" : type === "request" ? "por solicitud" : "";
  const headerTitle = `Crédito y cobranza (Facturación ${titleName})`;

  const [invoice, setInvoice] = useState<IInvoice>();
  const [detail, setDetail] = useState<IInvoiceDetail[]>([]);

  useEffect(() => {
    const readInvoice = async () => {
      const invoice = await getById(type as InvoiceType, invoiceId!);
      if (invoice) {
        setInvoice(invoice);
        setDetail(invoice.detalle);
      }
    };

    if (invoiceId && isValidType(type)) {
      readInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  const readCompany = async (companyId: string) => {
    setLoadingDetail(true);
    const company = await getCompany(companyId);
    if (company) {
      setInvoiceCompany(formTaxData, company);
    }
    setLoadingDetail(false);
  };

  const readPatient = async (recordId: string) => {
    setLoadingDetail(true);
    const patient = await getPatient(recordId);
    if (patient && patient.taxData && patient.taxData.length > 0) {
      setInvoicePatient(formTaxData, patient);
    }
    setLoadingDetail(false);
  };

  useEffect(() => {
    if (groupId && type === "company") {
      readCompany(groupId);
    }
    if (groupId && type === "request") {
      readPatient(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  if (!isValidType(type)) {
    return <Navigate to="/notFound" />;
  }

  if (invoiceId && !invoice) {
    return (
      <Center>
        <Spin spinning={true} tip="Cargando..." size="large"></Spin>
      </Center>
    );
  }

  return (
    <Spin spinning={loadingDetail}>
      <InvoiceHeader
        id={invoiceId}
        type={type}
        invoice={invoice}
        setInvoice={setInvoice}
        title={headerTitle}
        formInvoice={formInvoice}
        formTaxData={formTaxData}
        detail={detail}
        requests={requests}
      />
      <Divider className="header-divider" />
      <Space direction="vertical" size="middle">
        <InvoiceInfo id={invoiceId} form={formTaxData} type={type} invoice={invoice?.cliente} />
        <InvoiceData id={invoiceId} form={formInvoice} invoice={invoice} type={type} />
        <InvoiceConcept
          id={invoiceId}
          type={type}
          series={series}
          seriesNo={invoice?.serieNumero}
          invoice={invoice}
          detail={detail}
          setDetail={setDetail}
        />
      </Space>
    </Spin>
  );
};

export default observer(InvoiceDetail);
