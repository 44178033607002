import { Steps } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { Fragment } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { IRequestStudy } from "../../../app/models/request";
import { status } from "../../../app/util/catalogs";

type StatusTableProps = {
  currentStudy: IRequestStudy;
};

const StatusTable = ({ currentStudy }: StatusTableProps) => {
  const capturado = currentStudy.estatusId >= status.requestStudy.capturado;
  const validado = currentStudy.estatusId >= status.requestStudy.validado;
  const liberado = currentStudy.estatusId >= status.requestStudy.liberado;
  const enviado = currentStudy.estatusId >= status.requestStudy.enviado;
  const enRuta = currentStudy.estatusId == status.requestStudy.enRuta;

  return (
    <Fragment>
      <Steps
        initial={4}
        current={currentStudy.estatusId}
        size="small"
        items={
          enRuta
            ? [
                {
                  title: "",
                  description: "",
                  subTitle: "",
                  icon: (
                    <CheckCircleTwoTone
                      twoToneColor={!capturado ? "#f0f0f0" : "#1890FF"}
                    />
                  ),
                },
                {
                  title: "En Ruta",
                  description: "",
                  subTitle: "",
                  icon: <CheckCircleTwoTone twoToneColor="#1890FF" />,
                },
              ]
            : [
                {
                  title: "Capturado",
                  subTitle: capturado ? (currentStudy.claveUsuarioCaptura ?? "") + " " + moment(currentStudy.fechaCaptura).format("DD/MM/YYYY HH:mm") : "",
                  icon: (
                    <CheckCircleTwoTone
                      twoToneColor={!capturado ? "#f0f0f0" : "#1890FF"}
                    />
                  ),
                },
                {
                  title: "Validado",
                  subTitle:
                    validado && !enRuta ? (currentStudy.claveUsuarioValidacion ?? "") + " " + moment(currentStudy.fechaValidacion).format("DD/MM/YYYY HH:mm") : "",
                  icon: (
                    <CheckCircleTwoTone
                      twoToneColor={!validado || enRuta ? "#f0f0f0" : "#1890FF"}
                    />
                  ),
                },
                {
                  title: "Liberación",
                  subTitle:
                    liberado && !enRuta ? (currentStudy.claveUsuarioLiberado ?? "") + " " + moment(currentStudy.fechaLiberado).format("DD/MM/YYYY HH:mm") : "",
                  icon: (
                    <CheckCircleTwoTone
                      twoToneColor={!liberado || enRuta ? "#f0f0f0" : "#1890FF"}
                    />
                  ),
                },
                {
                  title: "Enviado",
                  subTitle:
                    enviado && !enRuta ? (currentStudy.claveUsuarioEnviado ?? "") + " " + moment(currentStudy.fechaEnviado).format("DD/MM/YYYY HH:mm") : "",
                  icon: (
                    <CheckCircleTwoTone
                      twoToneColor={!enviado || enRuta ? "#f0f0f0" : "#1890FF"}
                    />
                  ),
                },
              ]
        }
      />
    </Fragment>
  );
};

export default observer(StatusTable);
