import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MaskInput from "../../../../app/common/form/proposal/MaskInput";
import SelectTagInput from "../../../../app/common/form/proposal/SelectTagInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import {
  IContact,
  IInvoiceDeliveryInfo,
} from "../../../../app/models/invoiceLegacy";
import { IFormError } from "../../../../app/models/shared";
import { useStore } from "../../../../app/stores/store";
import { formItemLayout, validateEmail } from "../../../../app/util/utils";

const { Text } = Typography;
// type UrlParams = {
//   id: string;
//   tipo: string;
// };
type InvoiceCompanyDeliverType = {
  companiaId: string;
  facturapiId: string;
  id: string;
  tipo: string;
  expedienteId: string;
  solicitudId: string;
  invoiceFull: any;
};
const InvoiceCompanyDeliver = ({
  companiaId,
  facturapiId,
  tipo,
  id,
  expedienteId,
  solicitudId,
  invoiceFull,
}: InvoiceCompanyDeliverType) => {
  // let { id, tipo } = useParams<UrlParams>();
  const {
    companyStore,
    invoiceCompanyStoreLegacy: invoiceCompanyStore,
    requestStore,
  } = useStore();
  const { getContactsByCompany, contactos } = companyStore;
  const { getGeneral } = requestStore;
  const { isLoadingDeliver, sendInvoice, selectedRows, invoice, scopes } =
    invoiceCompanyStore;
  const [form] = Form.useForm<any>();
  const email = Form.useWatch("correo", form);
  const sendings = Form.useWatch("metodoEnvio", form);
  const emails = Form.useWatch("correos", form);
  const whatsapps = Form.useWatch("whatsapps", form);
  const whatsapp = Form.useWatch("whatsapp", form);
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [mediosEnvios, setMediosEnvios] = useState<any[]>([]);
  const [telefono, setTelefono] = useState<string>();
  const [correo, setCorreo] = useState<string>();
  const [isSelectedContacts, setIsSelectedContacts] = useState<boolean>(true);
  const [previousSendings, setPreviousSendings] = useState<string[]>([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidWhatsapp, setIsValidWhatsapp] = useState(false);

  useEffect(() => {
    setIsValidEmail(
      !!emails && emails.length > 0 && emails.every(validateEmail)
    );
    setIsValidWhatsapp(
      !!whatsapps &&
      whatsapps.length > 0 &&
      whatsapps.every(
        (whatsapp: any) =>
          (whatsapp ?? "").replaceAll("-", "").replaceAll("_", "").length ===
          10
      )
    );
  }, [emails, whatsapps]);
  useEffect(() => {
    const getRequestGeneral = async () => {
      const requestGeneral = await getGeneral(expedienteId, solicitudId!);
      if (requestGeneral) {
        form.setFieldsValue(requestGeneral);
      }
    };

    if (solicitudId && expedienteId) {
      getRequestGeneral();
    }
  }, []);
  useEffect(() => {
    if (!!companiaId) {
      getContactsByCompany(companiaId);
    }
  }, []);
  const sendOptions = [
    { label: "Mandar vía correo electronico", value: "correo" },
    { label: "Mandar vía Whatsapp", value: "whatsapp" },
    { label: "Ambos", value: "ambos" },
  ];
  const columns: any[] = [
    {
      key: "nombre",
      dataIndex: "nombre",
      title: "Nombre/Depto.",
      align: "center",
      width: 100,
      //   render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "telefono",
      dataIndex: "telefono",
      title: "Whatsapp",
      align: "center",
      width: 100,
      //   render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "correo",
      dataIndex: "correo",
      title: "Correo",
      align: "center",
      width: 100,
      //   render: (value) => (value ? "Sí" : "No"),
    },
  ];

  const sendEmail = async () => {
    // if (request && emails) {
    if (emails) {
      // await sendTestEmail(request.expedienteId, request.solicitudId!, emails);
      for (const currentEmail of emails) {
        let sendInvoiceData: IInvoiceDeliveryInfo = {
          contactos: [
            {
              nombre: "",
              telefono: "",
              correo: currentEmail,
            },
          ],
          nombreArchivo: `${invoiceFull.serie}-${invoiceFull.consecutivo}`,
          mediosEnvio: ["correo"],
          facturapiId: invoice?.facturaId!,
          esPrueba: true,
        };
        await sendInvoice(sendInvoiceData);
      }

      // message.info("Correos enviados exitosamente");

      message.info("Envío realizado correctamente");
    }
  };

  const sendWhatsapp = async () => {
    // if (request && whatsapps) {
    if (whatsapps) {
      for (const currentWhats of whatsapps) {
        let sendInvoiceData: IInvoiceDeliveryInfo = {
          contactos: [
            {
              nombre: "",
              telefono: currentWhats,
              correo: "",
            },
          ],
          nombreArchivo: `${invoiceFull.serie}-${invoiceFull.consecutivo}`,
          mediosEnvio: ["whatsapp"],
          facturapiId: invoice?.facturaId!,
          esPrueba: true,
        };
        await sendInvoice(sendInvoiceData);
      }
      // message.info("Mensajes enviados exitosamente");
    }
  };
  const onValuesChange = (changedValues: any) => {
    const path = Object.keys(changedValues)[0];

    if (path === "metodoEnvio") {
      const sendings: string[] = changedValues[path];
      let metodoEnvio: string[] = [];

      if (previousSendings.includes("ambos") && !sendings.includes("ambos")) {
        metodoEnvio = [];
        form.setFieldsValue({ correos: [], whatsapps: [] });
      } else if (
        !previousSendings.includes("ambos") &&
        sendings.includes("ambos")
      ) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else if (sendings.length === 2 && !sendings.includes("ambos")) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else {
        metodoEnvio = sendings.filter((x) => x !== "ambos");
      }

      if (!sendings.includes("correo")) {
        form.setFieldsValue({ correos: [] });
      }
      if (!sendings.includes("whatsapp")) {
        form.setFieldsValue({ whatsapps: [] });
      }

      form.setFieldsValue({ metodoEnvio });
      setPreviousSendings(metodoEnvio);
    }
  };
  return (
    <>
      <Spin spinning={isLoadingDeliver}>
        <Row>
          <Col span={9}>
            <Text>Seleccionar contacto de agenda</Text>
          </Col>
          {tipo !== "request" && (
            <Col>
              <Switch
                defaultChecked
                onChange={(checked) => setIsSelectedContacts(checked)}
              ></Switch>
            </Col>
          )}
        </Row>
        <Form<any>
          {...formItemLayout}
          form={form}
          onFinishFailed={({ errorFields }) => {
            const errors = errorFields.map((x) => ({
              name: x.name[0].toString(),
              errors: x.errors,
            }));
            setErrors(errors);
          }}
          initialValues={{
            metodoEnvio: [],
            correos: [],
            whatsapps: [],
          }}
          onValuesChange={onValuesChange}
          size="small"
        >
          {isSelectedContacts && tipo !== "request" ? (
            <Col span={24}>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (newSelectedRowKeys) => {
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                }}
                dataSource={contactos}
              />
            </Col>
          ) : (
            <>
              <Col span={24} style={{ textAlign: "start" }}>
                <Form.Item
                  noStyle
                  name="metodoEnvio"
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Checkbox.Group options={sendOptions} />
                </Form.Item>
              </Col>
              <br />
              <Col span={24}>
                <Form.Item
                  label="E-Mail"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  className="no-error-text"
                  help=""
                  required={sendings?.includes("correo")}
                >
                  <Input.Group>
                    <Row>
                      <Col span={12}>
                        <SelectTagInput
                          formProps={{
                            name: "correos",
                            label: "E-Mail",
                            noStyle: true,
                          }}
                          regex={
                            /^([A-Za-z0-9_.-]+)@([\dA-Za-z.-]+)\.([A-Za-z.]{2,6})$/
                          }
                          readonly={!sendings?.includes("correo")}
                          required={sendings?.includes("correo")}
                          errors={
                            errors.find((x) => x.name === "correos")?.errors
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Button
                          type="primary"
                          disabled={
                            (!sendings?.includes("correo") || !isValidEmail) &&
                            scopes.enviarCorreo
                          }
                          onClick={sendEmail}
                        >
                          Prueba
                        </Button>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
              </Col>
              <br></br>
              <Col span={24}>
                <Form.Item
                  label="Whatsapp"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  className="no-error-text"
                  help=""
                  required={sendings?.includes("whatsapp")}
                >
                  <Input.Group>
                    <Row>
                      <Col span={12}>
                        <SelectTagInput
                          formProps={{
                            name: "whatsapps",
                            label: "Whatsapp",
                            noStyle: true,
                          }}
                          regex={
                            /^([0-9]{3})-?([0-9]{3})-?([0-9]{2})-?([0-9]{2})$/
                          }
                          readonly={!sendings?.includes("whatsapp")}
                          required={sendings?.includes("whatsapp")}
                          errors={
                            errors.find((x) => x.name === "whatsapps")?.errors
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Button
                          type="primary"
                          disabled={
                            (!sendings?.includes("whatsapp") ||
                              !isValidWhatsapp) &&
                            scopes.enviarWapp
                          }
                          onClick={sendWhatsapp}
                        >
                          Prueba
                        </Button>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
              </Col>
            </>
          )}
        </Form>
        <br />
        <Row>
          <Col span={24}>
            <Button
              disabled={!scopes.enviarCorreo && !scopes.enviarWapp}
              onClick={async () => {
                if (isSelectedContacts && tipo !== "request") {
                  let sendInvoiceData: IInvoiceDeliveryInfo = {
                    contactos:
                      isSelectedContacts && tipo !== "request"
                        ? contactos
                          .filter((contacto: any) =>
                            selectedRowKeys?.includes(contacto.id)
                          )
                          .map((contacto) => ({
                            ...contacto,
                            telefono: "" + contacto.telefono,
                          }))
                        : [
                          {
                            nombre: "",
                            telefono: whatsapp,
                            correo: email,
                          },
                        ],
                    nombreArchivo: `${invoiceFull.serie}-${invoiceFull.consecutivo}`,
                    mediosEnvio: ["whatsapp", "correo"],
                    facturapiId: invoice?.facturaId!,
                    esPrueba: false,
                  };
                  sendInvoice(sendInvoiceData);
                  message.info("Envío realizado correctamente");
                } else {
                  if (whatsapps) {
                    for (const currentWhats of whatsapps) {
                      let sendInvoiceData: IInvoiceDeliveryInfo = {
                        contactos: [
                          {
                            nombre: "",
                            telefono: currentWhats,
                            correo: "",
                          },
                        ],
                        nombreArchivo: `${invoiceFull.serie}-${invoiceFull.consecutivo}`,
                        mediosEnvio: ["whatsapp"],
                        facturapiId: invoice?.facturaId!,
                        esPrueba: false,
                      };
                      await sendInvoice(sendInvoiceData);
                    }
                  }
                  if (emails) {
                    // await sendTestEmail(request.expedienteId, request.solicitudId!, emails);
                    for (const currentEmail of emails) {
                      let sendInvoiceData: IInvoiceDeliveryInfo = {
                        contactos: [
                          {
                            nombre: "",
                            telefono: "",
                            correo: currentEmail,
                          },
                        ],
                        nombreArchivo: `${invoiceFull.serie}-${invoiceFull.consecutivo}`,
                        mediosEnvio: ["correo"],
                        facturapiId: invoice?.facturaId!,
                        esPrueba: false,
                      };
                      await sendInvoice(sendInvoiceData);
                    }
                    message.info("Envío realizado correctamente");
                  }
                }
              }}
            >
              Enviar
            </Button>{" "}
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default observer(InvoiceCompanyDeliver);
