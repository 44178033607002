import moment from "moment";
import { ITagStudies, ITagTrackingOrder } from "./routeTracking";
import { IStudyTrackinOrder } from "./trackingOrder";
import { IRouteForm } from "./route";

export interface IShipmentTags {
  id: string;
  claveEtiqueta: string;
  recipiente: string;
  cantidad: number;
  estudios: string;
  solicitud: string;
  estatus: number;
  paciente: string;
  confirmacionOrigen: boolean;
  confirmacionDestino: boolean;
  tipoDestino: number;
}

export interface IReceiveTags {
  id: string;
  etiquetaId: number;
  claveEtiqueta: string;
  claveRuta: string;
  recipiente: string;
  cantidad: number;
  estudios: string;
  solicitud: string;
  solicitudId: string;
  estatus: number;
  escaneo: boolean;
  escaneoEntrega: boolean;
  extra: boolean;
  paciente: string;
  confirmacionOrigen?: boolean;
  confirmacionDestino?: boolean;
  tipoDestino?: number;
  destinos: IDestinationTagTracking[];
  estudiosId: ITagStudies[];
}

export interface IShipmentTracking {
  id: string;
  origen: string;
  destino: string;
  emisor: string;
  receptor: string;
  paqueteria: string;
  fechaEnvio: moment.Moment;
  fechaEstimada: moment.Moment;
  fechaReal: moment.Moment;
  estudios: IShipmentTags[];
  seguimiento: string;
  ruta: string;
  nombre: string;
  activo: boolean;
  estatus: number;
  extra: boolean;
}

export interface IReceiveTracking {
  id: string;
  origen: string;
  destino: string;
  destinoId: string;
  emisor: string;
  receptor: string;
  paqueteria: string;
  fechaEnvio: moment.Moment;
  fechaEstimada: moment.Moment;
  fechaReal: moment.Moment;
  estudios: IStudyTrackinOrder[];
  etiquetas: IReceiveTags[];
  seguimiento: string;
  ruta: string;
  nombre: string;
  activo: boolean;
  estatus: number;
  extra: boolean;
  temperatura: number;
  temperaturaEntrega: number;
  escaneoEntrega: boolean;
}

export interface IDestinationTagTracking {
  rutaId: string;
  nombreRuta: string;
  destinoId: string;
  claveRuta: string;
}

export class ReceiveTrackingFormValues implements IReceiveTracking {
  id = "";
  origen = "";
  destino = "";
  destinoId = "";
  emisor = "";
  receptor = "";
  paqueteria = "";
  fechaEnvio = moment().utcOffset(0, true);
  fechaEstimada = moment().utcOffset(0, true);
  fechaReal = moment().utcOffset(0, true);
  estudios: IStudyTrackinOrder[] = [];
  etiquetas: IReceiveTags[] = [];
  seguimiento = "";
  ruta = "";
  nombre = "";
  activo = true;
  estatus = 0;
  extra = false;
  temperatura = 0;
  temperaturaEntrega = 0;
  escaneoEntrega = false;

  constructor(init?: IReceiveTracking) {
    Object.assign(this, init);
  }
}
