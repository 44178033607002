import "./css/index.css";

import { Button, Checkbox, Col, Form, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import TextInput from "../../app/common/form/proposal/TextInput";
import {
  IInvoiceCatalogFilter,
  InvoiceCatalogFilterValues,
} from "../../app/models/invoiceCatalog";
import { useStore } from "../../app/stores/store";
import alerts from "../../app/util/alerts";
import { formItemLayout } from "../../app/util/utils";

const typeOptions = [
  { label: "Facturas", value: "invoice" },
  { label: "Recibos", value: "ticket" },
];

type Props = {
  setLoading: (loading: boolean) => void;
};

const InvoiceCatalogFilter = ({ setLoading }: Props) => {
  const { profileStore, optionStore, invoiceCatalogStore } = useStore();
  const { profile } = profileStore;
  const {
    branchCityOptions,
    companyOptions,
    getBranchCityOptions,
    getCompanyOptions,
  } = optionStore;
  const { filter, setFilter, setInvoices, getAll } = invoiceCatalogStore;

  const [form] = useForm<IInvoiceCatalogFilter>();

  useEffect(() => {
    if (branchCityOptions.length === 0) getBranchCityOptions();
    if (companyOptions.length === 0) getCompanyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const read = async (filter: IInvoiceCatalogFilter) => {
    setLoading(true);
    const invoices = await getAll(filter);
    setInvoices(invoices);
    setLoading(false);
  };

  useEffect(() => {
    if (filter.cargaInicial) {
      const newFilter = {
        ...filter,
        sucursales: [profile!.sucursal],
        cargaInicial: false,
      };
      setFilter(newFilter);
      form.setFieldsValue(newFilter);
      read(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: IInvoiceCatalogFilter) => {
    if (values.tipo.length === 0) {
      alerts.warning("Por favor selecciona una tipo");
      return;
    }

    if (values.fechas && values.fechas.length === 0) {
      values.fechas[0] = values.fechas[0].utcOffset(0, true);
      values.fechas[1] = values.fechas[1].utcOffset(0, true);
    }

    values.facturas = values.tipo.includes("invoice");
    values.recibos = values.tipo.includes("ticket");
    setFilter(values);
    read(values);
  };

  const resetFilter = () => {
    const clear = new InvoiceCatalogFilterValues();
    clear.sucursales = [profile!.sucursal];
    clear.cargaInicial = false;
    setFilter(clear);
    form.setFieldsValue(clear);
  };

  return (
    <div className="status-container">
      <Form<IInvoiceCatalogFilter>
        {...formItemLayout}
        form={form}
        name="invoiceCatalog"
        onFinish={onFinish}
        size="small"
        initialValues={filter}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <DateRangeInput
              formProps={{
                name: "fechas",
                label: "Fechas",
              }}
              disableAfterDates
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Clave",
              }}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              label="Tipo"
              name="tipo"
              help=""
              className="no-error-text"
              required
            >
              <Checkbox.Group options={typeOptions} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{
                name: "sucursales",
                label: "Sucursales",
              }}
              options={branchCityOptions}
              multiple
              selectOnlyChildren
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{
                name: "compañias",
                label: "Compañías",
              }}
              options={companyOptions}
              multiple
            />
          </Col>
          <Col span={8} style={{ textAlign: "end" }}>
            <Button onClick={resetFilter}>Limpiar</Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(InvoiceCatalogFilter);
