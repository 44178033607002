import {
  Button,
  Col,
  Form,
  FormInstance,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { SearchOutlined } from "@ant-design/icons";

import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { IInvoiceClient } from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import { regimenFiscal } from "../../../../app/util/catalogs";
import { formItemLayout } from "../../../../app/util/utils";
import { getCompanyTaxData } from "../utils/getCompanyData";
import { InvoiceType, setInvoiceCompany } from "../utils/utils";

const { Title } = Typography;

const formInputStyle: React.CSSProperties = {
  marginBottom: 8,
};

type Props = {
  id: string | undefined;
  form: FormInstance<IInvoiceClient>;
  type: InvoiceType;
  invoice: IInvoiceClient | undefined;
};

const InvoiceInfo = ({ id, form, type, invoice }: Props) => {
  const { locationStore } = useStore();
  const { getState } = locationStore;

  const [taxType, setTaxType] = useState<"company" | "patient">(
    invoice && invoice.compañiaId
      ? "company"
      : invoice || type === "request"
        ? "patient"
        : "company"
  );

  const onChange = (e: RadioChangeEvent) => {
    form.resetFields();
    setTaxType(e.target.value);
  };

  const title =
    taxType === "company" ? "Datos de la compañia" : "Datos del cliente";
  const isCompany = taxType === "company";
  const readonly = !!id || isCompany;

  const selectCompany = async () => {
    const company = await getCompanyTaxData();
    if (!company) return;

    setInvoiceCompany(form, company);
  };

  const clearLocation = () => {
    form.setFieldsValue({
      estado: undefined,
      ciudad: undefined,
    });
  };

  const onValuesChange = async (changedValues: any) => {
    const field = Object.keys(changedValues)[0];

    if (field === "codigoPostal") {

      const zipCode = changedValues[field] as string;

      if (zipCode && zipCode.trim().length === 5) {
        const location = await getState(zipCode);
        if (location) {
          form.setFieldsValue({
            estado: location.estado.toUpperCase(),
            ciudad: location.ciudad.toUpperCase(),
          });
        } else {
          clearLocation();
        }
      } else {
        clearLocation();
      }
    }
  };

  return (
    <div className="status-container" style={{ marginBottom: 0 }}>
      <Row>
        <Col span={12}>
          <Title level={5}>{title}</Title>
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          {type === "free" && isCompany && !id && (
            <Button
              shape="round"
              icon={<SearchOutlined />}
              onClick={selectCompany}
              style={{ marginRight: 12 }}
            >
              Buscar compañía
            </Button>
          )}
          <Radio.Group
            className={!!id || type !== "free" ? "unclickable" : ""}
            onChange={onChange}
            value={taxType}
          >
            <Radio value="company">Compañía</Radio>
            <Radio value="patient">Paciente</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Form<IInvoiceClient>
        form={form}
        {...formItemLayout}
        name="invoice"
        size="small"
        initialValues={invoice}
        onValuesChange={onValuesChange}
      >
        <Row justify="center" gutter={[12, 12]}>
          <Col span={8}>
            {isCompany && (
              <TextInput
                formProps={{
                  name: "compañiaId",
                  style: { display: "none" },
                }}
                readonly={readonly}
              />
            )}
            <TextInput
              formProps={{ label: "Nombre", name: "nombre" }}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <TextInput
              formProps={{ label: "C.P.", name: "codigoPostal" }}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <TextInput
              formProps={{ label: "Estado", name: "estado" }}
              style={formInputStyle}
              readonly={readonly}
            />
            <TextInput
              formProps={{ label: "Ciudad", name: "ciudad" }}
              style={formInputStyle}
              readonly={readonly}
            />
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{ label: "RFC", name: "rfc" }}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <TextInput
              formProps={{ label: "Razón social", name: "razonSocial" }}
              style={formInputStyle}
              required
              readonly={readonly}
            />
            <SelectInput
              formProps={{
                label: "Régimen fiscal",
                name: "regimenFiscal",
              }}
              options={regimenFiscal}
              style={formInputStyle}
              required
              readonly={readonly}
            />
          </Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(InvoiceInfo);
