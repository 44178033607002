import { PageHeader } from "antd";

import HeaderTitle from "../../app/common/header/HeaderTitle";
import DownloadIcon from "../../app/common/icons/DownloadIcon";
import { useStore } from "../../app/stores/store";

type Props = {
  setLoading: (loading: boolean) => void;
};

const InvoiceCatalogHeader = ({ setLoading }: Props) => {
  const { invoiceCatalogStore } = useStore();
  const { filter, downloadList, scopes } = invoiceCatalogStore;

  const download = async () => {
    setLoading(true);
    await downloadList(filter);
    setLoading(false);
  };

  return (
    <PageHeader
      ghost
      title={
        <HeaderTitle title="Catálogo de facturas y recibos" image="facturas" />
      }
      className="header-container"
      extra={[
        scopes.descargar && <DownloadIcon key="download" onClick={download} />,
      ]}
    ></PageHeader>
  );
};

export default InvoiceCatalogHeader;
