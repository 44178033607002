import { Button, Col, Divider, Form, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ItipoValorForm } from "../../../../app/models/parameter";
import { useSearchParams } from "react-router-dom";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const NumeroColumna: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [formValue] = Form.useForm<ItipoValorForm[]>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "ReadOnly"
  );

  useEffect(() => {
    setReadonly(searchParams.get("mode") === "ReadOnly");
  }, [searchParams]);

  const onValuesChange = (_: any, values: any) => {
    setParameterValues(
      values.value
        .filter((x: any) => x != null)
        .map((x: ItipoValorForm, i: any) => ({
          ...x,
          nombre: valueType,
          orden: i + 1,
        }))
    );
  };

  return (
    <div>
      <Divider orientation="left">
        Valores de referencia (Numérico por columna):
      </Divider>
      <Form<any[]>
        form={formValue}
        name={`form-${valueType}`}
        style={{ marginTop: 20 }}
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{ value: parameterValues }}
      >
        <Form.List name="value">
          {(Fields, { add, remove }) => (
            <>
              {Fields.map(({ key, name, ...valuesValor }) => (
                <Row key={key} gutter={12}>
                  <Form.Item hidden name={[name, "id"]}>
                    <InputNumber disabled={true} />
                  </Form.Item>
                  <Col>
                    <Form.Item
                      {...valuesValor}
                      label={"Valor " + (name + 1)}
                      name={[name, "primeraColumna"]}
                    >
                      <TextArea
                        disabled={readonly}
                        style={{ width: 350 }}
                        autoSize
                        placeholder={"Valor"}
                      />
                    </Form.Item>
                  </Col>
                  {parseInt(valueType) === 6 && (
                    <Col>
                      <Button
                        type="text"
                        disabled={readonly}
                        onClick={() => remove(name)}
                        icon={<MinusCircleOutlined />}
                      ></Button>
                    </Col>
                  )}
                  {parseInt(valueType) >= 11 && (
                    <>
                      <Col>
                        <Form.Item
                          {...valuesValor}
                          name={[name, "segundaColumna"]}
                        >
                          <TextArea
                            disabled={readonly}
                            style={{ width: 350 }}
                            autoSize
                            placeholder={"Valor"}
                          />
                        </Form.Item>
                      </Col>
                      {parseInt(valueType) === 11 && (
                        <Col>
                          <Button
                            type="text"
                            disabled={readonly}
                            onClick={() => remove(name)}
                            icon={<MinusCircleOutlined />}
                          ></Button>
                        </Col>
                      )}
                    </>
                  )}
                  {parseInt(valueType) >= 12 && (
                    <>
                      <Col>
                        <Form.Item
                          {...valuesValor}
                          name={[name, "terceraColumna"]}
                        >
                          <TextArea
                            disabled={readonly}
                            style={{ width: 350 }}
                            autoSize
                            placeholder={"Valor"}
                          />
                        </Form.Item>
                      </Col>
                      {parseInt(valueType) === 12 && (
                        <Col>
                          <Button
                            type="text"
                            disabled={readonly}
                            onClick={() => remove(name)}
                            icon={<MinusCircleOutlined />}
                          ></Button>
                        </Col>
                      )}
                    </>
                  )}
                  {parseInt(valueType) >= 13 && (
                    <>
                      <Col>
                        <Form.Item
                          {...valuesValor}
                          name={[name, "cuartaColumna"]}
                        >
                          <TextArea
                            disabled={readonly}
                            style={{ width: 350 }}
                            autoSize
                            placeholder={"Valor"}
                          />
                        </Form.Item>
                      </Col>
                      {parseInt(valueType) === 13 && (
                        <Col>
                          <Button
                            type="text"
                            disabled={readonly}
                            onClick={() => remove(name)}
                            icon={<MinusCircleOutlined />}
                          ></Button>
                        </Col>
                      )}
                    </>
                  )}
                  {valueType === "14" && (
                    <>
                      <Col>
                        <Form.Item
                          {...valuesValor}
                          name={[name, "quintaColumna"]}
                        >
                          <TextArea
                            disabled={readonly}
                            style={{ width: 350 }}
                            autoSize
                            placeholder={"Valor"}
                          />
                        </Form.Item>
                      </Col>
                      {parseInt(valueType) === 14 && (
                        <Col>
                          <Button
                            type="text"
                            disabled={readonly}
                            onClick={() => remove(name)}
                            icon={<MinusCircleOutlined />}
                          ></Button>
                        </Col>
                      )}
                    </>
                  )}
                  {/* </Space> */}
                </Row>
              ))}
              <Form.Item>
                <Button
                  disabled={readonly}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar campo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};
export default observer(NumeroColumna);
