import { makeAutoObservable, reaction } from "mobx";
import PriceList from "../api/priceList";
import Proceding from "../api/proceding";
import Appointment from "../api/appointment";
import { IGeneralForm } from "../models/general";
import { IPriceListInfoFilter } from "../models/priceList";
import {
  IAppointment,
  IAppointmentGeneral,
  IAppointmentInfo,
  IAppointmentPack,
  IAppointmentStudy,
  IAppointmentStudyUpdate,
  IAppointmentTotal,
  AppointmentStudyUpdate,
  AppointmentTotal,
  IAppointmentEvent,
} from "../models/appointment";
import alerts from "../util/alerts";
import { status } from "../util/catalogs";
import history from "../util/history";
import messages from "../util/messages";
import { getErrors } from "../util/utils";
import moment from "moment";
import { IScopes, Scopes } from "../models/shared";

export default class AppointmentStore {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.studies.slice(),
      (studies) => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.packs.slice(),
      (packs) => {
        this.calculateTotals();
      }
    );
  }

  scopes: IScopes = new Scopes();
  studyFilter: IPriceListInfoFilter = {};
  appointments: IAppointmentInfo[] = [];
  appointment?: IAppointment;
  totals: IAppointmentTotal = new AppointmentTotal();
  studies: IAppointmentStudy[] = [];
  packs: IAppointmentPack[] = [];
  loadingAppointments: boolean = false;
  loadingTabContentCount: number = 0;
  typeFilter: "L" | "D" = "L";

  disabledClave: boolean = false;
  focusClave: boolean = false;
  focusType: string = "";

  setDisabledClave = (disabled: boolean) => {
    this.disabledClave = disabled;
  };
  setFocusType = (type: string) => {
    this.focusType = type;
  };

  setFocusClave = (disabled: boolean) => {
    this.focusClave = disabled;
  };

  get loadingTabContent() {
    return this.loadingTabContentCount > 0;
  }

  get studyUpdate() {
    if (this.appointment) {
      const data: IAppointmentStudyUpdate = {
        citaId: this.appointment.citaId,
        expedienteId: this.appointment.expedienteId,
        paquetes: this.packs,
        estudios: this.studies,
        total: {
          ...this.totals,
          citaId: this.appointment.citaId,
          expedienteId: this.appointment.expedienteId,
        },
      };
      return data;
    }
    return new AppointmentStudyUpdate();
  }

  get allStudies() {
    const packStudies = this.packs
      .flatMap((x) => x.estudios)
      .map((x) => {
        x.type = "pack";
        return x;
      });
    const studies = this.studies.map((x) => {
      x.type = "study";
      return x;
    });

    return [...studies, ...packStudies];
  }

  get readonly() {
    return !this.scopes.modificar || !this.appointment?.activo;
  }

  get cancelledAppointments() {
    return this.appointments
      .filter((x) => x.estatusId === status.appointment.cancelado)
      .map((x) => x.citaId);
  }

  clearDetailData = () => {
    this.appointment = undefined;
    this.studies = [];
    this.packs = [];
    this.totals = new AppointmentTotal();
  };

  isPack(obj: IAppointmentStudy | IAppointmentPack): obj is IAppointmentPack {
    return obj.type === "pack";
  }

  isStudy(obj: IAppointmentStudy | IAppointmentPack): obj is IAppointmentStudy {
    return obj.type === "study";
  }

  setStudyFilter = (
    branchId?: string,
    doctorId?: string,
    companyId?: string
  ) => {
    this.studyFilter = {
      sucursalId: branchId,
      medicoId: doctorId,
      compañiaId: companyId,
    };
  };

  setStudy = (study: IAppointmentStudy) => {
    const index = this.studies.findIndex((x) => x.id === study.id);

    if (index > -1) {
      this.studies[index] = study;
    }

    this.packs = this.packs.map((x) => {
      const index = x.estudios.findIndex((x) => x.id === study.id);
      if (index > -1) {
        x.estudios[index] = study;
      }
      return x;
    });
  };

  setPack = (pack: IAppointmentPack) => {
    const index = this.packs.findIndex((x) => x.id === pack.id);

    if (index > -1) {
      this.packs[index] = pack;
    }
  };

  setType = (type: "L" | "D") => {
    this.typeFilter = type;
  };

  getById = async (appointmentId: string) => {
    try {
      const appointment = await Appointment.getById(appointmentId);
      appointment.citas = appointment.citas?.map((x) => ({
        ...x,
        date: moment(x.date),
      }));
      this.appointment = appointment;
    } catch (error) {
      alerts.warning(getErrors(error));
      history.push("/notFound");
    }
  };

  getSchedule = async (filter: IGeneralForm) => {
    try {
      const events = await Appointment.getSchedule(filter);
      for (const event of events) {
        event.date = moment(event.date);
      }
      return events;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getAppointments = async (filter: IGeneralForm) => {
    try {
      this.loadingAppointments = true;
      const appointments = await Appointment.getAppointments(filter);
      this.appointments = appointments;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingAppointments = false;
    }
  };

  getLocalAppointments = async (filter: IGeneralForm) => {
    try {
      const appointments = await Appointment.getAppointments(filter);
      return appointments;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getGeneral = async (appointmentId: string) => {
    try {
      this.loadingTabContentCount++;
      const appointment = await Appointment.getGeneral(appointmentId);
      appointment.metodoEnvio = [];
      if (appointment.correo) {
        appointment.metodoEnvio.push("correo");
        appointment.correos = appointment.correo.split(",");
      }
      if (appointment.whatsapp) {
        appointment.metodoEnvio.push("whatsapp");
        appointment.whatsapps = appointment.whatsapp.split(",");
      }
      if (appointment.metodoEnvio.length === 2)
        appointment.metodoEnvio.push("ambos");
      return appointment;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getStudies = async (appointmentId: string) => {
    try {
      this.loadingTabContentCount++;
      const data = await Appointment.getStudies(appointmentId);
      if (data.paquetes && data.paquetes.length > 0) {
        this.packs = data.paquetes;
      }
      this.studies = data.estudios;
      this.totals = data.total ?? new AppointmentTotal();
      this.calculateTotals();
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getPriceStudy = async (studyId: number, filter: IPriceListInfoFilter) => {
    try {
      filter.estudioId = studyId;
      const price = await PriceList.getPriceStudy(filter);

      const study: IAppointmentStudy = {
        ...price,
        type: "study",
        aplicaCargo: false,
      };

      const repeated = this.studies.filter(function (item) {
        return (
          item.parametros
            .map((x) => x.id)
            .filter((x) => study.parametros.map((y) => y.id).indexOf(x) !== -1)
            .length > 0
        );
      });

      if (repeated && repeated.length > 0) {
        alerts.confirm(
          "Coincidencias en estudios",
          "Se encuentran coincidencias en parámetros de solicitud, en estudios: " +
            repeated.map((x) => x.clave).join(", "),
          async () => {
            this.studies.push(study);
          }
        );
      } else {
        this.studies.push(study);
      }

      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  getPricePack = async (packId: number, filter: IPriceListInfoFilter) => {
    try {
      filter.paqueteId = packId;
      const price = await PriceList.getPricePack(filter);

      const pack: IAppointmentPack = {
        ...price,
        type: "pack",
        aplicaCargo: false,
        estudios: price.estudios.map((x) => ({
          ...x,
          type: "study",
          aplicaCargo: false,
        })),
      };

      this.packs.push(pack);
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  getRecords = async (filter: IGeneralForm) => {
    try {
      const records = await Proceding.getNow(filter);
      return records;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  sendTestEmail = async (appointmentId: string, email: string[]) => {
    try {
      this.loadingTabContentCount++;
      await Appointment.sendTestEmail(appointmentId, email);
      alerts.info("El correo se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  sendTestWhatsapp = async (appointmentId: string, phone: string[]) => {
    try {
      this.loadingTabContentCount++;
      await Appointment.sendTestWhatsapp(appointmentId, phone);
      alerts.info("El whatsapp se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  create = async (appointment: IAppointment) => {
    try {
      const id = await Appointment.create(appointment);
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  schedule = async (schedule: IAppointmentEvent) => {
    try {
      await Appointment.schedule(schedule);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  convertToRequest = async (appointmentId: string) => {
    try {
      this.loadingTabContentCount++;
      const id = await Appointment.convertToRequest(appointmentId);
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  updateBranch = async (appointment: IAppointment) => {
    try {
      this.loadingTabContentCount++;
      const newCode = await Appointment.updateBranch(appointment);
      if (this.appointment) {
        this.appointment.sucursalId = appointment.sucursalId;
        this.appointment.clave = newCode.toString();
      }
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  updateGeneral = async (
    appointment: IAppointmentGeneral,
    autoSave: boolean
  ) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      await Appointment.updateGeneral(appointment);
      if (!autoSave) alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  reschedule = async (schedule: IAppointmentEvent) => {
    try {
      await Appointment.reschedule(schedule);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateStatus = async (appointment: IAppointmentInfo) => {
    try {
      await Appointment.updateStatus(appointment);
      const index = this.appointments.findIndex(
        (x) => x.citaId === appointment.citaId
      );
      if (index > -1) {
        const app = [...this.appointments];
        app[index].estatusId = appointment.estatusId;
        this.appointments = app;
      }
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  assignRecord = async (
    appointmentId: string,
    autoSave: boolean,
    recordId?: string
  ) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      await Appointment.assignRecord(appointmentId, recordId);
      if (!autoSave) alerts.success(messages.updated);
      if (this.appointment) this.appointment.expedienteId = recordId;
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  updateTotals = async (appointment: IAppointmentTotal) => {
    try {
      await Appointment.updateTotals(appointment);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateStudies = async (
    appointment: IAppointmentStudyUpdate,
    autoSave: boolean
  ) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      const response = await Appointment.updateStudies(appointment);
      this.packs = response.paquetes ?? [];
      this.studies = response.estudios;
      if (!autoSave) alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  changeStudyPromotion = (study: IAppointmentStudy, promoId?: number) => {
    const index = this.studies.findIndex(
      (x) => x.id === study.id && x.identificador === study.identificador
    );
    if (index > -1) {
      const _study = { ...this.studies[index] };
      const promo = this.studies[index].promociones.find(
        (x) => x.promocionId === promoId
      );
      this.studies[index] = {
        ..._study,
        promocionId: promoId,
        promocion: promo?.promocion,
        descuento: promo?.descuento,
        descuentoPorcentaje: promo?.descuentoPorcentaje,
        precioFinal: _study.precio - (promo?.descuento ?? 0),
      };
      this.calculateTotals();
    }
  };

  changePackPromotion = (pack: IAppointmentPack, promoId?: number) => {
    const index = this.packs.findIndex(
      (x) => x.id === pack.id && x.identificador === pack.identificador
    );
    if (index > -1) {
      const promo = this.packs[index].promociones.find(
        (x) => x.promocionId === promoId
      );
      this.packs[index] = {
        ...this.packs[index],
        promocionId: promoId,
        promocion: promo?.promocion,
        promocionDescuento: promo?.descuento,
        promocionDescuentoPorcentaje: promo?.descuentoPorcentaje,
      };
      this.calculateTotals();
    }
  };

  deleteStudy = async (id: string) => {
    this.studies = this.studies.filter((x) => x.identificador !== id);
  };

  deletePack = async (id: number | string) => {
    this.packs = this.packs.filter((x) => x.identificador !== id);
  };

  cancelAppointment = async (appointmentId: string) => {
    try {
      await Appointment.cancelAppointment(appointmentId);
      if (this.appointment) {
        this.appointment.estatusId = status.appointment.cancelado;
        this.appointment.activo = false;
      }
      const index = this.appointments.findIndex(
        (x) => x.citaId === appointmentId
      );
      if (index > -1) {
        const app = [...this.appointments];
        app[index].estatusId = status.appointment.cancelado;
        this.appointments = app;
      }
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteCurrentAppointment = async () => {
    try {
      if (this.appointment?.citaId) {
        await Appointment.deleteAppointment(this.appointment.citaId);
        this.appointments = this.appointments.filter(
          (x) => x.citaId !== this.appointment?.citaId
        );
        return true;
      }
      alerts.warning("No hay cotización por eliminar");
      return false;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteSchedule = async (scheduleId: string) => {
    try {
      await Appointment.deleteSchedule(scheduleId);
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteStudies = async (appointment: IAppointmentStudyUpdate) => {
    try {
      await Appointment.deleteStudies(appointment);
      alerts.success(messages.updated);

      this.studies = this.studies.filter((x) => {
        const exists = appointment.estudios.find(
          (s) => s.id === x.id && s.identificador === x.identificador
        );
        if (exists) return false;
        return true;
      });

      this.packs = this.packs.filter((x) => {
        const exists = appointment.paquetes?.find(
          (s) => s.id === x.id && s.identificador === x.identificador
        );
        if (exists) return false;
        return true;
      });

      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  getAppointmentPdf = async (id: string) => {
    try {
      const url = await Appointment.getQuotePdfUrl(id);
      return url;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  // prettier-ignore
  private calculateTotals = () => {
    const studies = this.studies;
    const packs = this.packs;

    const studyAndPack = studies.map((x) => ({ descuento: x.descuento ?? 0, precio: x.precio, precioFinal: x.precioFinal }))
      .concat(packs.map((x) => ({ descuento: x.descuento, precio: x.precio, precioFinal: x.precioFinal }))); 
    
    const totalStudies = studyAndPack.reduce((acc, obj) => acc + obj.precio, 0);
    
    const discount = totalStudies === 0 ? 0 : studyAndPack.reduce((acc, obj) => acc + obj.descuento, 0);
    
    const finalTotal = totalStudies - discount;
    
    this.totals = {
      ...this.totals,
      totalEstudios: totalStudies,
      descuento: discount,
      total: finalTotal,
    };
  };
}
