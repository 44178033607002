import { IGeneralForm } from "../models/general";
import { IRequestedStudyList, IUpdate, IUpdateDate } from "../models/requestedStudy";
import { IScopes } from "../models/shared";
import requests from "./agent";

const RequestedStudy = {
  access: (): Promise<IScopes> => requests.get("scopes/report"),
  getAll: (search: IGeneralForm): Promise<IRequestedStudyList[]> =>
    requests.post(`requestedstudy/getList`, search),
  update: (update: IUpdate[]): Promise<void> =>
    requests.put("requestedstudy", update),
  updateDate: (update: IUpdateDate): Promise<void> =>
    requests.put("requestedstudy/updateDate", update),
  getOrderPdf: (recordId: string, requestId: string): Promise<void> =>
    requests.print(`requestedstudy/order/${recordId}/${requestId}`),
  getOrderAsImage: (recordId: string, requestId: string): Promise<string> =>
    requests.printAsImage(`requestedstudy/orderAsImage/${recordId}/${requestId}`),
  exportList: (search: IGeneralForm): Promise<void> =>
    requests.download(`requestedstudy/export/list`, search),
};

export default RequestedStudy;
