import { Col, Row } from "antd";
import { IRequestStudy } from "../../app/models/request";
import { status } from "../../app/util/catalogs";

export const updateStatus = async (
  esCancelacion: boolean = false,
  currentStudy: IRequestStudy,
  updateStatusStudy: (
    id: number,
    status: number,
    branch?: string
  ) => Promise<true | undefined>,
  cancelation: (status: number) => Promise<boolean | undefined>,
  removeSelectedStudy: (study: {
    id: number;
    tipo: string;
    orden: number;
    estatusId: number;
  }) => void,
  setCheckedPrint: (value: boolean) => void,
  setNewStatus?: (value: number) => void,
  activeBranch?: string
) => {
  let nuevoEstado = 0;

  if (currentStudy.estatusId === status.requestStudy.solicitado) {
    setNewStatus && setNewStatus(status.requestStudy.capturado);
    return await updateStatusStudy(
      currentStudy.id!,
      status.requestStudy.capturado,
      activeBranch
    );
  }
  if (currentStudy.estatusId === status.requestStudy.capturado) {
    nuevoEstado = esCancelacion
      ? status.requestStudy.solicitado
      : status.requestStudy.validado;
    setNewStatus && setNewStatus(nuevoEstado);

    return await updateStatusStudy(currentStudy.id!, nuevoEstado, activeBranch);
  }
  if (currentStudy.estatusId === status.requestStudy.validado) {
    nuevoEstado = esCancelacion
      ? status.requestStudy.capturado
      : status.requestStudy.liberado;
    setNewStatus && setNewStatus(nuevoEstado);

    return await updateStatusStudy(currentStudy.id!, nuevoEstado);
  }
  if (currentStudy.estatusId === status.requestStudy.liberado) {
    nuevoEstado = esCancelacion
      ? status.requestStudy.validado
      : status.requestStudy.enviado;
    setNewStatus && setNewStatus(nuevoEstado);

    return await updateStatusStudy(currentStudy.id!, nuevoEstado);
  }
  if (esCancelacion) {
    removeSelectedStudy({
      id: currentStudy.id!,
      tipo: "LABORATORY",
      orden: currentStudy.ordenEstudio,
      estatusId: currentStudy.estatusId,
    });
    await cancelation(nuevoEstado);
    setCheckedPrint(false);
  }
  return nuevoEstado;
};

export const referenceValues = (
  tipoValor: string,
  valorInicial?: string,
  valorFinal?: string,
  primeraColumna?: string,
  segundaColumna?: string,
  terceraColumna?: string,
  cuartaColumna?: string,
  quintaColumna?: string
) => {
  if (
    tipoValor === "1" ||
    tipoValor === "2" ||
    tipoValor === "3" ||
    tipoValor === "4"
  ) {
    return (
      <Row>
        <Col span={11} style={{ textAlign: "end" }}>
          {valorInicial}
        </Col>
        <Col span={2}>-</Col>
        <Col span={11} style={{ textAlign: "start" }}>
          {valorFinal}
        </Col>
      </Row>
    );
  } else if (
    tipoValor === "5" ||
    tipoValor === "7" ||
    tipoValor === "8" ||
    tipoValor === "10"
  ) {
    return valorInicial + "";
  } else if (tipoValor === "11") {
    return primeraColumna + " - " + segundaColumna + "\n";
  } else if (tipoValor === "12") {
    return `${primeraColumna} \t ${segundaColumna} \t ${terceraColumna}`;
  } else if (tipoValor === "13") {
    return `${primeraColumna} \t ${segundaColumna} \t ${terceraColumna} \t ${cuartaColumna}`;
  } else if (tipoValor === "14") {
    return `${primeraColumna} \t ${segundaColumna} \t ${terceraColumna} \t ${cuartaColumna} \t ${quintaColumna}`;
  }
};
