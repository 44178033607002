import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";

const AppointmentPrint = () => {
  const { appointmentStore } = useStore();
  const { appointment, totals, getAppointmentPdf } = appointmentStore;

  const [appointmentUrl, setAppointmentUrl] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUrl = async () => {
      if (appointment) {
        setLoading(true);
        const url = await getAppointmentPdf(appointment.citaId);
        setAppointmentUrl(url);
        setLoading(false);
      }
    };

    getUrl();
  }, [getAppointmentPdf, appointment, totals]);

  return (
    <Spin spinning={loading}>
      <object
        data={appointmentUrl}
        type="application/pdf"
        width="100%"
        height="600"
      >
        alt : <a href={appointmentUrl}>recibo.pdf</a>
      </object>
    </Spin>
  );
};

export default observer(AppointmentPrint);
