import { message } from "antd";
import { makeAutoObservable } from "mobx";

import { InvoiceType } from "../../components/invoice/invoice/utils/utils";
import Invoice from "../api/invoice";
import { IInvoiceCancelation, IInvoiceSend } from "../models/invoice";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";

export default class InvoiceStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  loadingDetail: boolean = false;

  setLoadingDetail = (loading: boolean) => {
    this.loadingDetail = loading;
  };

  getById = async (type: InvoiceType, invoiceId: string) => {
    try {
      const invoice = await Invoice.getById(type, invoiceId);
      return invoice;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  cancel = async (type: InvoiceType, invoice: IInvoiceCancelation) => {
    try {
      await Invoice.cancel(type, invoice);
      message.success("La factura se canceló con éxito")
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  send = async (type: InvoiceType, invoice: IInvoiceSend) => {
    try {
      await Invoice.send(type, invoice);
      alerts.info("El información se está enviando");
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  printPDF = async (type: InvoiceType, invoiceId: string) => {
    try {
      this.loadingDetail = true;
      await Invoice.printPDF(type, invoiceId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingDetail = false;
    }
  };

  downloadXML = async (type: InvoiceType, invoiceId: string) => {
    try {
      this.loadingDetail = true;
      await Invoice.downloadXML(type, invoiceId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingDetail = false;
    }
  };

  downloadPDF = async (type: InvoiceType, invoiceId: string) => {
    try {
      this.loadingDetail = true;
      await Invoice.downloadPDF(type, invoiceId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingDetail = false;
    }
  };
}
