import { Space, Table, Tag, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";

import { CloseOutlined } from "@ant-design/icons";

import IconButton from "../../../app/common/button/IconButton";
import { IColumns } from "../../../app/common/table/utils";
import { IInvoice } from "../../../app/models/invoice";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import { moneyFormatter } from "../../../app/util/utils";
import { cancelInvoice } from "../invoice/common/cancelInvoice";

const { Link } = Typography;

type Props = {
  invoices: IInvoice[];
};

const InvoiceGlobalTable = ({ invoices }: Props) => {
  const { invoiceGlobalStore } = useStore();
  const { cancel, scopes } = invoiceGlobalStore;

  const navigate = useNavigate();

  const onCancel = async (invoice: IInvoice) => {
    const ok = await cancelInvoice(
      invoice.facturaId,
      "global",
      `${invoice.serie} ${invoice.serieNumero}`
    );

    if (ok) {
      cancel(invoice.facturaId);
    }
  };

  const columns: IColumns<IInvoice> = [
    {
      dataIndex: "serie",
      title: "Serie",
      width: "12%",
      render: (_, invoice) => {
        const cancelled = invoice.estatus === status.invoice.cancelado;
        return (
          <Space>
            <Link
              onClick={() => navigate(`/invoices/global/${invoice.facturaId}`)}
              type={!cancelled ? undefined : "danger"}
            >
              {`${invoice.serie} ${invoice.serieNumero}`}
            </Link>
            {cancelled && <Tag color="error">C</Tag>}
          </Space>
        );
      },
    },
    {
      dataIndex: ["cliente", "razonSocial"],
      title: "Razón social",
      width: "28%",
    },
    {
      dataIndex: "metodoPago",
      title: "Método de pago",
      width: "20%",
    },
    {
      dataIndex: "formaPago",
      title: "Forma de pago",
      width: "15%",
    },
    {
      dataIndex: "total",
      title: "Importe",
      width: "10%",
      render: (value) => `${moneyFormatter.format(value)}`,
    },
    {
      dataIndex: "fecha",
      title: "Fecha",
      align: "center",
      width: "10%",
    },
    {
      dataIndex: "facturaId",
      title: "Cancelar",
      align: "center",
      width: "5%",
      render: (_, invoice) =>
        invoice.estatus === status.invoice.facturado &&
        scopes.modificar && (
          <IconButton
            icon={<CloseOutlined />}
            danger
            onClick={() => {
              onCancel(invoice);
            }}
          />
        ),
    },
  ];

  return (
    <Table<IInvoice>
      bordered
      showHeader={false}
      pagination={false}
      rowKey={(record) => record.facturaId}
      columns={columns}
      dataSource={invoices}
      locale={{
        emptyText: "Sin facturas timbradas",
      }}
    />
  );
};

export default observer(InvoiceGlobalTable);
