import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../app/stores/store";

const Consent = () => {
  const { profileStore } = useStore();
  const { getPdfConsent } = profileStore;
  const [pdf, setPdf] = useState<string>("");
  useEffect(() => {
    getPdfConsent().then((pdf) => setPdf(pdf ?? ""));
    setPdf("");
  }, []);
  const isMobile = () => {
    return /Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (pdf) {
      window.location.href = pdf; // Esto iniciará la descarga del PDF
    }
  }, [pdf]);
  return (
    <>
      {/* <iframe
          src={pdf}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        >
          alt: <a href={pdf}>test.pdf</a>
        </iframe> */}
      <p>Descargando el archivo...</p>
    </>
  );
};

export default observer(Consent);
