export const creditEstatus = [
  {
    label: "Facturadas",
    value: "facturadas",
  },
  {
    label: "Canceladas",
    value: "canceladas",
  },
];

export const creditTimeEstatus = [
  {
    label: "Pagada",
    value: "Pagada",
  },
  {
    label: "Próxima a vencer",
    value: "Próxima a vencer",
  },
  {
    label: "Vigente",
    value: "Vigente",
  },
  {
    label: "Vencida",
    value: "Vencida",
  },
];

export const categoryType = [
  {
    label: "Libre a crédito",
    value: "free",
  },
  {
    label: "Compañía a crédito",
    value: "company",
  },
];
