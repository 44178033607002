import { FC, Fragment, useEffect, useState } from "react";

import { ItipoValorForm } from "../../../../app/models/parameter";
import RangoEdad from "./RangoEdad";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const RangoNumericoXEdadSexo: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [menParameterValues, setMenParameterValues] = useState<
    ItipoValorForm[]
  >(parameterValues.filter((x) => x.nombre === "hombre"));
  const [womenParameterValues, setWomenParameterValues] = useState<
    ItipoValorForm[]
  >(parameterValues.filter((x) => x.nombre === "mujer"));

  useEffect(() => {
    setParameterValues([...menParameterValues, ...womenParameterValues]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menParameterValues, womenParameterValues]);

  return (
    <Fragment>
      <RangoEdad
        description="Valores de referencia Hombre (Numérico por edad y sexo):"
        valueType={"hombre"}
        parameterValues={menParameterValues}
        setParameterValues={setMenParameterValues}
      ></RangoEdad>
      <RangoEdad
        description="Valores de referencia Mujer (Numérico por edad y sexo):"
        valueType={"mujer"}
        parameterValues={womenParameterValues}
        setParameterValues={setWomenParameterValues}
      ></RangoEdad>
    </Fragment>
  );
};
export default RangoNumericoXEdadSexo;
