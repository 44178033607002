import moment from "moment";

export interface IInvoiceCatalog {
  facturaId?: string;
  serie: string;
  serieNumero: string;
  facturaCancelada: boolean;
  solicitudId?: string;
  solicitud?: string;
  solicitudCancelada: boolean;
  expedienteId?: string;
  tipo: string;
  fechaCreo: string;
  compañia?: string;
  procedencia?: string;
  razonSocial?: string;
  total: number;
  saldo: number;
  esFactura: boolean;
  origen: string;
}

export interface IInvoiceCatalogFilter {
  cargaInicial: boolean;
  fechas?: moment.Moment[];
  clave?: string;
  sucursales?: string[];
  compañias?: string[];
  facturas: boolean;
  recibos: boolean;
  tipo: ("invoice" | "ticket")[];
}

export class InvoiceCatalogFilterValues implements IInvoiceCatalogFilter {
  cargaInicial: boolean = true;
  fechas?: moment.Moment[] = [
    moment().utcOffset(0, true),
    moment().utcOffset(0, true),
  ];
  sucursales?: string[] = [];
  compañias?: string[] = [];
  facturas: boolean = true;
  recibos: boolean = true;
  tipo: ("invoice" | "ticket")[] = ["invoice", "ticket"];

  constructor(init?: IInvoiceCatalogFilter) {
    Object.assign(this, init);
  }
}
