import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IReportData } from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";

const getCanceledInvoiceColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      dataIndex: "tipo",
      title: "",
      width: 40,
      align: "center",
    },
    {
      ...getDefaultColumnProps("fechaCreacion", "Fecha de creación", {
        width: 150,
      }),
    },
    {
      ...getDefaultColumnProps("fechaCancelacion", "Fecha de cancelación", {
        width: 170,
      }),
    },
    {
      ...getDefaultColumnProps("serie", "Serie de factura", {
        searchState,
        setSearchState,
        width: 155,
      }),
      render: (_, invoice) => `${invoice.serie} ${invoice.serieNumero}`,
    },
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: 120,
      }),
    },
    {
      ...getDefaultColumnProps("compañia", "Compañia", {
        searchState,
        setSearchState,
        width: 200,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("razonSocial", "Razón Social", {
        searchState,
        setSearchState,
        width: 300,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("subtotal", "Subtotal", {
        width: 110,
      }),
      align: "right",
      render: (subtotal) => moneyFormatter.format(subtotal ?? 0),
    },
    {
      ...getDefaultColumnProps("iva", "IVA", {
        width: 90,
      }),
      align: "right",
      render: (taxes) => moneyFormatter.format(taxes ?? 0),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        width: 110,
      }),
      align: "right",
      render: (total, invoice) => moneyFormatter.format(total ?? 0),
    },
  ];

  return columns;
};

export default getCanceledInvoiceColumns;
