import { Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { VList } from "virtual-table-ant-design";

import LinkTo from "../../../app/common/link/LinkTo";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { ITagFormStudy } from "../../../app/models/tag";
import { useStore } from "../../../app/stores/store";
import views from "../../../app/util/view";

type Props = {
  studies: ITagFormStudy[];
};

const TagStudies = ({ studies }: Props) => {
  const { studyStore } = useStore();
  const { scopes: studyScopes } = studyStore;

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columns: IColumns<ITagFormStudy> = [
    {
      ...getDefaultColumnProps("claveEstudio", "Clave", {
        searchState,
        setSearchState,
        width: 100,
      }),
      render: (code, study) => (
        <LinkTo
          to={`/${views.study}/${study.estudioId}?mode=ReadOnly`}
          text={code}
          canRedirect={studyScopes.acceder}
        />
      ),
    },
    {
      ...getDefaultColumnProps("nombreEstudio", "Nombre", {
        searchState,
        setSearchState,
        width: 200,
      }),
    },
  ];

  const virtual = useMemo(() => {
    return VList({
      height: 500,
    });
  }, []);

  return (
    <div>
      <Divider orientation="left">Estudios</Divider>
      <Table<ITagFormStudy>
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        pagination={false}
        dataSource={studies}
        components={virtual}
        scroll={{
          x: "100%",
          y: 500,
        }}
      />
    </div>
  );
};

export default observer(TagStudies);
