import { useEffect, useState } from "react";
import Agenda from "../../../app/common/agenda/Agenda";
import moment from "moment";
import alerts from "../../../app/util/alerts";
import { Days, IAgendaColumn } from "../../../app/common/agenda/utils";
import { v4 as uuid } from "uuid";
import { observer } from "mobx-react-lite";
import {
  IAppointmentEvent,
  IAppointmentInfo,
} from "../../../app/models/appointment";
import { useStore } from "../../../app/stores/store";
import { catalog } from "../../../app/util/catalogs";

type Props = {
  type: "L" | "D" | undefined;
  draggingAppointment: IAppointmentInfo | undefined;
};

const AppointmentCalendar = ({ type, draggingAppointment }: Props) => {
  const { appointmentStore, profileStore, equipmentStore, userStore } =
    useStore();
  const { profile } = profileStore;
  const { getByBranch } = equipmentStore;
  const { getCollectors } = userStore;
  const {
    scopes,
    cancelledAppointments,
    getSchedule,
    schedule,
    reschedule,
    deleteSchedule,
  } = appointmentStore;

  const [events, setEvents] = useState<IAppointmentEvent[]>([]);
  const [equipments, setEquipments] = useState<IAgendaColumn[]>([]);
  const [collectors, setCollectors] = useState<IAgendaColumn[]>([]);
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);

  useEffect(() => {
    const readEquipments = async () => {
      const equipments = await getByBranch(
        profile!.sucursal,
        catalog.equipmentCategory.imagenologia
      );
      setEquipments(
        equipments.map((x) => ({ id: x.equipoSucursalId, title: x.nombre }))
      );
    };

    const readCollectors = async () => {
      const collectors = await getCollectors(profile!.sucursal);
      setCollectors(collectors.map((x) => ({ id: x.id, title: x.clave })));
    };

    readEquipments();
    readCollectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile!.sucursal]);

  useEffect(() => {
    const readData = async () => {
      if (selectedDates.length === 0) return;

      const events = await getSchedule({
        fecha: selectedDates,
        sucursalId: [profile!.sucursal],
      });
      setEvents(events);
    };

    readData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, type, profile?.sucursal]);

  useEffect(() => {
    setEvents((prev) =>
      prev.filter((x) => !cancelledAppointments.includes(x.citaId))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelledAppointments.length]);

  const scheduleEvent = (date: moment.Moment, column: IAgendaColumn) => {
    if (!draggingAppointment) return;

    alerts.confirm(
      "Agendar cita",
      `Agendar cita el ${date.format(
        "DD [de] MMM [del] YYYY"
      )} en el horario ${date.format("hh:mm a")}`,
      async () => {
        const newEvent: IAppointmentEvent = {
          id: uuid(),
          columnId: column.id,
          column: column.title,
          date: date,
          citaId: draggingAppointment.citaId,
          tipo: type,
          cita: draggingAppointment,
        };
        const ok = await schedule(newEvent);
        if (ok) setEvents((prev) => [...prev, newEvent]);
      }
    );
  };

  const rescheduleEvent = (
    event: IAppointmentEvent,
    date: moment.Moment,
    column: IAgendaColumn
  ) => {
    alerts.confirm(
      "Mover cita",
      `Mover cita ${event.cita.clave} al ${date.format(
        "DD [de] MMM [del] YYYY"
      )} en el horario ${date.format("hh:mm a")}`,
      async () => {
        const index = events.findIndex((x) => x.id === event.id);
        if (index > -1) {
          const newEvent: IAppointmentEvent = {
            ...event,
            date: date,
            columnId: column.id,
            column: column.title,
          };
          const ok = await reschedule(newEvent);
          if (ok) {
            const _events = [...events];
            _events[index] = newEvent;
            setEvents(_events);
          }
        }
      }
    );
  };

  const deleteEvent = (event: IAppointmentEvent) => {
    alerts.confirm(
      "Eliminar cita",
      `¿Desea eliminar la cita ${event.cita.clave}?, esta acción no se puede deshacer`,
      async () => {
        const ok = await deleteSchedule(event.id as string);
        if (ok) {
          const filtered = events.filter((x) => x.id !== event.id);
          setEvents(filtered);
        }
      }
    );
  };

  return (
    <div>
      <Agenda<IAppointmentEvent>
        startTime={moment("08:00", "HH:mm")}
        endTime={moment("19:00", "HH:mm")}
        interval={moment(type === "L" ? "00:30" : "01:00", "HH:mm")}
        defaultType="week"
        // calendarHeight={"60vh"}
        // cellHeight={50}
        excludeDays={[Days.Sunday]}
        columns={type === "L" ? [...equipments] : [...collectors]}
        events={[...events]}
        deletable={(e) => scopes.modificar}
        columnsTitle={type === "D" ? "recolector" : "equipo"}
        // showDetail={{
        //   title: (event) => event.cita.clave,
        //   content: (event) => (
        //     <Card
        //       bordered={false}
        //       size="small"
        //       title="Small size card"
        //       extra={<a href="#">More</a>}
        //       style={{ width: 300 }}
        //     >
        //       <p>Card content</p>
        //       <p>Card content</p>
        //       <p>Card content</p>
        //     </Card>
        //   ),
        // }}
        onDateChange={(dates) => {
          setSelectedDates([moment.min(dates).local(), moment.max(dates)]);
        }}
        render={(event) => (
          <div style={{ paddingLeft: 10, backgroundColor: "lightblue" }}>
            {event.cita.clave}
          </div>
        )}
        onDropEvent={(date, column, event) => {
          if (!scopes.modificar) return;
          if (!event) {
            scheduleEvent(date, column);
          } else {
            rescheduleEvent(event, date, column);
          }
        }}
        onDelete={deleteEvent}
      />
    </div>
  );
};

export default observer(AppointmentCalendar);
