import { Typography } from "antd";
import { IColumns, getDefaultColumnProps } from "../../../app/common/table/utils";
import { moneyFormatter } from "../../../app/util/utils";
import { SummaryBranchAllData, SummaryBranchData } from "../../../app/models/report";
const { Text } = Typography;


export const getsummaryBranchData = (
   
  ) => {

    const columns: IColumns<SummaryBranchData> = [
      {
        ...getDefaultColumnProps("totalPacientes", "PAC", {
          width: "20%",
        }),
      },
      {
        ...getDefaultColumnProps("totalEstudios", "Estudios", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("totalDescuentosPorcentual", "Desc. %", {
          width: "20%",
        }),
        render: (_value, record) => record.totalDescuentoPorcentual + "%",
      },
      {
        ...getDefaultColumnProps("totalDescuentos", "Desc.", {
          width: "20",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("total", "Total", {
          width: "25%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
    ];
   
     return columns;
  };

  const getsummaryAllData = (
    
  ) => {
    const columns: IColumns<SummaryBranchAllData> = [
      {
        ...getDefaultColumnProps("subtotal", "Subtotal", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("iva", "IVA", {
          width: "20%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("totalDescuentos", "Desc. %", {
          width: "25%",
        }),
        render: (value) => {return <Text>{value}%</Text>},
      },
    ];
    return columns;
  };

  export default getsummaryAllData;