import { ItipoValorForm } from "../../../../app/models/parameter";
import alerts from "../../../../app/util/alerts";
import messages from "../../../../app/util/messages";

export enum ValueTypeEnum {
  SinValor = 0,
  Numerico = 1,
  NumericoSexo = 2,
  NumericoEdad = 3,
  NumericoEdadSexo = 4,
  OpcionMultiple = 5,
  Numerico1Columna = 6,
  Texto = 7,
  Parrafo = 8,
  Etiqueta = 9,
  Observacion = 10,
  Numerico2Columnas = 11,
  Numerico3Columnas = 12,
  Numerico4Columnas = 13,
  Numerico5Columnas = 14,
}

const errorsInValues = (values: ItipoValorForm[]) => {
  let hasError = false;

  for (const x of values) {
    if (
      !!x.valorInicialNumerico &&
      !!x.valorFinalNumerico &&
      !!x.criticoMinimo &&
      !!x.criticoMaximo
    ) {
      if (Number(x.valorInicialNumerico) > Number(x.valorFinalNumerico)) {
        alerts.warning(`El valor inicial no puede ser mayor al valor final`);
        hasError = true;
        break;
      }

      if (Number(x.valorInicialNumerico) === Number(x.valorFinalNumerico)) {
        alerts.warning(`El valor inicial no puede ser igual al final`);
        hasError = true;
        break;
      }

      if (Number(x.criticoMinimo) > Number(x.valorInicialNumerico)) {
        alerts.warning(
          `El valor inicial no puede ser mayor al valor mínimo crítico`
        );
        hasError = true;
        break;
      }

      if (Number(x.criticoMinimo) > Number(x.valorFinalNumerico)) {
        alerts.warning(
          `El valor inicial no puede ser mayor al valor máximo crítico`
        );
        hasError = true;
        break;
      }

      if (Number(x.criticoMinimo) === Number(x.valorInicialNumerico)) {
        alerts.warning(
          `El valor inicial no puede ser igual al valor mínimo crítico`
        );
        hasError = true;
        break;
      }

      if (Number(x.valorFinalNumerico) < Number(x.valorInicialNumerico)) {
        alerts.warning(
          `El valor final no puede ser menor que el valor inicial`
        );
        hasError = true;
        break;
      }

      if (Number(x.criticoMaximo) < Number(x.valorInicialNumerico)) {
        alerts.warning(
          `El valor máximo crítico no puede ser menor que el valor inicial`
        );
        hasError = true;
        break;
      }

      if (Number(x.criticoMaximo) < Number(x.valorFinalNumerico)) {
        alerts.warning(
          `El valor máximo crítico no puede ser menor que el valor final`
        );
        hasError = true;
        break;
      }

      if (Number(x.criticoMaximo) === Number(x.valorFinalNumerico)) {
        alerts.warning(
          `El valor final no puede ser igual al valor máximo crítico`
        );
        hasError = true;
        break;
      }
    }
  }

  return hasError;
};

export const getParameterValues = (type: number, values: ItipoValorForm[]) => {
  if (type === ValueTypeEnum.Numerico) {
    const hasErrors = errorsInValues(values);
    // if (hasErrors) {
    //   return;
    // }
    return values;
  }
  if (type === ValueTypeEnum.NumericoSexo) {
    const hasErrors = errorsInValues(values);
    // if (hasErrors) {
    //   return;
    // }
    return values;
  } else if (type === ValueTypeEnum.NumericoEdad) {
    const hasErrors = errorsInValues(values);
    if (hasErrors) {
      return;
    }
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        valorInicialNumerico: x.valorInicialNumerico,
        valorFinalNumerico: x.valorFinalNumerico,
        criticoMinimo: x.criticoMinimo,
        criticoMaximo: x.criticoMaximo,
        rangoEdadInicial: x.rangoEdadInicial,
        rangoEdadFinal: x.rangoEdadFinal,
        medidaTiempoId: x.medidaTiempoId,
        orden: i + 1,
      };
      return data;
    });
  } else if (type === ValueTypeEnum.OpcionMultiple) {
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        opcion: x.opcion,
        orden: i + 1,
      };
      return data;
    });
  } else if (
    type === ValueTypeEnum.Numerico1Columna ||
    type === ValueTypeEnum.Numerico2Columnas ||
    type === ValueTypeEnum.Numerico3Columnas ||
    type === ValueTypeEnum.Numerico4Columnas ||
    type === ValueTypeEnum.Numerico5Columnas
  ) {
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        primeraColumna: x.primeraColumna,
        segundaColumna: x.segundaColumna,
        terceraColumna: x.terceraColumna,
        cuartaColumna: x.cuartaColumna,
        quintaColumna: x.quintaColumna,
        orden: i + 1,
      };
      return data;
    });
  } else if (type === ValueTypeEnum.Texto) {
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        descripcionTexto: x.descripcionTexto,
        orden: i + 1,
      };
      return data;
    });
  } else if (type === ValueTypeEnum.Parrafo) {
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        descripcionParrafo: x.descripcionParrafo,
        orden: i + 1,
      };
      return data;
    });
  } else if (type === ValueTypeEnum.Observacion) {
    return values.map((x: ItipoValorForm, i: number) => {
      let data: ItipoValorForm = {
        id: x.id,
        nombre: x.nombre,
        descripcionTexto: x.descripcionTexto,
        orden: i + 1,
      };
      return data;
    });
  }
};

export const isValidParamValues = (type: number, value: ItipoValorForm[]) => {
  if (type === ValueTypeEnum.Numerico) {
    if (
      !!value[0].valorInicial &&
      !!value[0].valorFinal &&
      Number(value[0].valorInicial) > Number(value[0].valorFinal)
    ) {
      alerts.warning(messages.warnings.initialValue);
      return false;
    }

    if (
      !!value[0].valorInicial &&
      !!value[0].valorFinal &&
      Number(value[0].valorFinal) === Number(value[0].valorInicial)
    ) {
      alerts.warning(messages.warnings.finalValue);
      return false;
    }

    if (!!value[0].criticoMinimo && !!value[0].criticoMaximo) {
      if (!!!value[0].valorInicial || !!!value[0].valorFinal) {
        alerts.warning(messages.warnings.referenceValue);
        return false;
      }

      if (Number(value[0].criticoMinimo) > Number(value[0].criticoMaximo)) {
        alerts.warning(messages.warnings.minimumValue);
        return false;
      } else if (
        Number(value[0].criticoMinimo) === Number(value[0].criticoMaximo)
      ) {
        alerts.warning(messages.warnings.maximumValue);
        return false;
      } else if (
        Number(value[0].criticoMinimo) > Number(value[0].valorInicial) ||
        Number(value[0].criticoMinimo) === Number(value[0].valorInicial)
      ) {
        alerts.warning(messages.warnings.initialMinimumValue);
        return false;
      } else if (
        Number(value[0].criticoMaximo) < Number(value[0].valorFinal) ||
        Number(value[0].criticoMaximo) === Number(value[0].valorFinal)
      ) {
        alerts.warning(messages.warnings.finalMaximumValue);
        return false;
      }
    }
  } else if (type === ValueTypeEnum.NumericoEdad) {
    for (const x of value) {
      if (
        !!x.valorInicialNumerico &&
        !!x.valorFinalNumerico &&
        !!x.criticoMinimo &&
        !!x.criticoMaximo
      ) {
        if (Number(x.valorInicialNumerico) > Number(x.valorFinalNumerico)) {
          alerts.warning(`El valor inicial no puede ser mayor al valor final`);
          return false;
        }

        if (Number(x.valorInicialNumerico) === Number(x.valorFinalNumerico)) {
          alerts.warning(`El valor inicial no puede ser igual al final`);
          return false;
        }

        if (Number(x.criticoMinimo) > Number(x.valorInicialNumerico)) {
          alerts.warning(
            `El valor mínimo crítico no puede ser mayor al valor inicial`
          );
          return false;
        }

        if (Number(x.criticoMinimo) > Number(x.valorFinalNumerico)) {
          alerts.warning(
            `El valor mínimo crítico no puede ser mayor al valor final`
          );
          return false;
        }

        if (Number(x.criticoMinimo) === Number(x.valorInicialNumerico)) {
          alerts.warning(
            `El valor mínimo crítico no puede ser igual al valor inicial`
          );
          return false;
        }

        if (Number(x.valorFinalNumerico) < Number(x.valorInicialNumerico)) {
          alerts.warning(
            `El valor final no puede ser menor que el valor inicial`
          );
          return false;
        }

        if (Number(x.criticoMaximo) < Number(x.valorInicialNumerico)) {
          alerts.warning(
            `El valor máximo crítico no puede ser menor que el valor inicial`
          );
          return false;
        }

        if (Number(x.criticoMaximo) < Number(x.valorFinalNumerico)) {
          alerts.warning(
            `El valor máximo crítico no puede ser menor que el valor final`
          );
          return false;
        }

        if (Number(x.criticoMaximo) === Number(x.valorFinalNumerico)) {
          alerts.warning(
            `El valor final no puede ser igual al valor máximo crítico`
          );
          return false;
        }
      }
    }
  }

  return true;
};
