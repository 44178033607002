import { Checkbox, Space, Table, Tag, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ExpandAll from "../../../app/common/table/ExpandAll";
import {
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import {
  IInvoiceRequestCompanyInvoice,
  IInvoiceRequestCompanyList,
} from "../../../app/models/invoiceCompany";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import { moneyFormatter } from "../../../app/util/utils";
import views from "../../../app/util/view";
import { InvoiceRequestCompanyType } from "../invoice/utils/utils";
import InvoiceCompanyTableDetail from "./InvoiceRequestCompanyTableDetail";

const { Link } = Typography;

type Props = {
  type: InvoiceRequestCompanyType;
  groupId: string | undefined;
  setGroupId: (value?: string) => void;
  selectedRowKeys: string[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
};

const InvoiceCompanyTable = ({
  type,
  groupId,
  setGroupId,
  selectedRowKeys,
  setSelectedRowKeys,
}: Props) => {
  const { invoiceRequestCompanyStore } = useStore();
  const { invoices } = invoiceRequestCompanyStore;

  const navigate = useNavigate();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const allExpanded = expandedRowKeys.length === invoices.length;

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(invoices.map((x) => x.solicitudId));
    }
  };

  const onExpandedRowsChange = (expandedRows: readonly React.Key[]) => {
    setExpandedRowKeys(expandedRows as string[]);
  };

  useEffect(() => {
    setExpandedRowKeys(invoices.map((x) => x.solicitudId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices.length]);

  const columns: IColumns<IInvoiceRequestCompanyList> = [
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (code, request) => {
        return (
          <Link
            onClick={() =>
              navigate(`/${views.request}/${request.expedienteId}/${request.solicitudId}`)
            }
          >
            {code}
          </Link>
        );
      },
    },
    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("sucursal", "Sucursal", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("paciente", "Paciente", {
        searchState,
        setSearchState,
        width: "30%",
      }),
    },
    {
      ...getDefaultColumnProps("facturas", "Facturas", {
        searchState,
        setSearchState,
        width: "30%",
      }),
      render: (invoices: IInvoiceRequestCompanyInvoice[]) => {
        return (
          <div>
            {invoices.map((invoice) => {
              const cancelled = invoice.estatus === status.invoice.cancelado;
              return (
                <div>
                  <Space>
                    <Link
                      onClick={() => navigate(`/invoices/${type}/${invoice.facturaId}`)}
                      type={!cancelled ? undefined : "danger"}
                    >
                      {`${invoice.serie} ${invoice.serieNumero}`}
                    </Link>
                    {cancelled && <Tag color="error">C</Tag>}
                  </Space>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("saldo", "Saldo", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
  ];

  const toggleSelectAll = () => {
    if (type === "request") {
      setSelectedRowKeys((keys) =>
        keys.length === invoices.length ? [] : invoices.map((r) => r.solicitudId)
      );
      return;
    }

    if (!groupId) {
      const row = invoices[0];
      const group = row.compañiaId;
      setGroupId(group);
      setSelectedRowKeys(
        invoices.filter((record) => record.compañiaId === group).map((x) => x.solicitudId)
      );
    } else {
      const groupInvoices = [...invoices.filter((record) => record.compañiaId === groupId)];
      if (selectedRowKeys.length === groupInvoices.length) {
        setGroupId(undefined);
      }
      setSelectedRowKeys((keys) =>
        keys.length === groupInvoices.length ? [] : groupInvoices.map((r) => r.solicitudId)
      );
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length > 0}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < invoices.length}
      onChange={toggleSelectAll}
    />
  );

  return (
    <Table<IInvoiceRequestCompanyList>
      bordered
      sticky
      rowClassName="row-search"
      rowKey={(record) => record.solicitudId}
      columns={columns}
      pagination={defaultPaginationPropertiesSmall}
      dataSource={invoices}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys,
        columnTitle: headerCheckbox,
        onChange: (selectedRowKeys, selectedRows, info) => {
          if (type === "request") {
            setSelectedRowKeys(selectedRowKeys as string[]);
            return;
          }

          if (selectedRowKeys.length === 0) {
            setGroupId(undefined);
            setSelectedRowKeys([]);
            return;
          }

          if (!groupId && selectedRowKeys.length === 1) {
            const row = selectedRows[0];
            setGroupId(row.compañiaId);
          }

          setSelectedRowKeys(selectedRowKeys as string[]);
        },
        getCheckboxProps: (record) => {
          return {
            disabled: !!groupId && type === "company" && record.compañiaId !== groupId,
          };
        },
      }}
      expandable={{
        defaultExpandAllRows: true,
        columnTitle: <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />,
        onExpandedRowsChange: onExpandedRowsChange,
        expandedRowKeys: expandedRowKeys,
        expandedRowRender: (request) => <InvoiceCompanyTableDetail studies={request.estudios} />,
      }}
    />
  );
};

export default observer(InvoiceCompanyTable);
