import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import { DeleteOutlined, InfoCircleTwoTone } from "@ant-design/icons";

import Study from "../../../../app/api/study";
import IconButton from "../../../../app/common/button/IconButton";
import HeaderTitle from "../../../../app/common/header/HeaderTitle";
import { getDefaultColumnProps, IColumns, ISearch } from "../../../../app/common/table/utils";
import { IRequestPack, IRequestStudy, IRequestStudyUpdate } from "../../../../app/models/request";
import { IOptions } from "../../../../app/models/shared";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import { status } from "../../../../app/util/catalogs";
import { moneyFormatter } from "../../../../app/util/utils";
import InfoStudy from "../InfoModal/InfoStudy";

const { Search } = Input;
const { Link, Text } = Typography;

const RequestStudy = () => {
  const { requestStore, optionStore, modalStore } = useStore();
  const { openModal } = modalStore;
  const { studyOptionsEx, packOptions, getStudyOptionsEx, getPackOptions } = optionStore;
  const {
    isStudy,
    readonly,
    request,
    studies,
    packs,
    studyFilter,
    setStudy,
    setPack,
    getPriceStudy,
    getPricePack,
    deleteStudy,
    deletePack,
    cancelStudies,
    setOriginalTotal,
    changeStudyDiscountSingle,
    changeStudyPromotion,
    changePackDiscountSingle,
    changePackPromotion,
    totals,
    updateStudies,
    studyUpdate,
    disabledClave,
    setDisabledClave,
    setFocusClave,
    focusClave,
    focusType,
    setFocusType,
    scopes,
  } = requestStore;

  const [selectedStudies, setSelectedStudies] = useState<(IRequestStudy | IRequestPack)[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [searchText, setSearchText] = useState<string>();
  const [checkingCode, setCheckingCode] = useState(false);
  const claveRef = useRef<any>(null);
  const searchRef = useRef<any>(null);
  useEffect(() => {
    getStudyOptionsEx();
    getPackOptions();
    setDisabledClave(false);
  }, [getPackOptions, getStudyOptionsEx]);

  useEffect(() => {}, [studies, packs]);
  useEffect(() => {
    if (focusType == "clave") claveRef?.current?.focus();
    else if (focusType == "search") searchRef?.current?.focus();
  }, [focusClave]);

  useEffect(() => {
    setOriginalTotal(totals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const options: IOptions[] = [
      {
        value: "study",
        label: "Estudios",
        options: studyOptionsEx,
        info: "",
      },
      {
        value: "pack",
        label: "Paquetes",
        options: packOptions,
        info: "",
      },
    ];

    setOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packOptions, studyOptionsEx]);

  const showStudyDetails = async (id: number, estudio: string) => {
    openModal({
      title: <HeaderTitle image={"infoStudy"} title="Ficha técnica" />,
      body: (
        <InfoStudy
          id={id}
          originBranch={request!.sucursal!}
          destinationBranch={request!.destino!}
        ></InfoStudy>
      ),
      width: 1000,
    });
  };

  const checkManualDiscount = (item: IRequestStudy | IRequestPack, value: boolean) => {
    const obj = toJS(item);
    obj.descuentoManual = value;
    obj.promocionId = undefined;
    obj.promocion = undefined;
    obj.descuento = 0;
    obj.descuentoPorcentaje = 0;
    obj.precioFinal = obj.precio;
    obj.descuentoModificado = true;

    if (isStudy(obj)) {
      setStudy(obj);
    } else {
      setPack(obj);
    }
  };

  const columns: IColumns<IRequestStudy | IRequestPack> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: 100,
      }),
      render: (value, item) => {
        if (isStudy(item)) {
          return (
            <Link
              onClick={() => {
                showStudyDetails(item.estudioId!, item.nombre);
              }}
            >
              {value}
            </Link>
          );
        } else {
          return <Text>{value}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("nombre", "Estudio", {
        searchState,
        setSearchState,
        width: 280,
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (value, item) => {
        let content = "";
        if (isStudy(item)) {
          content = `${value} (${(item.parametros ?? []).map((x) => x.clave).join(", ")})`;
        } else {
          content = `${value} (${item.estudios
            .flatMap((x) => x.parametros)
            .map((x) => x.clave)
            .join(", ")})`;
        }

        content = content.replace(" ()", "");

        return (
          <Tooltip placement="topLeft" title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchable: false,
        width: 140,
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {item.estatusId === status.requestStudy.cancelado ? (
            <div>
              <Text type="danger">{value}</Text>{" "}
            </div>
          ) : (
            value
          )}
        </div>
      ),
    },
    {
      key: "n",
      dataIndex: "n",
      title: "",
      align: "center",
      width: 40,
      render: (value) => "N",
    },
    {
      ...getDefaultColumnProps("precio", "Precio", {
        searchable: false,
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("dias", "Días", {
        searchable: false,
        width: 70,
      }),
    },
    {
      key: "descuentoManual",
      dataIndex: "descuentoManual",
      title: (
        <>
          <Tooltip title="Aplicar descuento manual">
            D <InfoCircleTwoTone />
          </Tooltip>
        </>
      ),
      align: "center",
      width: 60,
      render: (value, item) => (
        <Checkbox
          name="descuentoManual"
          checked={value}
          disabled={
            readonly ||
            (isStudy(item) ? item.estatusId === status.requestStudy.cancelado : item.cancelado)
          }
          onChange={(e) => {
            checkManualDiscount(item, e.target.checked);
          }}
        />
      ),
    },
    {
      ...getDefaultColumnProps("promocionId", "Promoción", {
        searchable: false,
        width: 250,
      }),
      render: (value, item) =>
        item.descuentoManual ? (
          <InputNumber<number>
            key={"desc-num"}
            className="no-padding-input"
            style={{ width: "100%" }}
            formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
            value={item.descuento}
            onChange={(value) => {
              if (isStudy(item)) {
                changeStudyDiscountSingle(item, value ?? 0);
              } else {
                changePackDiscountSingle(item, value ?? 0);
              }
            }}
            controls={false}
            bordered={false}
            min={0}
            max={item.precio}
            disabled={
              readonly ||
              (isStudy(item) ? item.estatusId === status.requestStudy.cancelado : item.cancelado)
            }
          />
        ) : item.promociones && item.promociones.length > 0 ? (
          <Select
            options={item.promociones.map((x) => ({
              value: x.promocionId,
              label: `${x.promocion} (${x.descuentoPorcentaje}%)`,
            }))}
            value={value}
            bordered={false}
            style={{ width: "100%" }}
            allowClear
            disabled={
              readonly ||
              (isStudy(item) ? item.estatusId === status.requestStudy.cancelado : item.cancelado)
            }
            placeholder="Seleccionar promoción"
            onChange={(promoId?: number) => {
              if (isStudy(item)) {
                changeStudyPromotion(item, promoId);
              } else {
                changePackPromotion(item, promoId);
              }
            }}
          />
        ) : (
          "Sin promociones disponibles"
        ),
    },
    {
      ...getDefaultColumnProps("precioFinal", "Precio Final", {
        searchable: false,
        width: 120,
      }),
      align: "right",
      render: (value) => moneyFormatter.format(value),
    },
    Table.SELECTION_COLUMN,
  ];

  const expandableConfig = () => {
    const nestedColumns: IColumns<IRequestStudy | IRequestPack> = [
      {
        ...getDefaultColumnProps("clave", "Clave", {
          searchState,
          setSearchState,
          width: 100,
        }),
        render: (value, item) => {
          if (isStudy(item)) {
            return (
              <Link
                onClick={() => {
                  showStudyDetails(item.estudioId!, item.nombre);
                }}
              >
                {value}
              </Link>
            );
          } else {
            return <Text>{value}</Text>;
          }
        },
      },
      {
        ...getDefaultColumnProps("nombre", "Estudio", {
          searchState,
          setSearchState,
          width: 280,
        }),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        ...getDefaultColumnProps("estatus", "Estatus", {
          searchable: false,
          width: 140,
        }),
        render: (value, item) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {item.estatusId === status.requestStudy.cancelado ? (
              <div>
                <Text type="danger">{value}</Text>{" "}
              </div>
            ) : (
              value
            )}
          </div>
        ),
      },
      {
        dataIndex: "n",
        width: 40,
        render: (value, item) => "",
      },
      {
        ...getDefaultColumnProps("precio", "Precio", {
          searchable: false,
          width: 120,
        }),
        render: (value, item) => "",
      },
      {
        ...getDefaultColumnProps("dias", "Días", {
          searchable: false,
          width: 70,
        }),
      },
      {
        dataIndex: "descuentoManual",
        width: 60,
        render: (value, item) => "",
      },
      {
        ...getDefaultColumnProps("promocionId", "Promoción", {
          searchable: false,
          width: 250,
        }),
        render: (value, item) => "",
      },
      {
        ...getDefaultColumnProps("precioFinal", "Precio Final", {
          searchable: false,
          width: 120,
        }),
        render: (value, item) => "",
      },
      Table.SELECTION_COLUMN,
    ];

    const isIRequestPack = (record: IRequestStudy | IRequestPack): record is IRequestPack => {
      return (record as IRequestPack).type === "pack";
    };

    return {
      expandedRowRender: (item: IRequestStudy | IRequestPack) => {
        if (isIRequestPack(item)) {
          return (
            <Table
              style={{ padding: 0 }}
              rowKey={(record) => record.id ?? record.identificador!}
              columns={nestedColumns}
              dataSource={item.estudios}
              pagination={false}
              showHeader={false}
              rowSelection={{
                getCheckboxProps: () => ({
                  style: { display: "none" },
                }),
              }}
            />
          );
        }
        return null;
      },
      rowExpandable: (record: IRequestStudy | IRequestPack) => {
        return isIRequestPack(record);
      },
      defaultExpandAllRows: true,
    };
  };

  const searchStudy = async (value: string) => {
    // value = value?.toLowerCase()?.trim();
    // const option = options.flatMap((x) => x.options).find((x) => value === x?.extra?.toLowerCase());
    // if (!option) {
    //   alerts.warning("No se encontró la clave");
    //   return;
    // }
    // setSearchText(undefined);
    // setDisabledClave(true);
    // setFocusType("clave");
    // addStudy(option);
    try {
      setCheckingCode(true);
      const response = await Study.getIsStudyOrPack(value);
      if (response.id === 0) {
        alerts.warning("No se encontró la clave");
        return;
      }
      setSearchText(undefined);
      setDisabledClave(true);
      setFocusType("clave");

      if (response.tipo === 1) {
        await getPriceStudy(response.id, studyFilter);
      }

      if (response.tipo === 2) {
        await getPricePack(response.id, studyFilter);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCheckingCode(false);
    }
  };

  const addStudy = async (option: IOptions) => {
    const value = parseInt(option.value.toString().split("-")[1]);

    if (isNaN(value)) return;

    if (option.group === "study") {
      await getPriceStudy(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }

    if (option.group === "pack") {
      await getPricePack(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }
  };

  const addStudyClick = async (option: string) => {
    const value = parseInt(option.toString().split("-")[1]);
    const type = option.toString().split("-")[0];

    if (isNaN(value)) return;

    if (type === "study") {
      await getPriceStudy(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }

    if (type === "pack") {
      await getPricePack(value, studyFilter);
      // await updateStudies(studyUpdate, false);
    }
  };

  const deleteStudyOrPack = (item: IRequestStudy | IRequestPack) => {
    alerts.confirm(
      "Eliminar estudio",
      `¿Desea eliminar el ${isStudy(item) ? "estudio" : "paquete"} ${item.nombre}?`,
      async () => {
        if (isStudy(item)) {
          deleteStudy(item.identificador!);
        } else {
          deletePack(item.identificador!);
        }
      }
    );
  };

  const cancel = () => {
    if (request) {
      alerts.confirm(
        "Cancelar estudios",
        `¿Desea cancelar los registros seleccionados?`,
        async () => {
          const data: IRequestStudyUpdate = {
            expedienteId: request.expedienteId,
            solicitudId: request.solicitudId!,
            estudios: selectedStudies.filter((x) => x.type === "study") as IRequestStudy[],
            paquetes: selectedStudies.filter((x) => x.type === "pack") as IRequestPack[],
          };
          const ok = await cancelStudies(data);
          if (ok) {
            setSelectedStudies([]);
            setSelectedRowKeys([]);
          }
        }
      );
    }
  };

  const selectRowCheckbox = (selectedRows: (IRequestStudy | IRequestPack)[]) => {
    setSelectedStudies(selectedRows);
    setSelectedRowKeys(selectedRows.map((x) => `${x.type}-${x.id ?? x.identificador!}`));
  };
  const handleSelect = (value: any, option: any) => {
    setFocusType("search");
    addStudyClick(value);
    searchRef.current.blur();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <Row gutter={[8, 8]}>
      {!readonly && scopes.modificar && scopes.crear && (
        <>
          <Col span={12} style={{ textAlign: "start" }}>
            <Search
              placeholder="Clave"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={searchStudy}
              style={{ width: "35%" }}
              disabled={checkingCode || disabledClave}
              ref={claveRef}
            />
          </Col>
          <Col span={12} style={{ textAlign: "end" }}>
            <Select
              id="selectStudy"
              showSearch
              value={[]}
              mode="multiple"
              placeholder="Buscar Estudios"
              optionFilterProp="children"
              style={{ width: "90%", textAlign: "left" }}
              filterOption={(input: string, option: any) => {
                if (input.indexOf("-") > -1) {
                  const value = input.split("-")[0];
                  return option.info.toLowerCase().split(",")[0] === value.toLowerCase();
                }
                return option.info.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              options={options}
              onSelect={handleSelect}
              onInputKeyDown={handleKeyDown}
              ref={searchRef}
            />
          </Col>
        </>
      )}
      <Col span={24}>
        <Table<IRequestStudy | IRequestPack>
          size="small"
          rowKey={(record) => `${record.type}-${record.id ?? record.identificador!}`}
          columns={columns}
          dataSource={[...studies, ...packs]}
          pagination={false}
          rowSelection={{
            onChange: (_selectedRowKeys, selectedRows) => {
              selectRowCheckbox(selectedRows);
            },
            getCheckboxProps: (item) => ({
              disabled:
                readonly ||
                item.nuevo ||
                !item.asignado ||
                (isStudy(item)
                  ? item.estatusId !== status.requestStudy.pendiente
                  : item.estudios.some((x) => x.estatusId !== status.requestStudy.pendiente)),
            }),
            selectedRowKeys: selectedRowKeys,
          }}
          sticky
          scroll={{ x: "fit-content" }}
          expandable={expandableConfig()}
        />
      </Col>
      <Col span={24} style={{ textAlign: "end" }}>
        {!readonly && (
          <Button danger onClick={cancel}>
            Cancelar estudios
          </Button>
        )}
      </Col>
    </Row>
  );
};

const ContainerBadge = ({ color }: { color: string }) => {
  return <div className="badge-container" style={{ backgroundColor: color }}></div>;
};

export default observer(RequestStudy);
