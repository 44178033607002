import {
  IInvoiceGlobalFilter,
  IInvoiceGlobalList,
} from "../models/invoiceGlobal";
import requests from "./agent";

const InvoiceGlobal = {
  getAll: (filter: IInvoiceGlobalFilter): Promise<IInvoiceGlobalList[]> =>
    requests.post("invoiceGlobal/filter", filter),
  create: (invoiceDto: string[]): Promise<void> =>
    requests.post(`invoiceGlobal/create`, invoiceDto),
  downloadList: (filter: IInvoiceGlobalFilter): Promise<void> =>
    requests.download(`invoiceGlobal/download/excel/list`, filter),
};

export default InvoiceGlobal;
