import moment from "moment";
import { IIndicationList } from "./indication";
import { IParameterList } from "./parameter";
import { IPriceListInfoPromo } from "./priceList";
import { IAgenda } from "../common/agenda/utils";

export interface IAppointmentBase {
  citaId: string;
  expedienteId?: string;
}

export interface IAppointmentFilter {
  fechaAlta?: moment.Moment[];
  fechaAInicial?: moment.Moment;
  fechaAFinal?: moment.Moment;
  ciudad?: string[];
  sucursales?: string[];
  correo?: string;
  telefono?: string;
  fechaNacimiento?: moment.Moment;
  fechaNInicial?: moment.Moment;
  fechaNFinal?: moment.Moment;
  expediente?: string;
}

export class AppointmentFilterForm implements IAppointmentFilter {
  fechaAlta = [moment().utcOffset(0, true), moment().utcOffset(0, true)];
  fechaAInicial = moment().utcOffset(0, true);
  fechaAFinal = moment().utcOffset(0, true);
  ciudad = [];
  constructor(init?: IAppointmentFilter) {
    Object.assign(this, init);
  }
}

export interface IAppointment extends Omit<IAppointmentBase, "citaId"> {
  citaId: string;
  tipo: "L" | "D";
  nombreMedico?: string;
  nombreCompania?: string;
  claveMedico?: string;
  observaciones?: string;
  sucursalId: string;
  clave?: string;
  registro?: string;
  estatusId: number;
  activo?: boolean;
  citas?: IAppointmentEvent[];
}

export interface IAppointmentInfo extends IAppointmentBase {
  clave: string;
  tipo: "L" | "D";
  estatusId: number;
  expediente: string;
  paciente: string;
  direccion?: string;
  correo?: string;
  whatsapp?: string;
  fecha: Date;
  activo: boolean;
  estudios: IAppointmentStudyInfo[];
}

export interface IAppointmentEvent extends IAgenda {
  citaId: string;
  tipo?: string;
  cita: IAppointmentInfo;
}

export interface IAppointmentStudyInfo {
  id: number;
  estudioId: number;
  clave: string;
  nombre: string;
}

export class AppointmentStudyInfoForm implements IAppointmentStudyInfo {
  id = 0;
  estudioId = 0;
  clave = "";
  nombre = "";

  constructor(init?: IAppointmentTotal) {
    Object.assign(this, init);
  }
}

export interface IAppointmentGeneral extends IAppointmentBase {
  procedencia: number;
  tipo: "L" | "D";
  compañiaId?: string;
  medicoId?: string;
  metodoEnvio?: string[];
  correo?: string;
  correos?: string[];
  whatsapp?: string;
  whatsapps?: string[];
  observaciones: string;
  activo: boolean;
}

export interface IAppointmentSend extends IAppointmentBase {
  correo?: string;
  telefono?: string;
}

export interface IAppointmentStudyUpdate extends IAppointmentBase {
  estudios: IAppointmentStudy[];
  paquetes?: IAppointmentPack[];
  total?: IAppointmentTotal;
}

export class AppointmentStudyUpdate implements IAppointmentStudyUpdate {
  estudios: IAppointmentStudy[] = [];
  paquetes: IAppointmentPack[] = [];
  total: IAppointmentTotal = new AppointmentTotal();
  citaId: string = "";

  constructor(init?: IAppointmentTotal) {
    Object.assign(this, init);
  }
}

export interface IAppointmentStudy {
  type: "study" | "pack";
  id?: number;
  identificador?: string;
  estudioId: number;
  clave: string;
  nombre: string;
  paqueteId?: number;
  paquete?: string;
  listaPrecioId: string;
  listaPrecio: string;
  promocionId?: number;
  promocion?: string;
  aplicaCargo: boolean;
  dias: number;
  horas: number;
  precio: number;
  descuento?: number;
  descuentoPorcentaje?: number;
  precioFinal: number;
  promociones: IPriceListInfoPromo[];
  parametros: IParameterList[];
  indicaciones: IIndicationList[];
}
export class AppointmentStudyValues implements IAppointmentStudy {
  type: "study" | "pack" = "study";
  id = 0;
  estudioId = 0;
  clave = "";
  nombre = "";
  paqueteId = 0;
  paquete = "";
  listaPrecioId = "";
  listaPrecio = "";
  promocionId = 0;
  promocion = "";
  aplicaCargo = false;
  dias = 0;
  horas = 0;
  precio = 0;
  descuento = 0;
  descuentoPorcentaje = 0;
  precioFinal = 0;
  parametros: IParameterList[] = [];
  indicaciones: IIndicationList[] = [];
  promociones = [];

  constructor(init?: IAppointmentStudy) {
    Object.assign(this, init);
  }
}

export interface IAppointmentPack {
  type: "study" | "pack";
  id?: number;
  identificador?: string;
  paqueteId: number;
  clave: string;
  nombre: string;
  listaPrecioId: string;
  listaPrecio: string;
  promocionId?: number;
  promocion?: string;
  aplicaCargo: boolean;
  dias: number;
  horas: number;
  precio: number;
  descuento: number;
  descuentoPorcentaje: number;
  promocionDescuento?: number;
  promocionDescuentoPorcentaje?: number;
  precioFinal: number;
  promociones: IPriceListInfoPromo[];
  estudios: IAppointmentStudy[];
}

export interface IAppointmentTotal extends IAppointmentBase {
  totalEstudios: number;
  descuento: number;
  total: number;
}

export class AppointmentTotal implements IAppointmentTotal {
  totalEstudios: number = 0;
  descuento: number = 0;
  total: number = 0;
  citaId: string = "";

  constructor(init?: IAppointmentTotal) {
    Object.assign(this, init);
  }
}
