import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import ParameterTable from "../components/parameter/ParameterTable";
import ParameterHeader from "../components/parameter/ParameterHeader";

const Parameter = () => {
  const { parameterStore } = useStore();
  const { exportList, scopes } = parameterStore;
  const [printing, setPrinting] = useState(false);
  const [searchParams] = useSearchParams();
  
  const handleDownload = async () => {
    setPrinting(true);
    var succes = await exportList(searchParams.get("search") ?? "all");
    if (succes) {
      setPrinting(false);
    }
  };

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <div ref={componentRef}>
      <ParameterHeader handleList={handleDownload} handlePrint={handlePrint} scopes={scopes} />
      <Divider className="header-divider" />
      <ParameterTable printing={printing} />
    </div>
  );
};

export default observer(Parameter);
