import {
  Button,
  Dropdown,
  FormInstance,
  MenuProps,
  PageHeader,
  Space,
} from "antd";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";

import HeaderTitle from "../../../../app/common/header/HeaderTitle";
import {
  IInvoice,
  IInvoiceClient,
  IInvoiceDetail,
} from "../../../../app/models/invoice";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import {
  regimenFiscal,
  status as invoiceStatus,
} from "../../../../app/util/catalogs";
import { cancelInvoice } from "../common/cancelInvoice";
import { InvoiceType } from "../utils/utils";
import InvoiceSend from "./InvoiceSend";
import { IValidationPermission } from "../../../../app/models/shared";
import { verifyCancelPermission } from "../../../../app/common/administrator/cancelValidation";

const items: MenuProps["items"] = [
  {
    label: "Descargar",
    key: "download",
  },
  {
    label: "Imprimir",
    key: "print",
  },
];

type Props = {
  id: string | undefined;
  type: InvoiceType;
  invoice: IInvoice | undefined;
  setInvoice: React.Dispatch<React.SetStateAction<IInvoice | undefined>>;
  title: string;
  formInvoice: FormInstance<IInvoice>;
  formTaxData: FormInstance<IInvoiceClient>;
  detail: IInvoiceDetail[];
  requests?: string[];
};

const InvoiceHeader: FC<Props> = ({
  id,
  type,
  invoice,
  setInvoice,
  title,
  formTaxData,
  formInvoice,
  detail,
  requests,
}) => {
  const {
    profileStore,
    modalStore,
    invoiceStore,
    invoiceFreeStore,
    invoiceGlobalStore,
    invoiceRequestCompanyStore,
  } = useStore();
  const { profile } = profileStore;
  const { openModal } = modalStore;
  const { downloadPDF, downloadXML, printPDF, scopes } = invoiceStore;
  // prettier-ignore
  const { create: createFreeInvoice, cancel: cancelFreeInvoice } = invoiceFreeStore;
  const {
    create: createRequestCompanyInvoice,
    cancel: cancelRequestCompanyInvoice,
  } = invoiceRequestCompanyStore;
  const { cancel: cancelGlobalInvoice } = invoiceGlobalStore;

  const [disableCancelInvoice, setDisableCancelInvoice] = useState<boolean>(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (!!profile) {
      if (!profile?.cancelaPagos) {
        setDisableCancelInvoice(true);
        return;
      }
    }
  }, [profile])
  const create = async () => {
    try {
      // prettier-ignore
      await Promise.all([formTaxData.validateFields(), formInvoice.validateFields()])
    } catch (error) {
      alerts.warning("Por favor ingresa todos los campos requeridos");
      return;
    }
    if (detail.length === 0) {
      alerts.warning("Por favor ingresa al menos 1 concepto");
      return;
    }
    // prettier-ignore
    if (
      detail.some((x) => !x.concepto || !x.claveProdServ || !x.unidades || !x.importe)
    ) {
      alerts.warning("Por favor ingresa los campos requeridos para cada uno de los registros de detalle (Clave Prod/Serv, Concepto, Cantidad, Importe)");
      return;
    }

    const invoiceData = formInvoice.getFieldsValue();
    const invoiceTaxData = formTaxData.getFieldsValue();

    const regimen = regimenFiscal.find(
      (x) => x.value === invoiceTaxData.regimenFiscal
    )?.label;

    if (!regimen) {
      alerts.warning("Régimen fiscal inválido");
      return;
    }

    const invoice: IInvoice = {
      ...invoiceData,
      sucursalId: profile!.sucursal,
      cliente: { ...invoiceTaxData, regimenFiscal: regimen.toString() },
      detalle: detail,
    };

    if (type === "free") {
      const newInvoice = await createFreeInvoice(invoice);

      if (newInvoice) {
        navigate(`/invoices/free/${newInvoice.facturaId}`, { replace: true });
      }
    } else if (type === "company" || type === "request") {
      invoice.solicitudes = requests?.map((x) => ({
        solicitudId: x,
      }));
      const id = await createRequestCompanyInvoice(type, invoice);

      if (id) {
        navigate(`/invoices/${type}/${id}`, { replace: true });
      }
    }
  };

  const onCancel = async () => {


    const invoiceId = invoice!.facturaId;
    const ok = await cancelInvoice(
      invoiceId,
      type,
      `${invoice!.serie} ${invoice!.serieNumero}`
    );
    if (!ok) return;

    setInvoice((curr) => ({
      ...curr!,
      estatus: invoiceStatus.invoice.cancelado,
    }));

    if (type === "free") cancelFreeInvoice(invoiceId);
    else if (type === "global") cancelGlobalInvoice(invoiceId);
    else cancelRequestCompanyInvoice(invoiceId);
  };

  const onSend = () => {
    openModal({
      width: 700,
      title: `Envío de Factura ${invoice!.serie} ${invoice!.serieNumero}`,
      body: (
        <InvoiceSend type={type} invoiceId={invoice!.facturaId} companyId="" />
      ),
    });
  };

  const handleMenuClickPdf: MenuProps["onClick"] = (e) => {
    if (e.key === "download") {
      downloadPDF(type, id!);
    } else if (e.key === "print") {
      printPDF(type, id!);
    }
  };

  const handleMenuClickXml: MenuProps["onClick"] = (e) => {
    if (e.key === "download") {
      downloadXML(type, id!);
    }
  };

  const menuPropsPdf = {
    items,
    onClick: handleMenuClickPdf,
  };

  const menuPropsXml = {
    items: items.filter((x) => x!.key === "download"),
    onClick: handleMenuClickXml,
  };



  return (
    <>
      <PageHeader
        ghost
        title={<HeaderTitle title={title} image="invoice-company" />}
        onBack={() => navigate(-1)}
        className="header-container"
        extra={
          !id
            ? [
              <Button key="create" type="primary" onClick={create}>
                Registrar Factura
              </Button>,
            ]
            : [
              <Dropdown
                key="pdf"
                menu={menuPropsPdf}
                placement="bottomRight"
                disabled={!scopes.descargar}
              >
                <Button>
                  <Space>
                    PDF
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>,
              <Dropdown
                key="xml"
                menu={menuPropsXml}
                placement="bottomRight"
                disabled={!scopes.descargar}
              >
                <Button>
                  <Space>
                    XML
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>,
              <Button
                key="send"
                onClick={onSend}
                disabled={!scopes.enviarCorreo && !scopes.enviarWapp}
              >
                Enviar factura
              </Button>,
              invoice &&
              invoice.estatus === invoiceStatus.invoice.facturado && (
                <Button
                  key="cancel"
                  danger
                  ghost
                  onClick={onCancel}
                  disabled={!scopes.modificar}
                >
                  Cancelar factura
                </Button>
              ),
            ]
        }
      ></PageHeader>
    </>
  );
};

export default observer(InvoiceHeader);
