import { observer } from "mobx-react-lite";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import messages from "../util/messages";
import { useCallback, useEffect, useState } from "react";
import Center from "./Center";
import { Spin } from "antd";

const PrivateRoute = () => {
  const { profileStore } = useStore();
  const { token, isLoggedIn, getProfile, getMenu, getScopes } = profileStore;

  const [loading, setLoading] = useState(true);

  const loadUser = useCallback(async () => {
    await Promise.all([getMenu(), getProfile(), getScopes()]);
    setLoading(false);
  }, [getMenu, getProfile, getScopes]);

  useEffect(() => {
    if (token) loadUser();
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let location = useLocation();

  if (loading)
    return (
      <Center>
        <Spin size="large" spinning={loading} />
      </Center>
    );

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        state={{ from: location, message: messages.login }}
      />
    );
  }

  return <Outlet />;
};

export default observer(PrivateRoute);
