import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Upload,
  UploadProps,
} from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import { EditOutlined, UploadOutlined } from "@ant-design/icons";

import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import NumberInput from "../../app/common/form/proposal/NumberInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import TextInput from "../../app/common/form/proposal/TextInput";
import { IInvoicesFreeFilter } from "../../app/models/invoiceLegacy";
import { IOptions } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";
import {
  formItemLayout,
  objectToFormData,
  uploadFakeRequest,
} from "../../app/util/utils";
import { categoryType, creditEstatus } from "./utils";

const allowedFileTypes = [".pdf", ".xml"];

type CreditMonitoringCompanyDataProps = {
  invoicesList: any[];
};

const CreditMonitoringCompanyData = ({
  invoicesList,
}: CreditMonitoringCompanyDataProps) => {
  const {
    profileStore,
    optionStore,
    invoiceCompanyStoreLegacy: invoiceCompanyStore,
    modalStore,
    companyStore,
  } = useStore();
  const { profile } = profileStore;
  const { getById } = companyStore;
  const { createPaymentAddon, scopes } = invoiceCompanyStore;
  const { closeModal } = modalStore;
  const { branchCityOptions, bankOptions, getbankOptions } = optionStore;

  const [form] = Form.useForm<any>();
  const selectedCity = Form.useWatch("ciudad", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [archivos, setArchivos] = useState<UploadFile[]>([]);
  const [saldoMax, setSaldoMax] = useState<number>(0);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const [companyInvoice, setCompanyInvoice] = useState<any>(null);

  useEffect(() => {
    getbankOptions();
  }, []);

  useEffect(() => {
    setSaldoMax(invoicesList[0].saldo);
    setSelectedInvoice(invoicesList[0]);
    form.setFieldsValue(invoicesList[0]);

  }, [invoicesList]);

  useEffect(() => {
    const getCompany = async () => {
      const company = await companyStore.getById(selectedInvoice.compañiaId);
      setCompanyInvoice(company);
    };
    if (
      !!selectedInvoice?.compañiaId &&
      selectedInvoice?.compañiaId !== "00000000-0000-0000-0000-000000000000"
    ) {

      getCompany();
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if (!!companyInvoice && !!bankOptions) {
      form.setFieldsValue({
        banco: bankOptions.find((x) => x.value === companyInvoice.bancoId)
          ?.label,
      });
    }
  }, [companyInvoice, bankOptions]);

  useEffect(() => {
    if (companyInvoice) {

      form.setFieldsValue({
        direccionFiscal: `${companyInvoice?.ciudad ?? ""} ${companyInvoice?.estado ?? ""
          } ${companyInvoice.codigoPostal ?? ""}`.trim(),
        numeroCuenta: companyInvoice?.numeroDeCuenta ?? null,
      });
    }
  }, [companyInvoice]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    setBranchOptions(
      branchCityOptions
        .filter((x) => selectedCity?.includes(x.value as string))
        .flatMap((x) => x.options ?? [])
    );
  }, [branchCityOptions, form, selectedCity]);

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;
    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();
  }, [branchCityOptions]);

  const onFinish = async (payment: any) => {

    const { archivos, ...rest } = payment;
    if (!archivos || archivos.fileList.length === 0) {
      message.error("Debe subir un archivo de complemento de pago");
      return;
    }
    if (archivos.fileList.length < 2) {
      message.error("Debe subir dos archivos de complemento de pago");
      return;
    }
    if (
      archivos.fileList.filter((x: any) => x.name.slice(-4) === ".pdf")
        .length <= 0
    ) {
      message.error("Debe cargar al menos un archivo PDF");
      return;
    }
    if (
      archivos.fileList.filter((x: any) => x.name.slice(-4) === ".xml")
        .length <= 0
    ) {
      message.error("Debe cargar al menos un archivo XML");
      return;
    }

    const values = {
      archivosComplementos: payment.archivos.fileList.map(
        (x: any) => x.originFileObj
      ),
      complementosPago: invoicesList.map((invoice) => {
        return {
          monto: payment.cantidad,
          facturaId: invoice.id,
          cliente: invoice.cliente,
        };
      }),
    };
    const formData = objectToFormData(values);

    await createPaymentAddon(formData);
    closeModal();
  };
  const props: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: true,
    customRequest: uploadFakeRequest,
    onChange: (info) => onChangeFile(info),
    style: { width: "100%", alignSelf: "center" },
    maxCount: 2,
    accept: allowedFileTypes.join(", "),
    listType: "text",
  };

  const onChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status, name, type } = info.file;

    if (info.fileList.length > 2 && status === "uploading") {
      message.error("Solo se pueden subir dos archivos");
      return;
    }
    if (status === "uploading") {
      setUploadingFile(true);
      return;
    } else if (status === "done") {
      setArchivos((x) => [...x, info.file.originFileObj!]);
      setUploadingFile(false);

      // submitFile(info.file.originFileObj!, info.file.name);
    } else if (status === "error") {
      setUploadingFile(false);
      message.error(`Error al cargar el archivo ${info.file.name}.`);
    }
  };

  return (
    <>
      <Form<any>
        {...formItemLayout}
        form={form}
        name="paymentAddon"
        onFinish={onFinish}
        size="small"
        initialValues={selectedInvoice}
      >
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          <Col style={{ textAlign: "right" }}>
            <NumberInput
              formProps={{
                name: "cantidad",
                label: "Cantidad",
              }}
              min={1}
              max={saldoMax}
              formatter={(value) => {
                return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }}
              parser={(value) => {
                const val = value!.replace(/\$\s?|(,*)/g, "");
                return Number(val);
              }}
              required
            // errors={errors.find((x) => x.name === "cantidad")?.errors}
            />
          </Col>
        </Row>
        <div className="status-container">
          <Row gutter={[12, 12]} align="middle">
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "documento",
                  label: "Clave",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "nombre",
                  label: "Nombre",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "rfc",
                  label: "RFC",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "razonSocial",
                  label: "Razón Social",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "formaPago",
                  label: "Forma de pago",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "numeroCuenta",
                  label: "Número de cuenta",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "banco",
                  label: "Banco",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "serie",
                  label: "Serie CFDI",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "usoCFDI",
                  label: "Uso de CFDI",
                }}
              />
            </Col>
            <Col span={8}>
              <TextInput
                readonly
                formProps={{
                  name: "direccionFiscal",
                  label: "Dirección fiscal",
                }}
              />
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Registrar pago
              </Button>
              {/* <Button shape="circle" icon={<EditOutlined />}>
                            </Button> */}
            </Col>
          </Row>
        </div>
        {scopes.crear && (
          <Row justify={"center"}>
            <Col>
              <Form.Item name="archivos" label="" required>
                <Upload {...props}>
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    disabled={archivos.length >= 2}
                    loading={uploadingFile}
                  >
                    Subir complemento de pago
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default observer(CreditMonitoringCompanyData);
