import { makeAutoObservable } from "mobx";

import InvoiceFree from "../api/invoiceFree";
import { IInvoice } from "../models/invoice";
import {
  IInvoiceFreeFilter,
  IInvoiceFreeList,
  InvoiceFreeFilterValues,
} from "../models/invoiceFree";
import alerts from "../util/alerts";
import { status } from "../util/catalogs";
import { getErrors } from "../util/utils";
import { store } from "./store";
import { IScopes, Scopes } from "../models/shared";

export default class InvoiceFreeStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  filter: IInvoiceFreeFilter = new InvoiceFreeFilterValues();
  invoices: IInvoiceFreeList[] = [];

  setFilter = (filter: IInvoiceFreeFilter) => {
    this.filter = filter;
  };

  setInvoices = (invoices: IInvoiceFreeList[]) => {
    this.invoices = invoices;
  };

  addNewInvoice = (invoice: IInvoiceFreeList) => {
    this.invoices = [...this.invoices, invoice].sort((a, b) =>
      a.serie.localeCompare(b.serie)
    );
  };

  getAll = async (filter: IInvoiceFreeFilter) => {
    try {
      const invoices = await InvoiceFree.getAll(filter);
      return invoices;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  create = async (invoice: IInvoice) => {
    try {
      store.invoiceStore.setLoadingDetail(true);
      const newInvoice = await InvoiceFree.create(invoice);
      this.addNewInvoice(newInvoice);
      return newInvoice;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      store.invoiceStore.setLoadingDetail(false);
    }
  };

  cancel = (invoiceId: string) => {
    this.invoices = this.invoices.map((x) => ({
      ...x,
      estatus: x.facturaId === invoiceId ? status.invoice.cancelado : x.estatus,
    }));
  };

  downloadList = async (filter: IInvoiceFreeFilter) => {
    try {
      await InvoiceFree.downloadList(filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
