import "../css/containerInfo.less";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { isNumber, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment";
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Editor } from "react-draft-wysiwyg";

import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";
import {
  IResultPathological,
  ResultPathologicalValues,
} from "../../../app/models/clinicResults";
import { IProceedingForm } from "../../../app/models/Proceeding";
import {
  IRequest,
  IRequestStudy,
  RequestStudyValues,
} from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import { status } from "../../../app/util/catalogs";
import { objectToFormData, toolBarOptions } from "../../../app/util/utils";
import StatusTable from "../content/StatusTable";
import StudyActions from "../content/StudyActions";
import { updateStatus } from "../utils";
import { ClinicalResultsDetailHandle } from "../../../app/models/clinicResults";

const { Text, Title } = Typography;
type ClinicalResultsFormProps = {
  estudio: IRequestStudy;
  paciente: IProceedingForm;
  medico: string;
  claveMedico: string;
  solicitud: IRequest;
  estudioId: number;
  isMarked?: boolean;
  showHeaderTable: boolean;
  activeBranch: string;
};

const baseUrl =
  process.env.REACT_APP_MEDICAL_RECORD_URL + "/images/ResultsPathological";

const ClinicalResultsForm = forwardRef<
  ClinicalResultsDetailHandle,
  ClinicalResultsFormProps
>(
  (
    {
      estudio,
      estudioId,
      paciente,
      medico,
      claveMedico,
      solicitud,
      isMarked,
      showHeaderTable,
      activeBranch,
    },
    ref
  ) => {
    const [disabled, setDisabled] = useState(false);
    const [currentStudy, setCurrentStudy] = useState<IRequestStudy>(
      new RequestStudyValues()
    );
    const [newEstatus, setNewEstatus] = useState<number>(0);
    const [pdf, setPdf] = useState<string>("");
    const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
    const [envioManual, setEnvioManual] = useState<boolean>(false);
    const [prueba, setPrueba] = useState<UploadFile[]>([]);
    const [editorMacroscopica, setEditorMacroscopica] = useState<any>(
      EditorState.createEmpty()
    );
    const [editorMicroscopica, setEditorMicroscopica] = useState<any>(
      EditorState.createEmpty()
    );
    const [loading, setLoading] = useState(false);
    const [editorDiagnostico, setEditorDiagnostico] = useState<any>(
      EditorState.createEmpty()
    );
    const [currentResult, setCurrentResult] = useState<IResultPathological>();
    const [checkedPrint, setCheckedPrint] = useState(false);

    const {
      profileStore,
      optionStore,
      clinicResultsStore,
      requestStore,
      branchStore,
    } = useStore();
    const { profile } = profileStore;
    const {
      createResultPathological,
      updateResultPathological,
      getResultPathological,
      getRequestStudyById,
      updateStatusStudy,
      addSelectedStudy,
      removeSelectedStudy,
      clearSelectedStudies,
      cancelResults,
      pathologicalPdf,
      printResultPathologicalFilePreview,
    } = clinicResultsStore;
    const { getById: getBranchById } = branchStore;
    const { allStudies } = requestStore;
    const { userOptions, getUsersOptions: getUsersByBranchOptions } =
      optionStore;
    const [form] = Form.useForm();

    useEffect(() => {
      clearSelectedStudies();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const loadOptions = async () => {
        await getUsersByBranchOptions();
      };
      loadOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      form.setFieldValue("dr", claveMedico);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claveMedico]);
    // useEffect(() => {
    //   if (isMarked == null) return;
    //   setCheckedPrint(isMarked);
    //   if (currentStudy.estatusId >= status.requestStudy.capturado) {
    //     if (isMarked) {
    //       addSelectedStudy({
    //         id: currentStudy.id!,
    //         tipo: "PATHOLOGICAL",
    //         orden: currentStudy.ordenEstudio,
    //       });
    //     } else {
    //       removeSelectedStudy({
    //         id: currentStudy.id!,
    //         tipo: "PATHOLOGICAL",
    //         orden: currentStudy.ordenEstudio,
    //       });
    //     }
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isMarked]);

    const loadInit = async () => {
      let resultPathological = await getResultPathological(estudio.id!);
      if (resultPathological === null) {
        resultPathological = new ResultPathologicalValues();
      }
      let archivos: RcFile[] = [];
      const cStudy = await getRequestStudyById(estudio.id!);

      setCurrentStudy(cStudy!);
      if (
        resultPathological?.imagenPatologica != null &&
        resultPathological?.imagenPatologica !== ""
      ) {
        archivos = resultPathological?.imagenPatologica
          .split(",")
          .map((str: any) => {
            return {
              uid: uniqueId(),
              name: str,
              status: "done", // custom error message to show
              url: `${baseUrl}/${estudio.id}/${str}`,
            };
          });
      }
      if (resultPathological?.pathologicalPdf) {
        const pdfInfo = {
          requestStudyId: estudio?.id,
        };
        const pdf = await printResultPathologicalFilePreview(pdfInfo);
        setPdf(pdf);
      }
      setPrueba(archivos);
      form.setFieldsValue(resultPathological);
      setCurrentResult(resultPathological);
    };

    useEffect(() => {
      loadInit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estudio, estudioId]);

    useEffect(() => {
      if (currentResult) {
        setEditorMacroscopica(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(currentResult.descripcionMacroscopica))
          )
        );
        setEditorMicroscopica(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(currentResult.descripcionMicroscopica))
          )
        );
        setEditorDiagnostico(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(currentResult.diagnostico))
          )
        );
      }
    }, [currentResult]);

    useEffect(() => {
      setDisabled(!(currentStudy.estatusId === status.requestStudy.solicitado));
    }, [estudio, currentStudy]);

    const guardarReporte = async (
      values: any,
      envioManual: boolean,
      deletePdf?: boolean,
      pdf?: any
    ) => {
      let sucess = false;

      let userName: string | undefined = undefined;
      if (values.usuarioDoctorId) {
        userName = userOptions
          .find((x) => x.value === values.usuarioDoctorId)
          ?.label?.toString();
      }
      const reporteClinico: IResultPathological = {
        solicitudId: solicitud.solicitudId!,
        estudioId: estudio.id!,
        requestStudyId: estudio.id!,
        descripcionMacroscopica: JSON.stringify(
          convertToRaw(editorMacroscopica.getCurrentContent())
        ),
        descripcionMicroscopica: JSON.stringify(
          convertToRaw(editorMicroscopica.getCurrentContent())
        ),
        diagnostico: JSON.stringify(
          convertToRaw(editorDiagnostico.getCurrentContent())
        ),
        muestraRecibida: values.muestraRecibida,
        usuarioDoctorId: values.usuarioDoctorId,
        usuarioDoctor: userName,
        imagenPatologica: prueba,
        listaImagenesCargadas: deletedFiles,
        estatus: newEstatus,
        departamentoEstudio:
          estudio.areaId === 30 ? "HISTOPATOLÓGICO" : "CITOLÓGICO",
        pathologicalPdf: pdf,
        deletePdf: pdf,
        clavePatologica: values.clavePatologica,
      };

      const formData = objectToFormData(reporteClinico);

      if (!!currentResult) {
        sucess = await updateResultPathological(formData, envioManual);
      } else {
        sucess = await createResultPathological(formData);
      }

      return sucess;
    };

    const removeTestFile = (file: any) => {
      const index = prueba.indexOf(file);
      const newFileList = prueba.slice();
      setDeletedFiles((x) => [...x, prueba[index].name!]);
      newFileList.splice(index, 1);
      setPrueba(newFileList);
    };
    const beforeUploadTest = (value: any) => {
      setPrueba((x) => [...x, value]);
      return false;
    };

    const cancelation = async (estado: number) => {
      await updateStatusStudy(currentStudy.id!, estado);
      if (estado === status.requestStudy.solicitado) {
        return cancelResults(currentStudy.id!);
      }
    };

    const onSubmit = async (
      esCancelacion: boolean,
      currentStudy: IRequestStudy,
      envioManual?: boolean,
      deletePdf?: boolean,
      actualPdf?: any
    ) => {
      setLoading(true);
      const isUpdated = await updateStatus(
        esCancelacion,
        currentStudy,
        updateStatusStudy,
        cancelation,
        removeSelectedStudy,
        setCheckedPrint,
        setNewEstatus,
        activeBranch
      );
      let estatus = 0;
      if (currentStudy.estatusId === status.requestStudy.solicitado) {
        estatus = status.requestStudy.capturado;
      }
      if (currentStudy.estatusId === status.requestStudy.capturado) {
        estatus = esCancelacion
          ? status.requestStudy.solicitado
          : status.requestStudy.validado;
      }
      if (currentStudy.estatusId === status.requestStudy.validado) {
        estatus = esCancelacion
          ? status.requestStudy.capturado
          : status.requestStudy.liberado;
      }
      if (currentStudy.estatusId === status.requestStudy.liberado) {
        estatus = esCancelacion
          ? status.requestStudy.validado
          : status.requestStudy.enviado;
      }

      const studyIndex = allStudies.findIndex((o) => o.id == currentStudy.id);
      if (studyIndex > -1) {
        allStudies[studyIndex].estatusId = estatus ?? 0;

        if (estatus == status.requestStudy.capturado) {
          allStudies[studyIndex].capturado = true;
          allStudies[studyIndex].sucursalCaptura = activeBranch;
        }
      }

      if (!esCancelacion) {
        const saveReport = await guardarReporte(
          form.getFieldsValue(),
          envioManual ?? false,
          !deletePdf,
          deletePdf
        );
        if (!saveReport) return;
      }

      await loadInit();
      if (isUpdated) {
        alerts.success("Se ha guardado correctamente");
      }
      setLoading(false);
    };

    const send = () => {
      onSubmit(false, currentStudy, true);
    };

    useImperativeHandle(ref, () => ({
      send,
    }));

    return (
      <Fragment key={estudio.id}>
        <Spin spinning={loading}>
          <Row gutter={[24, 24]} className="study-divider">
            <Col span={24}>
              <StudyActions
                currentStudy={currentStudy}
                setEnvioManual={setEnvioManual}
                setCheckedPrint={setCheckedPrint}
                checkedPrint={checkedPrint}
                isMarked={isMarked}
                submitResults={onSubmit}
                tipoEstudio={"PATHOLOGICAL"}
                isXRay={false}
                currentResult={currentResult}
                formRef={form}
              />
            </Col>
          </Row>
          {currentStudy.estatusId !== status.requestStudy.cancelado ? (
            <Fragment>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <StatusTable currentStudy={currentStudy} />
                </Col>
              </Row>
            </Fragment>
          ) : (
            ""
          )}

          {!!currentResult?.pathologicalPdf ? (
            <>
              <div>
                <object
                  data={pdf}
                  type="application/pdf"
                  width="100%"
                  height="600"
                >
                  alt : <a href={pdf}>test.pdf</a>
                </object>
              </div>
            </>
          ) : (
            <>
              <Row style={{ margin: "20px 0" }}>
                <Col span={24}>
                  <Card className="capture-observartions">
                    <Form
                      form={form}
                      initialValues={currentResult}
                      onFinish={(values) => guardarReporte(values, false)}
                      onValuesChange={(changes_values: any) => {
                        setDisabled(
                          !form.isFieldsTouched() ||
                            form
                              .getFieldsError()
                              .filter(({ errors }) => errors.length).length > 0
                        );
                      }}
                    >
                      <Row justify="space-between" gutter={[2, 12]}>
                        <Col span={8}>
                          <Text key="expediente">
                            Médico: <Text strong>{medico}</Text>
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text key="expediente">
                            Fecha:{" "}
                            <Text strong>
                              {moment().utcOffset(0, true).format("LL")}
                            </Text>
                          </Text>
                        </Col>
                      </Row>
                      <Row justify="space-between" gutter={[2, 12]}>
                        <Col span={8}>
                          <Text key="expediente">
                            Paciente: <Text strong>{paciente.nombre}</Text>
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text key="expediente">
                            Edad: <Text strong>{paciente.edad} años.</Text>
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Text key="expediente">
                            {/* Estudio: <Text strong>{currentStudy.nombre}</Text> */}
                            Estudio: <Text strong>{estudio.nombre}</Text>
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Text key="expediente">
                            {/* Clave: <Text strong>{estudio.clave}</Text> */}
                            Clave: <Text strong>{estudio.clave}</Text>
                          </Text>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col span={6}>
                          <Title level={5}>
                            REPORTE DE ESTUDIO
                            {estudio.areaId === 30
                              ? " HISTOPATOLÓGICO "
                              : " CITOLÓGICO "}
                          </Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2}>
                          <Text key="expediente">Muestra recibida:</Text>
                        </Col>
                        <Col span={5}>
                          <TextInput
                            formProps={{ name: "muestraRecibida" }}
                            readonly={disabled}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Text key="expediente">DESCRIPCIÓN MACROSCÓPICA</Text>
                          <Col span={24}>
                            <Editor
                              editorState={editorMacroscopica}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              onEditorStateChange={setEditorMacroscopica}
                              readOnly={disabled}
                              toolbar={toolBarOptions}
                            />
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Text key="expediente">DESCRIPCIÓN MICROSCÓPICA</Text>
                          <Editor
                            editorState={editorMicroscopica}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={setEditorMicroscopica}
                            readOnly={disabled}
                            toolbar={toolBarOptions}
                          />
                        </Col>
                      </Row>
                      {estudio.areaId === 30 && (
                        <Row
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <Col span={24}>
                            <Upload
                              multiple
                              listType="picture"
                              beforeUpload={beforeUploadTest}
                              onRemove={removeTestFile}
                              className="upload-list-inline"
                              // defaultFileList={prueba}
                              fileList={prueba}
                            >
                              <Button type="primary" disabled={disabled}>
                                Añadir imagen(es) +
                              </Button>
                            </Upload>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col span={24}>
                          <Text key="expediente">DIAGNÓSTICO</Text>
                          <Editor
                            editorState={editorDiagnostico}
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={setEditorDiagnostico}
                            readOnly={disabled}
                            toolbar={toolBarOptions}
                          />
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col span={2}>
                          <Text key="expediente">Atentamente.</Text>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col span={4}>
                          <SelectInput
                            formProps={{
                              name: "usuarioDoctorId",
                              label: "Dr.",
                            }}
                            options={userOptions}
                            readonly={disabled}
                          ></SelectInput>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </Fragment>
    );
  }
);
export default observer(ClinicalResultsForm);
