import { InputNumber, Select, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";

import IconButton from "../../../../../app/common/button/IconButton";
import { IColumns } from "../../../../../app/common/table/utils";
import { ICatalogDescriptionForm } from "../../../../../app/models/catalog";
import {
  IInvoice,
  IInvoiceDetail,
  IInvoiceTotal,
} from "../../../../../app/models/invoice";
import { useStore } from "../../../../../app/stores/store";
import { groupBy, moneyFormatter } from "../../../../../app/util/utils";
import {
  defaultConceptOptions,
  productServiceOptions,
} from "../../utils/options";
import { InvoiceType } from "../../utils/utils";
import ConceptRender from "./ConceptRender";
import { IConcept } from "./InvoiceConcept";

const { Text } = Typography;

type Props = {
  isNew: boolean;
  type: InvoiceType;
  invoice?: IInvoice;
  detail: IInvoiceDetail[];
  setDetail: React.Dispatch<React.SetStateAction<IInvoiceDetail[]>>;
};

const InvoiceConceptDetail = ({
  isNew,
  type,
  invoice,
  detail,
  setDetail,
}: Props) => {
  const { optionStore, catalogStore } = useStore();
  const { createLocal } = catalogStore;
  const {
    invoiceConceptsOptions,
    getInvoiceConceptsOptions,
    addInvoiceConceptsOptions,
  } = optionStore;

  const [totals, setTotals] = useState<IInvoiceTotal>({
    subtotal: 0,
    impuestos: [],
    total: 0,
  });

  useEffect(() => {
    if (invoiceConceptsOptions.length === 0) getInvoiceConceptsOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoice) {
      setTotals({
        subtotal: invoice.subtotal,
        impuestos: invoice.impuestos,
        total: invoice.total,
      });
    } else {
      const total = detail.reduce((acc, obj) => acc + obj.importe, 0);
      if (total === totals.total) return;
      const taxes = groupBy(detail, "tasaIva")
        .sort((a, b) => +b.key - +a.key)
        .map((x) => {
          const currTotal = x.items.reduce((acc, obj) => acc + obj.importe, 0);
          return {
            tasa: `IVA ${+x.key * 100}%`,
            importe: Number((currTotal - currTotal / (1 + +x.key)).toFixed(2)),
          };
        });
      const subtotal = total - taxes.reduce((acc, obj) => acc + obj.importe, 0);

      setTotals({
        subtotal: subtotal,
        impuestos: taxes,
        total: total,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, detail]);

  const conceptOptions = [
    ...defaultConceptOptions,
    ...invoiceConceptsOptions,
  ].sort((a, b) => a.label!.toString().localeCompare(b.label!.toString()));

  const onChangeProdServ = (id: string, newProdServ: string) => {
    setDetail((detail) =>
      detail.map((x) => ({
        ...x,
        claveProdServ: x.id !== id ? x.claveProdServ : newProdServ,
      }))
    );
  };

  const onChangeAmount = (id: string, amount: number) => {
    const data = detail.find((x) => x.id === id);
    if (!data) return;

    const price = amount;
    const subPrice = Number((price / (1 + data.tasaIva)).toFixed(2));
    const unitPrice = Number((amount / data.unidades).toFixed(2));
    const subUnitPrice = Number((unitPrice / (1 + data.tasaIva)).toFixed(2));

    setDetail((detail) =>
      detail.map((x) => ({
        ...x,
        importeUnitario: x.id !== id ? x.importeUnitario : unitPrice,
        subtotalUnitario: x.id !== id ? x.subtotalUnitario : subUnitPrice,
        importe: x.id !== id ? x.importe : price,
        subtotal: x.id !== id ? x.subtotal : subPrice,
      }))
    );
  };

  const onChangeConcept = (id: string, selectedOptions: string[]) => {
    setDetail((detail) =>
      detail.map((x) => ({
        ...x,
        concepto: x.id !== id ? x.concepto : selectedOptions.join("\n"),
      }))
    );
  };

  const onChangeConceptManual = (id: string, text: string) => {
    setDetail((detail) =>
      detail.map((x) => ({
        ...x,
        concepto: x.id !== id ? x.concepto : text,
      }))
    );
  };

  const deleteConcept = (id: string) => {
    setDetail((detail) => detail.filter((x) => x.id !== id));
  };

  const addConcept = async ({ alias, descripcion }: IConcept) => {
    const descriptionInvoice: ICatalogDescriptionForm = {
      id: 0,
      clave: alias,
      nombre: alias,
      descripcion: descripcion,
      activo: true,
    };
    const newCatalog = await createLocal("invoiceconcepts", descriptionInvoice);
    if (newCatalog) {
      descriptionInvoice.id = newCatalog.id;
      addInvoiceConceptsOptions(descriptionInvoice);
    }
  };

  const columns: IColumns<IInvoiceDetail> = [
    {
      dataIndex: "claveProdServ",
      title: "Clave Prod/Serv.",
      width: "30%",
      render: (value, data) =>
        !isNew ? (
          value
        ) : (
          <Select
            showArrow={true}
            size="small"
            bordered={false}
            style={{ width: "100%" }}
            value={value}
            onChange={(code) => onChangeProdServ(data.id, code)}
            options={productServiceOptions}
          />
        ),
    },
    {
      dataIndex: "concepto",
      key: "concepto",
      title: "Concepto",
      width: "30%",
      className: "no-padding-cell",
      render: (concept, data) =>
        !isNew ? (
          concept
        ) : (
          <ConceptRender
            data={data}
            conceptOptions={conceptOptions}
            onChangeConcept={onChangeConcept}
            onChangeConceptManual={onChangeConceptManual}
            addConcept={addConcept}
          />
        ),
    },
    {
      dataIndex: "unidades",
      title: "Cantidad",
      width: "10%",
    },
    {
      dataIndex: "subtotalUnitario",
      title: "Importe unitario",
      width: "10%",
    },
    {
      dataIndex: "subtotal",
      title: "Importe",
      width: "10%",
    },
    {
      dataIndex: "importe",
      title: "Importe (Con IVA)",
      width: "10%",
      align: "right",
      render: (amount, data) =>
        !isNew ? (
          moneyFormatter.format(amount)
        ) : (
          <InputNumber<number>
            value={amount}
            min={1}
            precision={2}
            controls={false}
            className="input-number-align-right"
            style={{ width: "100%" }}
            readOnly={type === "request"}
            formatter={(value) => {
              return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }}
            parser={(value) => {
              const val = value!.replace(/\$\s?|(,*)/g, "");
              return Number(val);
            }}
            onChange={(e) => {
              onChangeAmount(data.id, e ?? 1);
            }}
            bordered={false}
          />
        ),
    },
    {
      dataIndex: "delete",
      title: "Quitar",
      width: "10%",
      align: "center",
      hidden: !isNew || type === "request",
      render: (_, data) => (
        <IconButton
          title="Eliminar"
          danger
          icon={<DeleteOutlined />}
          onClick={() => deleteConcept(data.id)}
        />
      ),
    },
  ];

  return (
    <Table<IInvoiceDetail>
      size="small"
      bordered
      rowKey={(record) => record.id}
      pagination={false}
      dataSource={detail}
      columns={columns.filter((x) => !x.hidden)}
      summary={() => <TableFooter totals={totals} />}
    />
  );
};

export default InvoiceConceptDetail;

type TableFooterProps = {
  totals: IInvoiceTotal;
};

const TableFooter = ({ totals }: TableFooterProps) => {
  return (
    <Table.Summary fixed="top">
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={5} align="right">
          Subtotal :
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1} align="right">
          <Text>{moneyFormatter.format(totals.subtotal)}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      {totals.impuestos.map((x) => (
        <Table.Summary.Row key={x.tasa.toString()}>
          <Table.Summary.Cell index={0} colSpan={5} align="right">
            {x.tasa} :
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={1} align="right">
            <Text>{moneyFormatter.format(x.importe)}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={5} align="right">
          Total (Con IVA) :
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1} align="right">
          <Text>{moneyFormatter.format(totals.total)}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
