import { Col, Divider, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { IInvoiceCredit } from "../../../app/models/invoiceCredit";
import CreditMonitoringInvoiceDetail from "./CreditMonitoringInvoiceDetail";
import CreditMonitoringInvoiceForm from "./CreditMonitoringInvoiceForm";

const { Text, Title } = Typography;

type Props = {
  invoice: IInvoiceCredit;
};

const CreditMonitoringInvoice = ({ invoice }: Props) => {
  return (
    <>
      <CreditMonitoringInvoiceDetail invoice={invoice} />
      <Divider />
      <CreditMonitoringInvoiceForm invoice={invoice} />
    </>
  );
};

export default observer(CreditMonitoringInvoice);
