import { Col, Divider, Form, Input, InputNumber, Row } from "antd";
import { FC, useEffect, useState } from "react";

import { ItipoValorForm } from "../../../../app/models/parameter";
import { numberRules } from "../../../../app/util/utils";
import { useSearchParams } from "react-router-dom";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const ValorRNumerico: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  const [formValue] = Form.useForm<ItipoValorForm>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "ReadOnly"
  );

  useEffect(() => {
    setReadonly(searchParams.get("mode") === "ReadOnly");
  }, [searchParams]);

  const onValuesChange = (property: string, value: string) => {
    setParameterValues([
      {
        ...parameterValues[0],
        [property]: value,
        nombre: valueType,
        orden: 1,
      },
    ]);
  };

  if (parameterValues.length === 0) return null;

  return (
    <div>
      <Divider orientation="left">Valores de referencia (Numérico):</Divider>
      <Form<ItipoValorForm>
        form={formValue}
        layout={"vertical"}
        name={`form-${valueType}`}
        scrollToFirstError
        initialValues={parameterValues[0]}
      >
        <Row>
          <Col md={24} sm={24} xs={12} style={{ marginTop: 20 }}>
            <Row justify="center" gutter={[24, 4]}>
              <Form.Item hidden name={"id"}>
                <InputNumber disabled={true} />
              </Form.Item>
              <Col span={6}>
                <Form.Item
                  name="valorInicial"
                  label="Valor Inicial"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor Inicial"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].valorInicial}
                    onChange={(e) =>
                      onValuesChange("valorInicial", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="valorFinal"
                  label="Valor Final"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor Final"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].valorFinal}
                    onChange={(e) =>
                      onValuesChange("valorFinal", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="criticoMinimo"
                  label="Valor Crítico Mínimo"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor Crítico Mínimo"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].criticoMinimo}
                    onChange={(e) =>
                      onValuesChange("criticoMinimo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="criticoMaximo"
                  label="Valor Crítico Máximo"
                  rules={numberRules}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Valor Crítico Máximo"
                    allowClear
                    disabled={readonly}
                    value={parameterValues[0].criticoMaximo}
                    onChange={(e) =>
                      onValuesChange("criticoMaximo", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default ValorRNumerico;
