import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import BranchHeader from "../components/branch/BranchHeader";
import BranchTable from "../components/branch/BranchTable";
import alerts from "../app/util/alerts";
import messages from "../app/util/messages";
const Branch = () => {
  const { branchStore } = useStore();
  const { exportList, scopes } = branchStore;
  const [printing, setPrinting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef<any>();

  const handleDownload = async () => {
    setPrinting(true);

    if (!scopes.descargar) {
      alerts.warning(messages.forbidden);
      setPrinting(false);
      return;
    }

    let succes = await exportList(searchParams.get("search") ?? "all");
    if (succes) {
      setPrinting(false);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <BranchHeader handlePrint={handlePrint} handleList={handleDownload} scopes={scopes} />
      <Divider className="header-divider" />
      <BranchTable componentRef={componentRef} printing={printing} />
    </Fragment>
  );
};

export default Branch;
