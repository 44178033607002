import { Button, Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinkTo from "../../../app/common/link/LinkTo";
import {
	getDefaultColumnProps,
	IColumns,
} from "../../../app/common/table/utils";
import { IAppointmentInfo } from "../../../app/models/appointment";
import { useStore } from "../../../app/stores/store";
import views from "../../../app/util/view";

type ProceedingAppointmentsProps = {
  loading: boolean;
  readonly: boolean;
  recordId: string;
};

const ProceedingAppointments = ({
  loading,
  readonly,
  recordId,
}: ProceedingAppointmentsProps) => {
  const { procedingStore, appointmentStore, requestStore } = useStore();
  const { getAppointments } = procedingStore;
  const { scopes: appointmentScopes, convertToRequest } = appointmentStore;
  const { scopes: requestScopes } = requestStore;

  const navigate = useNavigate();

  const [converting, setConverting] = useState(false);
  const [appointments, setAppointments] = useState<IAppointmentInfo[]>([]);

  useEffect(() => {
    const readAppointments = async () => {
      const appointments = await getAppointments(recordId);
      setAppointments(appointments);
    };

    readAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  const columns: IColumns<IAppointmentInfo> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        width: 200,
      }),
      render: (value, appointment) => (
        <LinkTo
          to={`/${views.appointment}/${appointment.citaId}`}
          text={value}
          canRedirect={appointmentScopes.acceder}
        />
      ),
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre del paciente", {
        width: 200,
      }),
    },
    {
      ...getDefaultColumnProps("correo", "Email", {
        width: 150,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("whatsapp", "Whatsapp", {
        width: 150,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha", {
        width: 200,
      }),
    },
    {
      ...getDefaultColumnProps("expediente", "Expediente", {
        width: 100,
      }),
    },
    {
      key: "convertir",
      dataIndex: "id",
      title: "Convertir",
      align: "center",
      width: 100,
      render: (_value, appointment) =>
        requestScopes.acceder &&
        requestScopes.crear && (
          <Button
            type="primary"
            title=""
            onClick={async () => {
              setConverting(true);
              const requestId = await convertToRequest(appointment.citaId);
              setConverting(false);
              if (requestId && recordId) {
                navigate(`/${views.request}/${recordId}/${requestId}`);
              }
            }}
            disabled={!appointment.activo || readonly}
          >
            Convertir a solicitud
          </Button>
        ),
    },
  ];

  return (
    <>
      <Divider orientation="left">Citas</Divider>
      <Table<IAppointmentInfo>
        loading={loading || converting}
        size="small"
        rowKey={(record) => record.citaId}
        columns={columns}
        dataSource={appointments}
        pagination={false}
        sticky
        scroll={{
          x: "max-content",
          y: 400,
        }}
      />
    </>
  );
};

export default observer(ProceedingAppointments);
