import { Form, Row, Col, Button, Spin, Input } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import DateInput from "../../app/common/form/proposal/DateInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import SwitchInput from "../../app/common/form/proposal/SwitchInput";
import TimeRangeInput from "../../app/common/form/proposal/TimeRangeInput";
import { ICashRegisterFilter } from "../../app/models/cashRegister";
import { IOptions } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";
import { formItemLayout } from "../../app/util/utils";
import { toJS } from "mobx";

const typeCompanyOptions: IOptions[] = [
  {
    value: 1,
    label: "Convenio",
  },
  {
    value: 2,
    label: "Particulares",
  },
];

type CRFProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const CashRegisterFilter = ({ setLoading }: CRFProps) => {
  const { cashRegisterStore, optionStore, profileStore } = useStore();
  const { filter, setFilter, getByFilter, setShowChart } = cashRegisterStore;
  const { branchCityOptions, getBranchCityOptions } = optionStore;
  const { profile } = profileStore;

  const [form] = Form.useForm<ICashRegisterFilter>();
  const chartValue = Form.useWatch("grafica", form);
  const selectedCity = Form.useWatch("ciudad", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    getBranchCityOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;
    setLoading(true);
    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();

    const newFilter = {
      ...filter,
      ciudad: [userCity],
      sucursalId: [profileBranch],
    };

    form.setFieldsValue(newFilter);
    setFilter(newFilter);
    getByFilter(newFilter).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchCityOptions]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    if (selectedCity != undefined && selectedCity != null) {
      var branches = branchCityOptions.filter((x) =>
        selectedCity.includes(x.value.toString())
      );
      var options = branches.flatMap((x) =>
        x.options == undefined ? [] : x.options
      );
      setBranchOptions(options);
    }
  }, [branchCityOptions, form, selectedCity]);

  useEffect(() => {
    setShowChart(chartValue);
  }, [chartValue]);

  const onFinish = async (filter: ICashRegisterFilter) => {
    setLoading(true);
    await getByFilter(filter);
    setFilter(filter);
    setLoading(false);
  };

  return (
    <div className="status-container">
      <Form<ICashRegisterFilter>
        {...formItemLayout}
        form={form}
        name="cash"
        initialValues={filter}
        onFinish={onFinish}
      >
        <Row justify="space-between" gutter={[12, 12]}>
          <Col span={8}>
            <DateInput
              formProps={{ label: "Fecha", name: "fechaIndividual" }}
              required={true}
            />
          </Col>
          <Col span={8}>
            <TimeRangeInput
              formProps={{ label: "Hora", name: "hora" }}
              required={true}
            />
          </Col>
          <Col span={8}>
            <Form.Item label="Sucursal" className="no-error-text" help="">
              <Input.Group>
                <Row gutter={8}>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "ciudad",
                        label: "Ciudad",
                        noStyle: true,
                      }}
                      multiple
                      options={cityOptions}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "sucursalId",
                        label: "Sucursales",
                        noStyle: true,
                      }}
                      multiple
                      options={branchOptions}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              formProps={{ name: "tipoCompañia", label: "Convenio" }}
              multiple
              options={typeCompanyOptions}
            />
          </Col>
          <Col span={8}>
            {/* <SwitchInput name="grafica" label="Gráfica" /> */}
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button key="new" type="primary" htmlType="submit">
              Mostrar listado
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(CashRegisterFilter);
