const views = {
  reagent: "reagents",
  tag: "tags",
  study: "studies",
  medic: "medics",
  pack: "packs",
  price: "prices",
  promo: "promos",
  loyalty: "loyalties",
  proceeding: "expedientes",
  route: "routes",
  appointment: "appointments",
  request: "requests",
  quotation: "cotizacion",
  report: "reportes",
  cash: "cash",
  sampling: "samplings",
  routeTracking: "segRutas",
  shipmenttracking: "ShipmentTracking",
  recivetracking: "receiveTracking",
  historytracking: "historyTracking",
  workLists: "workLists",
  resultValidation: "results/validation",
  relaseValidation: "results/release",
  invoiceCatalog: "invoiceCatalog",
  reportStudy: "reportstudy",
  parameters: "parameters",
  notifications: "notifications",
};

export default views;
