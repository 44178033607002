import {
	IInvoiceCatalog,
	IInvoiceCatalogFilter,
} from "../models/invoiceCatalog";
import requests from "./agent";

const InvoiceCatalog = {
  getAll: (filter: IInvoiceCatalogFilter): Promise<IInvoiceCatalog[]> =>
    requests.post(`invoiceCatalog/filter`, filter),
  downloadInvoice: (invoiceId: string): Promise<void> =>
    requests.download(`invoiceCatalog/download/invoice/${invoiceId}`),
  printTicket: (recordId: string, requestId: string): Promise<void> =>
    requests.print(`invoiceCatalog/download/ticket/${recordId}/${requestId}`),
  downloadList: (filter: IInvoiceCatalogFilter): Promise<void> =>
    requests.download(`invoiceCatalog/download/excel/list`, filter),
};
export default InvoiceCatalog;
