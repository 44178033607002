import { Alert, Card, Col, Image, Row, Statistic, Table, Typography } from "antd";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useReactToPrint } from "react-to-print";

import Notifications from "../app/api/notiofications";
import SelectInput from "../app/common/form/proposal/SelectInput";
import {
  defaultPaginationProperties,
  defaultPaginationPropertiesSmall,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../app/common/table/utils";
import { IDashBoard } from "../app/models/dashboard";
import { IGeneralForm } from "../app/models/general";
import { INotificationsList } from "../app/models/notifications";
import { ISearchTracking } from "../app/models/routeTracking";
import { useStore } from "../app/stores/store";
import alerts from "../app/util/alerts";
import { getErrors } from "../app/util/utils";
import DashboardChart from "../components/dashboard/dashboardChart";
import AppointmentCalendar from "../components/dashboard/dashCalendar";

const { Text } = Typography;

const Home = () => {
  const { currentTheme } = useThemeSwitcher();

  const { profileStore } = useStore();
  const { profile } = profileStore;

  const [printing, setPrinting] = useState(false);
  const componentRef = useRef<any>();

  const { requestStore } = useStore();
  const { getRequests, getRequestsDashboard } = requestStore;
  const [calendar, setCalendar] = useState<boolean>(false);
  const [vista, setVista] = useState<number>(1);
  const [citas, setCitas] = useState<number>(0);
  const [enviar, setEnviar] = useState<number>(0);
  const [recibir, setRecibir] = useState<number>(0);
  const [solicitudes, setSolicitudes] = useState<number>(0);
  const [proxCierre, setProxCierre] = useState<number>(0);
  const [calendarType, setCalendarType] = useState<"week" | "date">("date");
  const [data, setData] = useState<IDashBoard[]>([]);
  const [notifications, setNotifications] = useState<INotificationsList[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readRequest = async () => {
      var temp: IDashBoard[] = [
        {
          pendiente: undefined,
          toma: undefined,
          ruta: undefined,
          solicitud: undefined,
          capturado: undefined,
          validado: undefined,
          liberado: undefined,
          enviado: undefined,
          entregado: undefined,
        },
      ];

      var filter: IGeneralForm = {
        fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
        tipoFecha: 1,
      };
      if (vista == 2) {
        var weeknumber = moment().utcOffset(0, true).week();
        var primer = moment().utcOffset(0, true).isoWeek(weeknumber).startOf("W");
        var final = moment().utcOffset(0, true).isoWeek(weeknumber).endOf("W").subtract(1, "d");
        filter = { fecha: [primer, final], tipoFecha: 1 };
      }
      var requests = await getRequestsDashboard(filter);

      setSolicitudes(requests!.length);

      var cierre = 0;
      requests?.forEach((solicitud) =>
        solicitud.estudios.forEach((x) => {
          if (x.estatusId == 6 || x.estatusId == 7 || x.estatusId == 10) {
            cierre++;
          }
        })
      );
      requests?.forEach((solicitud) =>
        solicitud.estudios.forEach((x) => {
          switch (x.estatusId) {
            case 1: {
              var datos = temp;
              if (datos[0].pendiente == undefined) {
                datos[0].pendiente = 0;
              }

              datos[0].pendiente++;
              setData(datos);
              break;
            }
            case 2: {
              var datos = temp;
              if (datos[0].toma == undefined) {
                datos[0].toma = 0;
              }

              datos[0].toma++;
              setData(datos);
              break;
            }
            case 3: {
              var datos = temp;
              if (datos[0].solicitud == undefined) {
                datos[0].solicitud = 0;
              }

              datos[0].solicitud++;
              setData(datos);
              break;
            }
            case 4: {
              var datos = temp;
              if (datos[0].capturado == undefined) {
                datos[0].capturado = 0;
              }
              datos[0].capturado++;
              setData(datos);
              break;
            }
            case 5: {
              var datos = temp;
              if (datos[0].validado == undefined) {
                datos[0].validado = 0;
              }
              datos[0].validado++;
              setData(datos);
              break;
            }
            case 6: {
              var datos = temp;
              if (datos[0].liberado == undefined) {
                datos[0].liberado = 0;
              }
              datos[0].liberado++;
              setData(datos);
              break;
            }
            case 7: {
              var datos = temp;
              if (datos[0].enviado == undefined) {
                datos[0].enviado = 0;
              }
              datos[0].enviado++;
              setData(datos);
              break;
            }
            case 8: {
              var datos = temp;
              if (datos[0].ruta == undefined) {
                datos[0].ruta = 0;
              }
              datos[0].ruta++;
              setData(datos);
              break;
            }
            case 10: {
              var datos = temp;
              if (datos[0].entregado == undefined) {
                datos[0].entregado = 0;
              }
              datos[0].entregado++;
              setData(datos);
              break;
            }
            default: {
              //statementss;
              break;
            }
          }
        })
      );
      setProxCierre(cierre);
    };
    readRequest();
  }, [getRequests, vista]);

  const notificationColumns: IColumns<INotificationsList> = [
    {
      ...getDefaultColumnProps("fecha", "Fecha", {
        searchState,
        setSearchState,
        width: 80,
      }),
    },
    {
      ...getDefaultColumnProps("titulo", "Título", {
        searchState,
        setSearchState,
        width: 80,
      }),
    },
    {
      ...getDefaultColumnProps("contenido", "Contenido", {
        searchState,
        setSearchState,
        width: 300,
      }),
    },
  ];

  useEffect(() => {
    const readNotifications = async () => {
      if (!profile || !profile.sucursal) {
        return;
      }
      try {
        const notifications = await Notifications.getCurrentNotices();
        setNotifications(notifications);
      } catch (error) {
        alerts.warning(getErrors(error));
      }
    };
    readNotifications();
  }, [profile]);

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        {notifications && notifications.length > 0 && (
          <Col span={24}>
            <Alert
              message="AVISOS"
              style={{
                border: "2px solid grey",
                borderRadius: 5,
                marginBottom: 12,
                backgroundColor: currentTheme === "light" ? "#d9d9d9" : "#1d1d1d",
                textAlign: "center",
              }}
            />
            <Table
              size="small"
              columns={notificationColumns}
              dataSource={[...notifications]}
              pagination={defaultPaginationPropertiesSmall}
            />
          </Col>
        )}
        <Col md={6} offset={18}>
          <SelectInput
            formProps={{ name: "fecha", label: "Ver por" }}
            options={[
              { value: 1, label: "Diario" },
              { value: 2, label: "Semanal" },
            ]}
            onChange={(values) => {
              setVista(values);
              if (values == 2) {
                setCalendarType("week");
              } else {
                setCalendarType("date");
              }
            }}
            defaultValue={1}
          />
        </Col>
        <Col md={8}>
          <Card
            onClick={() => {
              setCalendar(!calendar);
            }}
            className="dashboard-card"
          >
            <Statistic
              title={
                <Fragment>
                  <Row className="dashboard-card-title" justify="space-between" align="middle">
                    <Col span={12}>
                      <Text className="dashboard-card-text">Citas</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={`${process.env.REACT_APP_NAME}/assets/citas.png`}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              }
              value={citas}
              valueStyle={{ textAlign: "center", fontWeight: "500" }}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card className="dashboard-card">
            <Statistic
              title={
                <Fragment>
                  <Row className="dashboard-card-title" justify="space-between" align="middle">
                    <Col span={12}>
                      <Text className="dashboard-card-text">Solicitudes</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={`${process.env.REACT_APP_NAME}/assets/solicitud.png`}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              }
              value={solicitudes}
              valueStyle={{ textAlign: "center", fontWeight: "500" }}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card className="dashboard-card">
            <Statistic
              title={
                <Fragment>
                  <Row className="dashboard-card-title" justify="space-between" align="middle">
                    <Col span={12}>
                      <Text className="dashboard-card-text">Prox. a cierre</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={`${process.env.REACT_APP_NAME}/assets/cierre.png`}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              }
              value={proxCierre}
              valueStyle={{ textAlign: "center", fontWeight: "500" }}
            />
          </Card>
        </Col>
        <Col md={vista == 2 && !calendar ? 18 : 16}>
          {calendar && (
            <AppointmentCalendar
              type={calendarType}
              componentRef={componentRef}
              printing={printing}
            ></AppointmentCalendar>
          )}
          {!calendar && (
            <Fragment>
              <DashboardChart<IDashBoard>
                data={data as IDashBoard[]}
                series={[
                  { title: "Pendiente", dataIndex: "pendiente" },
                  { title: "Toma", dataIndex: "toma" },
                  { title: "En ruta", dataIndex: "ruta" },
                  { title: "Solicitado", dataIndex: "solicitud" },
                  { title: "Capturado", dataIndex: "capturado" },
                  { title: "Validado", dataIndex: "validado" },
                  { title: "Liberado", dataIndex: "liberado" },
                  { title: "Enviado", dataIndex: "enviado" },
                  { title: "Entregado", dataIndex: "entregado" },
                ]}
                axisLabel={{ interval: 0, rotate: 0 }}
              ></DashboardChart>
            </Fragment>
          )}
        </Col>
        <Col span={8}>
          <Card className="dashboard-card">
            <Statistic
              title={
                <Fragment>
                  <Row className="dashboard-card-title" justify="space-between" align="middle">
                    <Col span={12}>
                      <Text className="dashboard-card-text">Muestras a enviar</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={`${process.env.REACT_APP_NAME}/assets/enviar.png`}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              }
              value={enviar}
              valueStyle={{ textAlign: "center", fontWeight: "500" }}
            />
          </Card>
          <Card className="dashboard-card">
            <Statistic
              title={
                <Fragment>
                  <Row className="dashboard-card-title" justify="space-between" align="middle">
                    <Col span={12}>
                      <Text className="dashboard-card-text">Muestras a recibir</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={`${process.env.REACT_APP_NAME}/assets/recibir.png`}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              }
              value={recibir}
              valueStyle={{ textAlign: "center", fontWeight: "500" }}
            />
          </Card>
        </Col>
        {vista == 2 && !calendar && (
          <Col md={6}>
            <div
              style={{
                background: "#253B65",
                height: "auto",
                borderStyle: "solid",
                borderColor: "#CBC9C9",
                borderWidth: "1px",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "20%",
                width: "70%",
                marginLeft: "30%",
                color: "#F0F0F0",
              }}
            >
              <b style={{ fontSize: "20px" }}>
                Enviadas
                <Image
                  width={40}
                  style={{ marginLeft: "270%" }}
                  src={`${process.env.REACT_APP_NAME}/assets/enviadas.png`}
                  preview={false}
                />
              </b>
            </div>
            <div
              style={{
                marginLeft: "36%",
                textAlign: "center",
                height: "auto",
                borderColor: "#CBC9C9",
                borderWidth: "1px",
                borderRadius: "10px",
                padding: "10px",
                width: "57%",
              }}
            >
              {data.length > 0 ? (
                <b style={{ fontSize: "25px" }}>
                  {data[0].enviado == undefined ? data[0].enviado : 0}
                </b>
              ) : (
                <b style={{ fontSize: "25px" }}>0</b>
              )}
            </div>
            <div
              style={{
                background: "#253B65",
                height: "auto",
                borderStyle: "solid",
                borderColor: "#CBC9C9",
                borderWidth: "1px",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "5%",
                width: "70%",
                marginLeft: "30%",
                color: "#F0F0F0",
              }}
            >
              <b style={{ fontSize: "20px" }}>
                Entregadas
                <Image
                  width={40}
                  style={{ marginLeft: "220%" }}
                  src={`${process.env.REACT_APP_NAME}/assets/recibidas.png`}
                  preview={false}
                />
              </b>
            </div>
            <div
              style={{
                marginLeft: "36%",
                textAlign: "center",
                height: "auto",
                borderColor: "#CBC9C9",
                borderWidth: "1px",
                borderRadius: "10px",
                padding: "10px",
                width: "57%",
              }}
            >
              {data.length > 0 ? (
                <b style={{ fontSize: "25px" }}>{data[0].entregado}</b>
              ) : (
                <b style={{ fontSize: "25px" }}>0</b>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default Home;
