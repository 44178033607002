import moment from "moment";

export interface ISearchMantain {
  fecha: moment.Moment[];
  clave: string;
  idEquipo: string;
}

export class SearchMantainValues implements ISearchMantain {
  fecha = [];
  clave = "";
  idEquipo = "";
  constructor(init?: ISearchMantain) {
    Object.assign(this, init);
  }
}

export interface IImageSend {
  solicitudId: string;
  imagen: File | Blob;
  imagenUrl: string;
  clave: string;
  tipo: "orden" | "ine" | "formato";
  UsuarioId?: string;
}

export interface Idetail {
  clave: string;
  nombre: string;
  serie: string;
  id: string;
}

export interface IMantainmentForm {
  id: string;
  idEquipo: string;
  fecha: moment.Moment;
  descripcion: string;
  clave: string;
  no_serie: string;
  ativo: boolean;
  imagenUrl: IImageSend[];
  ide: number;
}

export class MantainValues implements IMantainmentForm {
  id = "";
  idEquipo = "";
  fecha = moment().utcOffset(0, true);
  descripcion = "";
  clave = "";
  no_serie = "";
  ativo = false;
  imagenUrl = [];
  ide = 0;
  constructor(init?: IMantainmentForm) {
    Object.assign(this, init);
  }
}
export interface IMantainList {
  id: string;
  clave: string;
  fecha: string;
  activo: boolean;
}
