import { Button, Col, Form, InputNumber, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { IProceedingForm } from "../../models/Proceeding";
import { ageDesciptor, calculateBirthdate } from "../../util/utils";
import TextInput from "../form/proposal/TextInput";
import SelectInput from "../form/proposal/SelectInput";
import DateInput from "../form/proposal/DateInput";
import MaskInput from "../form/proposal/MaskInput";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import moment from "moment";
import { toJS } from "mobx";

const { Option } = Select;

type Props = {
  record: IProceedingForm;
  onRecordUpdate: (updated: IProceedingForm) => Promise<void>;
};

const RecordForm = ({ record, onRecordUpdate }: Props) => {
  const { modalStore, procedingStore } = useStore();
  const { closeModal } = modalStore;
  const { updateContact } = procedingStore;

  const [form] = Form.useForm<IProceedingForm>();

  const [loading, setLoading] = useState(false);
  const [ageType, setAgeType] = useState<"days" | "months" | "years">("years");

  useEffect(() => {
    const birthdate = record.fechaNacimiento;
    // prettier-ignore
    const ageDescription = !birthdate ? undefined : ageDesciptor(birthdate as moment.Moment);
    if (ageDescription) {
      form.setFieldValue("edad", ageDescription[0]);
      setAgeType(ageDescription[1]);
    } else {
      form.setFieldValue("edad", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAgeType = (type: "days" | "months" | "years") => {
    let age = form.getFieldValue("edad");
    setAgeType(type);
    if (age || age === 0) {
      // prettier-ignore
      age = type === "days" && age > 30 ? 30 : type === "months" && age > 11 ? 11 : age;
      const birthdate = calculateBirthdate(age, type);
      form.setFieldValue("edad", age);
      form.setFieldValue("fechaNacimiento", birthdate);
    } else {
      form.setFieldValue("fechaNacimiento", undefined);
    }
  };

  const ageTypeSelector = (
    <Select value={ageType} className="select-after" onChange={onChangeAgeType}>
      <Option value="days">días</Option>
      <Option value="months">meses</Option>
      <Option value="years">años</Option>
    </Select>
  );

  const onValuesChange = async (changedValues: any) => {
    const field = Object.keys(changedValues)[0];
    if (field === "edad") {
      const age = changedValues[field];
      if (age || age === 0) {
        const birthdate = calculateBirthdate(age, ageType);
        form.setFieldValue("fechaNacimiento", birthdate);
      } else {
        form.setFieldValue("fechaNacimiento", undefined);
      }
    }
    if (field === "fechaNacimiento") {
      const birthdate = changedValues[field];
      const ageDescription = !birthdate ? undefined : ageDesciptor(birthdate);
      if (ageDescription) {
        form.setFieldValue("edad", ageDescription[0]);
        setAgeType(ageDescription[1]);
      } else {
        form.setFieldValue("edad", undefined);
      }
    }
  };

  const onFinish = async (formValues: IProceedingForm) => {
    const updated = { ...toJS(record), ...formValues };
    const currentDate = moment();
    const ageInYears = currentDate.diff(updated.fechaNacimiento, "years");
    updated.edad = ageInYears;
    // prettier-ignore
    updated.fechaNacimiento = (updated.fechaNacimiento as moment.Moment).utcOffset(0, true);

    setLoading(true);
    const success = await updateContact(updated);
    setLoading(false);

    if (success) {
      onRecordUpdate(updated);
      closeModal();
    }
  };

  return (
    <Spin spinning={loading}>
      <Form<IProceedingForm>
        onFinish={onFinish}
        form={form}
        size="small"
        labelWrap
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={{
          nombre: record.nombre,
          apellido: record.apellido,
          fechaNacimiento: record.fechaNacimiento,
          sexo: record.sexo,
          correo: record.correo,
          celular: record.celular,
          telefono: record.telefono,
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "nombre",
                label: "Nombre(s)",
              }}
              max={500}
              required
            />
          </Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "apellido",
                label: "Apellido(s)",
              }}
              max={500}
              required
            />
          </Col>
          <Col span={12}>
            <DateInput
              formProps={{
                name: "fechaNacimiento",
                label: "Fecha de nacimiento",
              }}
              disableAfterDates={true}
              required
            />
          </Col>
          <Col span={12}>
            <div className="custom-input">
              <Form.Item
                name="edad"
                label="Edad"
                required
                help=""
                className="no-error-text"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "number",
                    min: 0,
                  },
                  {
                    type: "number",
                    max:
                      ageType === "days" ? 30 : ageType === "months" ? 11 : 130,
                  },
                ]}
              >
                <InputNumber<number>
                  style={{ width: "100%" }}
                  precision={0}
                  step={1}
                  min={0}
                  addonAfter={ageTypeSelector}
                  placeholder="Edad"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <SelectInput
              formProps={{
                name: "sexo",
                label: "Sexo",
              }}
              options={[
                { label: "F", value: "F" },
                { label: "M", value: "M" },
              ]}
              required
            />
          </Col>
          <Col span={12}>
            <TextInput
              formProps={{
                name: "correo",
                label: "E-Mail",
              }}
              type="email"
              max={100}
            />
          </Col>
          <Col span={12}>
            <MaskInput
              formProps={{
                name: "celular",
                label: "Celular",
              }}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
              ]}
              validator={(_, value: any) => {
                if (!value || value.indexOf("_") === -1) {
                  return Promise.resolve();
                }
                return Promise.reject("El campo debe contener 10 dígitos");
              }}
            />
          </Col>
          <Col span={12}>
            <MaskInput
              formProps={{
                name: "telefono",
                label: "Teléfono",
              }}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/,
              ]}
              validator={(_, value: any) => {
                if (!value || value.indexOf("_") === -1) {
                  return Promise.resolve();
                }
                return Promise.reject("El campo debe contener 10 dígitos");
              }}
            />
          </Col>
          <Col span={24} style={{ textAlign: "end" }}>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(RecordForm);
