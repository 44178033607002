import { Button, Input, PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import GoBackIcon from "../../../app/common/icons/GoBackIcon";
import PrintIcon from "../../../app/common/icons/PrintIcon";
import { useStore } from "../../../app/stores/store";
import views from "../../../app/util/view";

type ShipmentTrackingProps = {
  handlePrint: () => void;
  handleDownload: () => Promise<void>;
  handleDownloadReport: () => Promise<void>;
};

const HistoryTrackingHeader: FC<ShipmentTrackingProps> = ({
  handlePrint,
  handleDownload,
  handleDownloadReport,
}) => {
  const { shipmentTrackingStore } = useStore();
  const { scopes } = shipmentTrackingStore;

  const navigate = useNavigate();

  const getBack = () => {
    navigate(`/${views.routeTracking}`);
  };

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Historial de seguimiento de entrega" image="entrega" />}
      className="header-container"
      extra={[
        <GoBackIcon key="back" onClick={getBack} />,
        // scopes.descargar && <DownloadIcon key="doc" onClick={handleDownload} />,
        scopes.descargar && (
          <Button
            key="docReport"
            icon={<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCloudArrowDown} />}
            onClick={handleDownload}
          >
            Orden de envío
          </Button>
        ),
        // scopes.descargar && <DownloadIcon key="docReport" onClick={handleDownloadReport} />,
        scopes.descargar && (
          <Button
            key="docReport"
            icon={<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCloudArrowDown} />}
            onClick={handleDownloadReport}
          >
            Rep. Recolección
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default observer(HistoryTrackingHeader);
