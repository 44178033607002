import { useState } from "react";
import IconButton from "../../../app/common/button/IconButton";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import {
  EditOutlined,
  CloseOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { Popconfirm, Space, Table, Tooltip, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import views from "../../../app/util/view";
import { IAppointmentInfo } from "../../../app/models/appointment";
import { status } from "../../../app/util/catalogs";
import { useStore } from "../../../app/stores/store";

const { Link, Text } = Typography;

type Props = {
  type: "L" | "D" | undefined;
  nextStatusId?: number;
  appointments: IAppointmentInfo[];
  setDraggingAppointment?: React.Dispatch<
    React.SetStateAction<IAppointmentInfo | undefined>
  >;
};

const AppointmentTable = ({
  type,
  nextStatusId,
  appointments,
  setDraggingAppointment,
}: Props) => {
  const { appointmentStore } = useStore();
  const { scopes, updateStatus, convertToRequest, cancelAppointment } =
    appointmentStore;

  const navigate = useNavigate();

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const convert = async (appointment: IAppointmentInfo) => {
    const requestId = await convertToRequest(appointment.citaId);
    if (requestId)
      navigate(`/${views.request}/${appointment.expedienteId}/${requestId}`);
  };

  const getNextStatusMessage = () => {
    let next = "";

    switch (nextStatusId) {
      case status.appointment.agendado:
        next = "Agendada";
        break;
      case status.appointment.listaScan:
        next = "Lista para scan";
        break;
      case status.appointment.terminada:
        next = "Terminada";
        break;
      default:
        break;
    }

    return `¿Desea actualizar la cita al estatus ${next}?`;
  };

  const columns: IColumns<IAppointmentInfo> = [
    {
      ...getDefaultColumnProps("clave", "Cita", {
        searchState,
        setSearchState,
        width: type === "L" ? "22%" : "15%",
      }),
      render: (value, item) => (
        <Link
          draggable={
            scopes.modificar && item.estatusId === status.appointment.generado
          }
          onDragStart={() => {
            if (
              scopes.modificar &&
              item.estatusId === status.appointment.generado
            ) {
              setDraggingAppointment!(item);
            }
          }}
          onClick={() => {
            navigate(`/${views.appointment}/${item.citaId}`);
          }}
        >
          {value}
        </Link>
      ),
    },
    {
      ...getDefaultColumnProps("expediente", "Expediente", {
        searchState,
        setSearchState,
        width: type === "L" ? "22%" : "15%",
      }),
      className: "no-padding-cell",
      render: (_value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text>{item.paciente}</Text>
          <small>
            <Text type="secondary">{item.expediente}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha de alta", {
        searchState,
        setSearchState,
        width: type === "L" ? "22%" : "15%",
      }),
    },
    {
      ...getDefaultColumnProps("direccion", "Dirección", {
        searchState,
        setSearchState,
        width: type === "L" ? "22%" : "15%",
      }),
      render: (value) => (
        <Tooltip title={value}>
          <Text style={{ maxWidth: 180 }} ellipsis={true}>
            {value}
          </Text>
        </Tooltip>
      ),
    },
    {
      ...getDefaultColumnProps("contacto", "Contacto", {
        searchState,
        setSearchState,
        width: type === "L" ? "22%" : "15%",
      }),
      className: "no-padding-cell",
      render: (_value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text>{item.correo?.split(",")?.join("\n")}</Text>
          <small>
            <Text type="secondary">{item.whatsapp}</Text>
          </small>
        </div>
      ),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Editar",
      align: "center",
      width: "12%",
      render: (value, item) => {
        const canUpdate =
          item.estatusId !== status.appointment.terminada &&
          item.estatusId !== status.appointment.convertida &&
          item.estatusId !== status.appointment.cancelado &&
          scopes.modificar;
        const canConvert =
          item.estatusId === status.appointment.terminada &&
          item.expedienteId &&
          scopes.modificar;

        return (
          <Space>
            {canUpdate && (
              <Popconfirm
                title={getNextStatusMessage()}
                okText="Sí"
                cancelText="No"
                trigger="click"
                placement="left"
                onConfirm={() => {
                  const app = { ...item };
                  app.estatusId = nextStatusId!;
                  return updateStatus(app);
                }}
              >
                <IconButton icon={<ArrowUpOutlined />} type="primary" />
              </Popconfirm>
            )}
            {canConvert && (
              <Popconfirm
                title="¿Desea convertir la cita a solicitud?"
                okText="Sí"
                cancelText="No"
                trigger="click"
                placement="left"
                onConfirm={() => convert(item)}
              >
                <IconButton icon={<ArrowUpOutlined />} type="primary" />
              </Popconfirm>
            )}
            <IconButton
              title="Editar cita"
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/${views.appointment}/${item.citaId}`);
              }}
            />
            {canUpdate && (
              <Popconfirm
                title="¿Desea cancelar la cita?"
                okText="Sí"
                cancelText="No"
                trigger="click"
                placement="left"
                onConfirm={() => cancelAppointment(item.citaId)}
              >
                <IconButton icon={<CloseOutlined />} danger />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table<IAppointmentInfo>
      size="small"
      rowKey={(record) => record.citaId}
      columns={columns.filter((x) =>
        type === "L" ? x.key !== "direccion" : true
      )}
      dataSource={[...appointments]}
      pagination={false}
      scroll={{ x: "max-content", y: 210 }}
    />
  );
};
export default observer(AppointmentTable);
