import { IOptions } from "../../../../app/models/shared";

export const productServiceOptions: IOptions[] = [
  {
    label: "85121800 - Laboratorios médicos (Servicios de análisis clínicos)",
    value: "85121800 - Laboratorios médicos (Servicios de análisis clínicos)",
  },
  {
    label: "85121811 - Servicios de laboratorios de detección del COVID",
    value: "85121811 - Servicios de laboratorios de detección del COVID",
  },
];

export const defaultConceptOptions: IOptions[] = [
  {
    key: "branch",
    label: "Sucursal",
    value: "ESTUDIOS DE LABORATORIO REALIZADOS EN SUCURSAL [branch]",
  },
  {
    key: "patient",
    label: "Paciente",
    value: "PACIENTE: [patient]",
  },
  {
    key: "cop",
    label: "Copago",
    value: "COPAGO TOTAL [total]",
  },
  {
    key: "simple",
    label: "Consulta",
    value: "CONSULTA MEDICA",
  },
];

export const cancelationReasonOptions: IOptions[] = [
  {
    label: "01 Comprobantes emitidos con errores con relación.",
    value: "01",
  },
  {
    label: "02 Comprobantes emitidos con errores sin relación",
    value: "02",
  },
  {
    label: "03 No se llevó a cabo la operación.",
    value: "03",
  },
  {
    label: "04 Operación nominativa relacionada en una factura global.",
    value: "04",
  },
];
