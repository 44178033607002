import { IPriceListStudy } from "../models/priceList";
import { IStudyDatasheet, IStudyForm, IStudyList, IStudySelector } from "../models/study";
import requests from "./agent";

const Study = {
  getAll: (search: string): Promise<IStudyList[]> =>
    requests.get(`study/all/${!search ? "all" : search}`),
  getAllPrice: (search: string): Promise<IPriceListStudy[]> =>
    requests.get(`study/studyList/${!search ? "all" : search}`),
  getActive: (): Promise<IStudyList[]> => requests.get(`study/active`),
  getById: (id: number): Promise<IStudyForm> => requests.get(`study/${id}`),
  getIsStudyOrPack: (code: string): Promise<IStudySelector> =>
    requests.get(`study/study-pack/${code}`),
  getStudyInfoById: (id: number): Promise<IStudyDatasheet> => requests.get(`study/tecinfo/${id}`),
  create: (study: IStudyForm): Promise<boolean> => requests.post("/study", study),
  update: (study: IStudyForm): Promise<boolean> => requests.put("/study", study),
  exportList: (search: string): Promise<void> =>
    requests.download(`study/export/list/${!search ? "all" : search}`),
  exportForm: (id: number, clave?: string): Promise<void> =>
    requests.download(`study/export/form/${id}`),
};

export default Study;
