import { makeAutoObservable } from "mobx";

import InvoiceCredit from "../api/invoiceCredit";
import {
  IInvoiceCredit,
  IInvoiceCreditCancel,
  IInvoiceCreditFilter,
  InvoiceCreditFilterValues,
} from "../models/invoiceCredit";
import alerts from "../util/alerts";
import messages from "../util/messages";
import { getErrors } from "../util/utils";
import { IScopes, Scopes } from "../models/shared";

export default class InvoiceCreditStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  loading: boolean = false;
  filter: IInvoiceCreditFilter = new InvoiceCreditFilterValues();
  invoices: IInvoiceCredit[] = [];

  setFilter = (filter: IInvoiceCreditFilter) => {
    this.filter = filter;
  };

  setInvoices = (invoices: IInvoiceCredit[]) => {
    this.invoices = invoices;
  };

  getAll = async (filter: IInvoiceCreditFilter) => {
    try {
      const invoices = await InvoiceCredit.getAll(filter);
      this.setInvoices(invoices);
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.setInvoices([]);
    }
  };

  create = async (invoice: FormData) => {
    try {
      await InvoiceCredit.create(invoice);
      await this.getAll(this.filter);
      alerts.success(messages.created);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  cancel = async (invoice: IInvoiceCreditCancel) => {
    try {
      await InvoiceCredit.cancel(invoice);
      alerts.success("El pago se canceló correctamente");
      await this.getAll(this.filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  downloadPdf = async (complementId: string) => {
    try {
      await InvoiceCredit.downloadPdf(complementId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  downloadXml = async (complementId: string) => {
    try {
      await InvoiceCredit.downloadXml(complementId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  downloadList = async (filter: IInvoiceCreditFilter) => {
    try {
      await InvoiceCredit.downloadList(filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
