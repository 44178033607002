import { Button, PageHeader, Tooltip, Typography } from "antd";
import { color } from "echarts";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import views from "../../../app/util/view";
import RequestWee from "./RequestWee";

const { Text } = Typography;

const RequestHeader = () => {
  const { requestStore, modalStore } = useStore();
  const { requests, scopes } = requestStore;
  const { openModal } = modalStore;

  const navigate = useNavigate();

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Estatus de solicitudes" />}
      className="header-container"
      extra={[
        <Text key="request">
          Total solicitudes: <Text strong>{requests.length}</Text>
        </Text>,

        <Text key="request-active">
          Vigentes:{" "}
          <Text strong>
            {
              requests.filter((x) => x.estatusId === status.request.vigente)
                .length
            }
          </Text>
        </Text>,
        <Text key="request-active-studies">
          Estudios:{" "}
          <Text strong>
            {
              requests
                .filter((x) => x.estatusId === status.request.vigente)
                .flatMap((x) => x.estudios).length
            }
          </Text>
        </Text>,

        <Text key="request-cancelled">
          Canceladas:{" "}
          <Text strong>
            {
              requests.filter((x) => x.estatusId === status.request.cancelado)
                .length
            }
          </Text>
        </Text>,
        <Tooltip
          key="request-cancelled-studies-info"
          title="Estudios de Sol. Canceladas"
          color={"blue"}
        >
          <Text key="request-cancelled-studies">
            Estudios C:{" "}
            <Text strong>
              {
                requests
                  .filter((x) => x.estatusId === status.request.cancelado)
                  .flatMap((x) => x.estudios).length
              }
            </Text>
          </Text>
        </Tooltip>,
        scopes.crear && (
          <Button
            key="new"
            type="primary"
            onClick={() => {
              navigate(`/${views.proceeding}`);
            }}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        ),
        scopes.crear && (
          <Button
            key="wee"
            onClick={() => {
              openModal({
                title: "Crear solicitud WeeClinic",
                body: <RequestWee />,
                width: 900,
              });
            }}
            icon={<PlusOutlined />}
          >
            Nuevo WeeClinic
          </Button>
        ),
      ]}
    ></PageHeader>
  );
};

export default observer(RequestHeader);
