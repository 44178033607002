import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import UserHeader from "../components/user/UserHeader";
import UserTable from "../components/user/UserTable";
import alerts from "../app/util/alerts";
import messages from "../app/util/messages";

const User = () => {
  const { userStore } = useStore();
  const { exportList, scopes } = userStore;
  const [printing, setPrinting] = useState(false);
  const [searchParams] = useSearchParams();

  const handleDownload = async () => {
    setPrinting(true);

    if (!scopes.descargar) {
      alerts.warning(messages.forbidden);
      setPrinting(false);
      return;
    }

    await exportList(searchParams.get("search") ?? "all");
    setPrinting(false);
  };

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <div ref={componentRef}>
      <UserHeader
        handlePrint={handlePrint}
        handleList={handleDownload}
        scopes={scopes}
      />
      <Divider className="header-divider" />
      <UserTable printing={printing} />
    </div>
  );
};

export default observer(User);
