import { Divider, Spin } from "antd";
import { observer } from "mobx-react-lite";
import InvoiceFreeHeader from "../components/invoice/InvoiceFree/InvoiceFreeHeader";
import InvoiceFreeFilter from "../components/invoice/InvoiceFree/InvoiceFreeFilter";
import InvoiceFreeTable from "../components/invoice/InvoiceFree/InvoiceFreeTable";
import { useState } from "react";
import { useStore } from "../app/stores/store";
import { Navigate } from "react-router-dom";

const InvoiceFree = () => {
  const { invoiceFreeStore } = useStore();
  const { scopes } = invoiceFreeStore;
  const [loading, setLoading] = useState(false);

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Spin spinning={loading}>
      <InvoiceFreeHeader setLoading={setLoading} />
      <Divider className="header-divider" />
      <InvoiceFreeFilter setLoading={setLoading} />
      <InvoiceFreeTable />
    </Spin>
  );
};

export default observer(InvoiceFree);
