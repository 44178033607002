import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../app/stores/store";
import RouteTrackingHeader from "../components/routeTracking/RouteHeader";
import RouteTrackingTable from "../components/routeTracking/RouteTrackingTable";
import { Navigate } from "react-router-dom";

const RouteTracking = () => {
  const { routeTrackingStore } = useStore();
  const { scopes } = routeTrackingStore;

  const [loading, setLoading] = useState(false);

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleDownload = async () => {
    setLoading(true);
    // await exportFormPending(searchPending!);
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <Fragment>
      <RouteTrackingHeader
        handlePrint={handlePrint}
        handleDownload={handleDownload}
      />
      <Divider className="header-divider" />
      <RouteTrackingTable printing={loading} />
    </Fragment>
  );
};

export default observer(RouteTracking);
