import { Table, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { EyeOutlined } from "@ant-design/icons";

import PrintIcon from "../../../app/common/icons/PrintIcon";
import LinkTo from "../../../app/common/link/LinkTo";
import { getDefaultColumnProps, IColumns, ISearch } from "../../../app/common/table/utils";
import { IClinicResultList, IClinicStudy } from "../../../app/models/clinicResults";
import { IScopes } from "../../../app/models/shared";

const { Link, Text } = Typography;

type tableProps = {
  recordScopes: IScopes;
  scopes: IScopes;
  printOrder: (recordId: string, requestId: string) => Promise<void>;
};

const ClinicResultsColumns = ({ recordScopes, scopes, printOrder }: tableProps) => {
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const navigate = useNavigate();

  const columns: IColumns<IClinicResultList> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link
            onClick={() => {
              navigate(`/clinicResultsDetails/${item.expedienteId}/${item.id}`);
            }}
          >
            {value}
          </Link>
          <small>
            <Text type="secondary">{item.clavePatologica}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre del Paciente", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "bolder", marginBottom: -5 }}>{value}</Text>
          {item.sucursal} {item.edad} {item.sexo}
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("expediente", "Expediente", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value, item) => (
        <LinkTo
          to={`/expedientes/${item.expedienteId!}?&mode=readonly`}
          text={value}
          canRedirect={recordScopes.acceder}
        />
      ),
    },
    {
      ...getDefaultColumnProps("registro", "Fecha Alta Solicitud", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },

    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      key: "observacion",
      dataIndex: "observacion",
      title: "Observación",
      align: "center",
      width: "15%",
      render: (_value, record) => {
        return (
          <>
            {record.observacion != null ? (
              <Tooltip title={record.observacion} color="#108ee9">
                <EyeOutlined style={{ cursor: "pointer" }} />
              </Tooltip>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      key: "imprimir",
      dataIndex: "imprimir",
      title: "Orden",
      align: "center",
      width: "5%",
      render: (_value, record) => {
        return (
          recordScopes.imprimir &&
          scopes.imprimir && (
            <PrintIcon
              key="imprimir"
              onClick={() => {
                printOrder(record.expedienteId!, record.id);
              }}
            />
          )
        );
      },
    },
  ];
  return columns;
};

export const ClinicResultsExpandable = () => {
  const nestedColumns: IColumns<IClinicStudy> = [
    {
      ...getDefaultColumnProps("clave", "Estudio", {
        width: 400,
      }),
      render: (_value, record) => record.clave + " - " + record.nombre,
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("nombreEstatus", "Estatus", {
        width: 150,
      }),
      render: (_value, record) => record.nombreEstatus!,
    },
    {
      ...getDefaultColumnProps("fechaActualizacion", "Fecha de Actualización", {
        width: 300,
      }),
      render: (_value, record) =>
        record.fechaActualizacion == null
          ? " - "
          : record.fechaActualizacion + " - " + (record.claveUsuarioActualizacion ?? "") + " (Actualización)",
    },
    {
      ...getDefaultColumnProps("entrega", "Fecha de Entrega", {
        width: 150,
      }),
      render: (_value, record) => (
        <Typography>
          <Text style={record.urgencia! > 1 ? { color: "red" } : {}}>
            {record.entrega} (Entrega)
          </Text>
        </Typography>
      ),
    },
  ];

  return {
    expandedRowRender: (item: IClinicResultList, index: any) => (
      <Table
        className="no-padding-table header-expandable-table"
        rowKey={(x) => x.solicitudEstudioId!}
        columns={nestedColumns}
        dataSource={item.estudios}
        pagination={false}
        showHeader={false}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

export default ClinicResultsColumns;
