import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { ItipoValorForm } from "../../../../app/models/parameter";
import RangoEdad from "./RangoEdad";

type Props = {
  valueType: string;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
};

const RangoNumericoXEdad: FC<Props> = ({
  valueType,
  parameterValues,
  setParameterValues,
}) => {
  return (
    <div>
      <Divider orientation="left"></Divider>
      <RangoEdad
        valueType={valueType}
        description="Valores de referencia (Numérico por edad):"
        parameterValues={parameterValues}
        setParameterValues={setParameterValues}
      ></RangoEdad>
    </div>
  );
};
export default observer(RangoNumericoXEdad);
