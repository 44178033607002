import { Button, Col, PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";

import SelectInput from "../../../app/common/form/proposal/SelectInput";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DownloadIcon from "../../../app/common/icons/DownloadIcon";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";

type InvoiceCompanyHeaderProps = {
  handleDownload: () => void;
};

type UrlParams = {
  id: string;
  tipo: string;
};

const InvoiceCompanyHeader: FC<InvoiceCompanyHeaderProps> = ({
  handleDownload,
}) => {
  let navigate = useNavigate();
  const { invoiceCompanyStoreLegacy: invoiceCompanyStore, profileStore } =
    useStore();
  const { profile } = profileStore;
  const {
    selectedRows,
    isSameCommpany: mismaCompania,
    setInvoice,
    printReceipt,
    invoice,
    selectedRequestGlobal,
    createInvoiceGlobal,
    getInvoicesCompany,
    formValues,
    scopes,
  } = invoiceCompanyStore;

  let { id, tipo } = useParams<UrlParams>();

  const invoiceOptions = [
    { label: "FACTURA", value: "Factura" },
    { label: "RECIBO", value: "Recibo" },
  ];
  const [tipoFactura, setTipoFactura] = useState<string>();

  const createInvoice = async () => {
    if (!selectedRows.length) {
      alerts.warning("No solicitudes seleccionadas");
      return;
    }
    if (!mismaCompania) {
      alerts.warning(
        "Las solicitudes seleccionadas no tienen la misma procedencia"
      );
      return;
    }

    let requestsWithInvoiceCompany: any[] = [];
    selectedRows.forEach((request) => {
      if (
        request.facturas.some(
          (invoice: any) =>
            invoice.tipo === tipo && invoice.estatus.nombre !== "Cancelado"
        )
      ) {
        requestsWithInvoiceCompany.push(request);
      }
    });

    if (!!requestsWithInvoiceCompany.length && tipoFactura === "Factura") {
      // if (false) {
      alerts.confirmInfo(
        "Solicitudes facturadas",
        <>
          <Col>
            <div>
              Alguna de las solicitudes seleccionadas ya se encuentran
              procesadas en una factura:
            </div>
            {requestsWithInvoiceCompany.map((request) => {
              return (
                <div>
                  {request?.clave} -{" "}
                  {
                    request?.facturas.find(
                      (invoice: any) => invoice.tipo === tipo
                    )?.serie
                  }
                  {"-"}
                  {
                    request?.facturas.find(
                      (invoice: any) => invoice.tipo === tipo
                    )?.consecutivo
                  }
                </div>
              );
            })}
          </Col>
        </>,
        async () => {}
      );
    }

    if (!requestsWithInvoiceCompany.length || tipoFactura === "Recibo") {
      // if (true) {
      if (tipoFactura === "Factura") {
        if (tipo === "company") {
          setInvoice(null);
          navigate(`/invoice/company/new`);
        }
        if (tipo === "request") {
          setInvoice(null);
          navigate(`/invoice/request/new`);
        }
      } else {
        let solicitudesId = selectedRows.map((row) => row.solicitudId);
        let receiptCompanyData = {
          sucursal: "MONTERREY", // "SUCURSAL MONTERREY"
          folio: "",
          atiende: "",
          usuario: "",
          Contraseña: "",
          ContactoTelefono: "",
          SolicitudesId: solicitudesId,
          tipo: tipo,
        };
        printReceipt(receiptCompanyData);
      }
    }
  };

  return (
    <>
      <PageHeader
        ghost
        title={
          <HeaderTitle
            title={
              tipo === "company"
                ? "Crédito y cobranza (Facturación por compañía)"
                : tipo === "request"
                ? "Crédito y cobranza (Facturación por solicitud)"
                : tipo === "free"
                ? "Crédito y cobranza (Facturación libre)"
                : tipo === "global"
                ? "Crédito y cobranza (Facturación global)"
                : ""
            }
            image="invoice-company"
          />
        }
        onBack={() => {
          if (invoice?.origenFactura === "free") {
            navigate(`/invoice/free`);
            return;
          }
          navigate(`/invoice/${tipo}`);
          // if (tipo !== "company") {
          // }
          // if (tipo === "request") {
          //   navigate(`/invoice/request`);
          // }
          // if (tipo === "free") {
          //   navigate(`/invoice/free`);
          // }
          // if (tipo === "global") {
          //   navigate(`/invoice/global`);
          // }
        }}
        className="header-container"
        extra={
          id
            ? [
                scopes.descargar && (
                  <DownloadIcon key="doc" onClick={handleDownload} />
                ),
              ]
            : [
                scopes.descargar && (
                  <DownloadIcon key="doc" onClick={handleDownload} />
                ),
                tipo === "free" || tipo === "global" ? (
                  ""
                ) : (
                  <SelectInput
                    formProps={{
                      name: "generar",
                      label: "",
                    }}
                    placeholder="Tipo factura"
                    options={invoiceOptions}
                    onChange={setTipoFactura}
                  />
                ),
                scopes.crear && (
                  <Button
                    key="new"
                    type="primary"
                    onClick={async () => {
                      if (tipo === "global") {
                        await createInvoiceGlobal(profile?.sucursal!);
                        getInvoicesCompany(formValues);
                        return;
                      }
                      if (tipo === "free") {
                        navigate(`/invoice/${tipo}/new`);
                      } else {
                        createInvoice();
                      }
                    }}
                    icon={<PlusOutlined />}
                  >
                    Generar
                  </Button>
                ),
              ]
        }
      ></PageHeader>
    </>
  );
};
export default observer(InvoiceCompanyHeader);
