import { IGeneralForm } from "../models/general";
import requests from "./agent";

const DeliveryResults = {
  getAllCaptureResults: (search: IGeneralForm): Promise<any[]> =>
    requests.post("deliveryResults/GetAllCaptureResults", search),
  sendResultFile: (listResults: any): Promise<boolean> =>
    requests.put(`deliveryResults/sendResultFile`, listResults),
  rollBackSentStatus: (studiesId: number[]): Promise<void> =>
    requests.put("deliveryResults", studiesId),
  printResultFile: (listResults: any): Promise<boolean> =>
    requests.post(`deliveryResults/printResultFile`, listResults),
  printResultFilePreview: (
    listResults: any,
    milliseconds: number | null = null
  ): Promise<string> =>
    requests.getFileUrl(
      `deliveryResults/printResultFilePreview`,
      "application/pdf",
      listResults
    ),
  exportListDeliverResult: (search: any): Promise<void> =>
    requests.download("deliveryResults/list", search),
  getOrderPdf: (recordId: string, requestId: string): Promise<void> =>
    requests.print(`deliveryResults/order/${recordId}/${requestId}`),
};

export default DeliveryResults;
