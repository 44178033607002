import { Empty, Comment, Tooltip, Alert, Spin } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Fragment, useState } from "react";
import { INotification } from "../models/shared";
import { useStore } from "../stores/store";
import { v4 as uuid } from "uuid";

const Notifications = () => {
  const { notificationStore } = useStore();
  const { notifications, markAsSeen } = notificationStore;

  const [loading, setLoading] = useState(false);

  const onClose = async (notification: INotification) => {
    setLoading(true);
    await markAsSeen(notification);
    setLoading(false);
  };

  return (
    <Fragment>
      {notifications.length === 0 ? (
        <Empty />
      ) : (
        <Spin spinning={loading}>
          {notifications.map((notification: INotification) => (
            <div key={uuid()}>
              <Comment
                className="notification-comment"
                content={
                  <Alert
                    description={notification.mensaje}
                    closable
                    onClose={() => onClose(notification)}
                  />
                }
                datetime={
                  <Tooltip
                    title={moment(notification.fecha).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  >
                    <span>{moment(notification.fecha).fromNow()}</span>
                  </Tooltip>
                }
              />
            </div>
          ))}
        </Spin>
      )}
    </Fragment>
  );
};

export default observer(Notifications);
