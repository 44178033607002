import { FC, Fragment } from "react";

import { ItipoValorForm } from "../../../../app/models/parameter";
import NumericoColumna from "./NumericoColumna";
import RangoEdadXSexo from "./NumericoXSexo";
import OpcionMultiple from "./OpcionMultiple";
import RangoNumericoXEdad from "./RangoNumericoXEdad";
import RangoNumericoXEdadSexo from "./RangoNumericoXEdadSexo";
import RangoObservacion from "./RangoObservacion";
import ReferenciaParrafo from "./ReferenciaParrafo";
import ReferenciaTexto from "./ReferenciaTexto";
import ValorRNumerico from "./ValorRNumerico";

interface ValorProps {
  value: number;
  parameterValues: ItipoValorForm[];
  setParameterValues: (values: ItipoValorForm[]) => void;
}

const ValorType: FC<ValorProps> = ({
  value,
  parameterValues,
  setParameterValues,
}) => {
  function LayoutContendio(value: number) {
    switch (value) {
      case 1:
        return (
          <ValorRNumerico
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></ValorRNumerico>
        );

      case 2:
        return (
          <RangoEdadXSexo
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></RangoEdadXSexo>
        );

      case 3:
        return (
          <RangoNumericoXEdad
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></RangoNumericoXEdad>
        );

      case 4:
        return (
          <RangoNumericoXEdadSexo
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></RangoNumericoXEdadSexo>
        );

      case 5:
        return (
          <OpcionMultiple
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></OpcionMultiple>
        );

      case 6:
      case 11:
      case 12:
      case 13:
      case 14:
        return (
          <NumericoColumna
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></NumericoColumna>
        );

      case 7:
        return (
          <ReferenciaTexto
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></ReferenciaTexto>
        );

      case 8:
        return (
          <ReferenciaParrafo
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></ReferenciaParrafo>
        );

      case 10:
        return (
          <RangoObservacion
            valueType={value.toString()}
            parameterValues={parameterValues}
            setParameterValues={setParameterValues}
          ></RangoObservacion>
        );

      default:
        return;
    }
  }
  return <Fragment>{LayoutContendio(value)}</Fragment>;
};
export default ValorType;
